import {
  PRODUCT_LIST, PRODUCT_LIST_SUCCESS, PRODUCT_LIST_FAILURE,
  PRODUCT_DETAILS, PRODUCT_DETAILS_SUCCESS, PRODUCT_DETAILS_FAILURE,
  PRODUCT_ADD, PRODUCT_ADD_SUCCESS, PRODUCT_ADD_FAILURE,
  PRODUCT_UPDATE, PRODUCT_UPDATE_SUCCESS, PRODUCT_UPDATE_FAILURE,
  PRODUCT_REMOVE, PRODUCT_REMOVE_SUCCESS, PRODUCT_REMOVE_FAILURE,

  VARIANT_LIST, VARIANT_LIST_SUCCESS, VARIANT_LIST_FAILURE,
  VARIANT_DETAILS, VARIANT_DETAILS_SUCCESS, VARIANT_DETAILS_FAILURE,
  VARIANT_ADD, VARIANT_ADD_SUCCESS, VARIANT_ADD_FAILURE,
  VARIANT_UPDATE, VARIANT_UPDATE_SUCCESS, VARIANT_UPDATE_FAILURE,
  VARIANT_REMOVE, VARIANT_REMOVE_SUCCESS, VARIANT_REMOVE_FAILURE,
  
  BRAND_LIST, BRAND_LIST_SUCCESS, BRAND_LIST_FAILURE,
  CLEAR_PRODUCT_DETAILS, CLEAR_VARIANT_DETAILS,
  RESET_PRODUCT_FLAG, RESET_VARIANT_FLAG,
} from "./actionTypes";

export const getProductsList = () => {
  return {
    type: PRODUCT_LIST,
  };
};

export const getProductsSuccess = (response) => {
  return {
    type: PRODUCT_LIST_SUCCESS,
    payload: response,
  };
};

export const getProductsFailure = (error) => {
  return {
    type: PRODUCT_LIST_FAILURE,
    payload: error,
  };
};

export const getProductDetails = (groupID) => {
  return {
    type: PRODUCT_DETAILS,
    payload: { groupID },
  };
};

export const getProductDetailsSuccess = (response) => {
  return {
    type: PRODUCT_DETAILS_SUCCESS,
    payload: response,
  };
};

export const getProductDetailsFailure = (error) => {
  return {
    type: PRODUCT_DETAILS_FAILURE,
    payload: error,
  };
};

export const addProduct = (data) => {
  return {
    type: PRODUCT_ADD,
    payload: data,
  };
};

export const addProductSuccess = (response) => {
  return {
    type: PRODUCT_ADD_SUCCESS,
    payload: response,
  };
};

export const addProductFailure = (error) => {
  return {
    type: PRODUCT_ADD_FAILURE,
    payload: error,
  };
};

export const updateProduct = (productID, data) => {
  return {
    type: PRODUCT_UPDATE,
    payload: {productID, data},
  };
};

export const updateProductSuccess = (response) => {
  return {
    type: PRODUCT_UPDATE_SUCCESS,
    payload: response,
  };
};

export const updateProductFailure = (error) => {
  return {
    type: PRODUCT_UPDATE_FAILURE,
    payload: error,
  };
};

export const removeProduct = (productID) => {
  return {
    type: PRODUCT_REMOVE,
    payload: productID,
  };
};

export const removeProductSuccess = (response) => {
  return {
    type: PRODUCT_REMOVE_SUCCESS,
    payload: response,
  };
};

export const removeProductFailure = (error) => {
  return {
    type: PRODUCT_REMOVE_FAILURE,
    payload: error,
  };
};

export const getVariants = (productID) => {
  return {
    type: VARIANT_LIST,
    payload: { productID },
  };
};

export const getVariantsSuccess = (response) => {
  return {
    type: VARIANT_LIST_SUCCESS,
    payload: response,
  };
};

export const getVariantsFailure = (error) => {
  return {
    type: VARIANT_LIST_FAILURE,
    payload: error,
  };
};

export const getVariantDetails = (productID, variantID) => {
  return {
    type: VARIANT_DETAILS,
    payload: { productID, variantID },
  };
};

export const getVariantDetailsSuccess = (response) => {
  return {
    type: VARIANT_DETAILS_SUCCESS,
    payload: response,
  };
};

export const getVariantDetailsFailure = (error) => {
  return {
    type: VARIANT_DETAILS_FAILURE,
    payload: error,
  };
};

export const addVariant = (productID, data) => {
  return {
    type: VARIANT_ADD,
    payload: {productID, data},
  };
};

export const addVariantSuccess = (response) => {
  return {
    type: VARIANT_ADD_SUCCESS,
    payload: response,
  };
};

export const addVariantFailure = (error) => {
  return {
    type: VARIANT_ADD_FAILURE,
    payload: error,
  };
};

export const updateVariant = (productID, variantID, data) => {
  return {
    type: VARIANT_UPDATE,
    payload: {productID, variantID, data},
  };
};

export const updateVariantSuccess = (response) => {
  return {
    type: VARIANT_UPDATE_SUCCESS,
    payload: response,
  };
};

export const updateVariantFailure = (error) => {
  return {
    type: VARIANT_UPDATE_FAILURE,
    payload: error,
  };
};

export const removeVariant = (productID, variantID) => {
  return {
    type: VARIANT_REMOVE,
    payload: {productID, variantID},
  };
};

export const removeVariantSuccess = (response) => {
  return {
    type: VARIANT_REMOVE_SUCCESS,
    payload: response,
  };
};

export const removeVariantFailure = (error) => {
  return {
    type: VARIANT_REMOVE_FAILURE,
    payload: error,
  };
};

export const getBrands = () => {
  return {
    type: BRAND_LIST,
  };
};

export const getBrandsSuccess = (response) => {
  return {
    type: BRAND_LIST_SUCCESS,
    payload: response,
  };
};

export const getBrandsFailure = (error) => {
  return {
    type: BRAND_LIST_FAILURE,
    payload: error,
  };
};

export const resetProductFlag = () => {
  return {
    type: RESET_PRODUCT_FLAG,
  };
};

export const resetVariantFlag = () => {
  return {
    type: RESET_VARIANT_FLAG,
  };
};

export const clearProductDetails = () => {
  return {
    type : CLEAR_PRODUCT_DETAILS
  }
}

export const clearVariantDetails = () => {
  return {
    type : CLEAR_VARIANT_DETAILS
  }
}
