import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Account Redux states
import { VERIFY_RESET_TOKEN } from "./actionTypes";
import { verifyResetTokenFailed, verifyResetTokenSuccess } from "./actions";

// Include Both Helper File with needed methods
import { verifyResetToken } from "../../../helpers/backend_helper";

// Is user register successfull then direct plot user in redux.
function* verifyResetPasswordToken({ payload: key }) {
  try {
    const response = yield call(verifyResetToken, key);
    yield put(verifyResetTokenSuccess(response));
  } catch (error) {
    yield put(verifyResetTokenFailed(error));
  }
}

export function* watchUserRegister() {
  yield takeEvery(VERIFY_RESET_TOKEN, verifyResetPasswordToken);
}

function* ResetTokenSaga() {
  yield all([fork(watchUserRegister)]);
}

export default ResetTokenSaga;
