// Public folder
export const PUBLIC_URL = process.env.PUBLIC_URL
export const pImage = (path) => {
  return PUBLIC_URL + '/' + path
}

export const convertToPlainText = (html) => {
  // Create a new div element
  var tempDivElement = document.createElement("div")

  // Set the HTML content with the given value
  tempDivElement.innerHTML = html

  // Retrieve the text property of the element
  return tempDivElement.textContent || tempDivElement.innerText || ""
}

export const capitalizeFirstChar = (str) => {
  if (str == null || str == '') return ''
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getById = (id, values) => {
  try {
    return values.filter(value => value.id == id)[0]
  } catch {
    return null
  }
}

export const calculatePercentValue = (value, totalValue) => {
  if (totalValue === 0) return 0;
  return +((value / totalValue) * 100).toFixed();
};
