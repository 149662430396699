import React, { useState, useMemo } from "react";
import { Col, Row, Table } from "reactstrap";

// common-helpers
import PageHeader from "../../../Components/Common/PageHeader";
import Loader from "../../../Components/Common/Loader";
import CurrencyFormatBox from "../../../Components/Common/CurrencyFormatBox";
import Paginatior from "../../../Components/Common/Paginator";
import { convertToDateMonthYearFormat } from "../../../helpers/time_helper";
import ErrorController from "../../../Components/Common/ErrorController";
import TimeInterval from "../../../Components/Hooks/UseIntervalHook";
// redux helper and actions
import { useSelector } from "react-redux";

let PageSize = 5;
const TopShades = () => {
  const { topShades } = useSelector((state) => ({
    topShades: state.Report.topShades,
  }));

  const fetchingTopShades = topShades.loading;

  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return topShades.data.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, topShades.data]);

  return (
    <Col className="product-list scroll-container main-container reporting-container">
      <div className="page-header">
        <PageHeader title="Top Shades" />
        <span>
          <TimeInterval prefix="Last updated" />
        </span>
      </div>

      {fetchingTopShades ? <Loader /> : null}
      {!fetchingTopShades &&
      currentTableData.length &&
      !topShades.errorMessage ? (
        <Row>
          <Col className="product-list">
            <Table className="align-middle table-nowrap mb-0">
              <tbody>
                {currentTableData.map((product, idx) => (
                  <tr className="product-item" key={idx}>
                    <td className="product-item-content">
                      <div className="title">{product.shade}</div>
                      <div className="sub-title">{product.complexion}</div>
                    </td>

                    <td className="product-item-content">
                      <div className="unit-value">
                        <CurrencyFormatBox number={product.purchases} />
                        <div className="sub-title">Units Sold</div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
          {currentTableData.length > 10 ? (
            <Paginatior
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={topShades.data.length}
              pageSize={PageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          ) : null}
        </Row>
      ) : topShades.errorMessage ? (
        <ErrorController messsage={topShades.errorMessage} />
      ) : !currentTableData.length && !fetchingTopShades ? (
        <span>No data found.</span>
      ) : null}
    </Col>
  );
};

export default TopShades;
