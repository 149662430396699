import React from "react";
import CommonCard from "./Common/CommonCard";
import NumberBox from "../../Components/Common/NumberBox";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getInStoreEngagement } from "../../store/dashboard/actions";
import Loader from "../../Components/Common/Loader";

const TopInStoreEngagement = () => {
  const dispatch = useDispatch();

  const { dateRange, inStoreEngagement, isFetchingData } = useSelector(
    (state) => ({
      dateRange: state.Dashboard.dateRange,
      inStoreEngagement: state.Dashboard.inStoreEngagement.data,
      isFetchingData: state.Dashboard.inStoreEngagement.loading,
    })
  );

  useEffect(() => {
    dispatch(
      getInStoreEngagement({
        startTime: dateRange[0],
        endTime: dateRange[1],
        grouping: "month",
      })
    );
  }, [dateRange]);

  return (
    <CommonCard title="Top In-Store Engagement">
      <tbody>
        {isFetchingData ? <Loader /> : null}
        {!isFetchingData && inStoreEngagement?.length ? (
          inStoreEngagement?.map((item, idx) => (
            <tr className="item" key={idx}>
              <td className="item-name">
                <div>
                  {item.brand} - {item.location}
                </div>
              </td>
              <td className="item-value">
                <NumberBox number={item.total} isInteger /> scans
              </td>
            </tr>
          ))
        ) : !isFetchingData && !inStoreEngagement?.length ? (
          <span>No data available for this time period</span>
        ) : null}
      </tbody>
    </CommonCard>
  );
};

export default TopInStoreEngagement;
