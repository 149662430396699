import {
  BUNDLE_LIST,BUNDLE_LIST_SUCCESS,BUNDLE_LIST_FAILURE,
  BUNDLE_DETAILS,BUNDLE_DETAILS_SUCCESS,BUNDLE_DETAILS_FAILURE,
  BUNDLE_PRODUCT_ADD,BUNDLE_PRODUCT_CLEAR,
  BUNDLE_ADD,BUNDLE_ADD_SUCCESS,BUNDLE_ADD_FAILURE,
  BUNDLE_UPDATE,BUNDLE_UPDATE_SUCCESS,BUNDLE_UPDATE_FAILURE,
  BUNDLE_REMOVE,BUNDLE_REMOVE_SUCCESS,BUNDLE_REMOVE_FAILURE,
  BUNDLE_PRODUCT_REMOVE,BUNDLE_PRODUCT_REMOVE_SUCCESS,BUNDLE_PRODUCT_REMOVE_FAILURE,EDIT_BUNDLE_PRODUCT_REMOVE,
  RESET_BUNDLE_FLAG,
} from "./actionTypes";

const initialState = {
  bundles: {
    data: [],
    loading: true,
    total: 0,
  },
  products: [],
  dependantVariable: "",
  changeCount: 0,
  bundleInEdit: {},
  productInEdit: {},
  loading: false,
  error: false,
  errorMsg: "",
};

const bundle = (state = initialState, action) => {
  switch (action.type) {
    case BUNDLE_LIST: {
      return {
        ...state, error: false, errorMsg: "",
        bundles: {
          ...state.bundles,
          loading: true,
          data: [],
        },
      };
    }
    case BUNDLE_LIST_SUCCESS: {
      const { bundles, totalBundles } = action?.payload ?? {
        bundles: [],
        totalBundles: 0,
      };
      return {
        ...state,
        bundles: {
          ...state.bundles,
          loading: false,
          data: bundles,
          total: totalBundles,
        },
      };
    }
    case BUNDLE_LIST_FAILURE: {
      return {
        ...state,
        bundles: {
          ...state.bundles,
          loading: false,
          data: [],
        },
      };
    }

    case BUNDLE_PRODUCT_ADD:
      return {
        ...state,
        products: [...state.products, action.payload],
      };
    case EDIT_BUNDLE_PRODUCT_REMOVE:
      return{
        ...state,
        products: [...action.payload]
      }

    case BUNDLE_PRODUCT_REMOVE:
      return {
        ...state, loading: true, error: false, errorMsg: "",
      };
    case BUNDLE_PRODUCT_REMOVE_SUCCESS: {
      const existingCount = state.changeCount;

      return {
        ...state,
        loading: false,
        changeCount: existingCount + 1,
        dependantVariable: "remove-product" + (existingCount + 1),
      };
    }
    case BUNDLE_PRODUCT_REMOVE_FAILURE:
      return {
        ...state, loading: false, error: true, errorMsg: action.payload,
      };

    case BUNDLE_PRODUCT_CLEAR:
      // const isClearingProduct = action?.payload;
      if (action?.payload) return { ...state, products: [] };
      return {
        ...state, loading: false, error: false, errorMsg: "",
        products: [],
        bundleInEdit: {},
      };

    case BUNDLE_DETAILS:
      return {
        ...state, loading: true, error: false, errorMsg: "",
        products: [],
        bundleInEdit: { loading: true },
      };
    case BUNDLE_DETAILS_SUCCESS:
      var bundle = action.payload?.bundle ?? {};
      var products = bundle?.products ?? [];
      delete bundle.products;
      return {
        ...state, loading: false,
        products,
        bundleInEdit: { ...bundle, loading: false },
      };
    case BUNDLE_DETAILS_FAILURE:
      return {
        ...state, loading: false, error: true, errorMsg: action.payload,
        bundleInEdit: { ...state.bundleInEdit, loading: false },
      };

    case BUNDLE_ADD:
      return {
        ...state, error: false, errorMsg: "",
        bundleInEdit: { ...state.bundleInEdit, loading: true, added: false },
      };
    case BUNDLE_ADD_SUCCESS:
      return {
        ...state,
        bundleInEdit: { ...state.bundleInEdit, loading: false, added: true },
      };
    case BUNDLE_ADD_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        bundleInEdit: { ...state.bundleInEdit, loading: false, added: false },
      };

    case BUNDLE_UPDATE:
      return {
        ...state, error: false, errorMsg: "",
        bundleInEdit: { ...state.bundleInEdit, loading: true, updated: false },
      };
    case BUNDLE_UPDATE_SUCCESS:
      return {
        ...state,
        bundleInEdit: { ...state.bundleInEdit, loading: false, updated: true },
      };
    case BUNDLE_UPDATE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        bundleInEdit: { ...state.bundleInEdit, loading: false, updated: false },
      };

    case BUNDLE_REMOVE:
      return {
        ...state, error: false, errorMsg: "",
        bundleInEdit: { ...state.bundleInEdit, loading: true, removed: false },
      };
    case BUNDLE_REMOVE_SUCCESS:
      return {
        ...state,
        bundleInEdit: { ...state.bundleInEdit, loading: false, removed: true },
      };
    case BUNDLE_REMOVE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        bundleInEdit: { ...state.bundleInEdit, loading: false, removed: false },
      };

    case RESET_BUNDLE_FLAG:
      return {
        ...state,
        bundleInEdit: {
          ...state.bundleInEdit,
          loading: false,
          added: false,
          updated: false,
          removed: false,
        },
        errorMsg: "",
        loading: false,
        error: false,
      };

    default:
      return { ...state };
  }
};

export default bundle;
