import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Col, Container, Form, Input, Label, Row } from "reactstrap";
import { toast } from "react-toastify";

// formik
import * as Yup from "yup";
import { useFormik } from "formik";

// common helper
import Loader from "../../../Components/Common/Loader";
import PageHeader from "../../../Components/Common/PageHeader";

import {
	getUserProfile, updateUserProfile, resetAppFlag,
} from "../../../store/settings/action";

const AboutMe = () => {
	const dispatch = useDispatch();

	const { isLoading, userProfile, errorMsg } = useSelector(
		(state) => ({
			isLoading: state.Setting.loading,
			userProfile: state.Setting.userProfile,
			errorMsg: state.Setting.errorMsg,
		})
	);

	useEffect(() => {
		if (userProfile.updated) {
			dispatch(resetAppFlag());
			toast.success("Profile updated successfully");
		}
	}, [dispatch, userProfile.updated]);

	useEffect(() => {
		if (errorMsg) {
			dispatch(resetAppFlag());
			toast.error(errorMsg);
		}
	}, [dispatch, errorMsg]);

	useEffect(() => {
		dispatch(getUserProfile());
	}, [dispatch]);

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			firstName: userProfile.firstName,
			lastName: userProfile.lastName,
			company: userProfile.company,
			street1: userProfile.street1,
			street2: userProfile.street2,
			city: userProfile.city,
			state: userProfile.state,
			country: userProfile.country,
			postalCode: userProfile.postalCode,
		},
		validationSchema: Yup.object({
			// firstName: Yup.string().required("Please Enter first name"),
			// lastName: Yup.string().required("Please Enter last name"),
			// company: Yup.string().required("Please Enter company name"),
			// street1: Yup.string().required("Please Enter street address"),
			// street2: Yup.number().required("Please Enter unit or suite number"),
			// city: Yup.string().required("Please Enter city"),
			// state: Yup.string().required("Please Enter state"),
			// country: Yup.string().required("Please Enter country"),
			// postalCode: Yup.string().required("Please Enter postal code"),
		}),
		onSubmit: (values) => {
			dispatch(updateUserProfile(values));
		},
	});

	document.title = "Settings | mime";

	return (
		<div className="page-content">
			<Container fluid>
				<Row className="ms-0 mb-5">
					<Col className="scroll-container about-me-container">
						{isLoading ? (
							<Loader />
						) : (
							<div className="mime-form">
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										validation.handleSubmit();
										return false;
									}}
								>
									<div className="header p-0 flex">
										<PageHeader title="Personal" />
										<Button className="btn-lg">Save</Button>
									</div>
									<Col lg={5} xs={4}>
										<Row className="mt-20">
											<Label className="form-label">First Name</Label>
											<Input
												name="firstName"
												type="text"
												className="form-control"
												value={validation.values.firstName || ""}
												placeholder="Type your first name"
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.firstName &&
													validation.errors.firstName
												}
											/>
										</Row>
										<Row className="mt-20">
											<Label className="form-label">Last Name</Label>
											<Input
												name="lastName"
												type="text"
												className="form-control"
												value={validation.values.lastName || ""}
												placeholder="Type your last name"
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.lastName &&
													validation.errors.lastName
												}
											/>
										</Row>
										<Row className="mt-40">
											<PageHeader title="Company" />
										</Row>
										<Row className="mt-20">
											<Label className="form-label">Company Name</Label>
											<Input
												name="company"
												type="text"
												className="form-control"
												value={validation.values.company || ""}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.company &&
													validation.errors.company
												}
											/>
										</Row>
										<Row className="mt-20">
											<Label className="form-label">Street Address</Label>
											<Input
												name="street1"
												type="text"
												className="form-control"
												value={validation.values.street1 || ""}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.street1 &&
													validation.errors.street1
												}
											/>
										</Row>
										<Row className="mt-20">
											<Label className="form-label">Unit or Suite Number</Label>
											<Input
												name="street2"
												type="text"
												className="form-control"
												value={validation.values.street2 || ""}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.street2 &&
													validation.errors.street2
												}
											/>
										</Row>
										<Row className="mt-20">
											<Label className="form-label">City</Label>
											<Input
												name="city"
												type="text"
												className="form-control"
												value={validation.values.city || ""}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.city &&
													validation.errors.city
												}
											/>
										</Row>
										<Row className="mt-20">
											<Label className="form-label">State</Label>
											<Input
												name="state"
												type="text"
												className="form-control"
												value={validation.values.state || ""}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.state &&
													validation.errors.state
												}
											/>
										</Row>
										<Row className="mt-20">
											<Label className="form-label">Country</Label>
											<Input
												name="country"
												type="text"
												className="form-control"
												value={validation.values.country || ""}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.country &&
													validation.errors.country
												}
											/>
										</Row>
										<Row className="mt-20">
											<Label className="form-label">Postal Code</Label>
											<Input
												name="postalCode"
												type="text"
												className="form-control"
												value={validation.values.postalCode || ""}
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.postalCode &&
													validation.errors.postalCode
												}
											/>
										</Row>
									</Col>
								</Form>
							</div>
						)}
					</Col>
					<Col
						xs={5}
						className="right-edit scroll-container meta-right-section"
					></Col>
				</Row>
			</Container>
		</div>
	);
};

export default AboutMe;
