import React from "react";

const FormSelectOptions = ({
  categories,
  defaultOption = {},
  defaultOptionLabel,
  hasPefix = false,
}) => {
  return (
    <React.Fragment>
      {defaultOption ? (
        <option value={defaultOption.value}>
          {defaultOption.label || defaultOptionLabel || "Please Select"}
        </option>
      ) : null}
      {categories
        // .filter((category) => category.value != defaultOption?.value)
        .map((category, idx) => (
          <React.Fragment key={idx}>
            <option
              key={idx}
              value={category.value}
              data-content={category?.hexColor}
            >
              {category.label}
            </option>
          </React.Fragment>
        ))}
    </React.Fragment>
  );
};

export default FormSelectOptions;
