import React from 'react';
import { Routes, Route } from "react-router-dom";

// Layouts
import NonAuthLayout from "../Layouts/NonAuthLayout";
import VerticalLayout from "../Layouts/index";
import Logout from "../pages/Authentication/Logout";

// routes
import { authProtectedRoutes, publicRoutes } from "./allRoutes";
import { AuthProtected } from './AuthProtected';

const Index = () => {
	return (
		<React.Fragment>
			<Routes>
				<Route path='/logout' element={<Logout />} exact={true} />

				{publicRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={
							<NonAuthLayout>
								{route.component}
							</NonAuthLayout>
						}
						key={idx}
						exact={true}
					/>
				))}

				{authProtectedRoutes.map((route, idx) => (
					<Route
						path={route.path}
						element={
							<AuthProtected>
								<VerticalLayout>{route.component}</VerticalLayout>
							</AuthProtected>}
						key={idx}
						exact={true}
					/>
				))}
			</Routes>
		</React.Fragment>
	);
};

export default Index;