import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ProductsApiService } from "../../services/apis/ProductApi";
import { toast } from "react-toastify";

const UseDropdowns = (productId) => {
  const dispatch = useDispatch();
  const [productOptions, setProductOptions] = useState([]);
  const [variantOptions, setVariantOptions] = useState([]);

  const { products } = useSelector((state) => ({
    products: state.Product.products.data,
  }));

  useEffect(() => {
    const options = products.map((product) => ({
      value: product.groupId,
      label: product.name,
    }));
    setProductOptions(options);
  }, [dispatch, products]);

  const getProductVariants = useCallback(async () => {
    if (!productId) {
      setVariantOptions([]);
      return;
    }

    const res = await ProductsApiService.getVariantsList(productId);
    // alert();
    if (res.success) {
      const options = res.variants.map((variant) => ({
        value: variant.id,
        label: variant.color,
      }));
      setVariantOptions(options);
    } else {
      toast.error("Something went wrong while fetching variants!");
    }
  }, [productId]);
  useEffect(() => {
    setVariantOptions([])
    getProductVariants();
  }, [productId]);

  return { productOptions, variantOptions };
};

export default UseDropdowns;
