import React, { useEffect, useCallback, useState } from "react";
import {
  Button,
  Col,
  Container,
  Input,
  Label,
  Row,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";

// common helper
import Loader from "../../../Components/Common/Loader";
import PageHeader from "../../../Components/Common/PageHeader";

// formik validation
import * as Yup from "yup";
import { useFormik } from "formik";


const EmailAndPassword = () => {
  const [isAdding, setIsAdding] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      current_password: "",
      new_password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Please Enter valid email")
        .required("Please Enter Your Password"),
      current_password: Yup.string().required(
        "Please Enter your current password"
      ),
      new_password: Yup.string().required("Please Enter your new password"),
    }),
    onSubmit: (values) => {},
  });

  document.title = "Settings | mime"
  
  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container email-password-container">
            {isAdding ? (
              <Loader />
            ) : (
              <Col className="mime-form">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="header p-0">
                    <PageHeader title="Email & Password" />
                    <Button
                      type="submit"
                      className="btn-lg"
                      disabled={isAdding}
                    >
                      {isAdding ? (
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      ) : null}
                      Save
                    </Button>
                  </div>
                  <Col xs={4} lg={6}>
                    <Row className="mt-20">
                      <Label className="form-label">Email*</Label>
                      <Input
                        name="email"
                        type="text"
                        className="form-control"
                        value={validation.values.email || ""}
                        placeholder="Type your email here"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.email && validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email && validation.errors.email ? (
                        <FormFeedback type="invalid">
                          {validation.errors.email}
                        </FormFeedback>
                      ) : null}
                    </Row>
                    <Row className="mt-20">
                      <Label className="form-label">Current Password*</Label>
                      <Input
                        name="current_password"
                        type="text"
                        className="form-control"
                        value={validation.values.current_password || ""}
                        placeholder="Type terms URL here"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.current_password &&
                          validation.errors.current_password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.current_password &&
                      validation.errors.current_password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.current_password}
                        </FormFeedback>
                      ) : null}
                    </Row>
                    <Row className="mt-20">
                      <Label className="form-label">New Password*</Label>
                      <Input
                        name="new_password"
                        type="text"
                        className="form-control"
                        value={validation.values.new_password || ""}
                        placeholder="Type Policy URL here"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.new_password &&
                          validation.errors.new_password
                            ? true
                            : false
                        }
                      />
                      {validation.touched.new_password &&
                      validation.errors.new_password ? (
                        <FormFeedback type="invalid">
                          {validation.errors.new_password}
                        </FormFeedback>
                      ) : null}
                    </Row>
                  </Col>
                </Form>
              </Col>
            )}
          </Col>
          <Col
            xs={5}
            className="right-edit scroll-container meta-right-section"
          ></Col>
        </Row>
      </Container>
    </div>
  );
};

export default EmailAndPassword;
