import axios from "axios";
import { toast } from "react-toastify";

import { dev, stag } from "../config";
import { jwtAccessToken, jwtRefreshToken, setAuthUser, removeAuthUser } from "./auth-user";
import * as url from "./url_helper";

// Api url
if (process.env.NODE_ENV === 'production') {
  // Real production
  axios.defaults.baseURL = stag.API_URL;
} else if (process.env.REACT_APP_ENV === 'production') {
  // Simulate production
  axios.defaults.baseURL = stag.API_URL;
} else {
  // Development mode
  axios.defaults.baseURL = dev.API_URL;
}

// content type
axios.defaults.headers.post["Content-Type"] = "application/json";
// Timeout: 29 seconds
axios.defaults.timeout = 29000;
// Jwt Access Token
const token = jwtAccessToken();
if (token) axios.defaults.headers.common["Authorization"] = token;

// intercepting to capture errors
axios.interceptors.response.use(
  function (response) {
    return response.data ? response.data : response;
  },
  async function (error) {
    if (error.code === 'ECONNABORTED') {
      const message = "We're having a little trouble.\nPlease refresh the page or try again later.";
      toast.error(message);
    }

    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response?.status === 401 && jwtRefreshToken()) {
			const originalRequest = error.config;
			
			// refresh token doesn't work, redirect to login page
			if (originalRequest.url === url.TOKEN_REFRESH) {
				removeAuthUser();
				window.location.replace("/login");
			}

			// Attempt to refresh token & save
			delete axios.defaults.headers.common["Authorization"];
			const authUser = await axios.post(url.TOKEN_REFRESH, {
				jwtRefreshToken: jwtRefreshToken(),
			});
			// Save new auth user data
			setAuthUser(authUser);

			// Update access token and retry original request
			const newAccessToken = authUser.jwtAccessToken;
			axios.defaults.headers.common["Authorization"] = newAccessToken;
			originalRequest.headers["Authorization"] = newAccessToken;

			return axios.request(originalRequest);
    }

    return Promise.reject(error.response?.data?.message || "Something went wrong.");
  }
);

/**
 * Sets the default authorization
 * @param {*} token
 */
const setAuthorization = (token) => {
  axios.defaults.headers.common["Authorization"] = token;
};

class APIClient {
  /**
   * Fetches data from given url
   */
  get = (url, params) => {
    let response;

    let paramKeys = [];

    if (params) {
      Object.keys(params).map((key) => {
        paramKeys.push(key + "=" + params[key]);
        return paramKeys;
      });

      const queryString =
        paramKeys && paramKeys.length ? paramKeys.join("&") : "";
      response = axios.get(`${url}?${queryString}`, params);
    } else {
      response = axios.get(`${url}`, params);
    }

    return response;
  };
  /**
   * post given data to url
   */
  create = (url, data) => {
    return axios.post(url, data);
  };
  /**
   * Updates data
   */
  update = (url, data) => {
    return axios.patch(url, data);
  };

  put = (url, data) => {
    return axios.put(url, data);
  };
  /**
   * Delete
   */
  delete = (url, config) => {
    return axios.delete(url, { ...config });
  };
}

export { APIClient, setAuthorization };
