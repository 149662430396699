import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { PlusCircle, Trash2 } from "feather-icons-react/build/IconComponents";
import { toast } from "react-toastify";
import { Check } from "feather-icons-react/build/IconComponents";

import FormSelectOptions from "../../../Components/Common/FormSelectOptions";
import { MenuItemEnums, RouteParams } from "../../../common/enums";
import {
  OptionsUndertone,
  OptionsSubtone,
  OptionsCurrency,
} from "../../../common/form-selector-values";

import { convertToPlainText } from "../../../helpers/utils";
import { getUrlParam } from "../../../helpers/location-helper";

import {
  getProductsList,
  removeProduct,
  resetProductFlag,
  getVariants,
  getVariantDetails,
  clearVariantDetails,
  addVariant,
  updateVariant,
  removeVariant,
  resetVariantFlag,
} from "../../../store/actions";
import { useMemo } from "react";

const VariantForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const variantIdFromPath = getUrlParam(RouteParams.variantId, location.search);
  const editableVariantId = variantIdFromPath
    ? +variantIdFromPath
    : variantIdFromPath;

  const [isUpdate, setIsUpdate] = useState(false);
  const [recommend, setRecommend] = useState(true);
  const [prices, setPrices] = useState([{ amount: 0, currency: "USD" }]);

  const { productID, productInEdit, variantInEdit, variantCount } = useSelector(
    (state) => ({
      productID: state.Menu.menuStatus.subMenu.id,
      productInEdit: state.Product.productInEdit,
      variantInEdit: state.Product.variantInEdit,
      variantCount: state.Product.variants.length,
    })
  );

  useEffect(() => {
    setPricesField(variantInEdit.prices);
  }, [variantInEdit]);

  useEffect(() => {
    if (getUrlParam(RouteParams.createVariant, location.search) == "true") {
      setIsUpdate(false);
    } else if (
      getUrlParam(RouteParams.editVariant, location.search) == "true"
    ) {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (!productID || !editableVariantId) {
      dispatch(clearVariantDetails());
      return;
    }
    dispatch(getVariantDetails(productID, editableVariantId));
  }, [dispatch, productID, editableVariantId]);

  useEffect(() => {
    if (variantInEdit.added || variantInEdit.updated || variantInEdit.removed) {
      dispatch(getVariants(productID));
      dispatch(resetVariantFlag());
      if (variantInEdit.added) {
        toast.success("Product variant created successfully");
      } else if (variantInEdit.updated) {
        toast.success("Product variant updated successfully");
      } else {
        toast.success("Product variant deleted successfully");
      }
      navigate(
        `/${MenuItemEnums.inventory}/${MenuItemEnums.product}/${productID}`
      );
    }
  }, [variantInEdit.added, variantInEdit.updated, variantInEdit.removed]);

  useEffect(() => {
    if (productInEdit.removed) {
      dispatch(getProductsList());
      dispatch(resetProductFlag());
      toast.success("Product deleted successfully");
      navigate(`/${MenuItemEnums.inventory}/${MenuItemEnums.product}`);
    }
  }, [productInEdit.removed]);

  const handleSubmit = async (values) => {
    if (isUpdate) {
      dispatch(updateVariant(productID, editableVariantId, values));
    } else {
      dispatch(addVariant(productID, values));
    }
  };

  const deleteVariant = async () => {
    if (variantCount === 1) {
      dispatch(removeProduct(productID));
    } else {
      dispatch(removeVariant(productID, editableVariantId));
    }
  };

  const onRecommendHandler = (event) => {
    if (event.target.value === "Yes") {
      setRecommend(true);
    } else {
      setRecommend(false);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      recommend: variantInEdit.showInRecommendations || recommend,
      shadeName: variantInEdit.shadeName || "",
      undertone: variantInEdit.undertone || "",
      subtone: variantInEdit.subtone || "",
      productUrl: variantInEdit.productUrl || "",
      imageUrl: variantInEdit.imageUrl || "",
      sku: variantInEdit.sku || "",
      description: convertToPlainText(variantInEdit.description ?? ""),
      ingredients: variantInEdit.ingredients || "",
    },
    validationSchema: Yup.object({
      undertone: Yup.string().required("Please Select Undertone"),
      subtone: Yup.string().required("Please Select Subtone"),
      shadeName: Yup.string().required("Please Enter Shade(Color) Name"),
      productUrl: Yup.string().required("Please Enter Product URL"),
      imageUrl: Yup.string().required("Please Enter Product Image URL"),
      sku: Yup.string().required("Please Enter SKU"),
      description: Yup.string().required("Please enter Product Description"),
      ingredients: Yup.string().required("Please enter Product Ingredients"),
      prices: Yup.array().min(1, "Please add at least 1 price"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const setPricesField = (prices) => {
    let validPrices =
      prices && prices.length > 0 ? prices : [{ amount: 0, currency: "USD" }];
    setPrices(validPrices);
    const filterPrices = validPrices.filter((price, i) =>
      price.amount > 0 ? true : false
    );
    validation.setFieldValue("prices", filterPrices);
  };

  const handlePriceChange = (e, idx, key) => {
    let _price = e.target.value;
    if ((key === "amount" && isNaN(_price)) || !_price) {
      _price = 0;
    }
    const oldPrices = [...prices];
    oldPrices[idx][key] = _price;
    setPricesField(oldPrices);
  };

  const handlePricePlus = () => {
    setPricesField([...prices, { amount: 0, currency: "USD" }]);
  };

  const handlePriceDelete = (idx) => {
    const filterPrices = prices.filter((_, i) => i !== idx);
    setPricesField(filterPrices);
  };

  const checklists = useMemo(
    () => [
      {
        name: `MIME Physically Swatched In-House`,
        isChecked: variantInEdit?.proMatched ?? false,
      },
      {
        name: `AI Curated by Professional Artist`,
        isChecked: variantInEdit?.expertVerified ?? false,
      },
    ],
    [variantInEdit]
  );

  console.log("variantInEdit", variantInEdit);

  return (
    <React.Fragment>
      <Col xs={4} className="mime-form right-edit scroll-container">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row className="form-header">
            <Col className="title">
              {isUpdate ? "Edit Variant" : "Add Variant"}
            </Col>

            <Col className="action-button">
              {isUpdate ? (
                <Button
                  type="button"
                  className="icon-btn"
                  onClick={() => deleteVariant()}
                >
                  <Trash2 size="24" stroke="#d26767" className="delete-icon" />
                </Button>
              ) : null}

              <Button
                type="submit"
                className="form-control btn-small btn-text-primary"
              >
                Save
              </Button>
            </Col>
          </Row>
          {isUpdate && (
            <Row className="accuracy-container">
              <div className="accuracy-title">Highest Accuracy</div>
              <div className="check-lists">
                {checklists.map((list, key) => (
                  <div className="list-item" key={key}>
                    <div
                      className={`check-circle ${
                        list.isChecked ? "active-check-circle" : ""
                      }`}
                    >
                      <Check />
                    </div>
                    <div>{list.name}</div>
                  </div>
                ))}
              </div>
            </Row>
          )}
          <Row className="mt-40">
            <Col>
              <Label htmlFor="recommend_yes" className="form-label">
                Show in Recommendation?
              </Label>
              <Input
                name="recommend_yes"
                type="button"
                className={
                  "form-control btn" + (recommend === true ? " active" : "")
                }
                onClick={onRecommendHandler}
                value={"Yes"}
              />
            </Col>
            <Col className="pl-10">
              <Label htmlFor="recommend_no" className="form-label hidden">
                Show in Recommendation?
              </Label>
              <Input
                name="recommend_no"
                type="button"
                className={
                  "form-control btn" + (recommend === false ? " active" : "")
                }
                onClick={onRecommendHandler}
                value={"No"}
              />
            </Col>
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Shade Name*</Label>
            <Input
              name="shadeName"
              type="text"
              className="form-control"
              value={validation.values.shadeName || ""}
              placeholder="Type the shade(color) name here"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.shadeName && validation.errors.shadeName
                  ? true
                  : false
              }
            />
            {validation.touched.shadeName && validation.errors.shadeName ? (
              <FormFeedback type="invalid">
                {validation.errors.shadeName}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Undertone</Label>
            <select
              name="undertone"
              value={validation.values.undertone}
              onChange={validation.handleChange}
              className={
                "form-select" +
                (validation.values.undertone ? "" : " empty") +
                (validation.touched.undertone && validation.errors.undertone
                  ? " is-invalid"
                  : "")
              }
            >
              <FormSelectOptions
                categories={OptionsUndertone}
                defaultOption={validation.values.undertone ? null : {}}
              />
            </select>
            {validation.touched.undertone && validation.errors.undertone ? (
              <FormFeedback type="invalid">
                {validation.errors.undertone}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Undertone - Subtone</Label>
            <select
              name="subtone"
              value={validation.values.subtone}
              onChange={validation.handleChange}
              className={
                "form-select" +
                (validation.values.subtone ? "" : " empty") +
                (validation.touched.subtone && validation.errors.subtone
                  ? " is-invalid"
                  : "")
              }
            >
              <FormSelectOptions
                categories={OptionsSubtone}
                defaultOption={validation.values.subtone ? null : {}}
              />
            </select>
            {validation.touched.subtone && validation.errors.subtone ? (
              <FormFeedback type="invalid">
                {validation.errors.subtone}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Product URL*</Label>
            <Input
              name="productUrl"
              type="text"
              className="form-control"
              value={validation.values.productUrl || ""}
              placeholder="https://www.website.com"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.productUrl && validation.errors.productUrl
                  ? true
                  : false
              }
            />
            {validation.touched.productUrl && validation.errors.productUrl ? (
              <FormFeedback type="invalid">
                {validation.errors.productUrl}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Image URL*</Label>
            <Input
              name="imageUrl"
              type="text"
              className="form-control"
              value={validation.values.imageUrl || ""}
              placeholder="https://www.website.com/image.jpg"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.imageUrl && validation.errors.imageUrl
                  ? true
                  : false
              }
            />
            {validation.touched.imageUrl && validation.errors.imageUrl ? (
              <FormFeedback type="invalid">
                {validation.errors.imageUrl}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">SKU*</Label>
            <Input
              name="sku"
              type="text"
              className="form-control"
              value={validation.values.sku || ""}
              placeholder="Enter a SKU"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.sku && validation.errors.sku ? true : false
              }
            />
            {validation.touched.sku && validation.errors.sku ? (
              <FormFeedback type="invalid">
                {validation.errors.sku}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Product Description*</Label>
            <Input
              name="description"
              type="textarea"
              className="form-control"
              value={validation.values.description || ""}
              placeholder="Enter the product description here"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.description && validation.errors.description
                  ? true
                  : false
              }
            />
            {validation.touched.description && validation.errors.description ? (
              <FormFeedback type="invalid">
                {validation.errors.description}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Product Ingredients*</Label>
            <Input
              name="ingredients"
              type="textarea"
              className="form-control"
              value={validation.values.ingredients || ""}
              placeholder="Enter the product ingredients here"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.ingredients && validation.errors.ingredients
                  ? true
                  : false
              }
            />
            {validation.touched.ingredients && validation.errors.ingredients ? (
              <FormFeedback type="invalid">
                {validation.errors.ingredients}
              </FormFeedback>
            ) : null}
          </Row>

          <div className="pricing-container">
            <Label className="form-label title">Pricing</Label>
            <Input type="hidden" className="is-invalid" />
            {validation.errors.prices ? (
              <FormFeedback type="invalid">
                {validation.errors.prices}
              </FormFeedback>
            ) : null}

            {prices &&
              prices.map((price, idx) => (
                <Row className="Price-Row mt-20" key={idx}>
                  <Col className="Amount-Col">
                    <Label className="form-label">Price*</Label>
                    <Input
                      type="number"
                      className="form-control"
                      value={price.amount}
                      placeholder="Enter a price"
                      onChange={(e) => handlePriceChange(e, idx, "amount")}
                    />
                  </Col>
                  <Col className="Currency-Col">
                    <Label className="form-label">Currency*</Label>
                    <select
                      name={`price-${idx}-currency`}
                      onChange={(e) => handlePriceChange(e, idx, "currency")}
                      value={price.currency}
                      className={
                        "form-select" + (price.currency ? "" : " empty")
                      }
                    >
                      <FormSelectOptions
                        categories={OptionsCurrency}
                        defaultOption={price.currency ? null : {}}
                      />
                    </select>
                  </Col>
                  <div className="Button-Col">
                    <Button
                      className="icon-btn"
                      onClick={() => handlePriceDelete(idx)}
                    >
                      <Trash2
                        size="24"
                        stroke="#d26767"
                        className="delete-icon"
                      />
                    </Button>
                  </div>
                  <div className="Button-Col">
                    <Button
                      className="icon-btn"
                      onClick={() => handlePricePlus(price)}
                    >
                      <PlusCircle />
                    </Button>
                  </div>
                </Row>
              ))}
          </div>
        </Form>
      </Col>
    </React.Fragment>
  );
};

export default VariantForm;
