import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  GET_REVENUES,
  GET_UNDERTONES,
  GET_COLOR_PREFERENCES,
  GET_COUNTRIES,
  GET_RECENT_SHOPPERS,
  GET_IN_STORE_ENGAGEMENT,
} from "./actionTypes";

import {
  getRevenuesSuccess,
  getRevenuesFailure,
  getUndertonesSuccess,
  getUndertonesFailure,
  getColorPreferencesSuccess,
  getColorPreferencesFailure,
  getCountriesSuccess,
  getCountriesFailure,
  getRecentShoppersSuccess,
  getRecentShoppersFailure,
  getInStoreEngagementSuccess,
  getInStoreEngagementFailure,
} from "./actions";

// Include Both Helper File with needed methods
import {
  getRevenues,
  getUndertones,
  getColorPreferences,
  getCountries,
  getRecentShoppers,
  getInStoreEngagement,
} from "../../helpers/backend_helper";

function* retrieveRevenues({ payload: filters }) {
  try {
    const response = yield call(getRevenues, filters);
    yield put(getRevenuesSuccess(response));
  } catch (error) {
    yield put(getRevenuesFailure(error));
  }
}

function* retrieveUndertones({ payload: filters }) {
  try {
    const response = yield call(getUndertones, filters);
    yield put(getUndertonesSuccess(response));
  } catch (error) {
    yield put(getUndertonesFailure(error));
  }
}

function* retrieveColorPreferences({ payload: filters }) {
  try {
    const response = yield call(getColorPreferences, filters);
    yield put(getColorPreferencesSuccess(response));
  } catch (error) {
    yield put(getColorPreferencesFailure(error));
  }
}

function* retrieveCountries({ payload: filters }) {
  try {
    const response = yield call(getCountries, filters);
    yield put(getCountriesSuccess(response));
  } catch (error) {
    yield put(getCountriesFailure(error));
  }
}

function* retrieveRecentShoppers() {
  try {
    const response = yield call(getRecentShoppers, {});
    yield put(getRecentShoppersSuccess(response));
  } catch (error) {
    yield put(getRecentShoppersFailure(error));
  }
}

function* retrieveInStoreEngagement({ payload: filters }) {
  try {
    const response = yield call(getInStoreEngagement, filters);
    yield put(getInStoreEngagementSuccess(response));
  } catch (error) {
    yield put(getInStoreEngagementFailure(error));
  }
}

function* bundleSaga() {
  yield takeEvery(GET_REVENUES, retrieveRevenues);
  yield takeEvery(GET_UNDERTONES, retrieveUndertones);
  yield takeEvery(GET_COLOR_PREFERENCES, retrieveColorPreferences);
  yield takeEvery(GET_COUNTRIES, retrieveCountries);
  yield takeEvery(GET_RECENT_SHOPPERS, retrieveRecentShoppers);
  yield takeEvery(GET_IN_STORE_ENGAGEMENT, retrieveInStoreEngagement);
}

export default bundleSaga;
