import { MenuItemEnums, RouteParams } from "../../common/enums";
import { getReturnList } from "../../store/AiTraining/returns/actions";
import {
  getCampaignList,
  getProductsList,
  getBundlesList,
  getTrainingList,
} from "../../store/actions";
import { getMissedConnectionList } from "../../store/audience/actions";
import { getAppList } from "../../store/settings/action";
import { getLoggedinUser } from "../../helpers/auth-user";

export const updateMenuItems = (menuItems, menu, subMenu, data) => {
  return menuItems.map((item) => {
    if (item.id !== menu) return item;

    const updatedSubItems = item.subItems.map((subItem) => {
      if (subItem.id === subMenu) subItem.childItems = data;

      return subItem;
    });

    return { ...item, subItems: updatedSubItems };
  });
};

export const retrieveMenuData = (menu) => {
  switch (menu) {
    case MenuItemEnums.dashboard:
      break;

    case MenuItemEnums.inventory:
      return [getProductsList(), getBundlesList()];

    case MenuItemEnums.campaign:
      return [getCampaignList(), getAppList({ isUpdateMenuList: false })];

    case MenuItemEnums.audience:
      return [
        getProductsList(),
        getMissedConnectionList(),
        getAppList({ isUpdateMenuList: false }),
      ];

    case MenuItemEnums.revenue:
      return [getProductsList(), getAppList({ isUpdateMenuList: false })];

    case MenuItemEnums.aiTraining:
      return [getTrainingList(), getReturnList()];

    case MenuItemEnums.settings:
      return [getAppList({ isUpdateMenuList: true })];

    default:
      break;
  }
  return [];
};

function accountMenuItems() {
  let items = [
    // {
    //   id: RouteParams.billing,
    //   label: "Billing",
    //   link: `/${MenuItemEnums.settings}/${MenuItemEnums.account}/${RouteParams.billing}`,
    //   childItems: [],
    // },
    {
      id: RouteParams.aboutMe,
      label: "About Me",
      link: `/${MenuItemEnums.settings}/${MenuItemEnums.account}/${RouteParams.aboutMe}`,
      childItems: [],
    },
    {
      id: RouteParams.emailPassword,
      label: "Email &  Password",
      link: `/${MenuItemEnums.settings}/${MenuItemEnums.account}/${RouteParams.emailPassword}`,
      childItems: [],
    },
    {
      id: RouteParams.logout,
      label: "Logout",
      link: `/${RouteParams.logout}`,
      childItems: [],
    },
  ];

  const user = getLoggedinUser();
  if (user?.role === "customer") {
    items.unshift({
      id: RouteParams.myTeam,
      label: "My Team",
      link: `/${MenuItemEnums.settings}/${MenuItemEnums.account}/${RouteParams.myTeam}`,
      childItems: [],
    });
  }

  return items;
}

export const menuItems = () => {
  return [
    {
      id: MenuItemEnums.dashboard,
      label: "Dashboard",
      icon: "./icons/icon-home.png",
      subItems: [
        {
          id: "dashboard",
          label: "Dashboard",
          link: `/${MenuItemEnums.dashboard}`,
        },
      ],
    },
    {
      id: MenuItemEnums.inventory,
      label: "Inventory",
      icon: "./icons/icon-inventory.png",
      subItems: [
        {
          id: MenuItemEnums.product,
          label: "My Products",
          link: `/${MenuItemEnums.inventory}/${MenuItemEnums.product}`,
          isClickable: true,
          childItems: [],
        },
        {
          id: RouteParams.createProduct,
          label: "+ Add a Product",
          button: true,
          isClickable: true,
          link: `/${MenuItemEnums.inventory}/${MenuItemEnums.product}?${RouteParams.createProduct}=true`,
        },
        {
          id: MenuItemEnums.bundle,
          label: "My Bundles",
          link: `/${MenuItemEnums.inventory}/${MenuItemEnums.bundle}`,
          isClickable: true,
          childItems: [],
        },
        {
          id: RouteParams.createBundle,
          label: "+ Add a Bundle",
          button: true,
          link: `/${MenuItemEnums.inventory}/${MenuItemEnums.bundle}/new?${RouteParams.createBundle}=true`,
        },
      ],
    },
    {
      id: MenuItemEnums.campaign,
      label: "Campaign",
      icon: "icons/icon-campaign.png",
      isClickable: true,
      subItems: [
        {
          id: MenuItemEnums.myCampaign,
          label: "My Campaigns",
          link: `/${MenuItemEnums.campaign}/${MenuItemEnums.myCampaign}`,
          isClickable: true,
          childItems: [],
        },
        {
          id: RouteParams.createCampaign,
          label: "+ Add a Campaign",
          button: true,
          link: `/${MenuItemEnums.campaign}/${MenuItemEnums.myCampaign}/create?${RouteParams.createCampaign}=true`,
        },
      ],
    },
    {
      id: MenuItemEnums.audience,
      label: "Audience",
      icon: "icons/icon-audience.png",
      subItems: [
        {
          id: RouteParams.opportunities,
          label: "Opportunities",
          isClickable: false,
          link: `/${MenuItemEnums.audience}/${RouteParams.opportunities}`,
          childItems: [
            {
              id: RouteParams.missedConnections,
              label: "Missed Connections",
              link: `/${MenuItemEnums.audience}/${RouteParams.opportunities}/${RouteParams.missedConnections}`,
              childItems: [],
            },
            // {
            //   id: RouteParams.undeserved,
            //   label: "Undeserved",
            //   link: `/${MenuItemEnums.audience}/${RouteParams.opportunities}/${RouteParams.undeserved}`,
            //   childItems: [],
            // },
          ],
        },
        {
          id: RouteParams.analysis,
          label: "Analysis",
          isClickable: false,
          // link: `/${MenuItemEnums.audience}/${RouteParams.analysis}`,
          childItems: [
            {
              id: RouteParams.myAudience,
              label: "My Audience",
              link: `/${MenuItemEnums.audience}/${RouteParams.analysis}/${RouteParams.myAudience}`,
              childItems: [],
            },
            {
              id: RouteParams.colorBenchMarks,
              label: "Color Benchmarks",
              link: `/${MenuItemEnums.audience}/${RouteParams.analysis}/${RouteParams.colorBenchMarks}`,
              childItems: [],
            },
          ],
        },
      ],
    },
    {
      id: MenuItemEnums.revenue,
      label: "Revenue",
      icon: "icons/icon-revenue.png",
      subItems: [
        {
          id: MenuItemEnums.reporting,
          label: "Reporting",
          link: `/${MenuItemEnums.reporting}`,
          isClickable: false,
          childItems: [
            {
              id: RouteParams.conversion,
              label: "Conversion",
              link: `/${MenuItemEnums.revenue}/${MenuItemEnums.reporting}/${RouteParams.conversion}`,
              childItems: [],
            },
            {
              id: RouteParams.reportingRevenue,
              label: "Revenue",
              link: `/${MenuItemEnums.revenue}/${MenuItemEnums.reporting}/${RouteParams.reportingRevenue}`,
              childItems: [],
            },
            {
              id: RouteParams.topProducts,
              label: "Top Products",
              link: `/${MenuItemEnums.revenue}/${MenuItemEnums.reporting}/${RouteParams.topProducts}`,
              childItems: [],
            },
            {
              id: RouteParams.topShades,
              label: "Top Shades",
              link: `/${MenuItemEnums.revenue}/${MenuItemEnums.reporting}/${RouteParams.topShades}`,
              childItems: [],
            },
            {
              id: RouteParams.topCoverages,
              label: "Top Coverages",
              link: `/${MenuItemEnums.revenue}/${MenuItemEnums.reporting}/${RouteParams.topCoverages}`,
              childItems: [],
            },
          ],
        },
      ],
    },
    {
      id: MenuItemEnums.aiTraining,
      label: "Training",
      icon: "icons/icon-ai-training.png",
      subItems: [
        {
          id: RouteParams.training,
          label: "Training",
          link: `/${MenuItemEnums.aiTraining}/${MenuItemEnums.training}`,
          isClickable: true,
          childItems: [],
        },
        {
          id: RouteParams.returns,
          label: "Returns",
          link: `/${MenuItemEnums.aiTraining}/${RouteParams.returns}`,
          isClickable: true,
          childItems: [
            {
              id: RouteParams.manageReturns,
              label: "Manage Returns",
              link: `/${MenuItemEnums.aiTraining}/${RouteParams.returns}/${RouteParams.manageReturns}?${RouteParams.manageReturns}=true`,
              childItems: [],
            },
          ],
        },
      ],
    },
    {
      id: MenuItemEnums.settings,
      label: "Settings",
      icon: "icons/icon-settings.png",
      subItems: [
        {
          id: RouteParams.myApps,
          label: "My Apps",
          link: `/${MenuItemEnums.settings}/${RouteParams.myApps}`,
          isClickable: false,
          childItems: [],
        },
        {
          id: MenuItemEnums.account,
          label: "Account",
          link: `/${MenuItemEnums.account}`,
          isClickable: false,
          childItems: accountMenuItems(),
        },
      ],
    },
  ];
}
