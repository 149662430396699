export const CAMPAIGN_LIST = "CAMPAIGN_LIST";
export const CAMPAIGN_LIST_SUCCESS = "CAMPAIGN_LIST_SUCCESS";
export const CAMPAIGN_LIST_FAILURE = "CAMPAIGN_LIST_FAILURE";

export const CAMPAIGN_SET = "CAMPAIGN_SET";

export const CAMPAIGN_ADD = "CAMPAIGN_ADD";
export const CAMPAIGN_ADD_SUCCESS = "CAMPAIGN_ADD_SUCCESS";
export const CAMPAIGN_ADD_FAILURE = "CAMPAIGN_ADD_FAILURE";

export const CAMPAIGN_UPDATE = "CAMPAIGN_UPDATE";
export const CAMPAIGN_UPDATE_SUCCESS = "CAMPAIGN_UPDATE_SUCCESS";
export const CAMPAIGN_UPDATE_FAILURE = "CAMPAIGN_UPDATE_FAILURE";

export const CAMPAIGN_REMOVE = "CAMPAIGN_REMOVE";
export const CAMPAIGN_REMOVE_SUCCESS = "CAMPAIGN_REMOVE_SUCCESS";
export const CAMPAIGN_REMOVE_FAILURE = "CAMPAIGN_REMOVE_FAILURE";

export const RESET_CAMPAIGN_FLAG = "RESET_CAMPAIGN_FLAG";