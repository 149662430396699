import React, {useEffect} from "react";
import { useNavigate } from "react-router";
import { MenuItemEnums, RouteParams } from "../../common/enums";

const Opportunities = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(
      `/${MenuItemEnums.audience}/${RouteParams.opportunities}/${RouteParams.missedConnections}`
    );
  }, []);
  return null;
};

export default Opportunities;
