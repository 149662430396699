import { MENU_STATUS } from "../store/menu/actionTypes";

import { menuItems as menuData, updateMenuItems } from "../Layouts/MimeLayout/MenuData";
import { MenuItemEnums, RouteParams } from "../common/enums";

// Local Storage
export const Storage = {
  saveJson: (key, json) => {
    localStorage.setItem(key, JSON.stringify(json));
  },

  getJson: (key, defaultValue = null) => {
    return localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : defaultValue;
  },

  remove: (key) => {
    localStorage.removeItem(key);
  },

  menuItems: () => {
    var items = menuData();

    var childItems = Storage.getProductsMenus(Storage.getProducts());
    if (childItems)
      items = updateMenuItems(
        items,
        MenuItemEnums.inventory,
        MenuItemEnums.product,
        childItems
      );

    childItems = Storage.getBundlesMenus(Storage.getBundles());
    if (childItems)
      items = updateMenuItems(
        items,
        MenuItemEnums.inventory,
        MenuItemEnums.bundle,
        childItems
      );

    childItems = Storage.getCampaignsMenus(Storage.getCampaigns());
    if (childItems)
      items = updateMenuItems(
        items,
        MenuItemEnums.campaign,
        MenuItemEnums.myCampaign,
        childItems
      );

    childItems = Storage.getTrainingMenus(Storage.getProducts());
    if (childItems)
      items = updateMenuItems(
        items,
        MenuItemEnums.aiTraining,
        RouteParams.training,
        childItems
      );

    childItems = Storage.getAppMenus(Storage.getApps());
    if (childItems)
      items = updateMenuItems(
        items,
        MenuItemEnums.settings,
        RouteParams.myApps,
        childItems
      );

    return items;
  },

  setMenu: (data) => {
    Storage.saveJson(MENU_STATUS, data);
  },
  getMenu: () => {
    return Storage.getJson(MENU_STATUS);
  },

  setProducts: (data) => {
    Storage.saveJson(MenuItemEnums.product, data);
  },
  getProducts: () => {
    return Storage.getJson(MenuItemEnums.product);
  },
  getProductsMenus: (products) => {
    return products?.map((item) => {
      return {
        id: item.groupId,
        label: item.name,
        link: `/${MenuItemEnums.inventory}/${MenuItemEnums.product}/${item.groupId}`,
        data: item,
      };
    });
  },

  setBundles: (data) => {
    Storage.saveJson(MenuItemEnums.bundle, data);
  },
  getBundles: () => {
    return Storage.getJson(MenuItemEnums.bundle);
  },
  getBundlesMenus: (bundles) => {
    return bundles?.map((item) => {
      return {
        id: item.id,
        label: item.name,
        link: `/${MenuItemEnums.inventory}/${MenuItemEnums.bundle}/${item.id}`,
        data: item,
      };
    });
  },

  // camapign data set and get local storage
  setCampaigns: (data) => {
    Storage.saveJson(MenuItemEnums.campaign, data);
  },
  getCampaigns: (data) => {
    return Storage.getJson(MenuItemEnums.campaign, data);
  },
  getCampaignsMenus: (campaigns) => {
    return campaigns?.map((item) => {
      return {
        id: item.id,
        label: item.name,
        link: `/${MenuItemEnums.campaign}/${MenuItemEnums.myCampaign}/${item.id}?${RouteParams.editCampaign}=true&${RouteParams.campaignId}=${item.id}`,
        data: item,
      };
    });
  },

  getTrainingMenus: (products) => {
    return products?.map((item) => {
      return {
        id: item.groupId,
        label: item.name,
        link: `/${MenuItemEnums.aiTraining}/${MenuItemEnums.training}/${item.groupId}`,
        data: item,
      };
    });
  },

  setApps: (data) => {
    Storage.saveJson(RouteParams.myApps, data);
  },
  getApps: (data) => {
    return Storage.getJson(RouteParams.myApps, data);
  },
  getAppMenus: (apps) => {
    if (apps === null) return null;

    const mappedArray = [];
    apps.forEach((item) => {
      mappedArray.push(
        {
          id: item.id,
          label: item.name,
          link: "",
        },
        {
          id: RouteParams.basics,
          label: "Basics",
          link: `/${MenuItemEnums.settings}/${RouteParams.myApps}/${RouteParams.basics}/${item.id}`,
          isChildSubItem: true,
        },
        {
          id: RouteParams.questions,
          label: "Questions",
          link: `/${MenuItemEnums.settings}/${RouteParams.myApps}/${RouteParams.questions}/${item.id}`,
          isChildSubItem: true,
        },
        {
          id: RouteParams.branding,
          label: "Branding",
          link: `/${MenuItemEnums.settings}/${RouteParams.myApps}/${RouteParams.branding}/${item.id}`,
          isChildSubItem: true,
        },
        {
          id: RouteParams.legal,
          label: "Legal",
          link: `/${MenuItemEnums.settings}/${RouteParams.myApps}/${RouteParams.legal}/${item.id}`,
          isChildSubItem: true,
        }
      );
    });
    return mappedArray;
  },
};
