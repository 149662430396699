import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, Table } from "reactstrap";
// common helper
import PageHeader from "../../../Components/Common/PageHeader";
import NumberBox from "../../../Components/Common/NumberBox";
import MyAudienceForm from "./MyAudienceForm";
import Loader from "../../../Components/Common/Loader";

const MyAudience = () => {
  const { complexions, fetchingData, errorMsg } = useSelector((state) => ({
    fetchingData: state.Report.loading,
    errorMsg: state.Report.errorMsg,
    complexions: state.Report.complexions,
  }));

  const hasData = !fetchingData && complexions.data.length;
  const hasNoData = !fetchingData && !complexions.data.length;

  document.title = "Audience | mime";

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container my-audience-container">
            <PageHeader title="My Audience" />
            {fetchingData ? <Loader /> : null}
            <Col className="chart">
              {hasData ? (
                complexions.data.map((item, idx) => (
                  <div
                    key={idx}
                    style={{
                      width: `calc(${item.percent}% - 1px)`,
                      background: `${item.color}`,
                    }}
                  ></div>
                ))
              ) : hasNoData ? (
                <span>No data available</span>
              ) : null}
            </Col>
            <Col>
              <div className="header">
                <PageHeader title="Breakdown" />
                Sample size: <NumberBox number={complexions.total} isInteger /> Shoppers
              </div>
              <Row>
                <Col className="product-list">
                  <Table className="align-middle table-nowrap mb-0">
                    <tbody>
                      {fetchingData ? <Loader /> : null}
                      {hasData ? (
                        complexions.data.map((item, idx) => (
                          <tr className="product-item" key={idx}>
                            <td className="item-color-container">
                              <div
                                className="item-color"
                                style={{
                                  backgroundColor: item.color,
                                }}
                              ></div>
                            </td>
                            <td className="product-item-content">
                              <div className="title">{item.name}</div>
                              <div className="sub-title">{item.percent}%</div>
                            </td>
                          </tr>
                        ))
                      ) : hasNoData ? (
                        <span>No data available</span>
                      ) : null}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col>
          </Col>
          <MyAudienceForm />
        </Row>
      </Container>
    </div>
  );
};

export default MyAudience;
