export const OptionsCategory = [
  { value: "foundation", label: "Foundation" },
  { value: "concealer", label: "Concealer" },
  { value: "blush", label: "Blush" },
  { value: "highlighter", label: "Highlighter" },
  { value: "lipstick", label: "Lipstick" },
  { value: "contour", label: "Contour" },
  { value: "eyes", label: "Eyes" },
];

export const OptionsUndertone = [
  { value: "warm", label: "Warm" },
  { value: "cool", label: "Cool" },
  { value: "neutral", label: "Neutral" },
];

export const OptionsSubtone = [
  { value: "yellow", label: "Yellow" },
  { value: "peach", label: "Peach" },
  { value: "beige", label: "Beige" },
  { value: "olive", label: "Olive" },
  { value: "red", label: "Red" },
  { value: "golden", label: "Golden" },
];

export const OptionsCoverage = [
  { value: "light", label: "Light" },
  { value: "medium", label: "Medium" },
  { value: "full", label: "Full" },
  { value: "buildable", label: "Buildable" },
];

export const OptionsFinish = [
  { value: "dewy", label: "Dewy" },
  { value: "luminous", label: "Luminous" },
  { value: "matte", label: "Matte" },
  { value: "natural", label: "Natural" },
  { value: "satin", label: "Satin" },
  { value: "radiant", label: "Radiant" },
  { value: "powder", label: "Powder" },
  { value: "liquid", label: "Liquid" },
];

export const OptionsCurrency = [
  { value: "USD", label: "USD" },
  { value: "GBP", label: "GBP" },
];

export const OptionsSkinType = [
  { value: "normal", label: "Normal" },
  { value: "oily", label: "Oily" },
  { value: "dry", label: "Dry" },
  { value: "combination", label: "Combination" },
  { value: "all", label: "All" },
];

export const OptionsBundleType = [
  { value: "for-contouring", label: "For Contouring" },
  { value: "for-mattifying", label: "For Mattifying" },
  { value: "for-priming", label: "For Priming" },
  { value: "for-concealing", label: "For Concealing" },
  { value: "for-highlighting", label: "For Highlighting" },
  { value: "for-blush", label: "For Blush" },
  { value: "for-foundation", label: "For Foundation" },
  { value: "for-bronzing", label: "For Bronzing" },
  { value: "for-setting", label: "For Setting" },
  { value: "for-brightening", label: "For Brightening" },
];

export const OptionsPriority = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
];

export const OptionsCampaignCategory = [
  { value: "Advertorial", label: "Advertorial" },
  { value: "Evergreen Content", label: "Evergreen Content" },
  { value: "Influencer Campaign", label: "Influencer Campaign" },
  { value: "Publisher Campaign", label: "Publisher Campaign" },
  { value: "In-store Partership", label: "In-store Partership" },
];

export const OptionsIntegrations = [
  { value: "Partnerize", label: "Partnerize" },
];

export const OptionsAffiliateIDs = [
  { value: "Affiliate ID 1", label: "Affiliate ID 1" },
  { value: "Affiliate ID 2", label: "Affiliate ID 2" },
  { value: "Affiliate ID 3", label: "Affiliate ID 3" },
  { value: "Affiliate ID 4", label: "Affiliate ID 4" },
];

// AI training return form options
export const OptionsReturnReasons = [
  { value: "Too light", label: "Too light" },
  { value: "Too dark", label: "Too dark" },
  { value: "Wrong undertone", label: "Wrong undertone" },
  { value: "Wrong coverage", label: "Wrong coverage" },
  { value: "Wrong finish", label: "Wrong finish" },
  { value: "Bad ingredients", label: "Bad ingredients" },
  { value: "Allergy", label: "Allergy" },
  { value: "Broken product", label: "Broken product" },
];

export const OptionsTimeframes = [
  { value: "Last 30 days", label: "Last 30 days" },
  { value: "Last 6 months", label: "Last 6 months" },
  { value: "Last 1 year", label: "Last 1 year" },
  { value: "custom", label: "Custom Range" },
];

export const OptionsPreferences = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

export const OptionsUserRoles = [
  { value: "customer-view-only", label: "View Only" },
  { value: "customer", label: "Admin" },
  { value: "customer-manager", label: "Editor" },
];
