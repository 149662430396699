export const APP_LIST = "APP_LIST"
export const APP_LIST_SUCCESS = "APP_LIST_SUCCESS"
export const APP_LIST_FAILURE = "APP_LIST_FAILURE"

export const APP_DETAILS = "APP_DETAILS";
export const APP_DETAILS_SUCCESS = "APP_DETAILS_SUCCESS";
export const APP_DETAILS_FAILURE = "APP_DETAILS_FAILURE";

export const APP_DETAILS_UPDATE = "APP_DETAILS_UPDATE";
export const APP_DETAILS_UPDATE_SUCCESS = "APP_DETAILS_UPDATE_SUCCESS";
export const APP_DETAILS_UPDATE_FAILURE = "APP_DETAILS_UPDATE_FAILURE";

export const APP_QUESTION_DETAILS = "APP_QUESTION_DETAILS";
export const APP_QUESTION_DETAILS_SUCCESS = "APP_QUESTION_DETAILS_SUCCESS";
export const APP_QUESTION_DETAILS_FAILURE = "APP_QUESTION_DETAILS_FAILURE";

export const UPDATE_QUESTIONS = 'UPDATE_QUESTIONS';
export const UPDATE_QUESTIONS_SUCCESS = "UPDATE_QUESTIONS_SUCCESS";
export const UPDATE_QUESTIONS_FAILURE = "UPDATE_QUESTIONS_FAILURE";

export const PROFILE_DETAILS = "PROFILE_DETAILS";
export const PROFILE_DETAILS_SUCCESS = "PROFILE_DETAILS_SUCCESS";
export const PROFILE_DETAILS_FAILURE = "PROFILE_DETAILS_FAILURE";

export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_FAILURE = "UPDATE_PROFILE_FAILURE";

export const RESET_APP_FLAG = "RESET_APP_FLAG";

export const GET_TEAMMATES = "GET_TEAMMATES";
export const GET_TEAMMATES_SUCCESS = "GET_TEAMMATES_SUCCESS";
export const GET_TEAMMATES_FAILURE = "GET_TEAMMATES_FAILURE";

export const SEND_INVITE = "SEND_INVITE";
export const SEND_INVITE_SUCCESS = "SEND_INVITE_SUCCESS";
export const SEND_INVITE_FAILURE = "SEND_INVITE_FAILURE";

export const UPDATE_INVITE = "UPDATE_INVITE";
export const UPDATE_INVITE_SUCCESS = "UPDATE_INVITE_SUCCESS";
export const UPDATE_INVITE_FAILURE = "UPDATE_INVITE_FAILURE";

export const DELETE_TEAMMATE = "DELETE_TEAMMATE";
export const DELETE_TEAMMATE_SUCCESS = "DELETE_TEAMMATE_SUCCESS";
export const DELETE_TEAMMATE_FAILURE = "DELETE_TEAMMATE_FAILURE";
