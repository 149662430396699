import React from "react";
import { Col, Row, Table } from "reactstrap";

const CommonCard = ({ children, title }) => {
  return (
    <div className="common-card">
      <Row className="header">
        <Col className="card-title">{title}</Col>
      </Row>
      <Row className="items-table">
        <Col className="item-list">
          <Table className="align-middle table-nowrap mb-0">{children}</Table>
        </Col>
      </Row>
    </div>
  );
};

export default CommonCard;
