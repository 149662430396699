const number2Str = (number, isInteger = false, decimals = 2) => {
  let updatedNumber = number;
  const formatted = updatedNumber?.toFixed(decimals);
  const intPart = formatted?.split(".")?.[0];
  const floatPart = formatted?.split(".")?.[1];
  const intPartStr = Number(intPart)?.toLocaleString();

  if (updatedNumber >= 1.0e12) return updatedNumber.toExponential(2);

  if (isInteger) return intPartStr;

  updatedNumber = `${intPartStr}${floatPart ? "." : ""}${
    floatPart ? floatPart : ""
  }`;

  return updatedNumber;
};

const NumberBox = ({
  number,
  prefix = "",
  suffix = "",
  isInteger = false,
  decimals = 2,
  ignoreZeroValue = false,
}) => {
  return (
    <>
      {number === null ||
      number === undefined ||
      (ignoreZeroValue && number == 0)
        ? "-"
        : `${prefix}${number2Str(+number, isInteger, decimals)}${suffix}`}
    </>
  );
};

export default NumberBox;
