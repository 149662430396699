import React, { useEffect, useLayoutEffect } from "react";

//Import Components
import MimeLayout from "./MimeLayout";
import { useLocation } from "react-router-dom";

const Sidebar = ({ layoutType }) => {
  const location = useLocation();

  useEffect(() => {
    var verticalOverlay = document.getElementsByClassName("vertical-overlay");
    if (verticalOverlay) {
      verticalOverlay[0].addEventListener("click", function () {
        document.body.classList.remove("vertical-sidebar-enable");
      });
    }
  });

  useLayoutEffect(() => {
    const appMenu = document.querySelector(".app-menu");
    const layoutWrapper = document.querySelector("#layout-wrapper");
    if (!location.pathname.includes("dashboard")) {
      appMenu.classList.remove("primary-menu");
      appMenu.classList.add("secondary-menu");
      layoutWrapper.classList.remove("dashboard-wrapper");
      return;
    }
    layoutWrapper.classList.add("dashboard-wrapper");
    appMenu.classList.remove("secondary-menu");
    appMenu.classList.add("primary-menu");
  }, [location]);

  return (
    <React.Fragment>
      <div className="app-menu navbar-menu">
        <React.Fragment>
          <MimeLayout layoutType={layoutType} />
        </React.Fragment>
      </div>
      <div className="vertical-overlay"></div>
    </React.Fragment>
  );
};

export default Sidebar;
