import {
	MENU_STATUS,
	PRODUCTS_UPDATED,
	BUNDLES_UPDATED,
	CAMPAIGN_UPDATED,
	TRAININGS_UPDATED,
	ACTIVE_MENU,
	APPS_UPDATED,
} from './actionTypes'

export const setActiveMenu = (payload) => {
	return { type : ACTIVE_MENU, payload }
}

export const updateMenuStatus = (payload) => {
	return { type: MENU_STATUS, payload: payload }
}

export const productsUpdated = response => {
	return { type: PRODUCTS_UPDATED, payload: response.products, }
}

export const bundlesUpdated = response => {
	return { type: BUNDLES_UPDATED, payload: response.bundles, }
}

export const camapaignUpdated = response => {
	return { type: CAMPAIGN_UPDATED, payload: response.campaigns, }
}

export const trainingUpdated = response => {
	return { type: TRAININGS_UPDATED, payload: response.products, }
}

export const appsUpdated = response => {
	return { type: APPS_UPDATED, payload : response.apps, }
}