import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Table } from "reactstrap";

import Loader from "../../Components/Common/Loader";
import TimeInterval from "../../Components/Hooks/UseIntervalHook";

import { getRecentShoppers } from "../../store/dashboard/actions";

let intervalId = null;
const OnlineShoppers = () => {
  const dispatch = useDispatch();

  const { shoppers, isLoading } = useSelector((state) => ({
    shoppers: state.Dashboard.shoppers.data,
    isLoading: state.Dashboard.shoppers.loading,
  }));

  const fetchData = () => {
    dispatch(getRecentShoppers());
  };

  const handleOnError = (e) => {
    e.target.src = "/sample/avatar.png";
  };

  const startInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }

    intervalId = setInterval(fetchData, 30000);
  };

  const stopInterval = () => {
    if (intervalId) {
      clearInterval(intervalId);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    startInterval();

    return () => {
      stopInterval();
    };
  }, []);

  return (
    <div className="online-shoppers-container">
      <Col className="card-title">Online Shoppers</Col>
      {isLoading ? <Loader /> : null}
      <Table className="align-middle table-nowrap mb-0">
        <tbody className="item-list">
          {shoppers.map((shopper, idx) => (
            <tr className="item" key={idx}>
              <td className="item-image">
                <img src={shopper.imageUrl} onError={handleOnError} alt="" />
              </td>
              <td className="shopper-details">
                <span className="shopper-activity">
                  Shopper in {shopper.countryCode} -
                  <TimeInterval timestamp={parseInt(shopper.timestamp)} />
                </span>
                <div className="shopper-status">Looking for {shopper.type}</div>
              </td>
            </tr>
          ))}

          {!isLoading && !shoppers.length && <span>No data available</span>}
        </tbody>
      </Table>
    </div>
  );
};

export default OnlineShoppers;
