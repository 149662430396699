import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";
import { Check } from "feather-icons-react/build/IconComponents";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// common helper
import { getIDFromPath } from "../../../helpers/location-helper";
import Loader from "../../../Components/Common/Loader";
import FormSelectOptions from "../../../Components/Common/FormSelectOptions";
import PageHeader from "../../../Components/Common/PageHeader";
import ErrorController from "../../../Components/Common/ErrorController";

import { getVariants } from "../../../store/actions";
import {
  getTraining,
  addTraining,
  getBestPractises,
} from "../../../store/AiTraining/training/actions";
import { MenuItemEnums } from "../../../common/enums";

const TrainingProduct = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [OptionsVariant, setOptionsVariant] = useState([]);

  const {
    trainingInEdit,
    variants,
    bestPractises,
    isFetchingTraining,
    errorMsg,
  } = useSelector((state) => ({
    trainingInEdit: state.Training.trainingInEdit,
    isFetchingTraining: state.Training.loading,
    variants: state.Product.variants,
    bestPractises: state.Training.bestPractises,
    errorMsg: state.Training.errorMsg,
  }));

  const [variantColors, setVariantColors] = useState({});
  const productId = getIDFromPath(location.pathname);


  useEffect(() => {
    if (!productId) {
      setOptionsVariant([]);
      return;
    }

    dispatch(getTraining(productId));
    dispatch(getVariants(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    const options = variants.map((variant) => ({
      value: variant.id,
      label: variant.color,
      hexColor: variant.hexColor,
    }));
    const variantColorsOptions = {};
    for (const variant of variants) {
      variantColorsOptions[variant.id] = variant.hexColor;
    }

    setVariantColors(variantColorsOptions);

    setOptionsVariant(options);
  }, [dispatch, variants]);

  useEffect(() => {
    if (!trainingInEdit.photo) return;

    if (trainingInEdit.added) {
      toast.success("Training Added Successfully!");
    }
  }, [trainingInEdit.added]);

  const submitTraining = async (values) => {
    if (!trainingInEdit.photo || !productId) return;
    const trainingData = {
      ...values,
      groupId: productId,
      productId: values.variantId,
    };
    delete trainingData.variantId;
    dispatch(addTraining(trainingData));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      photoId: trainingInEdit.photo?.id,
      variantId: "",
    },
    validationSchema: Yup.object({
      variantId: Yup.number().required("Please Select Category"),
    }),
    onSubmit: (values) => {
      submitTraining(values);
    },
  });

  const checklists = useMemo(
    () => [
      {
        name: `Train ${
          bestPractises?.progress?.trainingCountRemaining ?? 0
        } images for this product line`,
        isChecked: bestPractises?.progress?.trainingSuccess ?? false,
      },
      {
        name: `Add ${
          bestPractises?.progress?.returnsCountRemaining ?? 0
        } returns - if applicable`,
        isChecked: bestPractises?.progress?.returnsSuccess ?? false,
      },
      {
        name: `Sync ${
          bestPractises?.progress?.ratingsCountRemaining ?? 0
        } ratings`,
        isChecked: bestPractises?.progress?.ratingsSuccess ?? false,
      },
      {
        name: "Check progress weekly",
        isChecked: false,
      },
    ],
    [bestPractises]
  );

  useEffect(() => {
    if (!productId) return;
    dispatch(getBestPractises(productId));
  }, [productId]);

  document.title = "AI Training | mime";

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container training-container">
            {!isFetchingTraining && !errorMsg ? (
              <Col className="training-card">
                <PageHeader title="Train 100 Images" />

                <div className="progress-bar">
                  <span style={{ width: trainingInEdit.progressBar }}></span>
                </div>

                <div className="training-details">
                  <div className="image-container">
                    <img
                      src={trainingInEdit.photo?.imageUrl}
                      alt="profile-dp"
                    />
                  </div>
                  <Col className="mime-form">
                    <Form
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                      action="#"
                    >
                      <Row className="mt-20">
                        <Label className="form-label">
                          Which shade is best for this customer?
                        </Label>
                        <div className="selector">
                          {validation.values.variantId ? (
                            <div
                              className="prefix"
                              style={{
                                backgroundColor:
                                  variantColors[validation.values.variantId],
                              }}
                            ></div>
                          ) : null}

                          <select
                            name="variantId"
                            value={validation.values.variantId}
                            onChange={(e) => {
                              const variantId = +e.target.value;
                              validation.handleChange(e);
                              validation.setFieldValue(
                                "variantId",
                                variantId,
                                true
                              );
                            }}
                            className={
                              "form-select" +
                              (validation.values.variantId
                                ? " left-padding"
                                : " empty") +
                              (validation.touched.variantId &&
                              validation.errors.variantId
                                ? " is-invalid"
                                : "")
                            }
                          >
                            <FormSelectOptions
                              categories={OptionsVariant}
                              defaultOption={
                                validation.values.variantId ? null : {}
                              }
                              hasPefix={true}
                            />
                          </select>
                        </div>
                        {validation.touched.variantId &&
                        validation.errors.variantId ? (
                          <FormFeedback type="invalid">
                            {validation.errors.variantId}
                          </FormFeedback>
                        ) : null}
                      </Row>
                      <Row className="mt-20">
                        <Button
                          type="submit"
                          className="form-button btn-text-primary"
                          disabled={trainingInEdit.loading}
                        >
                          {trainingInEdit.loading ? (
                            <Spinner size="sm" className="me-2">
                              Loading...
                            </Spinner>
                          ) : null}
                          Next
                        </Button>
                      </Row>
                    </Form>
                    <div className="mt-20 training-footer">
                      Bad quality photo? <span>Tell us why.</span>
                    </div>
                  </Col>
                </div>
              </Col>
            ) : errorMsg ? (
              <ErrorController messsage={errorMsg} />
            ) : null}

            {isFetchingTraining ? <Loader /> : null}
          </Col>
          <Col xs={4} className="mime-form right-edit scroll-container">
            <Row className="form-header">
              <PageHeader title="Best practices checklist" />
              <div className="progress-bar">
                <span style={{ width: bestPractises.progressBar }}></span>
              </div>
            </Row>
            {bestPractises.loading ? (
              <Loader />
            ) : (
              <div className="check-lists">
                {checklists.map((list, key) => (
                  <div className="list-item" key={key}>
                    <div
                      className={`check-circle ${
                        list.isChecked ? "active-check-circle" : ""
                      }`}
                    >
                      <Check />
                    </div>
                    <div>{list.name}</div>
                  </div>
                ))}
              </div>
            )}

            <Row className="form-header mt-5">
              <PageHeader title="Previous Training" />
              <Link to={`/${MenuItemEnums.aiTraining}/${MenuItemEnums.training}/${MenuItemEnums.history}/${productId}`} className="link-view-previous" >View previous training data</Link>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TrainingProduct;
