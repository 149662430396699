import {
  FORGET_PASSWORD,
  FORGET_PASSWORD_SUCCESS,
  FORGET_PASSWORD_ERROR,
} from "./actionTypes";

const initialState = {
  message: null,
  errorMsg: null,
  success: false,
  loading: false,
};

const forgotPassword = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD:
      state = {
        ...state, loading: true, message: null, errorMsg: null,
      };
      break;
    case FORGET_PASSWORD_SUCCESS:
      state = {
        ...state, message: action.payload, success: true, loading: false,
      };
      break;
    case FORGET_PASSWORD_ERROR:
      state = { ...state, errorMsg: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default forgotPassword;
