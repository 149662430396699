import {
	MENU_STATUS,
	PRODUCTS_UPDATED,
	BUNDLES_UPDATED,
	CAMPAIGN_UPDATED,
	TRAININGS_UPDATED,
	ACTIVE_MENU,
	APPS_UPDATED,
} from "./actionTypes";

import { Storage } from "../../helpers/Storage";
import { menuItems as menuData, updateMenuItems } from "../../Layouts/MimeLayout/MenuData";
import { MenuItemEnums, RouteParams } from "../../common/enums";

const INIT_MENU_STATUS = {
	menu: MenuItemEnums.dashboard,
	subMenu: { id: "dashboard" },
};

function initialState() {
	const menuItems = menuData();
	return {
		menuStatus: INIT_MENU_STATUS,
		menuItems: menuItems,
		activeMenuTitle : MenuItemEnums.dashboard
	};
}

const Menu = (state = initialState(), action) => {
	switch (action.type) {
		case ACTIVE_MENU: 
		return { ...state, activeMenuTitle: action.payload }
		
		case MENU_STATUS:
			if (!action.payload) return initialState();

			Storage.saveJson(MENU_STATUS, action.payload);
			return { ...state, menuStatus: action.payload };

		case PRODUCTS_UPDATED: {
			Storage.setProducts(action.payload);
			var childItems = Storage.getProductsMenus(action.payload);
			var menuItems = updateMenuItems(state.menuItems, MenuItemEnums.inventory, MenuItemEnums.product, childItems);
			return { ...state, menuItems: menuItems };
		}

		case BUNDLES_UPDATED:
			Storage.setBundles(action.payload);
			childItems = Storage.getBundlesMenus(action.payload);
			menuItems = updateMenuItems(state.menuItems, MenuItemEnums.inventory, MenuItemEnums.bundle, childItems);
			return { ...state, menuItems: menuItems };

		case CAMPAIGN_UPDATED:
			Storage.setCampaigns(action.payload);
			childItems = Storage.getCampaignsMenus(action.payload);
			menuItems = updateMenuItems(state.menuItems, MenuItemEnums.campaign, MenuItemEnums.myCampaign, childItems);
			return { ...state, menuItems: menuItems };

		case TRAININGS_UPDATED :
			Storage.setProducts(action.payload)
			childItems = Storage.getTrainingMenus(action.payload);
			menuItems = updateMenuItems(state.menuItems, MenuItemEnums.aiTraining, MenuItemEnums.training, childItems);
			return { ...state, menuItems: menuItems };
		
		case APPS_UPDATED : 
			Storage.setApps(action.payload);
			childItems = Storage.getAppMenus(action.payload);
			menuItems = updateMenuItems(state.menuItems, MenuItemEnums.settings, RouteParams.myApps, childItems);
			return {...state, menuItems:menuItems};

		default:
			return state;
	}
};

export default Menu;
