import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert,
  Spinner,
} from "reactstrap";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import logoLight from "../../assets/images/logo/mime-logo.png";
import withRouter from "../../Components/Common/withRouter";
import ParticlesAuth from "./ParticlesAuth";

import { userForgotPassword } from "../../store/actions";

const ForgotPasswordPage = (props) => {
  const history = useNavigate();
  const dispatch = useDispatch();

  const { errorMsg, messsage, success, loading } = useSelector((state) => ({
    errorMsg: state.ForgotPassword.errorMsg,
    messsage: state.ForgotPassword.messsage,
    success: state.ForgotPassword.success,
    loading: state.ForgotPassword.loading,
  }));

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid Email Format")
        .required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      dispatch(userForgotPassword(values, props.history));
    },
  });

  document.title = "Forgot Password | mime";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-2 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="30" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={6} lg={4} xl={4}>
                <Card className="mt-4 auth-card">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <span className="auth-card-title">
                        Forgot your password?
                      </span>
                      <p className="auth-card-subtitle">
                        Enter your email to continue.
                      </p>
                    </div>
                    <div className="p-2">
                      <Form
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        {success ? (
                          <Alert color="success">
                            {messsage ??
                              "If that email exists, we will send a reset password email momentarily."}
                          </Alert>
                        ) : null}
                        {errorMsg ? (
                          <Alert color="danger">
                            {errorMsg ??
                              "something went wrong!, please try again later"}
                          </Alert>
                        ) : null}

                        <div className="mb-3">
                          <Label className="form-label auth-card-label">
                            Email
                          </Label>
                          <Input
                            name="email"
                            type="email"
                            className="form-control auth-form-control"
                            placeholder="Enter email address"
                            value={validation.values.email || ""}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type="invalid">
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3">
                          <Button
                            color="secondary"
                            className="btn btn-secondary w-100 auth-button"
                            type="submit"
                            disabled={loading}
                          >
                            {!loading ? null : (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            )}
                            Send
                          </Button>
                        </div>

                        <div className="mt-4 text-center"></div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-8 text-center">
                  <p className="mb-0">
                    <Link to="/login" className="auth-request">
                      {" "}
                      Back to login{" "}
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default withRouter(ForgotPasswordPage);
