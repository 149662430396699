import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Table } from "reactstrap";
import { bundlesStateSelector } from "../../../store/inventory/bundle/bundleSelector";
import Loader from "../../../Components/Common/Loader";
import BundleForm from "./BundleForm";
import { Link } from "react-router-dom";
import { MenuItemEnums, RouteParams } from "../../../common/enums";
import PageHeader from "../../../Components/Common/PageHeader";

const Bundles = () => {
  const dispatch = useDispatch();
  const bundlesState = useSelector(bundlesStateSelector);

  const bundles = bundlesState?.data ?? [];
  const bundleCounts = bundlesState?.total ?? 0;
  const loading = bundlesState?.loading;

  document.title = "Bundle | mime";

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container main-container">
            <PageHeader
              title="Showing all Bundles"
              count={bundleCounts}
              subtitleSuffix="Bundle Groups"
            />

            {loading ? <Loader /> : null}

            {!loading ? (
              <Row>
                <Col className="product-list">
                  <Table className="align-middle table-nowrap mb-0">
                    <tbody>
                      {bundles &&
                        bundles.map((bundle, idx) => (
                          <tr className="product-item" key={idx}>
                            {/* <td className="product-item-image">
                              <img src={bundle.imageUrl} alt="" />
                            </td> */}
                            <td className="product-item-content">
                              <div className="title">{bundle.name}</div>
                              <div className="sub-title">
                                {bundle.totalProducts}{" "}
                                products
                              </div>
                            </td>
                            <td className="product-item-button">
                              <Link
                                to={`/${MenuItemEnums.inventory}/${MenuItemEnums.bundle}/${bundle?.id}`}
                              >
                                <button type="button" className="btn">
                                  {" "}
                                  Manage{" "}
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : null}
          </Col>
          <BundleForm />
        </Row>
      </Container>
    </div>
  );
};

export default Bundles;
