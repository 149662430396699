import {
  RETURN_LIST, RETURN_LIST_FAILURE, RETURN_LIST_SUCCESS,
  RETURN_ADD, RETURN_ADD_SUCCESS, RETURN_ADD_FAILURE,
  RETURN_UPDATE, RETURN_UPDATE_SUCCESS, RETURN_UPDATE_FAILURE,
  RETURN_REMOVE, RETURN_REMOVE_SUCCESS, RETURN_REMOVE_FAILURE,
  RESET_RETURN_FLAG,
} from "./actionType";

const initialState = {
  returns: [],
  total: 0,
  returnInEdit: {},
  loading: true,
  errorMsg: "",
  error: false,
};

const aiReturn = (state = initialState, action) => {
  switch (action.type) {
      case RETURN_LIST: {
      return {
        ...state, loading: true, error: false, errorMsg: "",
        returns: [],
      };
    }
    case RETURN_LIST_SUCCESS: {
      const { returns, totalReturns } = action.payload ?? {
        returns: [],
        totalReturns: 0,
      };
      return {
        ...state, returns: returns, total: totalReturns, loading: false,
      };
    }
    case RETURN_LIST_FAILURE: {
      return {
        ...state, loading: false, error: true, errorMsg: action.payload,
      };
    }

    case RETURN_ADD:
      return {
        ...state, error: false, errorMsg: "",
        returnInEdit: { ...state.returnInEdit, loading: true, added: false },
      };
    case RETURN_ADD_SUCCESS:
      return {
        ...state,
        returnInEdit: { ...state.returnInEdit, loading: false, added: true },
      };
    case RETURN_ADD_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        returnInEdit: { ...state.returnInEdit, loading: false, added: false },
      };

    case RETURN_UPDATE:
      return {
        ...state, error: false, errorMsg: "",
        returnInEdit: { ...state.returnInEdit, loading: true, updated: false },
      };
    case RETURN_UPDATE_SUCCESS:
      return {
        ...state,
        returnInEdit: { ...state.returnInEdit, loading: false, updated: true },
      };
    case RETURN_UPDATE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        returnInEdit: { ...state.returnInEdit, loading: false, updated: false },
      };

    case RETURN_REMOVE:
      return {
        ...state, error: false, errorMsg: "",
        returnInEdit: { ...state.returnInEdit, loading: true, removed: false },
      };
    case RETURN_REMOVE_SUCCESS:
      return {
        ...state,
        returnInEdit: { ...state.returnInEdit, loading: false, removed: true },
      };
    case RETURN_REMOVE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        returnInEdit: { ...state.returnInEdit, loading: false, removed: false },
      };

    case RESET_RETURN_FLAG:
      return {
        ...state, error: false, errorMsg: "",
        returnInEdit: { ...state.returnInEdit, loading: false, added: false, updated: false, removed: false }
      };
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default aiReturn;
