import React, { useEffect, useState } from "react";
import { Navigate, Route } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { logoutUser } from "../store/actions";
import Loader from "../Components/Common/Loader";

const AuthProtected = (props) => {
	const dispatch = useDispatch();
	const [isValidToken, setIsValidToken] = useState(false);
	const { userProfile, loading, token } = useProfile();

	const verifyAuthorization = async () => {
		if (userProfile && !loading && token) {
			setIsValidToken(true);
			setAuthorization(token);
		} else if (!userProfile && loading && !token) {
			dispatch(logoutUser());
		}
	};
	useEffect(() => {
		verifyAuthorization();
	}, [token, userProfile, loading, dispatch]);

	/*
		redirect is un-auth access protected routes via url
	*/
	if (!userProfile && loading && !token) {
		return (
			<Navigate to={{ pathname: "/login", state: { from: props.location } }} />
		);
	} else if (isValidToken) return <>{props.children}</>;

	return (
		<div className="d-flex justify-content-center align-items-center vh-100">
			<Loader />
		</div>
	);
};

const AccessRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={(props) => {
				return (
					<>
						<Component {...props} />
					</>
				);
			}}
		/>
	);
};

export { AuthProtected, AccessRoute };
