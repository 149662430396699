import { call, put, takeEvery } from "redux-saga/effects";
import {
  getBrandProducts,
  getBrands,
  getMissedConnections,
  sendEmailToConnection,
} from "../../helpers/backend_helper";

// Login Redux States
import {
  MISSED_CONNECTION_LIST,
  RETRIEVE_BRANDS,
  RETRIEVE_BRAND_PRODUCTS,
  SEND_EMAIL,
} from "./actionType";

// Include Both Helper File with needed methods
import {
  getMissedConnectionsFailure,
  getMissedConnectionsSuccess,
  retrieveBrandProductsFailed,
  retrieveBrandProductsSuccess,
  retrieveBrandsFailed,
  retrieveBrandsSuccess,
  sendMailFailed,
  sendMailSuccess,
} from "./actions";

function* retrieveMissedConnections() {
  try {
    const response = yield call(getMissedConnections, {});
    yield put(getMissedConnectionsSuccess(response));
  } catch (error) {
    yield put(getMissedConnectionsFailure(error));
  }
}

function* sentMail(value) {
  try {
    const response = yield call(sendEmailToConnection, value);
    yield put(sendMailSuccess(response));
  } catch (error) {
    yield put(sendMailFailed(error));
  }
}

function* retrieveBrands() {
  try {
    const response = yield call(getBrands);
    yield put(retrieveBrandsSuccess(response));
  } catch (error) {
    yield put(retrieveBrandsFailed(error));
  }
}

function* retriveBrandProducts({payload: brandId}) {
  try {
    const response = yield call(getBrandProducts, brandId);
    yield put(retrieveBrandProductsSuccess(response));
  } catch (error) {
    yield put(retrieveBrandProductsFailed(error));
  }
}

function* missedConnectionSaga() {
  yield takeEvery(MISSED_CONNECTION_LIST, retrieveMissedConnections);
  yield takeEvery(SEND_EMAIL, sentMail);
  yield takeEvery(RETRIEVE_BRANDS, retrieveBrands);
  yield takeEvery(RETRIEVE_BRAND_PRODUCTS, retriveBrandProducts);
}

export default missedConnectionSaga;
