import React, { useState, useEffect } from "react";
import { Row, Col, Form, FormFeedback, Label, Button, Spinner, } from "reactstrap";

// formik
import * as Yup from "yup";
import { useFormik } from "formik";
import FormSelectOptions from "../../../Components/Common/FormSelectOptions";
import { useDispatch, useSelector } from "react-redux";
import { useCallback } from "react";
import UseDropdowns from "../../../Components/Hooks/UseDropdowns";
import {
  retrieveBrands, retrieveBrandProducts,
} from "../../../store/audience/actions";
import { getColorBenchmarks } from "../../../store/reporting/action";

const ColorBenchmarksForm = () => {
  const dispatch = useDispatch();
  
  const { isFetching, brands, brandProducts } = useSelector((state) => ({
    isFetching: state.Report.loading,
    brands: state.Audience.brands,
    brandProducts: state.Audience.brandProducts,
  }));

  const { productOptions } = UseDropdowns(0);
  const [brandsOptions, setBrandsOptions] = useState([]);
  const [brandProductsOptions, setBrandProductsOptions] = useState([]);

  useEffect(() => {
    const mappedBrands = brands.map(
      (brand) => ({
        value: brand.id,
        label: brand.name,
      })
    );
    setBrandsOptions(mappedBrands);
  }, [brands]);

  useEffect(() => {
    const mappedProducts = brandProducts.map(
      (product) => ({
        value: product.groupId,
        label: product.name,
      })
    );
    setBrandProductsOptions(mappedProducts);
  }, [brandProducts]);

  useEffect(() => {
    dispatch(retrieveBrands());
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      brandId: "",
      baseGroupId: productOptions[0]?.value || "",
      compareGroupId: "",
    },
    validationSchema: Yup.object({
      brandId: Yup.number(),
      baseGroupId: Yup.string().required("Please Selecet Product"),
      compareGroupId: Yup.number(),
    }),
    onSubmit: (values) => {
      retrieveColorBenchmarks(values);
    },
  });

  const getBrandProducts = useCallback(async (Id) => {
    const brandId = +Id;
    dispatch(retrieveBrandProducts(brandId));
  }, []);

  useEffect(() => {
    if (!validation.values.brandId) return;
    getBrandProducts(validation.values.brandId);
  }, [validation.values.brandId]);

  const retrieveColorBenchmarks = useCallback(async (values) => {
    const filterValues = { ...values };
    delete filterValues.brandId;
    if (!filterValues.compareGroupId) delete filterValues.compareGroupId;
    
    return dispatch(getColorBenchmarks(filterValues));
  });

  useEffect(() => {
    if (!validation.values.baseGroupId) return;
    retrieveColorBenchmarks(validation.values);
  }, [validation.values.baseGroupId]);

  return (
    <Col xs={4} className="mime-form right-edit scroll-container">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Row className="form-header">
          <Col className="title">Filter</Col>
        </Row>

        <Row className="mt-20">
          <Label className="form-label">Base Product Line*</Label>
          <select
            name="baseGroupId"
            value={validation.values.baseGroupId}
            onChange={(e) => {
              const baseGroupId = +e.target.value;
              validation.handleChange(e);
              validation.setFieldValue("baseGroupId", baseGroupId, true);
            }}
            className={
              "form-select" +
              (validation.values.baseGroupId ? "" : " empty") +
              (validation.touched.baseGroupId && validation.errors.baseGroupId ? " is-invalid" : "")
            }
            disabled={productOptions.length === 0}
          >
            <FormSelectOptions
              categories={productOptions}
              defaultOptionLabel={
                productOptions.length === 0 ? "No data found." : ""
              }
              defaultOption={validation.values.baseGroupId ? null : {}}
            />
          </select>
          {validation.touched.baseGroupId && validation.errors.baseGroupId ? (
            <FormFeedback type="invalid">
              {validation.errors.baseGroupId}
            </FormFeedback>
          ) : null}
        </Row>

        <Row className="mt-20">
          <Label className="form-label">Brand to comapare</Label>
          <select
            name="brandId"
            value={validation.values.brandId}
            onChange={validation.handleChange}
            className={
              "form-select" +
              (validation.values.brandId ? "" : " empty") +
              (validation.touched.brandId && validation.errors.brandId ? " is-invalid" : "")
            }
          >
            <FormSelectOptions
              categories={brandsOptions}
              defaultOption={validation.values.brandId ? null : {}}
            />
          </select>
          {validation.touched.brandId && validation.errors.brandId ? (
            <FormFeedback type="invalid">
              {validation.errors.brandId}
            </FormFeedback>
          ) : null}
        </Row>

        <Row className="mt-20">
          <Label className="form-label">Brand Product Line to compare</Label>
          <select
            name="compareGroupId"
            value={validation.values.compareGroupId}
            onChange={(e) => {
              const compareGroupId = +e.target.value;
              validation.handleChange(e);
              validation.setFieldValue("compareGroupId", compareGroupId, true);
            }}
            className={
              "form-select" +
              (validation.values.compareGroupId ? "" : " empty") +
              (validation.touched.compareGroupId &&
              validation.errors.compareGroupId ? " is-invalid" : "")
            }
            disabled={brandProductsOptions.length === 0}
          >
            <FormSelectOptions
              categories={brandProductsOptions}
              defaultOptionLabel={
                brandProductsOptions.length === 0 ? "No data found." : ""
              }
              defaultOption={validation.values.compareGroupId ? null : {}}
            />
          </select>
          {validation.touched.compareGroupId &&
          validation.errors.compareGroupId ? (
            <FormFeedback type="invalid">
              {validation.errors.compareGroupId}
            </FormFeedback>
          ) : null}
        </Row>
        <Row className="mt-20">
          <Button type="submit" className="btn-lg" disabled={isFetching}>
            {isFetching ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            Run Report
          </Button>
        </Row>
      </Form>
    </Col>
  );
};

export default ColorBenchmarksForm;
