import { call, put, takeEvery } from "redux-saga/effects";
import {
	APP_DETAILS, APP_DETAILS_UPDATE, APP_LIST,
	APP_QUESTION_DETAILS, UPDATE_QUESTIONS,
	PROFILE_DETAILS, UPDATE_PROFILE,
	GET_TEAMMATES, SEND_INVITE, UPDATE_INVITE, DELETE_TEAMMATE,
} from "./actionType";

// Include Both Helper File with needed methods
import {
	getAppDetails, getApps, updateAppDetails,
	getAppQuestions, patchAppQuestions,
	getUserProfile, patchUserProfile,
	getInviteTeammates, postInvite, patchInvite, deleteInvite,
} from "../../helpers/backend_helper";

import {
	getAppDetailsSuccess, getAppDetailsFailure,
	updateAppDetailsSuccess, updateAppDetailsFailure,
	getAppListSuccess, getAppListFailure,

	getQuestionsDetailsSuccess, getQuestionsDetailsFailure,
	updateQuestionsSuccess, updateQuestionsFailure,

	getUserProfileSuccess, getUserProfileFailure,
	updateUserProfileSuccess, updateUserProfileFailure,

	getTeammatesFailure, getTeammatesSuccess,
	sendInviteSuccess, sendInviteFailure,
	updateInviteSuccess, updateInviteFailure,
	deleteTeammateSuccess, deleteTeammateFailure,
} from "./action";
import { appsUpdated } from "../actions";

function* retrieveAppDetails({ payload }) {
	try {
		const response = yield call(getAppDetails, payload);
		yield put(getAppDetailsSuccess(response));
	} catch (error) {
		yield put(getAppDetailsFailure(error));
	}
}

function* modifyAppDetails({ payload: { appId, data } }) {
	try {
		const response = yield call(updateAppDetails, appId, data);
		yield put(updateAppDetailsSuccess(response));
	} catch (error) {
		yield put(updateAppDetailsFailure(error));
	}
}

function* retrieveAppList({ payload: { isUpdateMenuList } }) {
	try {
		const response = yield call(getApps, {});
		yield put(getAppListSuccess(response));
		if (isUpdateMenuList) yield put(appsUpdated(response));
	} catch (error) {
		yield put(getAppListFailure(error));
	}
}

function* retrieveAppQuestions({ payload }) {
	try {
		const response = yield call(getAppQuestions, payload);
		yield put(getQuestionsDetailsSuccess(response));
	} catch (error) {
		yield put(getQuestionsDetailsFailure(error));
	}
}

function* updateAppQuestions({ payload: { appId, data } }) {
	try {
		const response = yield call(patchAppQuestions, appId, data);
		yield put(updateQuestionsSuccess(response));
	} catch (error) {
		yield put(updateQuestionsFailure(error));
	}
}

function* retrieveUserProfile() {
	try {
		const response = yield call(getUserProfile, {});
		yield put(getUserProfileSuccess(response));
	} catch (error) {
		yield put(getUserProfileFailure(error));
	}
}

function* updateUserProfile({ payload: data }) {
	try {
		const response = yield call(patchUserProfile, data);
		yield put(updateUserProfileSuccess(response));
	} catch (error) {
		yield put(updateUserProfileFailure(error));
	}
}

function* retrieveTeammates({ payload: data }) {
	try {
		const response = yield call(getInviteTeammates, data);
		yield put(getTeammatesSuccess(response));
	} catch (error) {
		yield put(getTeammatesFailure(error));
	}
}

function* sendInvite({ payload: data }) {
	try {
		const response = yield call(postInvite, data);
		yield put(sendInviteSuccess(response));
	} catch (error) {
		yield put(sendInviteFailure(error));
	}
}

function* updateInvite({ payload: data }) {
	try {
		const response = yield call(patchInvite, data);
		yield put(updateInviteSuccess(response));
	} catch (error) {
		yield put(updateInviteFailure(error));
	}
}

function* removeInvite({ payload: userId }) {
	try {
		const response = yield call(deleteInvite, userId);
		yield put(deleteTeammateSuccess(userId));
	} catch (error) {
		yield put(deleteTeammateFailure(error));
	}
}

function* appSaga() {
	yield takeEvery(APP_LIST, retrieveAppList);
	yield takeEvery(APP_DETAILS, retrieveAppDetails);
	yield takeEvery(APP_DETAILS_UPDATE, modifyAppDetails);
	yield takeEvery(APP_QUESTION_DETAILS, retrieveAppQuestions);
	yield takeEvery(UPDATE_QUESTIONS, updateAppQuestions);
	yield takeEvery(PROFILE_DETAILS, retrieveUserProfile);
	yield takeEvery(UPDATE_PROFILE, updateUserProfile);
	yield takeEvery(GET_TEAMMATES, retrieveTeammates);
	yield takeEvery(SEND_INVITE, sendInvite);
	yield takeEvery(UPDATE_INVITE, updateInvite);
	yield takeEvery(DELETE_TEAMMATE, removeInvite);
}

export default appSaga;
