import React from "react";
import { Navigate } from "react-router-dom";

import { MenuItemEnums, RouteParams } from "../common/enums";

// login
import Login from "../pages/Authentication/Login";
import ForgotPasswordPage from "../pages/Authentication/ForgotPassword";
import ChangePasswordPage from "../pages/Authentication/ChangePassword";
import Register from "../pages/Authentication/Register";

// Home
import Dashboard from "../pages/Home/Dashboard";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";

// Inventory
import Products from "../pages/Inventory/Product/Products";
import ProductVariants from "../pages/Inventory/Product/ProductVariants";
import Bundles from "../pages/Inventory/Bundle/Bundles";
import BundleProducts from "../pages/Inventory/Bundle/BundleProducts";

// Campaign
import Campaigns from "../pages/Campaign/Campaigns";
import CampaignForm from "../pages/Campaign/CampaignForm";

// AI training
import TrainingProduct from "../pages/AITraining/Training/TrainingProduct";
import TrainingHistory from "../pages/AITraining/Training/TrainingHistory";
import Returns from "../pages/AITraining/Returns/Returns";
import Trainings from "../pages/AITraining/Training/Trainings";

// revenue
import Reporting from "../pages/Reporting/Reporting";
import Report from "../pages/Reporting";

// audience
import Opportunities from "../pages/Audience";
import MissedConnections from "../pages/Audience/MissedConnections";
import MyAudience from "../pages/Audience/Analysis/MyAudience";
import ColorBenchmarks from "../pages/Audience/Analysis/ColorBenchmarks";
// import Undeserved from "../pages/Audience/Undeserved";

// settings
import Questions from "../pages/Settings/FoundationFounder/Questions/Questions";
import Branding from "../pages/Settings/FoundationFounder/Branding";
import Basics from "../pages/Settings/FoundationFounder/MetaElements/Basics";
import Legal from "../pages/Settings/FoundationFounder/Legal/Legal";
import EmailAndPassword from "../pages/Settings/EmailPassword/EmailAndPassword";
import MyTeam from "../pages/Settings/MyTeam/MyTeam";
import AboutMe from "../pages/Settings/AboutMe/AboutMe";


const authProtectedRoutes = [
  // Home
  { path: `/${MenuItemEnums.dashboard}`, component: <Dashboard /> },

  // Inventory
  { path: `/${MenuItemEnums.inventory}/${MenuItemEnums.product}`, component: <Products /> },
  { path: `/${MenuItemEnums.inventory}/${MenuItemEnums.product}/:id`, component: <ProductVariants /> },
  { path: `/${MenuItemEnums.inventory}/${MenuItemEnums.bundle}`, component: <Bundles /> },
  { path: `/${MenuItemEnums.inventory}/${MenuItemEnums.bundle}/:id`, component: <BundleProducts /> },

  // Campaign
  { path: `/${MenuItemEnums.campaign}/${MenuItemEnums.myCampaign}`, component: <Campaigns /> },
  { path: `/${MenuItemEnums.campaign}/${MenuItemEnums.myCampaign}/:id`, component: <CampaignForm /> },

  // audience
  {path  :`/${MenuItemEnums.audience}/${RouteParams.opportunities}`, component : <Opportunities />},
  {path  :`/${MenuItemEnums.audience}/${RouteParams.opportunities}/${RouteParams.missedConnections}`, component : <MissedConnections />},
  {path  :`/${MenuItemEnums.audience}/${RouteParams.opportunities}/${RouteParams.missedConnections}/:id`, component : <MissedConnections />},
  // {path  :`/${MenuItemEnums.audience}/${RouteParams.opportunities}/${RouteParams.undeserved}`, component : <Undeserved />},
  {path  :`/${MenuItemEnums.audience}/${RouteParams.analysis}/${RouteParams.myAudience}`, component : <MyAudience />},
  {path  :`/${MenuItemEnums.audience}/${RouteParams.analysis}/${RouteParams.colorBenchMarks}`, component : <ColorBenchmarks />},

  // AI training
  {path : `/${MenuItemEnums.aiTraining}/${MenuItemEnums.training}`, component : <Trainings/>},
  {path : `/${MenuItemEnums.aiTraining}/${MenuItemEnums.training}/:id`, component : <TrainingProduct/>},
  {path : `/${MenuItemEnums.aiTraining}/${MenuItemEnums.training}/${MenuItemEnums.history}/:id`, component : <TrainingHistory/>},
  {path : `/${MenuItemEnums.aiTraining}/${RouteParams.returns}`, component : <Returns/>},
  {path : `/${MenuItemEnums.aiTraining}/${RouteParams.returns}/${RouteParams.manageReturns}`, component : <Returns/>},

  // revenue
  {path : `${MenuItemEnums.reporting}`, component : <Reporting/>},
  {path : `${MenuItemEnums.revenue}/${MenuItemEnums.reporting}/:id`, component : <Report/>},

  // settings
  {path : `${MenuItemEnums.settings}/${RouteParams.myApps}/${RouteParams.basics}/:id`, component : <Basics/>},
  {path : `${MenuItemEnums.settings}/${RouteParams.myApps}/${RouteParams.branding}/:id`, component : <Branding/>},
  {path : `${MenuItemEnums.settings}/${RouteParams.myApps}/${RouteParams.questions}/:id`, component : <Questions/>},
  {path : `${MenuItemEnums.settings}/${RouteParams.myApps}/${RouteParams.legal}/:id`, component : <Legal/>},
  {path : `${MenuItemEnums.settings}/${MenuItemEnums.account}/${RouteParams.emailPassword}`, component : <EmailAndPassword/>},
  {path : `${MenuItemEnums.settings}/${MenuItemEnums.account}/${RouteParams.myTeam}`, component : <MyTeam/>},
  {path : `${MenuItemEnums.settings}/${MenuItemEnums.account}/${RouteParams.aboutMe}`, component : <AboutMe/>},
  
  

  // User Profile
  { path: "/profile", component: <UserProfile /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: `/${MenuItemEnums.inventory}`, component: <Navigate to={`/${MenuItemEnums.inventory}/${MenuItemEnums.product}`} /> },
  { path: "/", component: <Navigate to={`/${MenuItemEnums.dashboard}`} /> },
  { path: "*", component: <Navigate to={`/${MenuItemEnums.dashboard}`} /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgotPasswordPage /> },
  { path: "/change-password", component: <ChangePasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes };
