import {
  PRODUCT_LIST, PRODUCT_LIST_SUCCESS, PRODUCT_LIST_FAILURE,
  PRODUCT_DETAILS, PRODUCT_DETAILS_SUCCESS, PRODUCT_DETAILS_FAILURE,
  PRODUCT_ADD, PRODUCT_ADD_SUCCESS, PRODUCT_ADD_FAILURE,
  PRODUCT_UPDATE, PRODUCT_UPDATE_SUCCESS, PRODUCT_UPDATE_FAILURE,
  PRODUCT_REMOVE, PRODUCT_REMOVE_SUCCESS, PRODUCT_REMOVE_FAILURE,

  VARIANT_LIST, VARIANT_LIST_SUCCESS, VARIANT_LIST_FAILURE,
  VARIANT_DETAILS, VARIANT_DETAILS_SUCCESS, VARIANT_DETAILS_FAILURE,
  VARIANT_ADD, VARIANT_ADD_SUCCESS, VARIANT_ADD_FAILURE,
  VARIANT_UPDATE, VARIANT_UPDATE_SUCCESS, VARIANT_UPDATE_FAILURE,
  VARIANT_REMOVE, VARIANT_REMOVE_SUCCESS, VARIANT_REMOVE_FAILURE,
  
  BRAND_LIST, BRAND_LIST_SUCCESS, BRAND_LIST_FAILURE,
  CLEAR_PRODUCT_DETAILS, CLEAR_VARIANT_DETAILS,
  RESET_PRODUCT_FLAG, RESET_VARIANT_FLAG,
} from "./actionTypes";

const initialState = {
  products: {
    data: [],
    loading: true,
    total: 0,
  },
  variants: [],
  brands: {
    data: [],
    loading: false,
  },
  productInEdit: {},
  variantInEdit: {},
  productWithLabel: {},
  errorMsg: "",
  loading: false,
  error: false,
};

const product = (state = initialState, action) => {
  switch (action.type) {
    case PRODUCT_LIST: {
      return {
        ...state, error: false, errorMsg: "",
        products: {
          ...state.products,
          loading: true,
          data: [],
        },
      };
    }
    case PRODUCT_LIST_SUCCESS: {
      const { products, totalProducts } = action?.payload ?? {
        products: [],
        totalProducts: 0,
      };

      // for get name with help of id
      const productWithLabel = {};
      products.forEach(
        (availableProduct) =>
          (productWithLabel[availableProduct.groupId] = availableProduct.name)
      );

      return {
        ...state,
        products: {
          ...state.products,
          loading: false,
          data: products,
          total: totalProducts,
        },
        productWithLabel: productWithLabel,
      };
    }
    case PRODUCT_LIST_FAILURE: {
      return {
        ...state, error: true, errorMsg: action.payload,
        products: {
          ...state.products,
          loading: false,
        },
      };
    }

    case PRODUCT_DETAILS:
      return {
        ...state, error: false, errorMsg: "",
        productInEdit: { ...state.productInEdit, loading: true },
      };
    case PRODUCT_DETAILS_SUCCESS:
      return {
        ...state,
        productInEdit: { ...action.payload, loading: false },
      };
    case PRODUCT_DETAILS_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        productInEdit: { ...state.productInEdit, loading: false },
      };

    case PRODUCT_ADD:
      return {
        ...state, error: false, errorMsg: "",
        productInEdit: { ...state.productInEdit, loading: true, added: false },
      };
    case PRODUCT_ADD_SUCCESS:
      return {
        ...state,
        productInEdit: { ...state.productInEdit, loading: false, added: true },
      };
    case PRODUCT_ADD_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        productInEdit: { ...state.productInEdit, loading: false, added: false },
      };

    case PRODUCT_UPDATE:
      return {
        ...state, error: false, errorMsg: "",
        productInEdit: { ...state.productInEdit, loading: true, updated: false },
      };
    case PRODUCT_UPDATE_SUCCESS:
      return {
        ...state,
        productInEdit: { ...state.productInEdit, loading: false, updated: true },
      };
    case PRODUCT_UPDATE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        productInEdit: { ...state.productInEdit, loading: false, updated: false },
      };

    case PRODUCT_REMOVE:
      return {
        ...state, error: false, errorMsg: "",
        productInEdit: { ...state.productInEdit, loading: true, removed: false },
      };
    case PRODUCT_REMOVE_SUCCESS:
      return {
        ...state,
        productInEdit: { ...state.productInEdit, loading: false, removed: true },
      };
    case PRODUCT_REMOVE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        productInEdit: { ...state.productInEdit, loading: false, removed: false },
      };

    case VARIANT_LIST:
      return { ...state, loading: true, error: false, errorMsg: "" };
    case VARIANT_LIST_SUCCESS:
      return {
        ...state, loading: false,
        variants: action.payload.variants,
      };
    case VARIANT_LIST_FAILURE:
      return {
        ...state, loading: false, error: true, errorMsg: action.payload,
      };

    case VARIANT_DETAILS:
      return {
        ...state, error: false, errorMsg: "",
        variantInEdit: { loading: true },
      };
    case VARIANT_DETAILS_SUCCESS:
      return {
        ...state,
        variantInEdit: { ...action.payload, loading: false },
      };
    case VARIANT_DETAILS_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        variantInEdit: { ...state.variantInEdit, loading: false },
      };

    case VARIANT_ADD:
      return {
        ...state, error: false, errorMsg: "",
        variantInEdit: { ...state.variantInEdit, loading: true, added: false },
      };
    case VARIANT_ADD_SUCCESS:
      return {
        ...state,
        variantInEdit: { ...state.variantInEdit, loading: false, added: true },
      };
    case VARIANT_ADD_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        variantInEdit: { ...state.variantInEdit, loading: false, added: false },
      };

    case VARIANT_UPDATE:
      return {
        ...state, error: false, errorMsg: "",
        variantInEdit: { ...state.variantInEdit, loading: true, updated: false },
      };
    case VARIANT_UPDATE_SUCCESS:
      return {
        ...state,
        variantInEdit: { ...state.variantInEdit, loading: false, updated: true },
      };
    case VARIANT_UPDATE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        variantInEdit: { ...state.variantInEdit, loading: false, updated: false },
      };

    case VARIANT_REMOVE:
      return {
        ...state, error: false, errorMsg: "",
        variantInEdit: { ...state.variantInEdit, loading: true, removed: false },
      };
    case VARIANT_REMOVE_SUCCESS:
      return {
        ...state,
        variantInEdit: { ...state.variantInEdit, loading: false, removed: true },
      };
    case VARIANT_REMOVE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        variantInEdit: { ...state.variantInEdit, loading: false, removed: false },
      };

    case BRAND_LIST:
      return {
        ...state, error: false, errorMsg: "",
        brands: { ...state.brands, loading: true },
      };
    case BRAND_LIST_SUCCESS:
      return {
        ...state,
        brands: { data: action.payload.brands, loading: false },
      };
    case BRAND_LIST_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        brands: { ...state.brands, loading: false },
      };

    case RESET_PRODUCT_FLAG:
      return {
        ...state,
        productInEdit: { ...state.productInEdit, loading: false, added: false, updated: false, removed: false }
      };
    case RESET_VARIANT_FLAG:
      return {
        ...state,
        variantInEdit: { ...state.variantInEdit, loading: false, added: false, updated: false, removed: false }
      };
    case CLEAR_PRODUCT_DETAILS:
      return { ...state, productInEdit: {} };
    case CLEAR_VARIANT_DETAILS:
      return { ...state, variantInEdit: {} };

    default:
      return { ...state };
  }
};

export default product;
