import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// common helpers
import CommonCard from "./Common/CommonCard";
import NumberBox from "../../Components/Common/NumberBox";
import Loader from "../../Components/Common/Loader";

import { getCountries } from "../../store/dashboard/actions";

const TopCountries = () => {
  const dispatch = useDispatch();

  const {
    dateRange, countries, isFetchingData
  } = useSelector((state) => ({
    dateRange: state.Dashboard.dateRange,
    countries: state.Dashboard.countries.data,
    isFetchingData: state.Dashboard.countries.loading,
  }));

	useEffect(() => {
    dispatch(getCountries({startTime: dateRange[0], endTime: dateRange[1], grouping: "month"}));
	}, [dateRange]);

	return (
		<CommonCard title="Top Countries">
			<tbody>
				{isFetchingData ? <Loader /> : null}
				{!isFetchingData && countries.length ?
					countries.map((item, idx) => (
						<tr className="item" key={idx}>
							<td className="item-name">
								<div>{item.country} ({item.countryCode})</div>
							</td>
							<td className="item-value">
								<NumberBox number={item.users} isInteger /> Shoppers
							</td>
						</tr>
					))
				: !isFetchingData && !countries.length ? (
					<span>No data available for this time period</span>
				) : null}
			</tbody>
		</CommonCard>
	);
};

export default TopCountries;
