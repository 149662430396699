import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { Col, Container, Form, Label, Row } from "reactstrap";
import { toast } from "react-toastify";

// common helper
import { getIDFromPath } from "../../../helpers/location-helper";
import Loader from "../../../Components/Common/Loader";
import FormSelectOptions from "../../../Components/Common/FormSelectOptions";
import PageHeader from "../../../Components/Common/PageHeader";
import ErrorController from "../../../Components/Common/ErrorController";

import { getVariants } from "../../../store/actions";
import {
  getTrainingHistory,
  updateTrainingHistory,
} from "../../../store/AiTraining/training/actions";
import { MenuItemEnums } from "../../../common/enums";

const TrainingHistory = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [OptionsVariant, setOptionsVariant] = useState([]);

  const { trainingHistory, variants, isFetchingTraining, errorMsg } =
    useSelector((state) => ({
      trainingHistory: state.Training.trainingHistory.data,
      isFetchingTraining: state.Training.loading,
      variants: state.Product.variants,
      errorMsg: state.Training.errorMsg,
    }));

  const [variantColors, setVariantColors] = useState({});
  const productId = getIDFromPath(location.pathname);

  const OptionsUndertones = [
    { label: "Cool", value: "cool" },
    { label: "Warm", value: "warm" },
    { label: "Neutral", value: "neutral" },
  ];

  useEffect(() => {
    if (!productId) {
      setOptionsVariant([]);
      return;
    }

    dispatch(getVariants(productId));
    dispatch(getTrainingHistory(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    const options = variants.map((variant) => ({
      value: variant.id,
      label: variant.color,
      hexColor: variant.hexColor,
    }));
    const variantColorsOptions = {};
    for (const variant of variants) {
      variantColorsOptions[variant.id] = variant.hexColor;
    }

    setVariantColors(variantColorsOptions);

    setOptionsVariant(options);
  }, [dispatch, variants]);

  const handleChange = (val, id, key) => {
    const data = { [key]: val, id };
    dispatch(updateTrainingHistory(data));
  };

  document.title = "AI Training | mime";

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container main-container">
            <PageHeader
              title="Showing Previous Training Data"
              editLink={`/${MenuItemEnums.aiTraining}/${MenuItemEnums.training}/${productId}`}
              count={trainingHistory?.length || 0}
              subtitleSuffix="image labels"
              editSuffix="Back To Training"
              isBack
            />
            {!isFetchingTraining && !errorMsg ? (
              <Row>
                {trainingHistory?.map((item, ind) => (
                  <Col xs={12} lg={6} className="training-card mt-4" key={ind}>
                    <div className="training-details">
                      <div className="image-container">
                        <div className="history-container">
                          <img src={item?.imageUrl} alt="profile-dp" />
                          <Col className="mime-form">
                            <Form
                              onSubmit={(e) => {
                                e.preventDefault();
                                return false;
                              }}
                              action="#"
                            >
                              <Row className="mt-10">
                                <Label className="form-label">
                                  Which shade is best for this customer?
                                </Label>
                                <div className="selector">
                                  {item.variantId ? (
                                    <div
                                      className="prefix"
                                      style={{
                                        backgroundColor:
                                          variantColors[item.variantId],
                                      }}
                                    ></div>
                                  ) : null}

                                  <select
                                    name="variantId"
                                    value={item.variantId}
                                    onChange={(e) => {
                                      const val = +e.target.value;
                                      handleChange(val, item.id, "variantId");
                                    }}
                                    className={
                                      "form-select" +
                                      (item.variantId
                                        ? " left-padding"
                                        : " empty")
                                    }
                                  >
                                    <FormSelectOptions
                                      categories={OptionsVariant}
                                      defaultOption={item.variantId ? null : {}}
                                      hasPefix={true}
                                    />
                                  </select>
                                </div>
                              </Row>
                              <Row className="mt-20">
                                <Label className="form-label">
                                  Which undertone is this customer?
                                </Label>
                                <div className="selector">
                                  <select
                                    name="undertone"
                                    value={item.undertone}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      handleChange(value, item.id, "undertone");
                                    }}
                                    className={"form-select"}
                                  >
                                    <FormSelectOptions
                                      categories={OptionsUndertones}
                                      defaultOption={item.undertone ? null : {}}
                                      hasPefix={true}
                                    />
                                  </select>
                                </div>
                              </Row>
                            </Form>
                          </Col>
                        </div>
                      </div>
                    </div>
                  </Col>
                ))}
              </Row>
            ) : errorMsg ? (
              <ErrorController messsage={errorMsg} />
            ) : null}

            {isFetchingTraining ? <Loader /> : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TrainingHistory;
