export const TRAINING_LIST = "TRAINING_LIST";

export const TRAINING = "TRAINING";
export const TRAINING_SUCCESS = "TRAINING_SUCCESS";
export const TRAINING_FAILURE = "TRAINING_FAILURE";

export const TRAINING_ADD = "TRAINING_ADD";
export const TRAINING_ADD_SUCCESS = "TRAINING_ADD_SUCCESS";
export const TRAINING_ADD_FAILURE = "TRAINING_ADD_FAILURE";

export const BEST_PRACTISES = "BEST_PRACTISES";
export const BEST_PRACTISES_SUCCESS = "BEST_PRACTISES_SUCCESS";
export const BEST_PRACTISES_FAILURE = "BEST_PRACTISES_FAILURE";

export const TRAINING_HISTORY = "TRAINING_HISTORY";
export const TRAINING_HISTORY_SUCCESS = "TRAINING_HISTORY_SUCCESS";
export const TRAINING_HISTORY_FAILURE = "TRAINING_HISTORY_FAILURE";

export const TRAINING_HISTORY_UPDATE = "TRAINING_HISTORY_UPDATE";
export const TRAINING_HISTORY_UPDATE_SUCCESS = "TRAINING_HISTORY_UPDATE_SUCCESS";
export const TRAINING_HISTORY_UPDATE_FAILURE = "TRAINING_HISTORY_UPDATE_FAILURE";
