import {
  TRAINING,
  TRAINING_FAILURE,
  TRAINING_SUCCESS,
  TRAINING_ADD,
  TRAINING_ADD_SUCCESS,
  TRAINING_ADD_FAILURE,
  BEST_PRACTISES,
  BEST_PRACTISES_SUCCESS,
  BEST_PRACTISES_FAILURE,
  TRAINING_LIST,
  TRAINING_HISTORY,
  TRAINING_HISTORY_SUCCESS,
  TRAINING_HISTORY_FAILURE,
  TRAINING_HISTORY_UPDATE,
  TRAINING_HISTORY_UPDATE_SUCCESS,
  TRAINING_HISTORY_UPDATE_FAILURE,
} from "./actionType";

export const getTrainingList = () => {
  return {
    type: TRAINING_LIST,
  };
};

export const getTraining = (productId) => {
  return {
    type: TRAINING,
    payload: { productId },
  };
};

export const getTrainingSuccess = (response) => {
  return {
    type: TRAINING_SUCCESS,
    payload: response,
  };
};

export const getTrainingFailure = (error) => {
  return {
    type: TRAINING_FAILURE,
    payload: error,
  };
};

export const addTraining = (data) => {
  return {
    type: TRAINING_ADD,
    payload: data,
  };
};

export const addTrainingSuccess = (response) => {
  return {
    type: TRAINING_ADD_SUCCESS,
    payload: response,
  };
};

export const addTrainingFailure = (error) => {
  return {
    type: TRAINING_ADD_FAILURE,
    payload: error,
  };
};

export const getBestPractises = (productId) => {
  return {
    type: BEST_PRACTISES,
    payload: productId,
  };
};

export const getBestPractisesSuccess = (response) => {
  return {
    type: BEST_PRACTISES_SUCCESS,
    payload: response,
  };
};

export const getBestPractisesFailure = (error) => {
  return {
    type: BEST_PRACTISES_FAILURE,
    payload: error,
  };
};

export const getTrainingHistory = (productId) => {
  return {
    type: TRAINING_HISTORY,
    payload: { productId },
  };
};

export const getTrainingHistorySuccess = (response) => {
  return {
    type: TRAINING_HISTORY_SUCCESS,
    payload: response,
  };
};

export const getTrainingHistoryFailure = (error) => {
  return {
    type: TRAINING_HISTORY_FAILURE,
    payload: error,
  };
};

export const updateTrainingHistory = (data) => {
  return {
    type: TRAINING_HISTORY_UPDATE,
    payload: data,
  };
};

export const updateTrainingHistorySuccess = (data) => {
  return {
    type: TRAINING_HISTORY_UPDATE_SUCCESS,
    payload: data,
  };
};

export const updateTrainingHistoryFailure = (error) => {
  console.log("error", error);
  return {
    type: TRAINING_HISTORY_UPDATE_FAILURE,
    payload: error,
  };
};
