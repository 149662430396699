import React, { useEffect, useState } from "react";
import { Container, Row, Col, Table } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

// redux
import { useSelector, useDispatch } from "react-redux";

import { getVariants } from "../../../store/actions";

import Loader from "../../../Components/Common/Loader";
import VariantForm from "./VariantForm";
import { MenuItemEnums, RouteParams } from "../../../common/enums";
import PageHeader from "../../../Components/Common/PageHeader";
import { getUrlParam } from "../../../helpers/location-helper";
import ProductForm from "./ProductForm";
import warningIcon from "../../../assets/images/svg/icon-error-circle.svg";

const ProductVariants = ({ props }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isUpdate, setIsUpdate] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const { menuStatus, variants, loading, error } = useSelector((state) => ({
    menuStatus: state.Menu.menuStatus,
    variants: state.Product.variants,
    loading: state.Product.loading,
    error: state.Product.error,
  }));

  useEffect(() => {
    if (isNaN(Number(menuStatus.subMenu.id))) return;
    dispatch(getVariants(menuStatus.subMenu.id));
  }, [dispatch, menuStatus.subMenu.id]);

  useEffect(() => {
    if (getUrlParam(RouteParams.editProduct, location.search) == "true") {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }

    if (getUrlParam(RouteParams.createVariant, location.search) == "true") {
      setShowForm(true);
    } else if (
      getUrlParam(RouteParams.editVariant, location.search) == "true"
    ) {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [location.search]);

  document.title = "Product Variants | mime";

  const offeredForSaleVariants = variants?.filter(
    (variant) => variant.offeredForSale === true
  );
  const notForSaleVariants = variants?.filter(
    (variant) => variant.offeredForSale === false
  );

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="ms-0">
            <Col className="product-list scroll-container main-container">
              <header className="page-header">
                <PageHeader
                  title={menuStatus.subMenu.label}
                  editLink={`/${MenuItemEnums.inventory}/${MenuItemEnums.product}/${menuStatus.subMenu.id}?${RouteParams.editProduct}=true&${RouteParams.productId}=${menuStatus.subMenu.id}`}
                  count={variants.length || 0}
                  subtitleSuffix="Variants"
                  editSuffix="Product"
                />
                <div className="action-button">
                  <Link
                    className="link"
                    to={`/${MenuItemEnums.inventory}/${MenuItemEnums.product}/${menuStatus.subMenu.id}?${RouteParams.createVariant}=true`}
                  >
                    + Add Variant
                  </Link>
                </div>
              </header>

              {loading ? <Loader /> : null}

              {!loading ? (
                <Table className="align-middle table-nowrap mb-0">
                  <tbody>
                    {offeredForSaleVariants &&
                      offeredForSaleVariants.map((variant, idx) => (
                        <tr className="product-item" key={idx}>
                          <td className="product-item-image">
                            <img src={variant.imageUrl} alt="" />
                          </td>
                          <td className="product-item-content">
                            <div className="title">{variant.name}</div>
                            <div className="sub-title">{variant.color}</div>
                          </td>
                          <td className="product-item-warning">
                            {!variant?.inStock && (
                              <div className="warning-label">
                                <img src={warningIcon} alt="" /> Out of stock
                              </div>
                            )}
                          </td>
                          <td className="product-item-button">
                            <Link
                              to={`/${MenuItemEnums.inventory}/${MenuItemEnums.product}/${menuStatus.subMenu.id}?${RouteParams.editVariant}=true&${RouteParams.variantId}=${variant?.id}`}
                            >
                              <button
                                type="button"
                                className="btn"
                                data-testid="manage-button"
                              >
                                Manage
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              ) : null}

              {notForSaleVariants.length > 0 && (
                <div className="mt-4">
                  <header className="page-header">
                    <PageHeader
                      title={"Variants Not for Sale"}
                      subtitleSuffix="MIME has these shades in its catalog, but you do not sell these shades."
                    />
                  </header>
                  {loading ? <Loader /> : null}
                  {!loading ? (
                    <Table className="align-middle table-nowrap mb-0">
                      <tbody>
                        {notForSaleVariants.map((variant, idx) => (
                          <tr className="product-item" key={idx}>
                            <td className="product-item-image">
                              <img src={variant.imageUrl} alt="" />
                            </td>
                            <td className="product-item-content">
                              <div className="title">{variant.name}</div>
                              <div className="sub-title">{variant.color}</div>
                            </td>
                            <td className="product-item-warning">
                              {!variant?.inStock && (
                                <div className="warning-label">
                                  <img src={warningIcon} alt="" /> Out of stock
                                </div>
                              )}
                            </td>
                            <td className="product-item-button">
                              <Link
                                to={`/${MenuItemEnums.inventory}/${MenuItemEnums.product}/${menuStatus.subMenu.id}?${RouteParams.editVariant}=true&${RouteParams.variantId}=${variant?.id}`}
                              >
                                <button
                                  type="button"
                                  className="btn"
                                  data-testid="manage-button"
                                >
                                  Manage
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : null}
                </div>
              )}
            </Col>
            {isUpdate ? <ProductForm /> : null}
            {showForm ? <VariantForm /> : null}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProductVariants;
