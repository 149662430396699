import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row, Col, Form, FormFeedback, Label, Button, Spinner,
} from "reactstrap";
import { useLocation } from "react-router-dom";
import Flatpickr from "react-flatpickr";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// common helper
import FormSelectOptions from "../../../Components/Common/FormSelectOptions";
import { getComplexionsList } from "../../../store/reporting/action";
import { OptionsTimeframes } from "../../../common/form-selector-values";
import UseDropdowns from "../../../Components/Hooks/UseDropdowns";
import { calculateDateRange } from "../../../helpers/time_helper";

const MyAudienceForm = ({}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { productOptions } = UseDropdowns(0);
  const [isClearing, setIsClearing] = useState(false);
  const [renderedCount, setRenderedCount] = useState(0);

  const { isLoading, appList } = useSelector((state) => ({
    isLoading: state.Report.complexions.loading,
    appList: state.Setting.appList,
  }));

  useEffect(() => {
    if (isLoading) return;
    setIsClearing(false);
  }, [isLoading]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      appId: appList[0]?.value || "",
      groupId: productOptions[0]?.value || "",
      timeframe: OptionsTimeframes[1].value || "",
      startTime: "",
      endTime: "",
    },
    validationSchema: Yup.object({
      appId: Yup.number().required("Please Enter App Type"),
      groupId: Yup.number().required("Please Select Product"),
      timeframe: Yup.string(),
      startTime: Yup.string().when("timeframe", {
        is: (timeframe) => (timeframe === "custom" ? true : false),
        then: () => Yup.string().required("Please Select Start Time"),
      }),
      endTime: Yup.string().when("timeframe", {
        is: (timeframe) => (timeframe === "custom" ? true : false),
        then: () => Yup.string().required("Please Select End Time"),
      }),
    }),
    onSubmit: () => {
      retrieveReports();
    },
  });

  const retrieveReports = useCallback(() => {
    const filterValues = { ...validation.values };
    const timeFrame = filterValues.timeframe;

    if (timeFrame !== "custom") {
      const dateRanges = calculateDateRange(timeFrame)
      filterValues.startTime = dateRanges[0] || "01-01-22"
      filterValues.endTime = dateRanges[1] || "12-31-22"
    }

    if (!renderedCount) setRenderedCount(renderedCount + 1);
    delete filterValues.timeframe;
    filterValues["grouping"] = "month";

    return dispatch(getComplexionsList(filterValues));
  }, [
    validation.values.appId,
    validation.values.groupId,
    validation.values.timeframe,
    dispatch,
    validation.values,
  ]);

  const clearAppliedFilters = () => {
    setIsClearing(true);
    const filteredValues = validation.values;
    filteredValues.appId = appList[0]?.value;
    filteredValues.groupId = productOptions[0]?.value;
    filteredValues.timeframe = OptionsTimeframes[1].value;
    filteredValues.startTime = "";
    filteredValues.endTime = "";
    setRenderedCount(0);
  };

  useEffect(() => {
    if (
      !validation.values.appId ||
      !validation.values.groupId ||
      !validation.values.timeframe ||
      !!renderedCount
    )
      return;
    retrieveReports();
  }, [
    validation.values.appId,
    validation.values.groupId,
    validation.values.timeframe,
    renderedCount,
    location.pathname,
  ]);

  return (
    <Col xs={4} className="mime-form right-edit scroll-container">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
        action="#"
      >
        <Row className="form-header">
          <Col className="title">Filter</Col>
        </Row>
        <Row className="mt-20">
          <Label className="form-label">App to analyze</Label>
          <select
            name="appId"
            value={validation.values.appId}
            onChange={(e) => {
              const appId = +e.target.value;
              validation.handleChange(e);
              validation.setFieldValue("appId", appId, true);
            }}
            className={
              "form-select" +
              (validation.values.appId ? "" : " empty") +
              (validation.touched.appId && validation.errors.appId ? " is-invalid" : "")
            }
            disabled={appList.length === 0}
          >
            <FormSelectOptions
              categories={appList}
              defaultOptionLabel={
                appList.length === 0 ? "No data found." : ""
              }
              defaultOption={validation.values.appId ? null : {}}
            />
          </select>
          {validation.touched.appId && validation.errors.appId ? (
            <FormFeedback type="invalid">
              {validation.errors.appId}
            </FormFeedback>
          ) : null}
        </Row>
        <Row className="mt-20">
          <Label className="form-label">Product to analyze</Label>
          <select
            name="groupId"
            value={validation.values.groupId}
            onChange={(e) => {
              const groupId = +e.target.value;
              validation.handleChange(e);
              validation.setFieldValue("groupId", groupId, true);
            }}
            className={
              "form-select" +
              (validation.values.groupId ? "" : " empty") +
              (validation.touched.groupId && validation.errors.groupId ? " is-invalid" : "")
            }
            disabled={productOptions.length === 0}
          >
            <FormSelectOptions
              categories={productOptions}
              defaultOptionLabel={
                productOptions.length === 0 ? "No data found." : ""
              }
              defaultOption={validation.values.groupId ? null : {}}
            />
          </select>
          {validation.touched.groupId && validation.errors.groupId ? (
            <FormFeedback type="invalid">
              {validation.errors.groupId}
            </FormFeedback>
          ) : null}
        </Row>
        <Row className="mt-20">
          <Label className="form-label">Timeframe</Label>
          <select
            name="timeframe"
            value={validation.values.timeframe}
            onChange={(e) => {
              validation.handleChange(e);
              validation.setFieldValue("startTime", "", true);
              validation.setFieldValue("endTime", "", true);
            }}
            className={
              "form-select" +
              (validation.values.timeframe ? "" : " empty") +
              (validation.touched.timeframe && validation.errors.timeframe ? " is-invalid" : "")
            }
          >
            <FormSelectOptions
              categories={OptionsTimeframes}
              defaultOption={validation.values.timeframe ? null : {}}
            />
          </select>
          {validation.touched.timeframe && validation.errors.timeframe ? (
            <FormFeedback type="invalid">
              {validation.errors.timeframe}
            </FormFeedback>
          ) : null}
        </Row>
        {validation.values.timeframe === "custom" && (
          <Row className="mt-20 custom-range-input">
            <Col>
              <Label className="form-label">Start Date</Label>
              <Flatpickr
                className="form-control bg-light border-light"
                id="datepicker-publish-input"
                value={validation.values.startTime}
                onChange={function (selectedDates, dateStr, instance) {
                  validation.setFieldValue("startTime", dateStr);
                  validation.setFieldValue("endTime", "");
                }}
                onBlur={validation.handleBlur}
                placeholder="Select a date"
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  mode: "single",
                  dateFormat: "m-d-y",
                  maxDate: Date.now(),
                }}
              />
              {validation.touched.startTime && validation.errors.startTime ? (
                <FormFeedback type="invalid" style={{ display: "flex" }}>
                  {validation.errors.startTime}
                </FormFeedback>
              ) : null}
            </Col>
            <Col>
              <Label className="form-label">End Date</Label>
              <Flatpickr
                className="form-control bg-light border-light"
                id="datepicker-publish-input"
                onChange={function (selectedDates, dateStr, instance) {
                  validation.setFieldValue("endTime", dateStr);
                }}
                placeholder="Select a date"
                value={validation.values.endTime}
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  mode: "single",
                  dateFormat: "m-d-y",
                  minDate: validation.values.startTime,
                  maxDate: Date.now(),
                }}
              />
              {validation.touched.endTime && validation.errors.endTime ? (
                <FormFeedback type="invalid" style={{ display: "flex" }}>
                  {validation.errors.endTime}
                </FormFeedback>
              ) : null}
            </Col>
          </Row>
        )}
        <Row className="mt-20">
          <Button
            type="submit"
            className="btn-lg"
            disabled={isLoading || isClearing}
          >
            {isLoading && !isClearing ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            Run Report
          </Button>
          <Button
            type="button"
            className="btn-lg btn-gray mt-2"
            onClick={clearAppliedFilters}
            disabled={isLoading}
          >
            {isLoading && isClearing ? (
              <Spinner size="sm" className="me-2">
                Loading...
              </Spinner>
            ) : null}
            Clear
          </Button>
        </Row>
      </Form>
    </Col>
  );
};

export default MyAudienceForm;
