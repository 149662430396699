// Authentication
export const POST_REGISTER = "/user"
export const POST_LOGIN = "/login"
export const POST_LOGOUT = "/logout"
export const TOKEN_REFRESH = "/refresh"
export const POST_PASSWORD_FORGET = "/reset-password"
export const POST_PASSWORD_CHANGE = "/change-password"
export const VERIFY_RESET_TOKEN = "/verify-reset-token"

// Dashboard
export const REPORT = "/report"
export const PURCHASES = "purchases"
export const PURCHASES_COUNT = "purchases-count"
export const COMPLEXIONS = "complexions"
export const TOP_PRODUCTS = "top-products"
export const TOP_SHADES = "top-shades"
export const COVERAGES = "coverages"
export const SIGNUPS = "signups"
export const CLICKS = "clicks"
export const SEARCHES = "searches"
export const COUNTRIES = "countries"
export const UNDERTONES = "undertones"
export const COLOR_PREFERENCES = "color-preferences"
export const RECENT_SHOPPERS = "/recent-shoppers"
export const IN_STORE_ENGAGEMENT = "scans"

// Inventory
export const PRODUCT = "/inventory"
export const VARIANT = "/variant"
export const BUNDLE = "/bundle"
export const BUNDLE_PRODUCT = "/product"
export const INTERNAL = "internal"

// Campaign
export const CAMPAIGN = "/campaign"
export const APPS = "/app"

// audience
export const MISSED_CONNECTIONS = "/missed-connections"
export const EMAIL_CONNECTION = "/email-connection"
export const BRANDS = "/brands"
export const PRODUCTS = "products"
export const COLOR_BENCHMAKRS = "color-benchmarks"

// AI trainig
export const TRAINING = "/training"
export const LABELS = "labels"
export const LABEL = "label"
export const BEST_PRACTISES = "best-practices"
export const RETURN = "/return"

// settings
export const QUESTION = "question"
export const PROFILE = "/profile"
export const INVITE = "/teams/invite"
export const TEAMS = "/teams"