import React, { useEffect, useMemo } from "react";
import { Col, Container, Row } from "reactstrap";
import SalesReports from "./SalesReports";
import Revenue from "./Revenue";
import TopCountries from "./TopCountries";
import TopComplexions from "./TopComplexions";
import TopUndertones from "./TopUndertones";
import TopColorPreferences from "./TopColorPreferences";
// import PerformanceTips from "./PerformanceTips";
import OnlineShoppers from "./OnlineShoppers";
import TimeInterval from "../../Components/Hooks/UseIntervalHook";
import TopInStoreEngagement from "./TopInStoreEngagement";

const Dashboard = () => {
  // useEffect(() => {
  //   const layoutWrapper = document.querySelector("#layout-wrapper");
  //   layoutWrapper.classList.add("dashboard-wrapper");

  //   return () => {
  //     layoutWrapper.classList.remove("dashboard-wrapper");
  //   };
  // }, []);

  const LastUpdatedSection = () =>
    useMemo(() => {
      return (
        <Col className="last-update-status">
          <TimeInterval prefix="Data Last updated" />
        </Col>
      );
    }, []);

  document.title = "Dashboard | mime";

  return (
    <React.Fragment>
      <div className="page-content dashboard">
        <Container fluid>
          <Row className="ms-0">
            <Col className="scroll-container dashboard-container">
              {/* <SalesReports /> */}
              <Row>
                <div className="toppers-container">
                  <div className="left-wrapper">
                    <Revenue />
                    <div className="footer-container">
                      <div className="best-sellers">
                        <TopInStoreEngagement />
                      </div>
                      <div className="top-countries">
                        <TopCountries />
                      </div>
                    </div>
                  </div>
                  <div className="right-wrapper">
                    <div className="top-complexions">
                      <TopComplexions />
                    </div>
                    <div className="top-undertones">
                      <TopUndertones />
                    </div>
                    <div className="top-color-preferences">
                      <TopColorPreferences />
                    </div>
                  </div>
                </div>
              </Row>
              <LastUpdatedSection />
            </Col>
            <Col
              xs={4}
              className="right-edit scroll-container right-side-container"
            >
              {/* <PerformanceTips /> */}
              <OnlineShoppers />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
