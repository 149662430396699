import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  getProducts,
  getTraining,
  postTraining,
  getBestPractises,
  getTrainingHistory,
  patchTrainingHistory,
} from "../../../helpers/backend_helper";

// Login Redux States
import {
  TRAINING_LIST,
  TRAINING,
  TRAINING_ADD,
  BEST_PRACTISES,
  TRAINING_HISTORY,
  TRAINING_HISTORY_UPDATE,
} from "./actionType";

// Include Both Helper File with needed methods
import {
  getTrainingFailure,
  getTrainingSuccess,
  addTrainingSuccess,
  addTrainingFailure,
  getBestPractisesSuccess,
  getBestPractisesFailure,
  getTrainingHistorySuccess,
  getTrainingHistoryFailure,
  updateTrainingHistorySuccess,
  updateTrainingHistoryFailure,
} from "./actions";
import { trainingUpdated } from "../../actions";

function* retrieveTrainings() {
  try {
    const response = yield call(getProducts, {});
    yield put(trainingUpdated(response));
  } catch (error) {
    yield put();
  }
}

function* retrieveTraining({ payload: { productId } }) {
  try {
    const response = yield call(getTraining, productId);
    yield put(getTrainingSuccess(response));
  } catch (error) {
    yield put(getTrainingFailure(error));
  }
}

function* addTraining({ payload: data }) {
  try {
    const response = yield call(postTraining, data);
    yield put(addTrainingSuccess(response));
  } catch (error) {
    yield put(addTrainingFailure(error));
  }
}

function* retrieveBestPractises({ payload: productId }) {
  try {
    const response = yield call(getBestPractises, productId);
    yield put(getBestPractisesSuccess(response));
  } catch (error) {
    yield put(getBestPractisesFailure(error));
  }
}

function* retrieveTrainingHistory({ payload: { productId } }) {
  try {
    const response = yield call(getTrainingHistory, productId);
    yield put(getTrainingHistorySuccess(response));
  } catch (error) {
    yield put(getTrainingHistoryFailure(error));
  }
}

function* updateTrainingHistory({ payload: data }) {
  try {
    const response = yield call(patchTrainingHistory, data);
    yield put(updateTrainingHistorySuccess(data));
  } catch (error) {
    yield put(updateTrainingHistoryFailure(error));
  }
}

function* trainingSaga() {
  yield takeEvery(TRAINING_LIST, retrieveTrainings);
  yield takeEvery(TRAINING, retrieveTraining);
  yield takeEvery(TRAINING_HISTORY, retrieveTrainingHistory);
  yield takeEvery(TRAINING_HISTORY_UPDATE, updateTrainingHistory);
  yield takeEvery(TRAINING_ADD, addTraining);
  yield takeEvery(BEST_PRACTISES, retrieveBestPractises);
}

export default trainingSaga;
