import React, { useEffect } from 'react';
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";

import withRouter from '../Components/Common/withRouter';
import { useProfile } from "../Components/Hooks/UserHooks";

const NonAuthLayout = (props) => {
  const { userProfile, loading, token } = useProfile();
	const {
		layoutModeType,
	} = useSelector(state => ({
		layoutModeType: state.Layout.layoutModeType,
	}));

	useEffect(() => {
		if (layoutModeType === "dark") {
			document.body.setAttribute("data-layout-mode", "dark");
		} else {
			document.body.setAttribute("data-layout-mode", "light");
		}
		return () => {
			document.body.removeAttribute("data-layout-mode");
		};
	}, [layoutModeType]);

	// redirect to dashboard if user logged in
	if (userProfile && !loading && token) {
		return (
			<Navigate to={{ pathname: "/dashboard", state: { from: props.location } }} />
		);
	}

	return (
		<div>
			{props.children}
		</div>
	);
};

export default withRouter(NonAuthLayout);