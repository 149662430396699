import React from "react";
import { FormFeedback, Label } from "reactstrap";
import FormSelectOptions from "./FormSelectOptions";

const SelectInput = ({
  label,
  fieldName,
  handleChange,
  handleBlur = () => {},
  fieldValue,
  isTouchedField,
  fieldError,
  hasSameLabel = false,
  selectOptions = [],
}) => {
//   const generateLabelName = (label) => {
//     if(!label) return "";
//     if (hasSameLabel) return label;

//     const seperateLabel = label.includes("-") ? label.split("-") : label;
//     if (typeof seperateLabel === "string") return capitalizeFirstChar(label);

//     seperateLabel.forEach((label) => capitalizeFirstChar(label));
//     return seperateLabel.join(" ");
//   };

  return (
    <>
      <Label className="form-label">{label}</Label>
      <select
        name={fieldName}
        onChange={handleChange}
        value={fieldValue}
        onBlur={handleBlur}
        className={
          "form-select" +
          (fieldValue ? "" : " empty") +
          (isTouchedField && fieldError ? " is-invalid" : "")
        }
      >
        <FormSelectOptions
          categories={selectOptions}
          defaultOption={
            fieldValue
              ? null
              : {}
          }
        />
      </select>
      {isTouchedField && fieldError ? (
        <FormFeedback type="invalid">{fieldError}</FormFeedback>
      ) : null}
    </>
  );
};

export default SelectInput;
