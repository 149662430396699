import {
  CLEAR_ERROR,
  MISSED_CONNECTION_LIST, MISSED_CONNECTION_LIST_SUCCESS, MISSED_CONNECTION_LIST_FAILURE,
  RETRIEVE_BRANDS, RETRIEVE_BRANDS_SUCCESS, RETRIEVE_BRANDS_FAILED,
  RETRIEVE_BRAND_PRODUCTS, RETRIEVE_BRAND_PRODUCTS_SUCCESS, RETRIEVE_BRAND_PRODUCTS_FAILED,
  SEND_EMAIL, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILED,
} from "./actionType";

const initialState = {
  loading: true,
  connections: [],
  mailInEdit: {},
  brands: [],
  brandProducts: [],
  connectionListWithId: {},
  total: 0,
  errorMsg: "",
  error: false,
};

const audience = (state = initialState, action) => {
  switch (action.type) {
    case MISSED_CONNECTION_LIST: {
      return {
        ...state, loading: true, error: false, errorMsg: "",
        connections: [],
      };
    }
    case MISSED_CONNECTION_LIST_SUCCESS: {
      const { users, totalUsers } = action.payload ?? {
        users: [],
        totalUsers: 0,
      };

      const connectionListWithId = {};
      for (const user of users) {
        connectionListWithId[user.userId] = user;
      }

      return {
        ...state, loading: false,
        connections: users,
        total: totalUsers,
        connectionListWithId,
      };
    }
    case MISSED_CONNECTION_LIST_FAILURE: {
      return {
        ...state, loading: false, error: true, errorMsg: action.payload,
      };
    }

    case SEND_EMAIL:
      return {
        ...state, error: false, errorMsg: "",
        mailInEdit: { loading: true, sent: false }
      };
    case SEND_EMAIL_SUCCESS:
      return { ...state, mailInEdit: { loading: false, sent: true } };
    case SEND_EMAIL_FAILED:
      return {
        ...state, error: true, errorMsg: action.payload,
        mailInEdit: { loading: false, sent: false }
      };

    case RETRIEVE_BRANDS: {
      return {
        ...state, loading: true, error: false, errorMsg: "",
      };
    }
    case RETRIEVE_BRANDS_SUCCESS: {
      return {
        ...state, loading: false, error: false, errorMsg: "",
        brands: action.payload.brands,
      };
    }
    case RETRIEVE_BRANDS_FAILED: {
      return {
        ...state, loading: false, error: true, errorMsg: action.payload,
      };
    }

    case RETRIEVE_BRAND_PRODUCTS: {
      return {
        ...state, loading: true, error: false, errorMsg: "",
      };
    }
    case RETRIEVE_BRAND_PRODUCTS_SUCCESS: {
      return {
        ...state, loading: false,
        brandProducts: action.payload.products,
      };
    }
    case RETRIEVE_BRAND_PRODUCTS_FAILED: {
      return {
        ...state, loading: false, error: true, errorMsg: action.payload,
      };
    }

    case CLEAR_ERROR: {
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: "",
      };
    }

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default audience;
