import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Col, Container, Row, Table } from "reactstrap";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
} from "recharts";

// common helper
import PageHeader from "../../../Components/Common/PageHeader";
import ColorBenchmarksForm from "./ColorBenchmarksForm";
import Loader from "../../../Components/Common/Loader";

const CustomDot = (props) => {
  const { cx, cy, payload } = props;

  return (
    <g
      className="custom-dot"
      data-chart-x={cx}
      data-chart-y={cy}
      data-x-value={payload.colorValue}
      data-y-value={payload.colorValue}
      data-radius={payload.dotSize ?? 3}
    >
      <circle
        cx={cx}
        cy={cy}
        r={payload.dotSize ?? 3}
        fill={payload?.dotHexColor ?? "gray"}
      />
    </g>
  );
};

const ColorBenchmarks = () => {
  const { colorBenchmarks, isFetching, errorMsg } = useSelector((state) => ({
    isFetching: state.Report.loading,
    errorMsg: state.Report.errorMsg,
    colorBenchmarks: state.Report.colorBenchmarks,
  }));

  document.title = "Audience | mime";

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container color-benchmarks-container">
            <PageHeader title="Color Benchmarks" />
            <Col className="chart">
              {isFetching ? <Loader /> : null}
              {!isFetching &&
              (colorBenchmarks.baseProductData?.length ||
                colorBenchmarks.comparisonProductData?.length) ? (
                <ResponsiveContainer width="100%" height="100%">
                  <ScatterChart height={400} margin={{ top: 50 }}>
                    <CartesianGrid stroke="#fbfbfb" fill="#fbfbfb" />
                    <XAxis
                      type="number"
                      dataKey="colorValue"
                      tickCount={10}
                      // domain={[0, "dataMax + 10"]}
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: "black" }}
                    />
                    <YAxis
                      type="number"
                      dataKey="colorValue"
                      tickFormatter={(_, index) => {
                        if (index === 0) return "";
                        if (index === 1) return "Cool";
                        if (index === 2) return "Neutral";
                        if (index === 3) return "Warm";
                      }}
                      tickCount={4}
                      axisLine={false}
                      tickLine={false}
                      tick={{ fill: "black" }}
                    />
                    <Scatter
                      data={colorBenchmarks.comparisonProductData}
                      shape={<CustomDot />}
                      wrapperStyle={{ color: "green" }}
                    />
                    <Scatter
                      data={colorBenchmarks.baseProductData}
                      shape={<CustomDot />}
                      wrapperStyle={{ color: "green" }}
                    />
                  </ScatterChart>
                </ResponsiveContainer>
              ) : !isFetching ? (
                <span>No data available</span>
              ) : null}
            </Col>

            {/* <Col>
              <div className="header">
                <PageHeader title="Breakdown" />
                <div>Sample size: 60 top brands</div>
              </div>
              <Row>
                <Col className="product-list">
                  <Table className="align-middle table-nowrap mb-0">
                    <tbody>
                      {breakDown.length
                        ? breakDown.map((item, idx) => (
                            <tr className="product-item" key={idx}>
                              <td className="item-color-container">
                                <div
                                  className="item-color"
                                  style={{
                                    backgroundColor: item.color,
                                  }}
                                ></div>
                              </td>
                              <td className="product-item-content">
                                <div className="title">{item.title}</div>
                                <div className="sub-title">{item.subTitle}</div>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Col> */}
          </Col>
          <ColorBenchmarksForm />
        </Row>
      </Container>
    </div>
  );
};

export default ColorBenchmarks;
