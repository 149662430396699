import { MenuItemEnums, RouteParams } from "../../common/enums";
import {
  CAMPAIGN_LIST, CAMPAIGN_LIST_SUCCESS, CAMPAIGN_LIST_FAILURE,
  CAMPAIGN_ADD, CAMPAIGN_ADD_SUCCESS, CAMPAIGN_ADD_FAILURE,
  CAMPAIGN_UPDATE, CAMPAIGN_UPDATE_SUCCESS, CAMPAIGN_UPDATE_FAILURE,
  CAMPAIGN_REMOVE, CAMPAIGN_REMOVE_SUCCESS, CAMPAIGN_REMOVE_FAILURE,
  CAMPAIGN_SET, RESET_CAMPAIGN_FLAG,
} from "./actionType";

const initialState = {
  campaigns: [],
  campaignList: {},
  campaignInEdit: {},
  total: 0,
  loading: false,
  navigationLink: "",
  errorMsg: "",
  error: false,
};

const campaign = (state = initialState, action) => {
  switch (action.type) {
    case CAMPAIGN_LIST: {
      return {
        ...state, loading: true, error: false, errorMsg: "",
        campaigns: [], total: 0
      };
    }
    case CAMPAIGN_LIST_SUCCESS: {
      const { campaigns, totalCampaigns } = action?.payload ?? {
        campaigns: [],
        totalProducts: 0,
      };
      const navigationLink = !campaigns.length
        ? `/${MenuItemEnums.campaign}/${MenuItemEnums.myCampaign}/create?${RouteParams.createCampaign}=true`
        : "";
      const campaignList = {};
      for (const campaign of campaigns) {
        campaignList[campaign.id] = campaign;
      }

      return {
        ...state, loading: false,
        total: totalCampaigns,
        campaigns,
        navigationLink,
        campaignList,
      };
    }
    case CAMPAIGN_LIST_FAILURE: {
      return {
        ...state, loading: false, error: true, errorMsg: action.payload,
      };
    }
    
    case CAMPAIGN_SET:
      return {
        ...state,
        campaignInEdit: action.payload,
      };
    
    case CAMPAIGN_ADD:
      return {
        ...state, error: false, errorMsg: "",
        campaignInEdit: { ...state.campaignInEdit, loading: true, added: false },
      };
    case CAMPAIGN_ADD_SUCCESS:
      return {
        ...state,
        campaignInEdit: { ...action.payload, loading: false, added: true },
      };
    case CAMPAIGN_ADD_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        campaignInEdit: { ...state.campaignInEdit, loading: false, added: false },
      };

    case CAMPAIGN_UPDATE:
      return {
        ...state, error: false, errorMsg: "",
        campaignInEdit: { ...state.campaignInEdit, loading: true, updated: false },
      };
    case CAMPAIGN_UPDATE_SUCCESS:
      return {
        ...state,
        campaignInEdit: { ...action.payload, loading: false, updated: true },
      };
    case CAMPAIGN_UPDATE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        campaignInEdit: { ...state.campaignInEdit, loading: false, updated: false },
      };

    case CAMPAIGN_REMOVE:
      return {
        ...state, error: false, errorMsg: "",
        campaignInEdit: { ...state.campaignInEdit, loading: true, removed: false },
      };
    case CAMPAIGN_REMOVE_SUCCESS:
      return {
        ...state,
        campaignInEdit: { ...state.campaignInEdit, loading: false, removed: true },
      };
    case CAMPAIGN_REMOVE_FAILURE:
      return {
        ...state, error: true, errorMsg: action.payload,
        campaignInEdit: { ...state.campaignInEdit, loading: false, removed: false },
      };

    case RESET_CAMPAIGN_FLAG:
      return {
        ...state,
        loading: false,
        error: false,
        errorMsg: "",
        campaignInEdit: {
          ...state.campaignInEdit,
          loading: false,
          added: false,
          updated: false,
          removed: false,
        },
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default campaign;
