export const REPORT_DETAILS = "REPORT_LIST";
export const REPORT_DETAILS_SUCCESS = "REPORT_DETAILS_SUCCESS";
export const REPORT_DETAILS_FAILURE = "REPORT_DETAILS_FAILURE";
export const CLEAR_REPORTS = "CLEAR_REPORTS"

export const SEARCHES_DETAILS = "SEARCHES_DETAILS";
export const SEARCHES_DETAILS_SUCCESS = "SEARCHES_DETAILS_SUCCESS";
export const SEARCHES_DETAILS_FAILURE = "SEARCHES_DETAILS_FAILURE";

export const CLICKS_DETAILS = "CLICKS_DETAILS";
export const CLICKS_DETAILS_SUCCESS = "CLICKS_DETAILS_SUCCESS";
export const CLICKS_DETAILS_FAILURE = "CLICKS_DETAILS_FAILURE";

export const PURCHASES_DETAILS = "PURCHASES_DETAILS";
export const PURCHASES_DETAILS_SUCCESS = "PURCHASES_DETAILS_SUCCESS";
export const PURCHASES_DETAILS_FAILURE = "PURCHASES_DETAILS_FAILURE";

export const TOP_PRODUCTS_DETAILS = "TOP_PRODUCTS_DETAILS"
export const TOP_PRODUCTS_DETAILS_SUCCESS = "TOP_PRODUCTS_DETAILS_SUCCESS"
export const TOP_PRODUCTS_DETAILS_FAILURE = "TOP_PRODUCTS_DETAILS_FAILURE"

export const TOP_SHADES_DETAILS = "TOP_SHADES_DETAILS"
export const TOP_SHADES_DETAILS_SUCCESS = "TOP_SHADES_DETAILS_SUCCESS"
export const TOP_SHADES_DETAILS_FAILURE = "TOP_SHADES_DETAILS_FAILURE"

export const TOP_SKIN_CONCERNS_DETAILS = "TOP_SKIN_CONCERNS_DETAILS"
export const TOP_SKIN_CONCERNS_DETAILS_SUCCESS = "TOP_SKIN_CONCERNS_DETAILS_SUCCESS"
export const TOP_SKIN_CONCERNS_DETAILS_FAILURE = "TOP_SKIN_CONCERNS_DETAILS_FAILURE"

export const COMPLEXIONS = "COMPLEXIONS"
export const COMPLEXIONS_SUCCESS = "COMPLEXIONS_SUCCESS"
export const COMPLEXIONS_FAILURE = "COMPLEXIONS_FAILURE"

export const COLOR_BENCHMAKRS_GET = "COLOR_BENCHMAKRS_GET"
export const COLOR_BENCHMAKRS_SUCCESS = "COLOR_BENCHMAKRS_SUCCESS"
export const COLOR_BENCHMAKRS_FAILURE = "COLOR_BENCHMAKRS_FAILURE"
