import {
	DATE_RANGE,
	GET_REVENUES, GET_REVENUES_SUCCESS, GET_REVENUES_FAILURE,
	GET_UNDERTONES, GET_UNDERTONES_SUCCESS, GET_UNDERTONES_FAILURE,
	GET_COLOR_PREFERENCES, GET_COLOR_PREFERENCES_SUCCESS, GET_COLOR_PREFERENCES_FAILURE,
	GET_COUNTRIES, GET_COUNTRIES_SUCCESS, GET_COUNTRIES_FAILURE,
	GET_RECENT_SHOPPERS, GET_RECENT_SHOPPERS_SUCCESS, GET_RECENT_SHOPPERS_FAILURE, GET_IN_STORE_ENGAGEMENT, GET_IN_STORE_ENGAGEMENT_SUCCESS, GET_IN_STORE_ENGAGEMENT_FAILURE,
} from "./actionTypes";

export const setDateRange = (payload) => {
	return {
		type: DATE_RANGE,
		payload,
	};
};

export const getRevenues = (filters) => {
	return {
		type: GET_REVENUES,
		payload: filters
	};
};

export const getRevenuesSuccess = (response) => {
	return {
		type: GET_REVENUES_SUCCESS,
		payload: response,
	};
};

export const getRevenuesFailure = (error) => {
	return {
		type: GET_REVENUES_FAILURE,
		payload: error,
	};
};

export const getUndertones = (filters) => {
	return {
		type: GET_UNDERTONES,
		payload: filters
	};
};

export const getUndertonesSuccess = (response) => {
	return {
		type: GET_UNDERTONES_SUCCESS,
		payload: response,
	};
};

export const getUndertonesFailure = (error) => {
	return {
		type: GET_UNDERTONES_FAILURE,
		payload: error,
	};
};

export const getColorPreferences = (filters) => {
	return {
		type: GET_COLOR_PREFERENCES,
		payload: filters
	};
};

export const getColorPreferencesSuccess = (response) => {
	return {
		type: GET_COLOR_PREFERENCES_SUCCESS,
		payload: response,
	};
};

export const getColorPreferencesFailure = (error) => {
	return {
		type: GET_COLOR_PREFERENCES_FAILURE,
		payload: error,
	};
};

export const getCountries = (filters) => {
	return {
		type: GET_COUNTRIES,
		payload: filters
	};
};

export const getCountriesSuccess = (response) => {
	return {
		type: GET_COUNTRIES_SUCCESS,
		payload: response,
	};
};

export const getCountriesFailure = (error) => {
	return {
		type: GET_COUNTRIES_FAILURE,
		payload: error,
	};
};

export const getRecentShoppers = () => {
	return {
		type: GET_RECENT_SHOPPERS,
		payload: {}
	};
};

export const getRecentShoppersSuccess = (response) => {
	return {
		type: GET_RECENT_SHOPPERS_SUCCESS,
		payload: response,
	};
};

export const getRecentShoppersFailure = (error) => {
	return {
		type: GET_RECENT_SHOPPERS_FAILURE,
		payload: error,
	};
};

export const getInStoreEngagement = (filters) => {
	return {
		type: GET_IN_STORE_ENGAGEMENT,
		payload: filters
	};
};

export const getInStoreEngagementSuccess = (response) => {
	return {
		type: GET_IN_STORE_ENGAGEMENT_SUCCESS,
		payload: response,
	};
};

export const getInStoreEngagementFailure = (error) => {
	return {
		type: GET_IN_STORE_ENGAGEMENT_FAILURE,
		payload: error,
	};
};
