import jwtDecode from "jwt-decode";
const AuthUserKey = "authUser";

const getLoggedinUser = () => {
	const user = localStorage.getItem(AuthUserKey);
	if (!user || user === "undefined") {
		return null;
	} else {
		return JSON.parse(user);
	}
};

const setAuthUser = (user) => {
	localStorage.setItem(AuthUserKey, JSON.stringify(user));
};

const removeAuthUser = () => {
	localStorage.clear();
};

const jwtRefreshToken = () => {
	return getLoggedinUser()?.jwtRefreshToken;
};

const jwtAccessToken = () => {
	return getLoggedinUser()?.jwtAccessToken;
};

const verifyToken = (token) => {
	const accessToken = token;

	try {
		const decodedToken = jwtDecode(accessToken);
		const expiresTime = (decodedToken?.exp || 0) * 1000;

		return expiresTime - 20 * 1000 < new Date().getTime();
	} catch (e) {
		return false;
	}
};

export {
	getLoggedinUser,
	setAuthUser,
	removeAuthUser,
	jwtAccessToken,
	jwtRefreshToken,
	verifyToken,
};
