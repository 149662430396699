import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Container, Row, Table } from "reactstrap";
import { Link, useLocation } from "react-router-dom";

import { productsStateSelector } from "../../../store/inventory/product/productSelectors";
import Loader from "../../../Components/Common/Loader";
import ProductForm from "./ProductForm";
import { MenuItemEnums, RouteParams } from "../../../common/enums";
import { getUrlParam } from "../../../helpers/location-helper";
import PageHeader from "../../../Components/Common/PageHeader";

const Products = () => {
  const location = useLocation();

  const productsState = useSelector(productsStateSelector);
  const products = productsState?.data ?? [];
  const productCounts = productsState?.total ?? 0;
  const loading = productsState?.loading;

  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (getUrlParam(RouteParams.createProduct, location.search) == "true") {
      setShowForm(true);
    } else if (getUrlParam(RouteParams.editProduct, location.search) == "true") {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [location.search]);

  document.title = "Products | mime"

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container main-container">
            <PageHeader
              title="Showing all products"
              count={productCounts}
              subtitleSuffix="Product Groups"
              editSuffix="Product"
            />

            {loading ? <Loader /> : null}

            {!loading ? (
              <Table className="align-middle table-nowrap mb-0">
                <tbody>
                  {products &&
                    products.map((product, idx) => (
                      <tr className="product-item" key={idx}>
                        <td className="product-item-image">
                          <img src={product.imageUrl} alt="" />
                        </td>
                        <td className="product-item-content">
                          <div className="title">{product.name}</div>
                          <div className="sub-title">
                            {product.variants ? product.variants : "0"} variants
                          </div>
                        </td>
                        <td className="product-item-button">
                          <Link
                            to={`/${MenuItemEnums.inventory}/${MenuItemEnums.product}?${RouteParams.editProduct}=true&${RouteParams.productId}=${product.groupId}`}
                          >
                            <button type="button" className="btn">
                              Manage
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            ) : null}
          </Col>
          
          {showForm ? <ProductForm /> : null}
        </Row>
      </Container>
    </div>
  );
};

export default Products;
