import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
import MenuSaga from "./menu/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import ChangePasswordSaga from "./auth/changepwd/saga";
import ResetTokenSaga from "./auth/resetpwdtoken/saga";
// Dashboard
import DashboardSaga from "./dashboard/saga"
// Inventory
import ProductSaga from "./inventory/product/saga";
import BundleSaga from "./inventory/bundle/saga";
// Campaign
import campaignSaga from "./campaign/saga";
// audience
import missedConnectionSaga from "./audience/saga";
// reports
import reportSaga from "./reporting/saga";
// AI training
import trainingSaga from "./AiTraining/training/saga";
import returnSaga from "./AiTraining/returns/saga";
import appSaga from "./settings/saga";

export default function* rootSaga() {
  yield all([
    fork(LayoutSaga),
    fork(MenuSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(ChangePasswordSaga),
    fork(ResetTokenSaga),
    
    fork(DashboardSaga),
    fork(ProductSaga),
    fork(BundleSaga),
    fork(campaignSaga),
    fork(missedConnectionSaga),
    fork(reportSaga),
    fork(returnSaga),
    fork(trainingSaga),
    fork(appSaga),
  ]);
}
