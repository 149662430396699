import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, CHANGE_PASSWORD } from "./actionTypes";
import { userForgotPasswordSuccess, userForgotPasswordError } from "./actions";

// Include Both Helper File with needed methods
import {
	postForgetPwd
} from "../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
	try {
		const response = yield call(postForgetPwd, {emailAddress:user.email});
		yield put(userForgotPasswordSuccess("Reset password link sent, please check your email."));
	} catch (error) {
		yield put(userForgotPasswordError(error));
	}
}

export function* watchUserPasswordForget() {
	yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgotPasswordSaga() {
	yield all([fork(watchUserPasswordForget)]);
}

export default forgotPasswordSaga;
