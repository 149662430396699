import React, { useState } from "react";
import { Col, Container, Row } from "reactstrap";

// common helper
import Loader from "../../../../Components/Common/Loader";
import PageHeader from "../../../../Components/Common/PageHeader";
import sampleLogo from "../../../../assets/images/logo/campaign_logo_sample.jpg";

// redux
import { useSelector } from "react-redux";

import BasicsForm from "./BasicsForm";

const Basics = () => {
  const [seoImage, setSeoImage] = useState("");

  const { appDetails, isFetching } = useSelector((state) => ({
    appDetails: state.Setting.appDetails,
    isFetching: state.Setting.appDetails.loading,
  }));

  document.title = "Settings | mime"
  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container meta-elements-container">
            <BasicsForm setSeoImage={setSeoImage} />
          </Col>
          <Col
            xs={5}
            className="right-edit scroll-container meta-right-section"
          >
            <PageHeader title="SEO Preview" />
            {isFetching ? (
              <Loader />
            ) : (
              <div className="image-container">
                <img src={seoImage || appDetails?.metaImageUrl} alt="profile-dp" />

                <div className="founder-card">
                  <div className="foundation-finder-name">
                    {appDetails?.metaPageTitle}
                  </div>
                  <div className="founder-description mt-2">
                    {appDetails?.metaDescription}
                  </div>
                </div>
              </div>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Basics;
