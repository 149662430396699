import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";
import Menu from "./menu/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgotPassword from "./auth/forgetpwd/reducer";
import ChangePassword from "./auth/changepwd/reducer";
import Profile from "./auth/profile/reducer";
import ResetToken from "./auth/resetpwdtoken/reducer";

// Dashboard
import Dashboard from "./dashboard/reducer";

// inventory
import Product from "./inventory/product/reducer";
import Bundle from "./inventory/bundle/reducer";

// campaign
import Campaign from "./campaign/reducer";

// audience
import Audience from "./audience/reducer";

// reporting
import Report from "./reporting/reducer";

// AI training
import Training from "./AiTraining/training/reducer";
import Return from "./AiTraining/returns/reducer";

// settings
import Setting from "./settings/reducer";

const rootReducer = combineReducers({
  Layout,
  Menu,
  Login,
  Account,
  ChangePassword,
  ForgotPassword,
  Profile,
  Dashboard,
  Product,
  Bundle,
  Campaign,
  Audience,
  Report,
  Training,
  Return,
  Setting,
  ResetToken,
});

export default rootReducer;
