export const RETURN_LIST = "RETURN_LIST";
export const RETURN_LIST_SUCCESS = "RETURN_LIST_SUCCESS";
export const RETURN_LIST_FAILURE = "RETURN_LIST_FAILURE";

export const RETURN_ADD = "RETURN_ADD";
export const RETURN_ADD_SUCCESS = "RETURN_ADD_SUCCESS";
export const RETURN_ADD_FAILURE = "RETURN_ADD_FAILURE";

export const RETURN_UPDATE = "RETURN_UPDATE";
export const RETURN_UPDATE_SUCCESS = "RETURN_UPDATE_SUCCESS";
export const RETURN_UPDATE_FAILURE = "RETURN_UPDATE_FAILURE";

export const RETURN_REMOVE = "RETURN_REMOVE";
export const RETURN_REMOVE_SUCCESS = "RETURN_REMOVE_SUCCESS";
export const RETURN_REMOVE_FAILURE = "RETURN_REMOVE_FAILURE";

export const RESET_RETURN_FLAG = "RESET_RETURN_FLAG";
