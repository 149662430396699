import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { MenuItemEnums, RouteParams } from "../../common/enums";

const Reporting = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(
      `/${MenuItemEnums.revenue}/${MenuItemEnums.reporting}/${RouteParams.conversion}`
    );
  }, []);
  return null;
};

export default Reporting;
