import React, { useEffect, useState } from "react";
import {
	Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner,
} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import "react-toastify/dist/ReactToastify.css";
import * as Yup from "yup";
import { useFormik } from "formik";

import ParticlesAuth from "./ParticlesAuth";
import logoLight from "../../assets/images/logo/mime-logo.png";
import withRouter from "../../Components/Common/withRouter";
import { getUrlParam } from "../../helpers/location-helper";

import { userChangePassword } from "../../store/actions";
import { verifyResetToken } from "../../store/auth/resetpwdtoken/actions";

const ChangePasswordPage = (props) => {
	const history = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();

	const [errroMessage, setErrorMessage] = useState("");
	const [passwordShow, setPasswordShow] = useState(false);

	const { tokenStatus, passwordStatus } =
		useSelector((state) => ({
			tokenStatus: state.ResetToken,
			passwordStatus: state.ChangePassword,
		}));

	const getKeyFromPath = getUrlParam("key", location.search) || "dummy-token";

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			password: "",
			confirmPassword: "",
			key: getKeyFromPath,
		},
		validationSchema: Yup.object({
			password: Yup.string().required("Please Enter Your Password"),
			confirmPassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
		}),
		onSubmit: (values) => {
			dispatch(userChangePassword(values, props.history));
		},
	});

	useEffect(() => {
		dispatch(verifyResetToken(getKeyFromPath));
	}, [dispatch, getKeyFromPath]);

	useEffect(() => {
		if (tokenStatus.error)
			setErrorMessage(tokenStatus.message);
		else if (passwordStatus.error)
			setErrorMessage(passwordStatus.message);
		else
			setErrorMessage("");
	}, [tokenStatus, passwordStatus]);

	useEffect(() => {
		if (passwordStatus.success) {
			setTimeout(() => history("/login"), 3000);
		}
	}, [dispatch, passwordStatus.success, history]);

	const isEditable = () => {
		return tokenStatus.success && !passwordStatus.loading && !passwordStatus.success
	}

	document.title = "Change Password | mime";

	return (
		<React.Fragment>
			<ParticlesAuth>
				<div className="auth-page-content">
					<Container>
						<Row>
							<Col lg={12}>
								<div className="text-center mt-sm-2 mb-4 text-white-50">
									<div>
										<Link to="/" className="d-inline-block auth-logo">
											<img src={logoLight} alt="" height="30" />
										</Link>
									</div>
								</div>
							</Col>
						</Row>

						<Row className="justify-content-center">
							<Col md={6} lg={4} xl={4}>
								<Card className="mt-4 auth-card">
									<CardBody className="p-4">
										<div className="text-center mt-2">
											<span className="auth-card-title">
												Reset Your Password
											</span>
											<p className="auth-card-subtitle">
												Please create and confirm a new password.
											</p>
										</div>
										<div className="p-2">
											<Form
												onSubmit={(e) => {
													e.preventDefault();
													validation.handleSubmit();
													return false;
												}}
												action="#"
											>
												{passwordStatus.success ? (
													<Alert color="success">
														{passwordStatus.message ??
															"Password changed successfully!"}
													</Alert>
												) : null}
												{errroMessage ? (
													<Alert color="danger">
														{errroMessage}
													</Alert>
												) : null}

												<div className="mt-3">
													<Label className="form-label auth-card-label">
														New Password
													</Label>
													<Input
														name="password"
														type={passwordShow ? "text" : "password"}
														className="form-control auth-form-control pe-5"
														placeholder="Enter password"
														value={validation.values.password}
														onChange={(e) => {
															if (!isEditable()) return;
															validation.handleChange(e);
														}}
														onBlur={validation.handleBlur}
														invalid={
															validation.touched.password &&
															validation.errors.password
														}
														disabled={!isEditable()}
													/>
													{validation.touched.password &&
													validation.errors.password ? (
														<FormFeedback type="invalid">
															<div>{validation.errors.password}</div>
														</FormFeedback>
													) : null}
													{/* <button
														className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
														type="button"
														id="password-addon"
														onClick={() => setPasswordShow(!passwordShow)}
													>
														<i className="ri-eye-fill align-middle"></i>
													</button> */}
												</div>

												<div className="mt-3">
													<Label className="form-label auth-card-label">
														Confirm New Password
													</Label>
													<div className="position-relative auth-pass-inputgroup mb-1">
														<Input
															name="confirmPassword"
															type={passwordShow ? "text" : "password"}
															className="form-control auth-form-control pe-5"
															placeholder="Confirm password"
															value={validation.values.confirmPassword}
															onChange={(e) => {
																if (!isEditable()) return;
																validation.handleChange(e);
															}}
															onBlur={validation.handleBlur}
															invalid={
																validation.touched.confirmPassword &&
																validation.errors.confirmPassword
															}
															disabled={!isEditable()}
														/>
														{validation.touched.confirmPassword &&
														validation.errors.confirmPassword ? (
															<FormFeedback type="invalid">
																<div>{validation.errors.confirmPassword}</div>
															</FormFeedback>
														) : null}
														<button
															className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted"
															type="button"
															id="confirm-password-addon"
															onClick={() => setPasswordShow(!passwordShow)}
														>
															<i className="ri-eye-fill align-middle"></i>
														</button>
													</div>
												</div>

												<div className="mt-3">
													<Button
														color="secondary"
														className="btn btn-secondary w-100 auth-button"
														type="submit"
														disabled={!isEditable()}
													>
														{tokenStatus.loading || passwordStatus.loading ? (
															<Spinner size="sm" className="me-2">
																Loading...
															</Spinner>
														) : null}
														Change Password
													</Button>
												</div>

												<div className="mt-4 text-center"></div>
											</Form>
										</div>
									</CardBody>
								</Card>

								<div className="mt-8 text-center">
									<p className="mb-0">
										<Link to="/login" className="auth-request"> Back to login </Link>
									</p>
								</div>

							</Col>
						</Row>
					</Container>
				</div>
			</ParticlesAuth>
		</React.Fragment>
	);
};

export default withRouter(ChangePasswordPage);
