import React from "react";
import { toast } from "react-toastify";
import { Input } from "reactstrap";

const CopyTextToClipboard = ({copyText}) => {
  async function copyToClipboard(text) {
    if ("clipboard" in navigator)
      return await navigator.clipboard.writeText(text);
    else return document.execCommand("copy", true, text);
  }

  // onClick handler function for the copy button
  const handleCopyClick = () => {
    // Asynchronously call copyTextToClipboard
    copyToClipboard(copyText)
      .then(() => {
        toast.success("successfully copied the text")
      })
      .catch(() => {
        toast.error("something went wrong!")
      });
  };

  return (
    <Input
      type="button"
      className="form-control copy-button"
      value={"Copy"}
      onClick={handleCopyClick}
    />
  );
};

export default CopyTextToClipboard;
