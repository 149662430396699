import React from "react";
import { useIdleTimer } from "react-idle-timer";
import { useNavigate } from "react-router";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";

//import Scss
import "./assets/scss/themes.scss";
import "./App.css";

//imoprt Route
import Route from "./Routes";
import "react-toastify/dist/ReactToastify.css";
import ErrorFallback from "./Components/Common/ErrorFallback";

function App() {
  const navigate = useNavigate();
  const onIdle = () => {
    navigate("/logout");
  };

  useIdleTimer({
    onIdle,
    timeout: 30 * 60 * 1000,
  });

  return (
    <React.Fragment>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ToastContainer autoClose={2000} limit={1} closeOnClick />
        <Route />
      </ErrorBoundary>
    </React.Fragment>
  );
}

export default App;
