import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import Flatpickr from "react-flatpickr";

// common helpers
import { MenuItemEnums } from "../common/enums";
import { getLoggedinUser } from "../helpers/auth-user";
import SearchOption from "../Components/Common/SearchOption";
import { setDateRange } from "../store/dashboard/actions";

const Header = ({ headerClass }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { dateRange, userProfile } = useSelector((state) => ({
    dateRange: state.Dashboard.dateRange,
    userProfile: state.Setting.userProfile,
  }));

  const [state, setState] = useState({
    headerTitle: "Dashboard",
    searchBar: false,
    isDashboardPage: false,
  });

  const [dates, setDates] = useState({
    startTime: dateRange[0],
    endTime: dateRange[1],
  });

  const handleDateChange = () => {
    if (!dates.startTime || !dates.endTime) {
      toast.error("please select startTime or EndTime");
      return;
    }
    const dateRanges = [dates.startTime, dates.endTime];
    dispatch(setDateRange(dateRanges));
  };

  useEffect(() => {
    let title = state.headerTitle;
    let searchBar = false;
    let isDashboardPage = false;

    if (location?.pathname?.includes(`/${MenuItemEnums.dashboard}`)) {
      const firstName = getLoggedinUser()?.firstName;
      if (firstName) {
        title = `Welcome, ${firstName}.`;
      } else {
        title = `Welcome!`;
      }
      isDashboardPage = true;
    }

    if (location?.pathname?.includes(`/${MenuItemEnums.inventory}`)) {
      title = "Inventory Manager";
      searchBar = false;
    }

    if (location?.pathname?.includes(`/${MenuItemEnums.campaign}`)) {
      title = "Campaign Manager";
    }

    if (location?.pathname?.includes(`/${MenuItemEnums.audience}`)) {
      title = "Audience";
    }

    if (location?.pathname?.includes(`/${MenuItemEnums.aiTraining}`)) {
      title = "My AI Training";
    }

    if (location?.pathname?.includes(`/${MenuItemEnums.revenue}`)) {
      title = "Reporting";
    }

    if (location?.pathname?.includes(`/${MenuItemEnums.settings}`)) {
      title = "My Settings";
    }

    setState({
      ...state,
      headerTitle: title,
      searchBar,
      isDashboardPage,
    });
  }, [location.pathname]);

  const toogleMenuBtn = () => {
    var windowSize = document.documentElement.clientWidth;

    if (windowSize > 767)
      document.querySelector(".hamburger-icon").classList.toggle("open");

    // For collapse vertical menu
    if (document.documentElement.getAttribute("data-layout") === "vertical") {
      if (windowSize < 1025 && windowSize > 767) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "sm"
          ? document.documentElement.setAttribute("data-sidebar-size", "")
          : document.documentElement.setAttribute("data-sidebar-size", "sm");
      } else if (windowSize > 1025) {
        document.body.classList.remove("vertical-sidebar-enable");
        document.documentElement.getAttribute("data-sidebar-size") === "lg"
          ? document.documentElement.setAttribute("data-sidebar-size", "sm")
          : document.documentElement.setAttribute("data-sidebar-size", "lg");
      } else if (windowSize <= 767) {
        document.body.classList.add("vertical-sidebar-enable");
        document.documentElement.setAttribute("data-sidebar-size", "lg");
      }
    }

    // Two column menu
    if (document.documentElement.getAttribute("data-layout") === "twocolumn") {
      document.body.classList.contains("twocolumn-panel")
        ? document.body.classList.remove("twocolumn-panel")
        : document.body.classList.add("twocolumn-panel");
    }
  };

  return (
    <React.Fragment>
      <header id="page-topbar" className={headerClass}>
        <div className="layout-width">
          <div
            className={`navbar-header ${
              state.isDashboardPage ? "dashboard-header" : ""
            }`}
          >
            <div className="d-flex">
              <button
                onClick={toogleMenuBtn}
                type="button"
                className="btn btn-sm px-0 fs-16 header-item vertical-menu-btn topnav-hamburger"
                id="topnav-hamburger-icon"
              >
                <span className="hamburger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                </span>
              </button>
              <p
                id="page-title"
                className={`topbar-title mb-0 ${
                  state.isDashboardPage ? "dashboard-title" : ""
                }`}
              >
                {state.headerTitle}
              </p>
            </div>

            {state.searchBar ? (
              <div xs={1} className="search-bar">
                <SearchOption />
              </div>
            ) : null}
            {state.isDashboardPage ? (
              <div className="custom-range-input">
                <div className="start-time-pickr">
                  <Flatpickr
                    className="form-control bg-light border-light"
                    id="datepicker-publish-input"
                    value={dates.startTime}
                    onChange={(selectedDates, dateStr, instance) => {
                      setDates({ ...dates, startTime: dateStr });
                    }}
                    placeholder="Select a date"
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      mode: "single",
                      dateFormat: "m-d-y",
                      maxDate: dates.endTime,
                    }}
                  />
                </div>
                <div className="end-time-pickr">
                  <Flatpickr
                    className="form-control bg-light border-light"
                    id="datepicker-publish-input"
                    onChange={(selectedDates, dateStr, instance) => {
                      setDates({ ...dates, endTime: dateStr });
                    }}
                    placeholder="Select a date"
                    value={dates.endTime}
                    options={{
                      altInput: true,
                      altFormat: "F j, Y",
                      mode: "single",
                      dateFormat: "m-d-y",
                      minDate: dates.startTime,
                      maxDate: Date.now(),
                    }}
                  />
                </div>
                <div className="filter-button">
                  <Button onClick={handleDateChange}>Filter</Button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
