import {
  TRAINING,
  TRAINING_FAILURE,
  TRAINING_SUCCESS,
  TRAINING_ADD,
  TRAINING_ADD_SUCCESS,
  TRAINING_ADD_FAILURE,
  BEST_PRACTISES,
  BEST_PRACTISES_SUCCESS,
  BEST_PRACTISES_FAILURE,
  TRAINING_HISTORY,
  TRAINING_HISTORY_SUCCESS,
  TRAINING_HISTORY_FAILURE,
  TRAINING_HISTORY_UPDATE,
  TRAINING_HISTORY_UPDATE_SUCCESS,
  TRAINING_HISTORY_UPDATE_FAILURE,
} from "./actionType";

const initialState = {
  loading: false,
  trainingInEdit: {},
  bestPractises: {},
  trainingHistory: [],
  errorMsg: "",
  error: false,
};

function processTrainingData(response) {
  const photosTrained = response.photosTrained ?? 0;
  const photosRemaining = response.photosRemaining ?? 0;
  const totalPhotos = photosTrained + photosRemaining;
  const progressBar =
    totalPhotos === 0 ? "0%" : `${(photosTrained / totalPhotos) * 100}%`;
  return { ...response, progressBar };
}

function processBestPractieseData(response) {
  const progressBar =
    response.progressBarTotal === 0
      ? "0%"
      : `${(response.progressBarComplete / response.progressBarTotal) * 100}%`;
  return { ...response, progressBar };
}

function updatedHistory(history, payload) {
  const updatedData = history?.trainingHistory?.data?.map((item) =>
    item.id === payload.id ? { ...item, ...payload } : item
  );
  return { data: updatedData };
}

const training = (state = initialState, action) => {
  switch (action.type) {
    case TRAINING:
      return { ...state, loading: true, error: false, errorMsg: "" };
    case TRAINING_SUCCESS:
      return {
        ...state,
        loading: false,
        trainingInEdit: processTrainingData(action.payload),
      };
    case TRAINING_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };

    case TRAINING_ADD:
      return {
        ...state,
        error: false,
        errorMsg: "",
        trainingInEdit: {
          ...state.trainingInEdit,
          loading: true,
          added: false,
        },
      };
    case TRAINING_ADD_SUCCESS:
      return {
        ...state,
        trainingInEdit: {
          ...processTrainingData(action.payload),
          loading: false,
          added: true,
        },
        bestPractises: {
          ...state.bestPractises,
          trainingCountRemaining:
            state.bestPractises.trainingCountRemaining - 1,
        },
      };
    case TRAINING_ADD_FAILURE:
      return {
        ...state,
        error: true,
        errorMsg: action.payload,
        trainingInEdit: {
          ...state.trainingInEdit,
          loading: false,
          added: false,
        },
      };

    case BEST_PRACTISES:
      return {
        ...state,
        error: false,
        errorMsg: "",
        bestPractises: { ...state.bestPractises, loading: true },
      };
    case BEST_PRACTISES_SUCCESS:
      return {
        ...state,
        bestPractises: {
          ...processBestPractieseData(action.payload),
          loading: false,
        },
      };
    case BEST_PRACTISES_FAILURE:
      return {
        ...state,
        error: true,
        errorMsg: action.payload,
        bestPractises: { ...state.bestPractises, loading: false },
      };
    case TRAINING_HISTORY:
      return { ...state, loading: true, error: false, errorMsg: "" };
    case TRAINING_HISTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        trainingHistory: action.payload,
      };
    case TRAINING_HISTORY_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    case TRAINING_HISTORY_UPDATE:
      return { ...state, loading: false, error: false, errorMsg: "" };
    case TRAINING_HISTORY_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        trainingHistory: updatedHistory(state, action.payload),
      };
    case TRAINING_HISTORY_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default training;
