import { all, call, put, takeEvery } from "redux-saga/effects";

import {
  PURCHASES_DETAILS,
  REPORT_DETAILS,
  TOP_PRODUCTS_DETAILS,
  TOP_SHADES_DETAILS,
  TOP_SKIN_CONCERNS_DETAILS,
  COMPLEXIONS,
  COLOR_BENCHMAKRS_GET,
} from "./actionType";
import {
  getClicks,
  getPurchases,
  getPurchasesCount,
  getSearches,
  getTopProducts,
  getTopShades,
  getTopCoverages,
  getComplexions,
  getColorBenchmarks,
} from "../../helpers/backend_helper";
import {
  getPurchaseSuccess,
  getPurchasesFailure,
  getReportsSuccess,
  getReportsFailure,
  getTopProductListSuccess,
  getTopProductListFailure,
  getTopShadesListSuccess,
  getTopShadesListFailure,
  getTopCoveragesListSuccess,
  getTopCoveragesListFailure,
  getComplexionsSuccess,
  getComplexionsFailure,
  getColorBenchmarksSuccess,
  getColorBenchmarksFailure,
} from "./action";

function* retrieveReports({ payload }) {
  try {
    const [searches, clicks, purchases, purchasesCount] = yield all([
      call(getSearches, payload),
      call(getClicks, payload),
      call(getPurchases, payload),
      call(getPurchasesCount, payload),
    ]);

    yield put(getReportsSuccess([searches, clicks, purchases, purchasesCount]));
  } catch (error) {
    yield put(getReportsFailure(error));
  }
}

function* retrievePurchases({ payload }) {
  try {
    const response = yield call(getPurchases, payload);
    yield put(getPurchaseSuccess(response));
  } catch (error) {
    yield put(getPurchasesFailure(error));
  }
}

function* retrieveTopProducts({ payload }) {
  try {
    const response = yield call(getTopProducts, payload);
    yield put(getTopProductListSuccess(response));
  } catch (error) {
    yield put(getTopProductListFailure(error));
  }
}

function* retrieveTopShades({ payload }) {
  try {
    const response = yield call(getTopShades, payload);
    yield put(getTopShadesListSuccess(response));
  } catch (error) {
    yield put(getTopShadesListFailure(error));
  }
}

function* retrieveTopCoverages({ payload }) {
  try {
    const response = yield call(getTopCoverages, payload);
    yield put(getTopCoveragesListSuccess(response));
  } catch (error) {
    yield put(getTopCoveragesListFailure(error));
  }
}

function* retrieveComplexions({ payload }) {
  try {
    const response = yield call(getComplexions, payload);
    yield put(getComplexionsSuccess(response));
  } catch (error) {
    yield put(getComplexionsFailure(error));
  }
}

function* retrieveColorBenchmarks({ payload }) {
  try {
    const response = yield call(getColorBenchmarks, payload);
    yield put(getColorBenchmarksSuccess(response));
  } catch (error) {
    yield put(getColorBenchmarksFailure(error));
  }
}

function* reportSaga() {
  yield takeEvery(REPORT_DETAILS, retrieveReports);
  yield takeEvery(PURCHASES_DETAILS, retrievePurchases);
  yield takeEvery(TOP_PRODUCTS_DETAILS, retrieveTopProducts);
  yield takeEvery(TOP_SHADES_DETAILS, retrieveTopShades);
  yield takeEvery(TOP_SKIN_CONCERNS_DETAILS, retrieveTopCoverages);
  yield takeEvery(COMPLEXIONS, retrieveComplexions);
  yield takeEvery(COLOR_BENCHMAKRS_GET, retrieveColorBenchmarks);
}

export default reportSaga;
