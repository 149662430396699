import React, { useState, useEffect } from "react";
import { calculateTimeFromNow } from "../../helpers/time_helper";

// let timeInterval = null;
const TimeInterval = ({ timestamp = null, delay = 1000, prefix = "" }) => {
  const [currentTime, setCurrentTime] = useState(timestamp || Date.now());
  const [lastUpdatedTime, setlastUpdatedTime] = useState(
    calculateTimeFromNow(timestamp || Date.now())
  );

  const timeInterval = setInterval(() => {
    setlastUpdatedTime(calculateTimeFromNow(currentTime));
  }, delay);

  useEffect(() => {
    return () => {
      if (timeInterval) clearInterval(timeInterval);
    };
  }, []);
  return (
    <>
      {prefix} {lastUpdatedTime}
    </>
  );
};

export default TimeInterval;
