import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Col,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { toast } from "react-toastify";

// formik
import * as Yup from "yup";
import { useFormik } from "formik";

// common helpers
import { MenuItemEnums, RouteParams } from "../../../common/enums";
import { getUrlParam } from "../../../helpers/location-helper";
import FormSelectOptions from "../../../Components/Common/FormSelectOptions";
import { OptionsReturnReasons } from "../../../common/form-selector-values";
// redux
import { useDispatch, useSelector } from "react-redux";
import {
  getReturnList,
  addReturn,
  resetReturnFlag,
} from "../../../store/AiTraining/returns/actions";
import UseDropdowns from "../../../Components/Hooks/UseDropdowns";

const ReturnForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [showForm, setShowForm] = useState(false);

  const { returnInEdit } = useSelector((state) => ({
    returnInEdit: state.Return.returnInEdit,
  }));

  useEffect(() => {
    if (getUrlParam(RouteParams.manageReturns, location.search) == "true") {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (returnInEdit.added || returnInEdit.updated || returnInEdit.removed) {
      dispatch(getReturnList());
      dispatch(resetReturnFlag());
      if (returnInEdit.added) {
        toast.success("Return created successfully");
      } else if (returnInEdit.updated) {
        toast.success("Return updated successfully");
      } else {
        toast.success("Return deleted successfully");
      }
      navigate(`/${MenuItemEnums.aiTraining}/${RouteParams.returns}`);
    }
  }, [returnInEdit.added, returnInEdit.updated, returnInEdit.removed]);

  const submitReturn = async (values) => {
    dispatch(addReturn(values));
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      emailAddress: "",
      returnedGroupId: "",
      returnedProductId: "",
      replacementGroupId: "",
      replacementProductId: "",
      reason: "",
      comments: "",
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string().email().required("Please Enter Email Address"),
      returnedGroupId: Yup.number().required("Please Select Wrong Product"),
      replacementGroupId: Yup.number().required(
        "Please Select Replacement Product"
      ),
      reason: Yup.string().required("Please Select Reason For Return"),
      comments: Yup.string()
        .test(
          "len",
          "Must be Less than 1600 characters",
          (val) => val.length < 1600
        )
        .required("Please Enter Comment"),
    }),
    onSubmit: (values) => {
      submitReturn(values);
    },
  });

  const {
    productOptions: returnedProductOptions,
    variantOptions: returnedVariantOptions,
  } = UseDropdowns(+validation.values.returnedGroupId);
  const {
    productOptions: replacementProductOptions,
    variantOptions: replacementVariantOptions,
  } = UseDropdowns(+validation.values.replacementGroupId);

  console.log("returnedProductOptions>>>>>>>>", returnedProductOptions);

  return (
    <React.Fragment>
      {showForm ? (
        <Col xs={4} className="mime-form right-edit scroll-container">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              validation.handleSubmit();
              return false;
            }}
          >
            <Row className="form-header">
              <Col className="title">Add Return</Col>

              <Col className="action-button">
                <Button
                  type="submit"
                  className="form-control btn-small btn-text-primary"
                  disabled={returnInEdit.loading}
                >
                  {returnInEdit.loading ? (
                    <Spinner size="sm" className="me-2">
                      Loading...
                    </Spinner>
                  ) : null}
                  Save
                </Button>
              </Col>
            </Row>
            <Row className="mt-20">
              <Label className="form-label">User Email*</Label>
              <Input
                name="emailAddress"
                type="text"
                className="form-control"
                value={validation.values.emailAddress || ""}
                placeholder="email@domain.com"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.emailAddress &&
                  validation.errors.emailAddress
                    ? true
                    : false
                }
              />
              {validation.touched.emailAddress &&
              validation.errors.emailAddress ? (
                <FormFeedback type="invalid">
                  {validation.errors.emailAddress}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Wrong Product*</Label>
              <select
                name="returnedGroupId"
                value={validation.values.returnedGroupId}
                onChange={(e) => {
                  const returnedGroupId = +e.target.value;
                  validation.handleChange(e);
                  validation.setFieldValue(
                    "returnedGroupId",
                    returnedGroupId,
                    true
                  );
                }}
                className={
                  "form-select" +
                  (validation.values.returnedGroupId ? "" : " empty") +
                  (validation.touched.returnedGroupId &&
                  validation.errors.returnedGroupId
                    ? " is-invalid"
                    : "") +
                  (returnedProductOptions.length === 0 ? " loading" : "")
                }
                disabled={returnedProductOptions.length === 0}
              >
                <FormSelectOptions
                  categories={returnedProductOptions}
                  defaultOptionLabel={
                    returnedProductOptions.length === 0 ? "No data found." : ""
                  }
                  defaultOption={
                    validation.values.returnedGroupId ? null : {}
                  }
                />
              </select>
              {validation.touched.returnedGroupId &&
              validation.errors.returnedGroupId ? (
                <FormFeedback type="invalid">
                  {validation.errors.returnedGroupId}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Label className="form-label">
                Wrong Product Variant / Shade*
              </Label>
              <select
                name="returnedProductId"
                value={validation.values.returnedProductId}
                onChange={(e) => {
                  const returnedProductId = +e.target.value;
                  validation.handleChange(e);
                  validation.setFieldValue(
                    "returnedProductId",
                    returnedProductId,
                    true
                  );
                }}
                className={
                  "form-select" +
                  (validation.values.returnedProductId ? "" : " empty") +
                  (validation.touched.returnedProductId &&
                  validation.errors.returnedProductId
                    ? " is-invalid"
                    : "") +
                  (returnedVariantOptions.length === 0 ? " loading" : "")
                }
                disabled={returnedVariantOptions.length === 0}
              >
                <FormSelectOptions
                  categories={returnedVariantOptions}
                  defaultOptionLabel={
                    returnedProductOptions.length === 0
                      ? "No data found."
                      : returnedVariantOptions.length === 0
                      ? "Loading..."
                      : ""
                  }
                  defaultOption={
                    validation.values.returnedProductId ? null : {}
                  }
                />
              </select>
              {validation.touched.returnedProductId &&
              validation.errors.returnedProductId ? (
                <FormFeedback type="invalid">
                  {validation.errors.returnedProductId}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Replacement Product*</Label>
              <select
                name="replacementGroupId"
                value={validation.values.replacementGroupId}
                onChange={(e) => {
                  const replacementGroupId = +e.target.value;
                  validation.handleChange(e);
                  validation.setFieldValue(
                    "replacementGroupId",
                    replacementGroupId,
                    true
                  );
                }}
                className={
                  "form-select" +
                  (validation.values.replacementGroupId ? "" : " empty") +
                  (validation.touched.replacementGroupId &&
                  validation.errors.replacementGroupId
                    ? " is-invalid"
                    : "") +
                  (replacementProductOptions.length === 0 ? " loading" : "")
                }
                disabled={replacementProductOptions.length === 0}
              >
                <FormSelectOptions
                  categories={replacementProductOptions}
                  defaultOptionLabel={
                    replacementProductOptions.length === 0
                      ? "No data found."
                      : ""
                  }
                  defaultOption={
                    validation.values.replacementGroupId ? null : {}
                  }
                />
              </select>
              {validation.touched.replacementGroupId &&
              validation.errors.replacementGroupId ? (
                <FormFeedback type="invalid">
                  {validation.errors.replacementGroupId}
                </FormFeedback>
              ) : null}
            </Row>

            <Row className="mt-20">
              <Label className="form-label">
                Replacement Product Variant / Shade
              </Label>
              <select
                name="replacementProductId"
                value={validation.values.replacementProductId}
                onChange={(e) => {
                  const replacementProductId = +e.target.value;
                  validation.handleChange(e);
                  validation.setFieldValue(
                    "replacementProductId",
                    replacementProductId,
                    true
                  );
                }}
                className={
                  "form-select" +
                  (validation.values.replacementProductId ? "" : " empty") +
                  (validation.touched.replacementProductId &&
                  validation.errors.replacementProductId
                    ? " is-invalid"
                    : "") +
                  (replacementVariantOptions.length === 0 ? " loading" : "")
                }
                disabled={replacementVariantOptions.length === 0}
              >
                <FormSelectOptions
                  categories={replacementVariantOptions}
                  defaultOptionLabel={
                    replacementProductOptions.length === 0
                      ? "No data found."
                      : replacementVariantOptions.length === 0
                      ? "Loading..."
                      : ""
                  }
                  defaultOption={
                    validation.values.replacementProductId ? null : {}
                  }
                />
              </select>
              {validation.touched.replacementProductId &&
              validation.errors.replacementProductId ? (
                <FormFeedback type="invalid">
                  {validation.errors.replacementProductId}
                </FormFeedback>
              ) : null}
            </Row>

            <Row className="mt-20">
              <Label className="form-label">Reason for Return*</Label>
              <select
                name="reason"
                value={validation.values.reason}
                onChange={validation.handleChange}
                className={
                  "form-select" +
                  (validation.values.reason ? "" : " empty") +
                  (validation.touched.reason && validation.errors.reason
                    ? " is-invalid"
                    : "")
                }
              >
                <FormSelectOptions
                  categories={OptionsReturnReasons}
                  defaultOption={validation.values.reason ? null : {}}
                />
              </select>
              {validation.touched.reason && validation.errors.reason ? (
                <FormFeedback type="invalid">
                  {validation.errors.reason}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Comments*</Label>
              <Input
                name="comments"
                type="textarea"
                className="form-control"
                maxLength={1600}
                value={validation.values.comments || ""}
                placeholder="Enter the product description here"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.comments && validation.errors.comments
                    ? true
                    : false
                }
              />
              {validation.touched.comments || validation.errors.comments ? (
                <FormFeedback type="invalid">
                  {validation.errors.comments}
                </FormFeedback>
              ) : null}
            </Row>
          </Form>
        </Col>
      ) : null}
    </React.Fragment>
  );
};

export default ReturnForm;
