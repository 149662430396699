import {
	LOGIN_USER, LOGIN_SUCCESS,
	LOGOUT_USER, LOGOUT_USER_SUCCESS,
	API_ERROR,
	RESET_LOGIN_FLAG
} from "./actionTypes";

const initialState = {
	errorMsg: "",
	loading: false,
	error: false,
};

const login = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN_USER:
			state = {
				...state, loading: true, error: false, errorMsg: "",
			};
			break;
		case LOGIN_SUCCESS:
			state = {
				...state, loading: false,
			};
			break;

		case LOGOUT_USER:
			state = { ...state, isUserLogout: false };
			break;
		case LOGOUT_USER_SUCCESS:
			state = { ...state, isUserLogout: true };
			break;

		case API_ERROR:
			state = {
				...state, errorMsg: action.payload, loading: false, error: true, isUserLogout: false,
			};
			break;

		case RESET_LOGIN_FLAG:
			state = {
				...state,
				errorMsg: "",
				loading: false,
				error: false,
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default login;
