export const DATE_RANGE = "DATE_RANGE"

export const GET_REVENUES = "GET_REVENUES";
export const GET_REVENUES_SUCCESS = "GET_REVENUES_SUCCESS";
export const GET_REVENUES_FAILURE = "GET_REVENUES_FAILURE";

export const GET_UNDERTONES = "GET_UNDERTONES";
export const GET_UNDERTONES_SUCCESS = "GET_UNDERTONES_SUCCESS";
export const GET_UNDERTONES_FAILURE = "GET_UNDERTONES_FAILURE";

export const GET_COLOR_PREFERENCES = "GET_COLOR_PREFERENCES";
export const GET_COLOR_PREFERENCES_SUCCESS = "GET_COLOR_PREFERENCES_SUCCESS";
export const GET_COLOR_PREFERENCES_FAILURE = "GET_COLOR_PREFERENCES_FAILURE";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILURE = "GET_COUNTRIES_FAILURE";

export const GET_RECENT_SHOPPERS = "GET_RECENT_SHOPPERS";
export const GET_RECENT_SHOPPERS_SUCCESS = "GET_RECENT_SHOPPERS_SUCCESS";
export const GET_RECENT_SHOPPERS_FAILURE = "GET_RECENT_SHOPPERS_FAILURE";

export const GET_IN_STORE_ENGAGEMENT = "GET_IN_STORE_ENGAGEMENT";
export const GET_IN_STORE_ENGAGEMENT_SUCCESS = "GET_IN_STORE_ENGAGEMENT_SUCCESS";
export const GET_IN_STORE_ENGAGEMENT_FAILURE = "GET_IN_STORE_ENGAGEMENT_FAILURE";
