import {
  RETURN_LIST, RETURN_LIST_FAILURE, RETURN_LIST_SUCCESS,
  RETURN_ADD, RETURN_ADD_SUCCESS, RETURN_ADD_FAILURE,
  RETURN_UPDATE, RETURN_UPDATE_SUCCESS, RETURN_UPDATE_FAILURE,
  RETURN_REMOVE, RETURN_REMOVE_SUCCESS, RETURN_REMOVE_FAILURE,
  RESET_RETURN_FLAG,
} from "./actionType";

export const getReturnList = () => {
  return { type: RETURN_LIST };
};

export const getReturnListSuccess = (returns) => {
  return {
    type: RETURN_LIST_SUCCESS,
    payload: returns,
  };
};

export const getReturnListFailure = (error) => {
  return {
    type: RETURN_LIST_FAILURE,
    payload: error,
  };
};

export const addReturn = (data) => {
  return {
    type: RETURN_ADD,
    payload: data,
  };
};

export const addReturnSuccess = (response) => {
  return {
    type: RETURN_ADD_SUCCESS,
    payload: response,
  };
};

export const addReturnFailure = (error) => {
  return {
    type: RETURN_ADD_FAILURE,
    payload: error,
  };
};

export const updateReturn = (returnId, data) => {
  return {
    type: RETURN_UPDATE,
    payload: {returnId, data},
  };
};

export const updateReturnSuccess = (response) => {
  return {
    type: RETURN_UPDATE_SUCCESS,
    payload: response,
  };
};

export const updateReturnFailure = (error) => {
  return {
    type: RETURN_UPDATE_FAILURE,
    payload: error,
  };
};

export const removeReturn = (returnId) => {
  return {
    type: RETURN_REMOVE,
    payload: returnId,
  };
};

export const removeReturnSuccess = (response) => {
  return {
    type: RETURN_REMOVE_SUCCESS,
    payload: response,
  };
};

export const removeReturnFailure = (error) => {
  return {
    type: RETURN_REMOVE_FAILURE,
    payload: error,
  };
};

export const resetReturnFlag = () => {
  return {
    type: RESET_RETURN_FLAG,
  };
};
