import {
  getVariants,
} from "../../helpers/backend_helper";

export class ProductsApiService {
  static getVariantsList = async (productID) => {
    try {
      const res = await getVariants(productID);

      return res;
    } catch (err) {
      return {
        success: false,
      };
    }
  };
}
