import React, { useCallback } from "react";
import { Col, Row } from "reactstrap";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

// common helpers
import PageHeader from "../../../Components/Common/PageHeader";
import Loader from "../../../Components/Common/Loader";
import CurrencyFormatBox, {
  currencyFormat,
} from "../../../Components/Common/CurrencyFormatBox";
import ErrorController from "../../../Components/Common/ErrorController";
import { convertToDateMonthYearFormat } from "../../../helpers/time_helper";
import TimeInterval from "../../../Components/Hooks/UseIntervalHook";

import { useSelector } from "react-redux";

const Revenue = ({ hasHeader = true }) => {
  const { purchases } = useSelector((state) => ({
    purchases: state.Report.purchases,
  }));

  const revenue = useCallback(() => {
    if (!purchases.data.length) return 0;
    let totalRevenue = 0;
    purchases.data?.forEach((purchase) => (totalRevenue += +purchase?.sum));
    return totalRevenue;
  }, [purchases.data]);

  const CustomTooltip = useCallback(({ active, payload, valueTitle }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>
            Date:{" "}
            {convertToDateMonthYearFormat(
              payload[0]?.payload?.date,
              false,
              true
            )}
          </p>
          <p>
            {valueTitle}: ${payload[0]?.payload?.sum} &nbsp;
          </p>
        </div>
      );
    }
  }, []);

  const fetchingRevenue = purchases.loading;

  return (
    <Col
      className={
        hasHeader
          ? "product-list scroll-container main-container reporting-container revenue-container"
          : "dashboard-revenue-container"
      }
    >
      {hasHeader && (
        <div className="page-header">
          <PageHeader title="Revenue" />
          <span>
            <TimeInterval prefix="Last updated" />
          </span>
        </div>
      )}

      {fetchingRevenue ? <Loader /> : null}
      {!fetchingRevenue && purchases.data.length ? (
        <Row>
          <Col>
            <div className={hasHeader ? "revenue-wrapper common-card" : ""}>
              <Row className="revenue-details-container">
                <Col>
                  <div className="details-card border-right-none">
                    <span className="amount color-purple-light">
                      <CurrencyFormatBox
                        number={revenue()}
                        prefix="$"
                        decimals={0}
                      />
                    </span>
                    <span className="heading">Revenue</span>
                  </div>
                </Col>
                {/* <Col>
                  <div className="details-card">
                    <span className="amount">
                      <CurrencyFormatBox
                        number={497000}
                        prefix="$"
                        decimals={0}
                      />
                    </span>
                    <span className="heading">Earnings</span>
                  </div>
                </Col> */}
                {/* <Col>
                  <div className="details-card">
                    <span className="amount">3.6%</span>
                    <span className="heading">Profit Ratio</span>
                  </div>
                </Col> */}
              </Row>
              <div className="chart">
                <ResponsiveContainer width="100%" height="100%">
                  <ComposedChart width={"100%"} data={purchases.data}>
                    <defs>
                      <linearGradient
                        id="area-chart-1"
                        x1="0"
                        y1="0"
                        x2="0"
                        y2="1"
                      >
                        <stop
                          offset="100%"
                          stopColor="#06b39c"
                          stopOpacity={0.4}
                        />
                      </linearGradient>
                    </defs>
                    <XAxis
                      dataKey="date"
                      axisLine={false}
                      tickLine={false}
                      stroke="#b8c0c8"
                      tickFormatter={(tick) =>
                        convertToDateMonthYearFormat(tick, false, true)
                      }
                    />
                    <YAxis
                      axisLine={false}
                      tickLine={false}
                      allowDecimals
                      tickFormatter={(tick) => `$${currencyFormat(tick)}`}
                    />
                    <Tooltip content={<CustomTooltip valueTitle="Revenue" />} />
                    <Legend iconType="circle" iconSize={8} align="center" />
                    <CartesianGrid
                      stroke="#f5f5f5"
                      horizontal={true}
                      vertical={false}
                    />
                    <Area
                      type="monotone"
                      dataKey="sum"
                      name="Revenue"
                      stroke="#06b39c"
                      fill="url(#area-chart-1)"
                      strokeWidth={2}
                    />
                  </ComposedChart>
                </ResponsiveContainer>
              </div>
            </div>
          </Col>
        </Row>
      ) : !purchases.data.length && !fetchingRevenue ? (
        <span className="error-message">No data available</span>
      ) : null}
    </Col>
  );
};

export default Revenue;
