import { Link } from "react-router-dom";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  // Call resetErrorBoundary() to reset the error boundary and retry the render.

  return (
    <div className="fallback-container">
      <div className="error-fallback">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
        <Link to={"/dashboard"} className="text-underline" onClick={resetErrorBoundary}>
          Go to Home
        </Link>
      </div>
    </div>
  );
};

export default ErrorFallback;
