export const MISSED_CONNECTION_LIST = "MISSED_CONNECTION_LIST";
export const MISSED_CONNECTION_LIST_SUCCESS = "MISSED_CONNECTION_LIST_SUCCESS";
export const MISSED_CONNECTION_LIST_FAILURE = "MISSED_CONNECTION_LIST_FAILURE";

export const SEND_EMAIL = "SEND_EMAIL";
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS";
export const SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED";

export const RETRIEVE_BRANDS = "RETRIEVE_BRANDS";
export const RETRIEVE_BRANDS_SUCCESS = "RETRIEVE_BRANDS_SUCCESS";
export const RETRIEVE_BRANDS_FAILED = "RETRIEVE_BRANDS_FAILED";

export const RETRIEVE_BRAND_PRODUCTS = "RETRIEVE_BRAND_PRODUCTS";
export const RETRIEVE_BRAND_PRODUCTS_SUCCESS =
  "RETRIEVE_BRAND_PRODUCTS_SUCCESS";
export const RETRIEVE_BRAND_PRODUCTS_FAILED = "RETRIEVE_BRAND_PRODUCTS_FAILED";

export const CLEAR_ERROR = "CLEAR_ERROR";
