import {
  BUNDLE_LIST,BUNDLE_LIST_SUCCESS,BUNDLE_LIST_FAILURE,
  BUNDLE_DETAILS,BUNDLE_DETAILS_SUCCESS,BUNDLE_DETAILS_FAILURE,
  BUNDLE_PRODUCT_ADD,BUNDLE_PRODUCT_CLEAR,
  BUNDLE_ADD,BUNDLE_ADD_SUCCESS,BUNDLE_ADD_FAILURE,
  BUNDLE_UPDATE,BUNDLE_UPDATE_SUCCESS,BUNDLE_UPDATE_FAILURE,
  BUNDLE_REMOVE,BUNDLE_REMOVE_SUCCESS,BUNDLE_REMOVE_FAILURE,
  BUNDLE_PRODUCT_REMOVE,BUNDLE_PRODUCT_REMOVE_SUCCESS,BUNDLE_PRODUCT_REMOVE_FAILURE,EDIT_BUNDLE_PRODUCT_REMOVE,
  RESET_BUNDLE_FLAG,
} from "./actionTypes";

export const getBundlesList = () => {
  return {
    type: BUNDLE_LIST,
  };
};

export const getBundlesSuccess = (response) => {
  return {
    type: BUNDLE_LIST_SUCCESS,
    payload: response,
  };
};

export const getBundlesFailure = (error) => {
  return {
    type: BUNDLE_LIST_FAILURE,
    payload: error,
  };
};

export const getBundleDetails = (bundleID) => {
  return {
    type: BUNDLE_DETAILS,
    payload: { bundleID },
  };
};

export const getBundleDetailsSuccess = (response) => {
  return {
    type: BUNDLE_DETAILS_SUCCESS,
    payload: response,
  };
};

export const getBundleDetailsFailure = (error) => {
  return {
    type: BUNDLE_DETAILS_FAILURE,
    payload: error,
  };
};

export const addBundleProduct = (product) => {
  return {
    type: BUNDLE_PRODUCT_ADD,
    payload: product,
  };
};

export const removeBundleProduct = (variantID, bundleID) => {
  return {
    type: BUNDLE_PRODUCT_REMOVE,
    payload: { variantID, bundleID },
  };
};

export const removeEditBundleProduct = (filteredProduct) => {
  return {
    type: EDIT_BUNDLE_PRODUCT_REMOVE,
    payload:  filteredProduct ,
  };
};

export const bundleProductRemoveSuccess = (response) => {
  return {
    type: BUNDLE_PRODUCT_REMOVE_SUCCESS,
    payload: response,
  };
};

export const bundleProductRemoveFailure = (error) => {
  return {
    type: BUNDLE_PRODUCT_REMOVE_FAILURE,
    payload: error,
  };
};


export const clearBundleProduct = (isClearProduct = false) => {
  return {
    type: BUNDLE_PRODUCT_CLEAR,
    payload: isClearProduct,
  };
};

export const addBundle = (data) => {
  return {
    type: BUNDLE_ADD,
    payload: data,
  };
};

export const addBundleSuccess = (response) => {
  return {
    type: BUNDLE_ADD_SUCCESS,
    payload: response,
  };
};

export const addBundleFailure = (error) => {
  return {
    type: BUNDLE_ADD_FAILURE,
    payload: error,
  };
};

export const updateBundle = (bundleID, data) => {
  return {
    type: BUNDLE_UPDATE,
    payload: { bundleID, data },
  };
};

export const updateBundleSuccess = (response) => {
  return {
    type: BUNDLE_UPDATE_SUCCESS,
    payload: response,
  };
};

export const updateBundleFailure = (error) => {
  return {
    type: BUNDLE_UPDATE_FAILURE,
    payload: error,
  };
};

export const removeBundle = (bundleID) => {
  return {
    type: BUNDLE_REMOVE,
    payload: bundleID,
  };
};

export const removeBundleSuccess = (response) => {
  return {
    type: BUNDLE_REMOVE_SUCCESS,
    payload: response,
  };
};

export const removeBundleFailure = (error) => {
  return {
    type: BUNDLE_REMOVE_FAILURE,
    payload: error,
  };
};

export const resetBundleFlag = () => {
  return {
    type: RESET_BUNDLE_FLAG,
  };
};
