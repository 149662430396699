import React, { useEffect, useState } from "react";
import { Container, Button, Row, Col, Table } from "reactstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Trash2 from "feather-icons-react/build/IconComponents/Trash2";

import { MenuItemEnums, RouteParams } from "../../../common/enums";
import { getIDFromPath } from "../../../helpers/location-helper";
import Loader from "../../../Components/Common/Loader";
import PageHeader from "../../../Components/Common/PageHeader";

import {
  getBundlesList,
  getBundleDetails,
  removeBundle,
  clearBundleProduct,
  removeBundleProduct,
  removeEditBundleProduct,
} from "../../../store/actions";
import BundleForm from "./BundleForm";

const BundleProducts = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    menuStatus,
    products,
    loading,
    bundleInEdit,
    productWithLabel,
    dependantVariable,
  } = useSelector((state) => ({
    menuStatus: state.Menu.menuStatus,
    products: state.Bundle.products,
    dependantVariable: state.Bundle.dependantVariable,
    productWithLabel: state.Product.productWithLabel,
    bundles: state.Bundle.bundles,
    bundleInEdit: state.Bundle.bundleInEdit,
    loading: state.Bundle.loading,
    error: state.Bundle.error,
  }));

  const editableBundleID = getIDFromPath(location.pathname);

  const removeProduct = (product, bundleID, idx) => {
    if (products.length === 1) {
      dispatch(removeBundle(editableBundleID));
      navigate(`/${MenuItemEnums.inventory}/${MenuItemEnums.bundle}`);
      return;
    }
    if (!product.productShade) {
      const filteredProduct = products.filter((_, index) => idx !== index);
      dispatch(removeEditBundleProduct(filteredProduct));
      return;
    }
    dispatch(removeBundleProduct(product.variantId, bundleID));
  };

  useEffect(() => {
    if (!editableBundleID) {
      dispatch(clearBundleProduct());
      return;
    }

    if (!location.search) {
      dispatch(clearBundleProduct(true));
    }
    dispatch(getBundleDetails(editableBundleID));
  }, [dispatch, editableBundleID, dependantVariable]);

  document.title = "Bundle Products | mime";

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="bundle-products-page">
          <Row className="ms-0">
            <Col className="product-list scroll-container main-container">
              {!loading ? (
                <div className="header">
                  {bundleInEdit?.imageUrl && (
                    <img
                      src={bundleInEdit?.imageUrl}
                      className="bundle-image"
                      alt="bundlephoto"
                    />
                  )}
                  <PageHeader
                    title={
                      editableBundleID !== null
                        ? bundleInEdit?.name
                        : "Create Bundle"
                    }
                    editLink={
                      editableBundleID !== null
                        ? `${menuStatus.subMenu.link}?${RouteParams.editBundle}=true`
                        : ""
                    }
                    count={products.length || 0}
                    subtitleSuffix="Products in Bundle"
                    editSuffix={editableBundleID !== null ? "Bundle" : ""}
                  />
                </div>
              ) : null}

              {loading ? <Loader /> : null}

              {!loading ? (
                <Table className="align-middle table-nowrap mb-0">
                  <tbody>
                    {products.length
                      ? products
                          .sort((a, b) => a.priority - b.priority)
                          .map((product, idx) => (
                            <tr className="product-item" key={idx}>
                              <td className="product-item-image">
                                <img src={product.imageUrl} alt="" />
                              </td>
                              <td className="product-item-content">
                                <div className="title">
                                  {productWithLabel[product.groupId]} -{" "}
                                  {product.productShade}
                                </div>
                                <div className="sub-title">
                                  {product.bundleType} - Priority:{" "}
                                  {product.priority}
                                </div>
                              </td>
                              <td className="fit-content">
                                <Button
                                  type="button"
                                  className="icon-btn"
                                  onClick={() =>
                                    removeProduct(
                                      product,
                                      editableBundleID,
                                      idx
                                    )
                                  }
                                >
                                  <Trash2
                                    size="24"
                                    stroke="#d26767"
                                    className="delete-icon"
                                  />
                                </Button>
                              </td>
                            </tr>
                          ))
                      : null}
                  </tbody>
                </Table>
              ) : null}
            </Col>
            <BundleForm />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default BundleProducts;
