import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";
import { updateMenuStatus } from "../../menu/actions";
import { setDateRange } from "../../dashboard/actions";
import { jwtAccessToken, setAuthUser, removeAuthUser } from "../../../helpers/auth-user";

// Include Both Helper File with needed methods
import {
	postLogin, postLogout
} from "../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }) {
	try {
		const response = yield call(postLogin, {
			email: user.email,
			password: user.password,
		});

		// setAuthUser should be called first
		setAuthUser(response);

		yield put(updateMenuStatus(null));
		yield put(setDateRange(null));
		yield put(loginSuccess(response));
		history('/dashboard');
	} catch (error) {
		yield put(apiError(error));
	}
}

function* logoutUser() {
	try {
		const token = jwtAccessToken()
		if (token) {
			yield call(postLogout, {jwtAccessToken: token})
		}
	} catch (error) {
		yield put(apiError(LOGOUT_USER, error));
	} finally {
		removeAuthUser()
		yield put(logoutUserSuccess(LOGOUT_USER, true));
	}
}

function* authSaga() {
	yield takeEvery(LOGIN_USER, loginUser);
	yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
