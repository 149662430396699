import {
  VERIFY_RESET_TOKEN, VERIFY_RESET_TOKEN_SUCCESS, VERIFY_RESET_TOKEN_FAILED,
} from "./actionTypes";

const initialState = {
  message: "",
  loading: true,
  success: false,
  error: false,
};

const ResetToken = (state = initialState, action) => {
  switch (action.type) {
    case VERIFY_RESET_TOKEN:
      state = { ...state, message: "", loading: true, success: false, error: false, };
      break;
    case VERIFY_RESET_TOKEN_SUCCESS:
      state = { ...state, message: action.payload, success: true, loading: false, };
      break;
    case VERIFY_RESET_TOKEN_FAILED:
      state = { ...state, message: action.payload, loading: false, error: true, };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ResetToken;
