import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import RevenueChart from "./../Reporting/Revenue/Revenue";
import { getPurchases } from "../../store/reporting/action";
import { useDispatch, useSelector } from "react-redux";

const Revenue = () => {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState("1y");
	const { dateRange } = useSelector((state) => ({
		dateRange: state.Dashboard.dateRange,
	}));

	useEffect(() => {
		const filterValues = { startTime: dateRange[0], endTime: dateRange[1], grouping: "month" };
		dispatch(getPurchases(filterValues));
	}, [dateRange]);

	const revenueTabOptions = [
		{
			label: "ALL",
			value: "all",
		},
		{
			label: "1M",
			value: "1m",
		},
		{
			label: "6M",
			value: "6m",
		},
		{
			label: "1Y",
			value: "1y",
		},
	];

	return (
		<div className="revenue-wrapper common-card">
			<Row>
				<Col className="card-title">Revenue</Col>
				{/* <Col xs={2}>
					<div className="revenue-duration-tabs">
						{revenueTabOptions.map((tab) => (
							<span
								key={tab.value}
								className={`duration ${
									tab.value === activeTab ? "active-tab" : ""
								}`}
								onClick={() => setActiveTab(tab.value)}
							>
								{tab.label}
							</span>
						))}
					</div>
				</Col> */}
			</Row>
			<RevenueChart hasHeader={false} />
		</div>
	);
};

export default Revenue;
