import {
  CAMPAIGN_LIST, CAMPAIGN_LIST_SUCCESS, CAMPAIGN_LIST_FAILURE,
  CAMPAIGN_ADD, CAMPAIGN_ADD_SUCCESS, CAMPAIGN_ADD_FAILURE,
  CAMPAIGN_UPDATE, CAMPAIGN_UPDATE_SUCCESS, CAMPAIGN_UPDATE_FAILURE,
  CAMPAIGN_REMOVE, CAMPAIGN_REMOVE_SUCCESS, CAMPAIGN_REMOVE_FAILURE,
  CAMPAIGN_SET, RESET_CAMPAIGN_FLAG,
} from "./actionType";

export const getCampaignList = () => {
  return {
    type: CAMPAIGN_LIST,
  };
};

export const getCampaignsSuccess = (response) => {
  return {
    type: CAMPAIGN_LIST_SUCCESS,
    payload: response,
  };
};

export const getCampaignsFailure = (error) => {
  return {
    type: CAMPAIGN_LIST_FAILURE,
    payload: error,
  };
};

export const createCampaign = (data) => {
  return {
    type: CAMPAIGN_ADD,
    payload: data,
  };
};

export const createCampaignSuccess = (response) => {
  return {
    type: CAMPAIGN_ADD_SUCCESS,
    payload: response,
  };
};

export const createCampaignFailure = (error) => {
  return {
    type: CAMPAIGN_ADD_FAILURE,
    payload: error,
  };
};

export const updateCampaign = (campaignID, data) => {
  return {
    type: CAMPAIGN_UPDATE,
    payload: {campaignID, data},
  };
};

export const updateCampaignSuccess = (response) => {
  return {
    type: CAMPAIGN_UPDATE_SUCCESS,
    payload: response,
  };
};

export const updateCampaignFailure = (error) => {
  return {
    type: CAMPAIGN_UPDATE_FAILURE,
    payload: error,
  };
};

export const removeCampaign = (campaignID) => {
  return {
    type: CAMPAIGN_REMOVE,
    payload: campaignID,
  };
};

export const removeCampaignSuccess = (response) => {
  return {
    type: CAMPAIGN_REMOVE_SUCCESS,
    payload: response,
  };
};

export const removeCampaignFailure = (error) => {
  return {
    type: CAMPAIGN_REMOVE_FAILURE,
    payload: error,
  };
};

export const setCampaign = (data) => {
  return {
    type: CAMPAIGN_SET,
    payload: data,
  };
};

export const resetCampaignFlag = () => {
  return {
    type: RESET_CAMPAIGN_FLAG,
  };
};
