import React, { useEffect } from "react";
import { Col, Container, Row, Table } from "reactstrap";

// location helpers
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getUrlParam } from "../../helpers/location-helper";

// common helpers
import Loader from "../../Components/Common/Loader";
import { MenuItemEnums, RouteParams } from "../../common/enums";
import PageHeader from "../../Components/Common/PageHeader";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { campaignStateSelector } from "../../store/campaign/campaignSelector";

import CampaignForm from "./CampaignForm";
import { toast } from "react-toastify";
import ErrorController from "../../Components/Common/ErrorController";
import { useState } from "react";

const Campaigns = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const camapignState = useSelector(campaignStateSelector);
  const campaigns = camapignState.campaigns ?? [];
  const campaignCounts = camapignState.total ?? 0;
  const fetchingCampaigns = camapignState.loading;
  const navigationLink = camapignState.navigationLink;
  const errorMessage = camapignState.errorMsg;

  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    if (getUrlParam(RouteParams.createCampaign, location.search) === "true") {
      setShowForm(true);
    } else if (getUrlParam(RouteParams.editCampaign, location.search) === "true") {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (!navigationLink) return;
    navigate(navigationLink);
  }, [navigate, navigationLink]);

  useEffect(() => {
    if (!errorMessage) return;
    toast.error(errorMessage ?? "something went wrong!");
  }, [errorMessage]);

  document.title = "Campaign | mime"

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container campaign-container main-container">
            <PageHeader
              title="Showing all Campaigns"
              count={campaignCounts}
              subtitleSuffix="Campaign Groups"
            />

            {fetchingCampaigns ? <Loader /> : null}

            {!fetchingCampaigns && !errorMessage ? (
              <Row>
                <Col className="product-list">
                  <Table className="align-middle table-nowrap mb-0">
                    <tbody>
                      {campaigns &&
                        campaigns.map((campaign, idx) => (
                          <tr className="product-item" key={idx}>
                            <td className="product-item-image">
                              <img src={campaign.imageUrl} alt="" />
                            </td>
                            <td className="product-item-content">
                              <div className="title">{campaign.name}</div>
                              <div className="sub-title">
                                {campaign.campaignType}
                              </div>
                            </td>
                            <td className="product-item-button">
                              <Link
                                to={`/${MenuItemEnums.campaign}/${MenuItemEnums.myCampaign}/${campaign?.id}?${RouteParams.editCampaign}=true&${RouteParams.campaignId}=${campaign?.id}`}
                              >
                                <button type="button" className="btn">
                                  Manage
                                </button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : errorMessage ? (
              <ErrorController messsage={errorMessage} />
            ) : null}
          </Col>
          {showForm ? <CampaignForm /> : null}
        </Row>
      </Container>
    </div>
  );
};

export default Campaigns;
