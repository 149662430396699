import {
	REGISTER_USER,
	REGISTER_USER_SUCCESSFUL,
	REGISTER_USER_FAILED,
	RESET_REGISTER_FLAG
} from "./actionTypes";

const initialState = {
	errorMsg: "",
	message: '',
	loading: false,
	user: {},
	success: false,
	error: false
};

const Account = (state = initialState, action) => {
	switch (action.type) {
		case REGISTER_USER:
			state = {
				...state, loading: true, success: false, error: false, errorMsg: "",
				user: {
					email: action.payload.user.emailAddress,
					password: action.payload.user.password
				}
			};
			break;
		case REGISTER_USER_SUCCESSFUL:
			state = {
				...state, loading: false, success: true,
				// user: action.payload,
			};
			break;
		case REGISTER_USER_FAILED:
			state = {
				...state, loading: false, error: true, errorMsg: action.payload,
				user: null,
			};
			break;
		case RESET_REGISTER_FLAG:
			state = {
				...state,
				loading: false,
				success: false,
				error: false,
				errorMsg: "",
			};
			break;
		default:
			state = { ...state };
			break;
	}
	return state;
};

export default Account;
