import React from 'react';
import withRouter from '../../Components/Common/withRouter';

const ParticlesAuth = ({ children }) => {
	return (
		<React.Fragment>
			<div className="auth-page-wrapper">
				<div className="auth-one-bg" id="auth-particles">
					<div className="bg-overlay"></div>

					{/* pass the children */}
					{children}

				</div>
			</div>
		</React.Fragment>
	);
};

export default withRouter(ParticlesAuth);