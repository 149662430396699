import {
	FORGET_PASSWORD,
	FORGET_PASSWORD_SUCCESS,
	FORGET_PASSWORD_ERROR,	
} from "./actionTypes";

export const userForgotPassword = (user, history) => {
	return {
		type: FORGET_PASSWORD,
		payload: { user, history },
	};
};

export const userForgotPasswordSuccess = message => {
	return {
		type: FORGET_PASSWORD_SUCCESS,
		payload: message,
	};
};

export const userForgotPasswordError = message => {
	return {
		type: FORGET_PASSWORD_ERROR,
		payload: message,
	};
};

