import React, { useEffect, useState } from "react";
import Conversions from "./Conversion/Conversions";
import Revenue from "./Revenue/Revenue";
import TopProducts from "./TopProducts/TopProducts";
import TopShades from "./TopShades/TopShades";
import TopCoverages from "./TopCoverages";
import { useLocation } from "react-router";
import { Container, Row } from "reactstrap";
import FilterForm from "./FilterForm";
import { MenuItemEnums, RouteParams } from "../../common/enums";

const Report = () => {
  const location = useLocation();
  const [fetchingCount, setFetchingCount] = useState(0);

  useEffect(() => {
    setFetchingCount(0);
  }, [location.pathname]);

  const ReportingScreens = () => {
    const location = useLocation();
    if (location.pathname.includes(RouteParams.conversion)) return <Conversions />;
    else if (location.pathname.includes(`/${MenuItemEnums.reporting}/${RouteParams.reportingRevenue}`))
      return <Revenue />;
    else if (location.pathname.includes(RouteParams.topProducts)) return <TopProducts />;
    else if (location.pathname.includes(RouteParams.topShades)) return <TopShades />;
    else if (location.pathname.includes(RouteParams.topCoverages))
      return <TopCoverages />;

    else return null;
  };

  document.title = "Reporting | mime"
  
  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <ReportingScreens />
          <FilterForm
            fetchingCount={fetchingCount}
            setFetchingCount={setFetchingCount}
          />
        </Row>
      </Container>
    </div>
  );
};

export default Report;
