import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Form, FormFeedback, Input, Label, Row, Spinner } from "reactstrap";
import { toast } from "react-toastify";

import { useFormik } from "formik";
import * as Yup from "yup";
import { PlusCircle, Trash2 } from "feather-icons-react/build/IconComponents";

import FormSelectOptions from "../../../Components/Common/FormSelectOptions";
import { MenuItemEnums, RouteParams } from "../../../common/enums";
import {
  OptionsCategory,
  OptionsCoverage,
  OptionsFinish,
  OptionsCurrency,
  OptionsSkinType,
} from "../../../common/form-selector-values";

import { convertToPlainText } from "../../../helpers/utils";
import { getUrlParam } from "../../../helpers/location-helper";
import SelectInput from "../../../Components/Common/SelectInput";

import {
  addProduct,
  updateProduct,
  removeProduct,
  resetProductFlag,
  clearProductDetails,
  getProductDetails,
  getProductsList,
  getBrands,
} from "../../../store/actions";

const ProductForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [productId, setProductId] = useState(-1);
  const [isUpdate, setIsUpdate] = useState(false);
  const [recommend, setRecommend] = useState(true);
  const [prices, setPrices] = useState([{ amount: 0, currency: "USD" }]);
  const [brandsOptions, setBrandsOptions] = useState([]);

  const { productInEdit, brands } = useSelector((state) => ({
    productInEdit: state.Product.productInEdit,
    brands: state.Product.brands.data,
  }));

  useEffect(() => {
    setPricesField(productInEdit.prices);
  }, [productInEdit]);

  useEffect(() => {
    const mappedBrands = brands.map((brand) => ({
      value: brand.id,
      label: brand.name,
    }));
    setBrandsOptions(mappedBrands);
  }, [brands]);

  const initialBrandId = () => {
    for (let i = 0; i < brands.length; i++) {
      if (
        brands[i].name.toLowerCase() === productInEdit.brandName?.toLowerCase()
      ) {
        return brands[i].id;
      }
    }
  };

  useEffect(() => {
    if (getUrlParam(RouteParams.createProduct, location.search) == "true") {
      setIsUpdate(false);
      setProductId(-1);
    } else if (getUrlParam(RouteParams.editProduct, location.search) == "true") {
      setIsUpdate(true);

      const productIdfromPath = getUrlParam(
        RouteParams.productId,
        location.search
      );
      const editableProductId = productIdfromPath
        ? +productIdfromPath
        : productIdfromPath;
      setProductId(editableProductId);
    }
  }, [location.search]);

  useEffect(() => {
    dispatch(getBrands());

    if (productId < 0) {
      dispatch(clearProductDetails());
      return;
    }
    dispatch(getProductDetails(productId));
  }, [dispatch, productId]);

  useEffect(() => {
    if (productInEdit.added || productInEdit.updated || productInEdit.removed) {
      dispatch(getProductsList());
      dispatch(resetProductFlag());
      if (productInEdit.added) {
        toast.success("Product created successfully");
      } else if (productInEdit.updated) {
        toast.success("Product updated successfully");
      } else {
        toast.success("Product deleted successfully");
      }
      navigate(`/${MenuItemEnums.inventory}/${MenuItemEnums.product}`);
    }
  }, [productInEdit.added, productInEdit.updated, productInEdit.removed]);

  const handleSubmit = async (values) => {
    if (isUpdate) {
      dispatch(updateProduct(productId, values));
    } else {
      dispatch(addProduct(values));
    }
  };

  const deleteProduct = async () => {
    dispatch(removeProduct(productId));
  };

  const onRecommendHandler = (event) => {
    if (event.target.value === "Yes") {
      setRecommend(true);
    } else {
      setRecommend(false);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      recommend: productInEdit.showInRecommendations || recommend,
      productTitle: productInEdit.productTitle || "",
      brandId: initialBrandId(),
      category: productInEdit.category || "",
      productUrl: productInEdit.productUrl || "",
      coverage: productInEdit.coverage || "",
      finish: productInEdit.finish || "",
      imageUrl: productInEdit.imageUrl || "",
      skinType: productInEdit.skinType || "",
      sku: productInEdit.sku || "",
      description: convertToPlainText(productInEdit.description ?? "") || "",
      ingredients: productInEdit.ingredients || "",
    },
    validationSchema: Yup.object({
      productTitle: Yup.string().required("Please Enter Product Title"),
      brandId: Yup.number().required("Please Select Brand Name"),
      category: Yup.string().required("Please Select Category"),
      productUrl: Yup.string().required("Please Enter Product URL"),
      imageUrl: Yup.string().required("Please Enter Product Image URL"),
      skinType: Yup.string().required("Please Select Skin Type"),
      sku: Yup.string().required("Please Enter SKU"),
      description: Yup.string().required("Please enter Product Description"),
      ingredients: Yup.string().required("Please enter Product Ingredients"),
      prices: Yup.array().min(1, "Please add at least 1 price"),
    }),
    onSubmit: (values) => {
      handleSubmit(values);
    },
  });

  const setPricesField = (prices) => {
    let validPrices =
      prices && prices.length > 0 ? prices : [{ amount: 0, currency: "USD" }];
    setPrices(validPrices);
    const filterPrices = validPrices.filter((price, i) =>
      price.amount > 0 ? true : false
    );
    validation.setFieldValue("prices", filterPrices);
  };

  const handlePriceChange = (e, idx, key) => {
    let _price = e.target.value;
    if ((key === "amount" && isNaN(_price)) || !_price) {
      _price = 0;
    }
    const oldPrices = [...prices];
    oldPrices[idx][key] = _price;
    setPricesField(oldPrices);
  };

  const handlePricePlus = () => {
    setPricesField([...prices, { currency: "USD" }]);
  };

  const handlePriceDelete = (idx) => {
    const filterPrices = prices.filter((_, i) => i !== idx);
    setPricesField(filterPrices);
  };

  return (
    <React.Fragment>
      <Col xs={4} className="mime-form right-edit scroll-container">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <Row className="form-header">
            <Col className="title">
              {isUpdate ? "Edit Product" : "Add Product"}
            </Col>

            <Col className="action-button">
              {isUpdate ? (
                <Button
                  type="button"
                  className="icon-btn"
                  onClick={() => deleteProduct()}
                >
                  <Trash2
                    size="24"
                    stroke="#d26767"
                    className="delete-icon"
                  />
                </Button>
              ) : null}

              <Button
                type="submit"
                className="form-control btn-small btn-text-primary"
                disabled={productInEdit.loading}
              >
                {productInEdit.loading ? (
                  <Spinner size="sm" className="me-2">
                    Loading...
                  </Spinner>
                ) : null}
                Save
              </Button>
            </Col>
          </Row>
          <Row className="mt-40">
            <Col>
              <Label htmlFor="recommend_yes" className="form-label">
                Show in Recommendation?
              </Label>
              <Input
                name="recommend_yes"
                type="button"
                className={
                  "form-control btn" + (recommend === true ? " active" : "")
                }
                onClick={onRecommendHandler}
                value={"Yes"}
              />
            </Col>
            <Col className="pl-10">
              <Label htmlFor="recommend_no" className="form-label hidden">
                Show in Recommendation?
              </Label>
              <Input
                name="recommend_no"
                type="button"
                className={
                  "form-control btn" + (recommend === false ? " active" : "")
                }
                onClick={onRecommendHandler}
                value={"No"}
              />
            </Col>
          </Row>
          <Row className="mt-20">
            <Label htmlFor="productTitle" className="form-label">
              Product Title*
            </Label>
            <Input
              name="productTitle"
              type="text"
              data-testid="productTitle" // Make sure this line is present
              className="form-control"
              value={validation.values.productTitle || ""}
              placeholder="Type the title here"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.productTitle &&
                validation.errors.productTitle
              }
            />
            {validation.touched.productTitle &&
            validation.errors.productTitle ? (
              <FormFeedback type="invalid">
                {validation.errors.productTitle}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Brand Name*</Label>
            <select
              name="brandId"
              value={validation.values.brandId}
              onChange={validation.handleChange}
              className={
                "form-select" +
                (validation.values.brandId ? "" : " empty") +
                (validation.touched.brandId && validation.errors.brandId ? " is-invalid" : "")
              }
            >
              <FormSelectOptions
                categories={brandsOptions}
                defaultOption={validation.values.brandId ? null : {}}
              />
            </select>
            {validation.touched.brandId && validation.errors.brandId ? (
              <FormFeedback type="invalid">
                {validation.errors.brandId}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <SelectInput
              label="Category*"
              fieldName="category"
              handleChange={validation.handleChange}
              fieldValue={validation.values.category}
              isTouchedField={validation.touched.category}
              fieldError={validation.errors.category}
              selectOptions={OptionsCategory}
            />
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Coverage</Label>
            <select
              name="coverage"
              value={validation.values.coverage}
              onChange={validation.handleChange}
              className={
                "form-select" +
                (validation.values.coverage ? "" : " empty") +
                (validation.touched.coverage && validation.errors.coverage ? " is-invalid" : "")
              }
            >
              <FormSelectOptions
                categories={OptionsCoverage}
                defaultOption={validation.values.coverage ? null : {}}
              />
            </select>
            {validation.touched.coverage && validation.errors.coverage ? (
              <FormFeedback type="invalid">
                {validation.errors.coverage}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Finish</Label>
            <select
              name="finish"
              value={validation.values.finish}
              onChange={validation.handleChange}
              className={
                "form-select" +
                (validation.values.finish ? "" : " empty") +
                (validation.touched.finish && validation.errors.finish ? " is-invalid" : "")
              }
            >
              <FormSelectOptions
                categories={OptionsFinish}
                defaultOption={validation.values.finish ? null : {}}
              />
            </select>
            {validation.touched.finish && validation.errors.finish ? (
              <FormFeedback type="invalid">
                {validation.errors.finish}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Product URL*</Label>
            <Input
              name="productUrl"
              type="text"
              className="form-control"
              value={validation.values.productUrl || ""}
              placeholder="https://www.website.com"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.productUrl && validation.errors.productUrl
              }
            />
            {validation.touched.productUrl && validation.errors.productUrl ? (
              <FormFeedback type="invalid">
                {validation.errors.productUrl}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Image URL*</Label>
            <Input
              name="imageUrl"
              type="text"
              className="form-control"
              value={validation.values.imageUrl || ""}
              placeholder="https://www.website.com/image.jpg"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.imageUrl && validation.errors.imageUrl
              }
            />
            {validation.touched.imageUrl && validation.errors.imageUrl ? (
              <FormFeedback type="invalid">
                {validation.errors.imageUrl}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">SKU*</Label>
            <Input
              name="sku"
              type="text"
              className="form-control"
              value={validation.values.sku || ""}
              placeholder="Enter a SKU"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.sku && validation.errors.sku ? true : false
              }
            />
            {validation.touched.sku && validation.errors.sku ? (
              <FormFeedback type="invalid">
                {validation.errors.sku}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Skin Type*</Label>
            <select
              name="skinType"
              value={validation.values.skinType}
              onChange={validation.handleChange}
              className={
                "form-select" +
                (validation.values.skinType ? "" : " empty") +
                (validation.touched.skinType && validation.errors.skinType ? " is-invalid" : "")
              }
            >
              <FormSelectOptions
                categories={OptionsSkinType}
                defaultOption={validation.values.skinType ? null : {}}
              />
            </select>
            {validation.touched.skinType && validation.errors.skinType ? (
              <FormFeedback type="invalid">
                {validation.errors.skinType}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Product Description*</Label>
            <Input
              name="description"
              type="textarea"
              className="form-control"
              value={validation.values.description || ""}
              placeholder="Enter the product description here"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.description &&
                validation.errors.description
              }
            />
            {validation.touched.description &&
            validation.errors.description ? (
              <FormFeedback type="invalid">
                {validation.errors.description}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Product Ingredients*</Label>
            <Input
              name="ingredients"
              type="textarea"
              className="form-control"
              value={validation.values.ingredients || ""}
              placeholder="Enter the product ingredients here"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.ingredients &&
                validation.errors.ingredients
              }
            />
            {validation.touched.ingredients &&
            validation.errors.ingredients ? (
              <FormFeedback type="invalid">
                {validation.errors.ingredients}
              </FormFeedback>
            ) : null}
          </Row>

          <div className="pricing-container">
            <Label className="form-label title">Pricing</Label>
            <Input type="hidden" className="is-invalid" />
            {validation.errors.prices ? (
              <FormFeedback type="invalid">
                {validation.errors.prices}
              </FormFeedback>
            ) : null}

            {prices &&
              prices.map((price, idx) => (
                <Row className="Price-Row mt-20" key={idx}>
                  <Col className="Amount-Col">
                    <Label className="form-label">Price*</Label>
                    <Input
                      type="number"
                      className="form-control"
                      value={price.amount}
                      placeholder="Enter a price"
                      onChange={(e) => handlePriceChange(e, idx, "amount")}
                    />
                  </Col>
                  <Col className="Currency-Col">
                    <Label className="form-label">Currency*</Label>
                    <select
                      name={`price-${idx}-currency`}
                      value={price.currency}
                      onChange={(e) => handlePriceChange(e, idx, "currency")}
                      className={
                        "form-select" + (price.currency ? "" : " empty")
                      }
                    >
                      <FormSelectOptions
                        categories={OptionsCurrency}
                        defaultOption={price.currency ? null : {}}
                      />
                    </select>
                  </Col>
                  <div className="Button-Col">
                    <Button
                      className="icon-btn"
                      onClick={() => handlePriceDelete(idx)}
                    >
                      <Trash2
                        size="24"
                        stroke="#d26767"
                        className="delete-icon"
                      />
                    </Button>
                  </div>
                  <div className="Button-Col">
                    <Button
                      className="icon-btn"
                      onClick={() => handlePricePlus(price)}
                    >
                      <PlusCircle />
                    </Button>
                  </div>
                </Row>
              ))}
          </div>
        </Form>
      </Col>
    </React.Fragment>
  );
};

export default ProductForm;
