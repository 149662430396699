import React from "react";
import { useId } from "react";
import { Link } from "react-router-dom";

const PageHeader = ({
  title,
  count,
  subtitleSuffix,
  editLink = "",
  editSuffix = "",
  isBack = false,
}) => {
  const uniqueId = useId();
  return (
    <div className="header-container" id={uniqueId}>
      <div className="left-container">
        <span className="title">
          {title}
          {title && editLink ? (
            <Link className="edit-link" to={editLink}>
              &nbsp;- {isBack ? "" : "Edit"} {editSuffix}
            </Link>
          ) : null}
        </span>
        <span className="sub-title">
          {count} {subtitleSuffix}
        </span>
      </div>
    </div>
  );
};

export default PageHeader;
