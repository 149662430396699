import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Button, Col, Form, Container, Input, Label, Row,
} from "reactstrap";
import { toast } from "react-toastify";

import { useFormik } from "formik";
import * as Yup from "yup";

// common helper
import Loader from "../../../Components/Common/Loader";
import PageHeader from "../../../Components/Common/PageHeader";
import FormSelectOptions from "../../../Components/Common/FormSelectOptions";
import { Trash2 } from "feather-icons-react/build/IconComponents";
import { OptionsUserRoles } from "../../../common/form-selector-values";

import {
	getTeammates, sendInvite, updateInvite, deleteTeammate,
	resetAppFlag,
} from "../../../store/settings/action";

const MyTeam = () => {
	const dispatch = useDispatch();

	const { isLoading, teamMates, myTeamStatus, errorMsg } = useSelector((state) => ({
		isLoading: state.Setting.loading,
		teamMates: state.Setting.teamMates,
		myTeamStatus: state.Setting.myTeamStatus,
		errorMsg: state.Setting.errorMsg,
	}));

	useEffect(() => {
		dispatch(getTeammates());
	}, [dispatch]);

	useEffect(() => {
		if (myTeamStatus.sended) {
			dispatch(resetAppFlag());
			validation.setFieldValue("emailAddress", "");
			toast.success("The user has been invited!");
		} else if (myTeamStatus.updated) {
			dispatch(resetAppFlag());
			toast.success("The user has been updated!");
		} else if (myTeamStatus.deleted) {
			dispatch(resetAppFlag());
			toast.success("The user has been deleted!");
		}
	}, [dispatch, myTeamStatus]);

	useEffect(() => {
		if (errorMsg) {
			dispatch(resetAppFlag());
			toast.error(errorMsg);
		}
	}, [errorMsg]);

	const validation = useFormik({
		// enableReinitialize : use this flag when initial values needs to be changed
		enableReinitialize: true,

		initialValues: {
			emailAddress: "",
			role: OptionsUserRoles[0].value,
		},
		validationSchema: Yup.object({
			emailAddress: Yup.string().required("Please enter the valid email address."),
		}),
		onSubmit: (values) => {
			dispatch(sendInvite(values));
		},
	});

	const handleRoleChange = (e, index) => {
		const teamMate = teamMates[index];
		teamMate.role = e.target.value;
		dispatch(updateInvite(teamMate));
	};

	const handleDeleteUser = (index) => {
		const teamMate = teamMates[index];
		dispatch(deleteTeammate(teamMate.id));
	};

	document.title = "Settings | mime";

	return (
		<div className="page-content">
			<Container fluid>
				<Row className="ms-0">
					<Col className="scroll-container my-team-container">
						{isLoading ? (
							<Loader />
						) : (
							<div className="mime-form">
								<div className="header p-0">
									<PageHeader title="Send Invitation" />
								</div>
								<Form
									onSubmit={(e) => {
										e.preventDefault();
										validation.handleSubmit();
										return false;
									}}
								>
									<Row className="user-row">
										<Col xs={4} lg={6} className="email-col">
											<Label className="form-label">Teammate Email Address</Label>
											<Input
												name="emailAddress"
												type="text"
												className="form-control"
												value={validation.values.emailAddress || ""}
												placeholder="john@doe.com"
												onChange={validation.handleChange}
												onBlur={validation.handleBlur}
												invalid={
													validation.touched.emailAddress &&
													validation.errors.emailAddress
												}
											/>
										</Col>
										<div className="role-col">
											<Label className="form-label">Role</Label>
											<select
												name="role"
												value={validation.values.role}
												onChange={validation.handleChange}
												className={
													"form-select" +
													(validation.values.role ? "" : " empty") +
													(validation.touched.role && validation.errors.role ? " is-invalid" : "")
												}
											>
												<FormSelectOptions
													categories={OptionsUserRoles}
													defaultOption={validation.values.role ? null : {}}
												/>
											</select>
										</div>
										<div className="button-col">
											<Button type="submit" className="btn-add">
												Send Invite
											</Button>
										</div>
									</Row>
								</Form>
								<div className="header mt-4 p-0">
									<PageHeader title="Invited Teammates" />
								</div>
								{teamMates.map((teammate, index) => (
									<Row key={index} className="user-row mb-3">
										<Col xs={4} lg={6} className="email-col">
											<Label className="form-label">User</Label>
											<Input
												type="email"
												className="form-control"
												value={teammate.emailAddress}
											/>
										</Col>
										<div className="role-col">
											<Label className="form-label">Role</Label>
											<select
												name="fontHeader"
												className="form-select"
												value={teammate.role}
												onChange={(e) => {
													handleRoleChange(e, index);
												}}
											>
												<FormSelectOptions
													categories={OptionsUserRoles}
													defaultOption={validation.values.role ? null : {}}
												/>
											</select>
										</div>
										<div className="button-col">
											<Button
												className="icon-btn"
												onClick={() => handleDeleteUser(index)}
											>
												<Trash2
													size="24"
													stroke="#d26767"
													className="delete-icon"
												/>
											</Button>
										</div>
									</Row>
								))}
							</div>
						)}
					</Col>
					<Col
						xs={5}
						className="right-edit scroll-container meta-right-section"
					></Col>
				</Row>
			</Container>
		</div>
	);
};

export default MyTeam;
