import React, { useEffect, useState, useCallback } from "react";
import {
  Button, Col, Form, FormFeedback, Input, Label, Row, Spinner,
} from "reactstrap";
import { toast } from "react-toastify";

// formik
import * as Yup from "yup";
import { useFormik } from "formik";

// common helpers
import FormSelectOptions from "../../../../Components/Common/FormSelectOptions";
import PageHeader from "../../../../Components/Common/PageHeader";
import Loader from "../../../../Components/Common/Loader";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  getAppDetails, updateAppDetails, resetAppFlag,
} from "../../../../store/settings/action";

import { CommonApiService } from "../../../../services/apis/CommonApi";
import { useLocation } from "react-router";
import { getIDFromPath } from "../../../../helpers/location-helper";

const BrandingForm = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const appId = getIDFromPath(location.pathname);

  const [fontOptions, setFontOptions] = useState([]);

  const { appDetails } = useSelector((state) => ({
    appDetails: state.Setting.appDetails,
  }));

  useEffect(() => {
    if (appDetails.updated) {
      dispatch(resetAppFlag());
      toast.success("Successfully updated the app settings.");
    }
  }, [appDetails.updated]);

  const handleAppSettings = useCallback(async (appId, values) => {
    dispatch(updateAppDetails(appId, values));
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      fontHeader: appDetails?.fontHeader ?? "",
      fontBody: appDetails?.fontBody ?? "",
      colorPrimaryHex: appDetails?.colorPrimaryHex ?? "",
      colorSecondaryHex: appDetails?.colorSecondaryHex ?? "",
      imageLogoUrl: appDetails?.imageLogoUrl ?? "",
      imageLandingPageImageUrl: appDetails?.imageLandingPageImageUrl ?? "",
    },
    validationSchema: Yup.object({
      fontHeader: Yup.string(),
      fontBody: Yup.string(),
      colorPrimaryHex: Yup.string(),
      colorSecondaryHex: Yup.string(),
    }),
    onSubmit: (values) => handleAppSettings(appId, values),
  });

  useEffect(() => {
    if (!appId || appDetails?.id === appId) return;
    dispatch(getAppDetails(appId));
  }, [dispatch, appId]);

  const getFonts = useCallback(async () => {
    const fontRes = await CommonApiService.getFontOptions();
    if (fontRes?.items.length) {
      const formattedList = fontRes.items.map((app) => ({
        value: app.family,
        label: app.family,
      }));
      setFontOptions(formattedList);
    } else {
      toast.error("Something went wrong while fetching fonts!");
    }
  }, []);

  useEffect(() => {
    getFonts();
  }, []);

  const handleUploadFile = (event, fieldName) => {
    const [file] = event.currentTarget.files;
    validation.handleChange(event);
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      validation.setFieldValue(`${fieldName}`, fileReader.result, true);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <React.Fragment>
      <Col className="mime-form">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="header">
            <PageHeader title="Branding" />
            <Button
              type="submit"
              className="btn-lg"
              disabled={appDetails.loading}
            >
              {appDetails.loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              Save
            </Button>
          </div>
          {!appDetails.loading ? (
            <Col lg={7} xs={4}>
              <Row>
                <Label className="form-label">Primary Font (Headers)</Label>
                <select
                  name="fontHeader"
                  value={validation.values.fontHeader}
                  onChange={validation.handleChange}
                  className={
                    "form-select" +
                    (validation.values.fontHeader ? "" : " empty") +
                    (validation.touched.fontHeader &&
                    validation.errors.fontHeader ? " is-invalid" : "")
                  }
                >
                  <FormSelectOptions
                    categories={fontOptions}
                    defaultOption={validation.values.fontHeader ? null : {}}
                  />
                </select>
                {validation.touched.fontHeader &&
                validation.errors.fontHeader ? (
                  <FormFeedback type="invalid">
                    {validation.errors.fontHeader}
                  </FormFeedback>
                ) : null}
              </Row>
              <Row className="mt-20">
                <Label className="form-label">Secondary Font (Body Text)</Label>
                <select
                  name="fontBody"
                  value={validation.values.fontBody}
                  onChange={validation.handleChange}
                  className={
                    "form-select" +
                    (validation.values.fontBody ? "" : " empty") +
                    (validation.touched.fontBody && validation.errors.fontBody ? " is-invalid" : "")
                  }
                >
                  <FormSelectOptions
                    categories={fontOptions}
                    defaultOption={validation.values.fontBody ? null : {}}
                  />
                </select>
                {validation.touched.fontBody && validation.errors.fontBody ? (
                  <FormFeedback type="invalid">
                    {validation.errors.fontBody}
                  </FormFeedback>
                ) : null}
              </Row>
              <Row className="mt-20">
                <Label className="form-label">Primary Color</Label>
                <Input
                  type="color"
                  name="colorPrimaryHex"
                  value={validation.values.colorPrimaryHex}
                  onChange={validation.handleChange}
                  className="form-control form-color-input"
                  id="form-color-input"
                />
              </Row>
              <Row className="mt-20">
                <Label className="form-label">Secondary Color</Label>
                <Input
                  type="color"
                  name="colorSecondaryHex"
                  value={validation.values.colorSecondaryHex}
                  onChange={validation.handleChange}
                  className="form-control form-color-input"
                  id="form-color-input"
                />
              </Row>
              <Row className="mt-20">
                <Label className="form-label">imageLogoUrl</Label>
                <Label
                  className="form-label upload-iamge-container"
                  data-content="Click to Upload"
                >
                  <Input
                    type="file"
                    accept="image/*"
                    name="imageLogoUrl"
                    className="form-control upload-image"
                    onChange={(event) =>
                      handleUploadFile(event, "imageLogoUrl")
                    }
                  />
                </Label>
              </Row>
              <Row className="mt-20">
                <Label className="form-label">Landing Page Image</Label>
                <Label
                  className="form-label upload-iamge-container"
                  data-content="Click to Upload"
                >
                  <Input
                    type="file"
                    accept="image/*"
                    name="imageLandingPageImageUrl"
                    className="form-control upload-image"
                    onChange={(event) =>
                      handleUploadFile(event, "imageLandingPageImageUrl")
                    }
                  />
                </Label>
              </Row>
            </Col>
          ) : (
            <Loader />
          )}
        </Form>
      </Col>
    </React.Fragment>
  );
};

export default BrandingForm;
