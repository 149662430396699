import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Input,
  Label,
  Button,
  Spinner,
  Form,
  FormFeedback,
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// action
import { registerUser, apiError, resetRegisterFlag } from "../../store/actions";

// redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useNavigate } from "react-router-dom";

// import images
import logoLight from "../../assets/images/logo/mime-logo.png";
import ParticlesAuth from "./ParticlesAuth";

const Register = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  const [passwordShow, setPasswordShow] = useState(false);

  const { user, message, errorMsg, loading, success, error } = useSelector(
    (state) => ({
      user: state.Account.user,
      message: state.Account.message,
      errorMsg: state.Account.errorMsg,
      loading: state.Account.loading,
      success: state.Account.success,
      error: state.Account.error,
    })
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      emailAddress: "",
      firstName: "",
      lastName: "",
      password: "",
      confirmPassword: "",
      domain: "",
    },
    validationSchema: Yup.object({
      emailAddress: Yup.string()
        .email("Invalid Email Format")
        .required("Please Enter Your Email"),
      firstName: Yup.string().required("Please Enter Your First Name"),
      lastName: Yup.string().required("Please Enter Your Last Name"),
      password: Yup.string().required("Please Enter Your Password"),
      confirmPassword: Yup.string().oneOf(
        [Yup.ref("password")],
        "Passwords must match"
      ),
      domain: Yup.string().required("Please Enter Company"),
    }),
    onSubmit: (values) => {
      dispatch(registerUser(values));
    },
  });

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  useEffect(() => {
    if (success) {
      setTimeout(() => history("/login"), 3000);
    }

    setTimeout(() => {
      dispatch(resetRegisterFlag());
    }, 3000);
  }, [dispatch, success, error, history]);

  document.title = "Register | mime";

  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className="auth-page-content">
          <Container className="auth-container">
            <Row>
              <Col lg={12}>
                <div className="text-center mt-sm-2 mb-4 text-white-50">
                  <div>
                    <Link to="/" className="d-inline-block auth-logo">
                      <img src={logoLight} alt="" height="30" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>

            <Row className="justify-content-center">
              <Col md={6} lg={4} xl={4}>
                <Card className="mt-4 auth-card">
                  <CardBody className="p-4">
                    <div className="text-center mt-2">
                      <span className="auth-card-title">Request Access</span>
                      <p className="auth-card-subtitle">
                        Please enter the following details.
                      </p>
                    </div>

                    <div className="p-2">
                      <Form
                        className="mime-form"
                        onSubmit={(e) => {
                          e.preventDefault();
                          validation.handleSubmit();
                          return false;
                        }}
                        action="#"
                      >
                        {success && success ? (
                          <>
                            {toast("Your Redirect To Login Page...", {
                              position: "top-right",
                              hideProgressBar: false,
                              className: "bg-success text-white",
                              progress: undefined,
                              toastId: "",
                            })}
                            <ToastContainer autoClose={2000} limit={1} />
                            <Alert color="success">
                              Register User Successfully and Your Redirect To
                              Login Page...
                            </Alert>
                          </>
                        ) : null}

                        {error && error ? (
                          <Alert color="danger">
                            <div>{errorMsg}</div>
                          </Alert>
                        ) : null}

                        <div>
                          <Label className="form-label auth-card-label">
                            Email
                          </Label>
                          <Input
                            name="emailAddress"
                            type="email"
                            className="form-control auth-form-control"
                            placeholder="Enter email address"
                            value={validation.values.emailAddress}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.emailAddress &&
                              validation.errors.emailAddress
                                ? true
                                : false
                            }
                          />
                          {validation.touched.emailAddress &&
                          validation.errors.emailAddress ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.emailAddress}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3">
                          <Label className="form-label auth-card-label">
                            Password
                          </Label>
                          <Input
                            name="password"
                            type={passwordShow ? "text" : "password"}
                            className="form-control auth-form-control pe-5"
                            placeholder="Enter password"
                            value={validation.values.password}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.password &&
                              validation.errors.password
                                ? true
                                : false
                            }
                          />
                          {validation.touched.password &&
                          validation.errors.password ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.password}</div>
                            </FormFeedback>
                          ) : null}
                          {/* <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}>
														<i className="ri-eye-fill align-middle"></i>
													</button> */}
                        </div>

                        <div className="mt-3">
                          <Label className="form-label auth-card-label">
                            Confirm Password
                          </Label>
                          <Input
                            name="confirmPassword"
                            type={passwordShow ? "text" : "password"}
                            className="form-control auth-form-control pe-5"
                            placeholder="Confirm password"
                            value={validation.values.confirmPassword}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.confirmPassword &&
                              validation.errors.confirmPassword
                                ? true
                                : false
                            }
                          />
                          {validation.touched.confirmPassword &&
                          validation.errors.confirmPassword ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.confirmPassword}</div>
                            </FormFeedback>
                          ) : null}
                          {/* <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="confirm-password-addon" onClick={() => setPasswordShow(!passwordShow)}>
														<i className="ri-eye-fill align-middle"></i>
													</button> */}
                        </div>

                        <div className="mt-3">
                          <Label className="form-label auth-card-label">
                            First Name
                          </Label>
                          <Input
                            name="firstName"
                            type="text"
                            className="form-control auth-form-control"
                            placeholder="Enter first name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.firstName}
                            invalid={
                              validation.touched.firstName &&
                              validation.errors.firstName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.firstName &&
                          validation.errors.firstName ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.firstName}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3">
                          <Label className="form-label auth-card-label">
                            Last Name
                          </Label>
                          <Input
                            name="lastName"
                            type="text"
                            className="form-control auth-form-control"
                            placeholder="Enter last name"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.lastName}
                            invalid={
                              validation.touched.lastName &&
                              validation.errors.lastName
                                ? true
                                : false
                            }
                          />
                          {validation.touched.lastName &&
                          validation.errors.lastName ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.lastName}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className="mt-3">
                          <Label className="form-label auth-card-label">
                            Company URL
                          </Label>
                          <Input
                            name="domain"
                            type="url"
                            className="form-control auth-form-control"
                            placeholder="Enter company url"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.domain}
                            invalid={
                              validation.touched.domain &&
                              validation.errors.domain
                                ? true
                                : false
                            }
                          />
                          {validation.touched.domain &&
                          validation.errors.domain ? (
                            <FormFeedback type="invalid">
                              <div>{validation.errors.domain}</div>
                            </FormFeedback>
                          ) : null}
                        </div>

                        <Row className="mt-4">
                          <Label className="form-label">
                            Do you agree with the{" "}
                            <Link to="#" className="auth-btn-link">
                              Terms & Conditions?
                            </Link>
                          </Label>
                          <Col>
                            <Input
                              type="button"
                              className="form-control btn active"
                              value={"Yes"}
                            />
                          </Col>
                          <Col className="pl-10">
                            <Input
                              type="button"
                              className="form-control btn"
                              value={"No"}
                            />
                          </Col>
                        </Row>

                        <div className="mt-4">
                          <Button
                            disabled={error ? null : loading ? true : false}
                            color="secondary"
                            className="btn btn-secondary w-100 auth-button"
                            type="submit"
                          >
                            {error ? null : loading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            Request Access
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>

                <div className="mt-8 text-center">
                  <p className="mb-0">
                    <Link to="/login" className="auth-request">
                      {" "}
                      Log In{" "}
                    </Link>{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
    </React.Fragment>
  );
};

export default Register;
