import React, { useEffect, useState, useCallback } from "react";
import {
  Button, Col, Input, Label, Row, Form, FormFeedback, Spinner,
} from "reactstrap";
import { toast } from "react-toastify";

// formik
import * as Yup from "yup";
import { useFormik } from "formik";

// common helpers
import PageHeader from "../../../../Components/Common/PageHeader";
import SelectInput from "../../../../Components/Common/SelectInput";
import { OptionsPreferences } from "../../../../common/form-selector-values";
import Loader from "../../../../Components/Common/Loader";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  getAppDetails, updateAppDetails, resetAppFlag,
} from "../../../../store/settings/action";

import { useLocation } from "react-router";
import { getIDFromPath } from "../../../../helpers/location-helper";
// import { uploadFile } from "../../../../helpers/uploadGCS";

const BasicsForm = ({ setSeoImage }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const appId = getIDFromPath(location.pathname);

  const { appDetails } = useSelector((state) => ({
    appDetails: state.Setting.appDetails,
  }));

  useEffect(() => {
    if (appDetails.updated) {
      dispatch(resetAppFlag());
      toast.success("Successfully updated the app settings.");
    }
  }, [appDetails.updated]);

  const handleAppSettings = useCallback(async (appId, values) => {
    const settingValues = { ...values };
    settingValues.defaultToNeutralUndertone =
      settingValues.defaultToNeutralUndertone === "true" ? true : false;
    settingValues.sendRatingEmail =
      settingValues.sendRatingEmail === "true" ? true : false;
    settingValues.sendResultsEmail =
      settingValues.sendResultsEmail === "true" ? true : false;

    dispatch(updateAppDetails(appId, values));
  }, []);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      metaPageTitle: appDetails?.metaPageTitle ?? "",
      metaDescription: appDetails?.metaDescription ?? "",
      metaImageUrl: appDetails?.metaImageUrl ?? "",
      imageUrl: "",
      metaKeywords: appDetails?.metaKeywords ?? "",
      defaultToNeutralUndertone:
        appDetails?.defaultToNeutralUndertone?.toString() ?? "",
      sendResultsEmail: appDetails?.sendResultsEmail?.toString() ?? "",
      sendRatingEmail: appDetails?.sendRatingEmail?.toString() ?? "",
    },
    validationSchema: Yup.object({
      metaPageTitle: Yup.string(),
      metaDescription: Yup.string(),
      imageUrl: Yup.string(),
      metaKeywords: Yup.string(),
      defaultToNeutralUndertone: Yup.boolean(),
      sendResultsEmail: Yup.boolean(),
      sendRatingEmail: Yup.boolean(),
    }),
    onSubmit: (values) => handleAppSettings(appId, values),
  });

  useEffect(() => {
    if (!appId || appDetails?.id === appId) return;
    dispatch(getAppDetails(appId));
  }, [dispatch, appId]);

  const handleUploadFile = async (event, fieldName) => {
    const [file] = event.currentTarget.files;
    // const publicURL = await uploadFile(file)
    const imageUrl = URL.createObjectURL(file);
    setSeoImage(imageUrl);
    validation.handleChange(event);
    const fileReader = new FileReader();
    fileReader.onloadend = () => {
      validation.setFieldValue(`${fieldName}`, fileReader.result, true);
    };
    fileReader.readAsDataURL(file);
  };

  return (
    <React.Fragment>
      <Col className="mime-form">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="header">
            <PageHeader title="Meta Settings & Open Graph" />
            <Button
              type="submit"
              className="btn-lg"
              disabled={appDetails.loading}
            >
              {appDetails.loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              Save
            </Button>
          </div>
          {appDetails.loading ? (
            <Loader />
          ) : (
            <Col lg={7} xs={4}>
              <Row className="mt-20">
                <Label className="form-label">Meta Page Title</Label>
                <Input
                  name="metaPageTitle"
                  type="text"
                  className="form-control"
                  value={validation.values.metaPageTitle}
                  placeholder="Type the page Title here"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.metaPageTitle &&
                    validation.errors.metaPageTitle
                      ? true
                      : false
                  }
                />
                {validation.touched.metaPageTitle &&
                validation.errors.metaPageTitle ? (
                  <FormFeedback type="invalid">
                    {validation.errors.metaPageTitle}
                  </FormFeedback>
                ) : null}
              </Row>
              <Row className="mt-20">
                <Label className="form-label">Meta Page Description</Label>
                <Input
                  name="metaDescription"
                  type="text"
                  className="form-control"
                  value={validation.values.metaDescription}
                  placeholder="Type the page Description here"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.metaDescription &&
                    validation.errors.metaDescription
                      ? true
                      : false
                  }
                />
                {validation.touched.metaDescription &&
                validation.errors.metaDescription ? (
                  <FormFeedback type="invalid">
                    {validation.errors.metaDescription}
                  </FormFeedback>
                ) : null}
              </Row>
              <Row className="mt-20">
                <Label className="form-label">Meta Image</Label>
                <Label
                  className="form-label upload-iamge-container"
                  data-content="Click to Upload"
                >
                  <Input
                    type="file"
                    accept="image/*"
                    name="metaImageUrl"
                    className="form-control upload-image"
                    onChange={(event) =>
                      handleUploadFile(event, "metaImageUrl")
                    }
                  />
                </Label>
              </Row>
              <Row className="mt-20">
                <Label className="form-label">Meta Keywords</Label>
                <Input
                  name="metaKeywords"
                  type="text"
                  className="form-control"
                  value={validation.values.metaKeywords}
                  placeholder="Type the keywords here"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.metaKeywords &&
                    validation.errors.metaKeywords
                      ? true
                      : false
                  }
                />
                {validation.touched.metaKeywords &&
                validation.errors.metaKeywords ? (
                  <FormFeedback type="invalid">
                    {validation.errors.metaKeywords}
                  </FormFeedback>
                ) : null}
              </Row>
              <Row className="mt-40">
                <PageHeader title="Preferences" />
              </Row>
              <Row className="mt-20">
                <SelectInput
                  label="Should we show neutral undertone if we cannot match
                    user to correct undertone (warm / cool)?"
                  fieldName="defaultToNeutralUndertone"
                  handleChange={validation.handleChange}
                  fieldValue={validation.values.defaultToNeutralUndertone}
                  isTouchedField={validation.touched.defaultToNeutralUndertone}
                  fieldError={validation.errors.defaultToNeutralUndertone}
                  selectOptions={OptionsPreferences}
                  handleBlur={validation.handleBlur}
                />
              </Row>
              <Row className="mt-20">
                <SelectInput
                  label="Should we send a results email?"
                  fieldName="sendResultsEmail"
                  handleChange={validation.handleChange}
                  fieldValue={validation.values.sendResultsEmail}
                  isTouchedField={validation.touched.sendResultsEmail}
                  fieldError={validation.errors.sendResultsEmail}
                  selectOptions={OptionsPreferences}
                  handleBlur={validation.handleBlur}
                />
              </Row>
              <Row className="mt-20">
                <SelectInput
                  label="Should we send a rating email after purchase?"
                  fieldName="sendRatingEmail"
                  handleChange={validation.handleChange}
                  fieldValue={validation.values.sendRatingEmail}
                  isTouchedField={validation.touched.sendRatingEmail}
                  fieldError={validation.errors.sendRatingEmail}
                  selectOptions={OptionsPreferences}
                  handleBlur={validation.handleBlur}
                />
              </Row>
            </Col>
          )}
        </Form>
      </Col>
    </React.Fragment>
  );
};

export default BasicsForm;
