import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { CHANGE_PASSWORD } from "./actionTypes";
import { userChangePasswordSuccess, userChangePasswordError } from "./actions";

// Include Both Helper File with needed methods
import {
	postChangePwd
} from "../../../helpers/backend_helper";

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* changePwd({ payload: { user, history } }) {
	try {
		// const response = yield call(postForgetPwd, user);
		const response = yield call(postChangePwd, {
			password: user.password,
			confirmPassword: user.confirmPassword,
			key: user.key
		});
		yield put(userChangePasswordSuccess("Password successfully changed. Redirecting you to login in 3 seconds…"));
	} catch (error) {
		yield put(userChangePasswordError(error));
	}
}

export function* watchUserPasswordChange() {
	yield takeEvery(CHANGE_PASSWORD, changePwd);
}

function* changePasswordSaga() {
	yield all([fork(watchUserPasswordChange)]);
}

export default changePasswordSaga;
