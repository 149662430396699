import {
  CLEAR_REPORTS,
  CLICKS_DETAILS_FAILURE,
  CLICKS_DETAILS_SUCCESS,
  PURCHASES_DETAILS,
  PURCHASES_DETAILS_FAILURE,
  PURCHASES_DETAILS_SUCCESS,
  REPORT_DETAILS,
  REPORT_DETAILS_FAILURE,
  REPORT_DETAILS_SUCCESS,
  SEARCHES_DETAILS_FAILURE,
  SEARCHES_DETAILS_SUCCESS,
  TOP_PRODUCTS_DETAILS,
  TOP_PRODUCTS_DETAILS_FAILURE,
  TOP_PRODUCTS_DETAILS_SUCCESS,
  TOP_SHADES_DETAILS,
  TOP_SHADES_DETAILS_FAILURE,
  TOP_SHADES_DETAILS_SUCCESS,
  TOP_SKIN_CONCERNS_DETAILS,
  TOP_SKIN_CONCERNS_DETAILS_FAILURE,
  TOP_SKIN_CONCERNS_DETAILS_SUCCESS,
  COMPLEXIONS,
  COMPLEXIONS_FAILURE,
  COMPLEXIONS_SUCCESS,
  COLOR_BENCHMAKRS_GET,
  COLOR_BENCHMAKRS_SUCCESS,
  COLOR_BENCHMAKRS_FAILURE,
} from "./actionType";
import {
  calculateTotal,
  constructTopSkinData,
  constructComplexionsData,
} from "./DataFormatter";

const sampleData = {
  total: 0,
  data: [],
  loading: false,
  errorMessage: "",
};

const initialState = {
  errorMsg: "",
  loading: true,
  error: false,
  searches: { ...sampleData, totalEvents: 0 },
  clicks: { ...sampleData, totalEvents: 0 },
  purchases: { ...sampleData, totalEvents: 0 },
  purchasesCount: { ...sampleData, totalEvents: 0 },
  topProducts: { ...sampleData },
  topShades: { ...sampleData },
  topCoverages: { ...sampleData },
  complexions: { ...sampleData },
  colorBenchmarks: {
    baseProductData: [],
    comparisonProductData: [],
  },
};

const reports = (state = initialState, action) => {
  switch (action.type) {
    case REPORT_DETAILS: {
      return {
        ...initialState,
        loading: true,
      };
    }
    case REPORT_DETAILS_SUCCESS: {
      const [searches, clicks, purchases,purchasesCount] = action.payload;
      return {
        ...initialState,
        loading: false,
        searches: {
          ...state.searches,
          data: searches.data,
          total: searches.totalData,
          totalEvents: calculateTotal(searches.data),
        },
        clicks: {
          ...state.clicks,
          data: clicks.data,
          total: clicks.totalData,
          totalEvents: calculateTotal(clicks.data),
        },
        purchases: {
          ...state.purchases,
          data: purchases.data,
          total: purchases.totalData,
          totalEvents: calculateTotal(purchases.data, true),
        },
        purchasesCount: {
          ...state.purchases,
          data: purchasesCount.data,
          total: purchasesCount.totalData,
          totalEvents: calculateTotal(purchasesCount.data),
        },
      };
    }
    case REPORT_DETAILS_FAILURE: {
      return {
        ...initialState,
        errorMsg: action.payload,
        error: true,
        loading: false,
      };
    }

    case CLEAR_REPORTS: {
      return { ...initialState };
    }

    // top searches
    case SEARCHES_DETAILS_SUCCESS: {
      const { totalData, data } = action.payload ?? { totalData: 0, data: [] };

      return {
        ...initialState,
        searches: {
          ...state.searches,
          loading: false,
          data,
          total: totalData,
          totalEvents: calculateTotal(data),
        },
      };
    }
    case SEARCHES_DETAILS_FAILURE: {
      return {
        ...initialState,
        searches: {
          ...state.searches,
          loading: false,
          errorMessage: action.payload,
        },
      };
    }

    // top clicks
    case CLICKS_DETAILS_SUCCESS: {
      const { totalData, data } = action.payload ?? { totalData: 0, data: [] };
      return {
        ...initialState,
        clicks: {
          ...state.clicks,
          loading: false,
          data,
          total: totalData,
          totalEvents: calculateTotal(data),
        },
      };
    }
    case CLICKS_DETAILS_FAILURE: {
      return {
        ...initialState,
        clicks: {
          ...state.clicks,
          loading: false,
          errorMessage: action.payload,
        },
      };
    }

    // top purchases
    case PURCHASES_DETAILS: {
      return {
        ...state,
        loading: true,
        purchases: {
          ...state.purchases,
          loading: true,
          data: [],
          errorMessage: "",
        },
      };
    }
    case PURCHASES_DETAILS_SUCCESS: {
      const { totalData, data } = action.payload ?? { totalData: 0, data: [] };
      return {
        ...state,
        loading: false,
        purchases: {
          ...state.purchases,
          loading: false,
          data,
          total: totalData,
          totalEvents: calculateTotal(data, true),
        },
      };
    }
    case PURCHASES_DETAILS_FAILURE: {
      return {
        ...state,
        loading: false,
        purchases: {
          ...state.purchases,
          loading: false,
          errorMessage: action.payload,
        },
      };
    }

    // top prodcuts
    case TOP_PRODUCTS_DETAILS: {
      return {
        ...initialState,
        loading: true,
        topProducts: {
          ...state.topProducts,
          data: [],
          loading: true,
          errorMessage: "",
        },
      };
    }
    case TOP_PRODUCTS_DETAILS_SUCCESS: {
      const { totalData, data } = action.payload ?? { totalData: 0, data: [] };
      return {
        ...initialState,
        loading: false,
        topProducts: {
          ...state.topProducts,
          data: data,
          total: totalData,
          loading: false,
        },
      };
    }
    case TOP_PRODUCTS_DETAILS_FAILURE: {
      return {
        ...initialState,
        loading: false,
        topProducts: {
          ...state.topProducts,
          loading: false,
          errorMessage: action.payload,
        },
      };
    }

    // top shades
    case TOP_SHADES_DETAILS: {
      return {
        ...initialState,
        loading: true,
        topShades: {
          ...state.topShades,
          data: [],
          loading: true,
          errorMessage: "",
        },
      };
    }
    case TOP_SHADES_DETAILS_SUCCESS: {
      const { totalData, data } = action.payload ?? { totalData: 0, data: [] };
      return {
        ...initialState,
        loading: false,
        topShades: {
          ...state.topShades,
          data,
          total: totalData,
          loading: false,
        },
      };
    }
    case TOP_SHADES_DETAILS_FAILURE: {
      return {
        ...initialState,
        loading: false,
        topShades: {
          ...state.topShades,
          loading: false,
          errorMessage: action.payload,
        },
      };
    }

    // top skin concerns
    case TOP_SKIN_CONCERNS_DETAILS: {
      return {
        ...initialState,
        loading: true,
        topCoverages: {
          ...state.topCoverages,
          data: [],
          loading: true,
        },
      };
    }
    case TOP_SKIN_CONCERNS_DETAILS_SUCCESS: {
      const { totalData, data } = action.payload ?? { totalData: 0, data: [] };
      return {
        ...initialState,
        loading: false,
        topCoverages: {
          ...state.topCoverages,
          data: constructTopSkinData(data),
          total: totalData,
          loading: false,
        },
      };
    }
    case TOP_SKIN_CONCERNS_DETAILS_FAILURE: {
      return {
        ...initialState,
        loading: false,
        topCoverages: {
          ...state.topCoverages,
          loading: false,
          errorMessage: action.payload,
        },
      };
    }

    // Complexions
    case COMPLEXIONS: {
      return {
        ...state,
        loading: true,
        complexions: { ...sampleData, loading: true },
      };
    }
    case COMPLEXIONS_SUCCESS: {
      const { data, totalCount } = constructComplexionsData(action.payload);
      return {
        ...state,
        loading: false,
        complexions: {
          data: data,
          total: totalCount,
          loading: false,
        },
      };
    }
    case COMPLEXIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        complexions: {
          ...state.complexions,
          loading: false,
          errorMessage: action.payload,
        },
      };
    }

    // Color Benchmarks
    case COLOR_BENCHMAKRS_GET: {
      return {
        ...state,
        loading: true,
        error: false,
        errorMsg: "",
        colorBenchmarks: {
          baseProductData: [],
          comparisonProductData: [],
        },
      };
    }
    case COLOR_BENCHMAKRS_SUCCESS: {
      return {
        ...state,
        loading: false,
        colorBenchmarks: action.payload.data,
      };
    }
    case COLOR_BENCHMAKRS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: true,
        errorMsg: action.payload,
      };
    }

    default:
      return { ...state };
  }
};

export default reports;
