import React, {useEffect, useState} from "react";
import _ from "lodash";
import { toast } from "react-toastify";

// common helpers
import CommonCard from "./Common/CommonCard";
import Loader from "../../Components/Common/Loader";
// redux
import { useDispatch, useSelector } from "react-redux";

import { getUndertones } from "../../store/dashboard/actions";

const TopUndertones = () => {
  const dispatch = useDispatch();

  const {
    dateRange, undertones, isFetchingData
  } = useSelector((state) => ({
    dateRange: state.Dashboard.dateRange,
    undertones: state.Dashboard.undertones.data,
    isFetchingData: state.Dashboard.undertones.loading,
  }));

  useEffect(() => {
    dispatch(getUndertones({startTime: dateRange[0], endTime: dateRange[1], grouping: "month"}));
  }, [dateRange]);

  return (
    <CommonCard title="Top Undertones">
      {isFetchingData ? <Loader /> : null}
      {!isFetchingData && undertones.length ? (
        <tbody>
          {undertones.map((undertone, idx) => (
            <tr className="item item-sm" key={idx}>
              <td className="item-image">
                <div
                  className="item-color"
                  style={{
                    backgroundColor: undertone.color,
                  }}
                ></div>
              </td>
              <td className="item-name">{undertone.name}</td>
              <td className="item-value">{undertone.total} / {undertone.percent}%</td>
            </tr>
          ))}
        </tbody>
      ) : !isFetchingData && !undertones.length ? (
        <span>No data available for this time period</span>
      ) : null}
    </CommonCard>
  );
};

export default TopUndertones;
