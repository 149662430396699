import moment from "moment";

export const calculateTimeFromNow = (duration) => {
  return moment(duration).fromNow();
};

export const convertToDateMonthYearFormat = (duration,dmyFormat = false, myFormat = false) => {
  if(dmyFormat) return moment(duration).format("DD MMM YYYY")
  if(myFormat) return moment(duration).format("MMM YYYY")
  return moment(duration).format("MM-DD-YYYY");
};

export const calculateDateRange = (timeFrame) => {
  const recentDate = convertToDateMonthYearFormat(new Date());
  const extractedValues = timeFrame.split(" ");

  let substractSuffix;

  if (timeFrame.includes("day")) substractSuffix = "days";
  else if (timeFrame.includes("month")) substractSuffix = "months";
  else substractSuffix = "years";

  const startTime = moment(recentDate).subtract(
    +extractedValues[1],
    substractSuffix
  ).format("MM-DD-YYYY");

  return [startTime, recentDate];
};
