import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuItemEnums, RouteParams } from "../common/enums";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isDashboard, setIsDashboard] = useState(false);
  const [isInventory, setIsInventory] = useState(false);
  const [isCampaign, setIsCampaign] = useState(false);
  const [isAudience, setIsAudience] = useState(false);
  const [isReporting, setIsReporting] = useState(false);
  const [isTraining, setIsTraining] = useState(false);
  const [isSettings, setIsSettings] = useState(false);

  // Inventory
  const [isMyProducts, setMyProducts] = useState(false);
  const [isMyBundles, setMyBundles] = useState(false);
  // Campaign
  const [isMyCampaigns, setMyCampaigns] = useState(false);

  // Campain

  // Audience

  // Reporting

  // Settings

  const [iscurrentState, setIscurrentState] = useState("Dashboard");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Dashboard") {
      setIsDashboard(false);
    }
    if (iscurrentState !== "Inventory") {
      setIsInventory(false);
    }
    if (iscurrentState !== "Campaign") {
      setIsCampaign(false);
    }
    if (iscurrentState !== "Audience") {
      setIsAudience(false);
    }
    if (iscurrentState !== "Reporting") {
      setIsReporting(false);
    }
    if (iscurrentState !== "Training") {
      setIsTraining(false);
    }
    if (iscurrentState !== "Settings") {
      setIsSettings(false);
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isInventory,
    isCampaign,
    isAudience,
    isReporting,
    isTraining,
    isSettings,
  ]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: MenuItemEnums.dashboard,
      label: "Dashboard",
      icon: "./icons/icon-home.png",
      link: "/#",
      stateVariables: isDashboard,
      click: function (e) {
        e.preventDefault();
        setIsDashboard(!isDashboard);
        setIscurrentState("Dashboard");
        updateIconSidebar(e);
      },
      subItems: [
        {
          id: "dashboard",
          label: "Dashboard",
          link: "/dashboard",
          parentId: "dashboard",
        },
      ],
    },
    {
      id: MenuItemEnums.inventory,
      label: "Inventory",
      icon: "./icons/icon-inventory.png",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsInventory(!isInventory);
        setIscurrentState("Inventory");
        updateIconSidebar(e);
      },
      stateVariables: isInventory,
      subItems: [
        {
          id: "my-products",
          label: "My Products",
          link: "/#",
          parentId: "inventory",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setMyProducts(!isMyProducts);
          },
          stateVariables: isMyProducts,
          childItems: [
            {
              id: 1,
              label: "Beautiful Skin Flawless Foundation",
              link: "/product/1",
              parentId: "my-products",
            },
            {
              id: 2,
              label: "Beautiful Skin Concealer",
              link: "/product/2",
              parentId: "my-products",
            },
            {
              id: 3,
              label: "Highlighter",
              link: "/product/3",
              parentId: "my-products",
            },
            {
              id: 4,
              label: "Contour",
              link: "/product/4",
              parentId: "my-products",
            },
            {
              id: 5,
              label: "Bronzer",
              link: "/product/5",
              parentId: "my-products",
            },
            {
              id: 6,
              label: "Primer",
              link: "/product/6",
              parentId: "my-products",
            },
          ],
        },
        {
          id: "add-product",
          label: "+ Add a Product",
          button: true,
          link: `/${MenuItemEnums.inventory}/${MenuItemEnums.product}?${RouteParams.createProduct}=true`,
          parentId: "inventory",
        },
        {
          id: "my-bundles",
          label: "My Bundles",
          link: "/#",
          parentId: "inventory",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setMyBundles(!isMyBundles);
          },
          stateVariables: isMyBundles,
          childItems: [
            {
              id: 1,
              label: "Beautiful Skin Flawless Foundation",
              link: "/bundle/1",
              parentId: "my-bundles",
            },
            {
              id: 2,
              label: "Beautiful Skin Concealer",
              link: "/bundle/2",
              parentId: "my-bundles",
            },
            {
              id: 3,
              label: "Highlighter",
              link: "/bundle/3",
              parentId: "my-bundles",
            },
            {
              id: 4,
              label: "Contour",
              link: "/bundle/4",
              parentId: "my-bundles",
            },
            {
              id: 5,
              label: "Bronzer",
              link: "/bundle/5",
              parentId: "my-bundles",
            },
            {
              id: 6,
              label: "Primer",
              link: "/bundle/6",
              parentId: "my-bundles",
            },
          ],
        },
        {
          id: "add-bundle",
          label: "+ Add a Bundle",
          button: true,
          link: "/add-bundle",
          parentId: "inventory",
        },
      ],
    },
    {
      id: MenuItemEnums.campaign,
      label: "Campaign",
      icon: "icons/icon-campaign.png",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsCampaign(!isCampaign);
        setIscurrentState("Campaign");
        updateIconSidebar(e);
      },
      stateVariables: isCampaign,
      subItems: [
        {
          id: "my-campaigns",
          label: "My Campaigns",
          link: "/#",
          parentId: "campaign",
          isChildItem: true,
          click: function (e) {
            e.preventDefault();
            setMyCampaigns(!isMyCampaigns);
          },
          stateVariables: isMyCampaigns,
          childItems: [
            {
              id: 1,
              label: "Bella Hadid New Partnership Annoucement",
              link: "/campaign/1",
              parentId: "my-campaigns",
            },
            {
              id: 2,
              label: "Jose Jimenez",
              link: "/campaign/2",
              parentId: "my-campaigns",
            },
            {
              id: 3,
              label: "Batsheva Haart",
              link: "/campaign/3",
              parentId: "my-campaigns",
            },
            {
              id: 4,
              label: "Glamour UK",
              link: "/campaign/4",
              parentId: "my-campaigns",
            },
            {
              id: 5,
              label: "DailyMail UK",
              link: "/campaign/5",
              parentId: "my-campaigns",
            },
            {
              id: 6,
              label: "Sephora",
              link: "/campaign/6",
              parentId: "my-campaigns",
            },
            {
              id: 7,
              label: "Vogue",
              link: "/campaign/7",
              parentId: "my-campaigns",
            },
          ],
        },
        {
          id: "add-campaign",
          label: "+ Add a Campaign",
          button: true,
          link: "/add-campaign",
          parentId: "campaign",
        },
      ],
    },
    {
      label: "pages",
      isHeader: true,
    },
    {
      id: "audience",
      label: "Audience",
      icon: "icons/icon-audience.png",
      link: "/#",
      stateVariables: isAudience,
      click: function (e) {
        e.preventDefault();
        setIsAudience(!isAudience);
        setIscurrentState("Audience");
        updateIconSidebar(e);
      },
      subItems: [],
    },
    {
      id: "reporting",
      label: "Reporting",
      icon: "icons/icon-revenue.png",
      link: "/#",
      stateVariables: isReporting,
      click: function (e) {
        e.preventDefault();
        setIsReporting(!isReporting);
        setIscurrentState("Reporting");
        updateIconSidebar(e);
      },
      subItems: [],
    },
    {
      id: "training",
      label: "Training",
      icon: "icons/icon-ai-training.png",
      link: "/#",
      stateVariables: isTraining,
      click: function (e) {
        e.preventDefault();
        setIsTraining(!isTraining);
        setIscurrentState("Training");
        updateIconSidebar(e);
      },
      subItems: [],
    },
    {
      id: "settings",
      label: "Settings",
      icon: " icons/icon-settings.png",
      link: "/#",
      click: function (e) {
        e.preventDefault();
        setIsSettings(!isSettings);
        setIscurrentState("Settings");
        updateIconSidebar(e);
      },
      stateVariables: isSettings,
      subItems: [
        {
          id: "logout",
          label: "Logout",
          button: true,
          link: "/logout",
          parentId: "settings",
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
