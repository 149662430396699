import { calculatePercentValue } from "../../helpers/utils";

const calculateTotal = (data, hasSumKey= false) => {
  if (!data.length) return 0
  const mappedCounts = !hasSumKey ? data.map((item) => +item.count) :data.map((item) => +item.sum)

  const totalEvents = mappedCounts.reduce((total, num) => {
    return total + num
  })

  return totalEvents
}

const constructTopSkinData = (topSkins) => {
  if (!topSkins.length) return []
  const topSkinsConcerns = {}

  topSkins.forEach(({ coverageChosen, count }) => {
    if (
      typeof coverageChosen === "string" &&
      coverageChosen.toLowerCase() in topSkinsConcerns
    )
      topSkinsConcerns[coverageChosen.toLowerCase()] += +count
    else if (coverageChosen.includes("[") && coverageChosen.includes("]")) {
      constructTopSkinData({ coverages: [...coverageChosen], count })
    } else if (coverageChosen.includes(",")) {
      constructTopSkinData({ coverages: [...coverageChosen], count })
    } else {
      if (!coverageChosen) return (topSkinsConcerns["others"] = +count)
      topSkinsConcerns[coverageChosen.toLowerCase()] = +count
    }
  })

  const skinChartData = []

  for (const skin in topSkinsConcerns) {
    skinChartData.push({ name: skin, value: topSkinsConcerns[skin] })
  }

  return skinChartData
}

const constructComplexionsData = (result) => {
  const topComplexions = {}
  let totalCount = 0
  for (const item of result.data) {
    const complexionKey = item.complexion

    if (complexionKey in topComplexions) {
      topComplexions[complexionKey].total += +item.total
      totalCount += +item.total
    } else {
      totalCount += +item.total
      topComplexions[complexionKey] = {
        total: +item.total,
        order: item.order,
        color: item.hexColor,
        name: complexionKey,
      }
    }
  }

  const data = Object.values(topComplexions)
    .map((item) => ({
      ...item,
      percent: calculatePercentValue(item?.total ?? 0, totalCount),
    }))
    .sort((a, b) => b.total - a.total)
  
  return { data, totalCount }
}

export {
  calculateTotal,
  constructTopSkinData,
  constructComplexionsData,
}