import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { logoutUser } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import withRouter from "../../Components/Common/withRouter";
import Loader from "../../Components/Common/Loader";
import { getLoggedinUser } from "../../helpers/auth-user";

const Logout = (props) => {
  const dispatch = useDispatch();
  const loggedInUser = getLoggedinUser()

  const { isUserLogout } = useSelector((state) => ({
    isUserLogout: state.Login.isUserLogout,
  }));

  useEffect(() => {
    dispatch(logoutUser());
  }, [dispatch]);

  if (isUserLogout && !loggedInUser) {
    return <Navigate to="/login" replace={true} />;
  }

  return (
    <>
      {!isUserLogout ? (
        <div className="d-flex justify-content-center align-items-center vh-100">
          <Loader />
        </div>
      ) : null}
    </>
  );
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default withRouter(Logout);
