import {
  CLEAR_REPORTS, CLICKS_DETAILS_FAILURE, CLICKS_DETAILS_SUCCESS,
  PURCHASES_DETAILS, PURCHASES_DETAILS_FAILURE, PURCHASES_DETAILS_SUCCESS,
  REPORT_DETAILS, REPORT_DETAILS_FAILURE, REPORT_DETAILS_SUCCESS,
  SEARCHES_DETAILS_FAILURE, SEARCHES_DETAILS_SUCCESS,
  TOP_PRODUCTS_DETAILS, TOP_PRODUCTS_DETAILS_FAILURE, TOP_PRODUCTS_DETAILS_SUCCESS,
  TOP_SHADES_DETAILS, TOP_SHADES_DETAILS_FAILURE, TOP_SHADES_DETAILS_SUCCESS,
  TOP_SKIN_CONCERNS_DETAILS, TOP_SKIN_CONCERNS_DETAILS_FAILURE, TOP_SKIN_CONCERNS_DETAILS_SUCCESS,
  COMPLEXIONS, COMPLEXIONS_FAILURE, COMPLEXIONS_SUCCESS,
  COLOR_BENCHMAKRS_GET, COLOR_BENCHMAKRS_SUCCESS, COLOR_BENCHMAKRS_FAILURE,
} from "./actionType";

export const getReports = (data) => {
  return {
    type: REPORT_DETAILS,
    payload: data,
  };
};

export const getReportsSuccess = (payload) => {
  return {
    type: REPORT_DETAILS_SUCCESS,
    payload,
  };
};

export const getReportsFailure = (error) => {
  return {
    type: REPORT_DETAILS_FAILURE,
    payload: error,
  };
};

export const clearReports = () => {
  return {
    type: CLEAR_REPORTS,
  };
};

export const getSearchesSuccess = (payload) => {
  return {
    type: SEARCHES_DETAILS_SUCCESS,
    payload,
  };
};

export const getSearchesFailure = (payload) => {
  return {
    type: SEARCHES_DETAILS_FAILURE,
    payload,
  };
};

export const getClicksSuccess = (payload) => {
  return {
    type: CLICKS_DETAILS_SUCCESS,
    payload,
  };
};

export const getClicksFailure = (payload) => {
  return {
    type: CLICKS_DETAILS_FAILURE,
    payload,
  };
};

export const getPurchases = (payload) => {
  return {
    type: PURCHASES_DETAILS,
    payload,
  };
};

export const getPurchaseSuccess = (payload) => {
  return {
    type: PURCHASES_DETAILS_SUCCESS,
    payload,
  };
};

export const getPurchasesFailure = (payload) => {
  return {
    type: PURCHASES_DETAILS_FAILURE,
    payload,
  };
};

export const getTopProductList = (payload) => {
  return {
    type: TOP_PRODUCTS_DETAILS,
    payload,
  };
};

export const getTopProductListSuccess = (payload) => {
  return {
    type: TOP_PRODUCTS_DETAILS_SUCCESS,
    payload,
  };
};

export const getTopProductListFailure = (error) => {
  return {
    type: TOP_PRODUCTS_DETAILS_FAILURE,
    payload: error,
  };
};

export const getTopShadesList = (payload) => {
  return {
    type: TOP_SHADES_DETAILS,
    payload,
  };
};

export const getTopShadesListSuccess = (payload) => {
  return {
    type: TOP_SHADES_DETAILS_SUCCESS,
    payload,
  };
};

export const getTopShadesListFailure = (error) => {
  return {
    type: TOP_SHADES_DETAILS_FAILURE,
    payload: error,
  };
};

export const getTopCoveragesList = (payload) => {
  return {
    type: TOP_SKIN_CONCERNS_DETAILS,
    payload,
  };
};

export const getTopCoveragesListSuccess = (payload) => {
  return {
    type: TOP_SKIN_CONCERNS_DETAILS_SUCCESS,
    payload,
  };
};

export const getTopCoveragesListFailure = (error) => {
  return {
    type: TOP_SKIN_CONCERNS_DETAILS_FAILURE,
    payload: error,
  };
};

export const getComplexionsList = (payload) => {
  return {
    type: COMPLEXIONS,
    payload,
  };
};

export const getComplexionsSuccess = (payload) => {
  return {
    type: COMPLEXIONS_SUCCESS,
    payload,
  };
};

export const getComplexionsFailure = (error) => {
  return {
    type: COMPLEXIONS_FAILURE,
    payload: error,
  };
};

export const getColorBenchmarks = (payload) => {
  return {
    type: COLOR_BENCHMAKRS_GET,
    payload,
  };
};

export const getColorBenchmarksSuccess = (payload) => {
  return {
    type: COLOR_BENCHMAKRS_SUCCESS,
    payload,
  };
};

export const getColorBenchmarksFailure = (error) => {
  return {
    type: COLOR_BENCHMAKRS_FAILURE,
    payload: error,
  };
};
