import { APIClient } from "./api_helper";

import * as url from "./url_helper";

const api = new APIClient();

// Public API - Not Mime
export const getIP = (data) => api.get("https://ipapi.co/json", data);
export const getFonts = () => api.get("https://www.googleapis.com/webfonts/v1/webfonts?key=AIzaSyDlmIkteolaMLouRkr_o5sHWs0_tRfE_XM")

// Authentication
export const postRegister = (data) => api.create(url.POST_REGISTER, data);
export const postLogin = (data) => api.create(url.POST_LOGIN, data);
export const postLogout = (data) => api.create(url.POST_LOGOUT, data);
export const postRefreshToken = (data) => api.create(url.TOKEN_REFRESH, data);
export const postForgetPwd = (data) => api.create(url.POST_PASSWORD_FORGET, data);
export const postChangePwd = (data) => api.create(url.POST_PASSWORD_CHANGE, data);
export const verifyResetToken = (key) => api.create(url.VERIFY_RESET_TOKEN, key)

// dashboard
export const getRevenues = (startTime, endTime) => api.get(`${url.REPORT}/${url.PURCHASES}`, {startTime ,endTime})
export const getComplexions = (data) => api.get(`${url.REPORT}/${url.COMPLEXIONS}`, data)
export const getUndertones = (data) => api.get(`${url.REPORT}/${url.UNDERTONES}`, data)
export const getColorPreferences = (data) => api.get(`${url.REPORT}/${url.COLOR_PREFERENCES}`, data)
export const getCountries = (data) => api.get(`${url.REPORT}/${url.COUNTRIES}`, data)
export const getRecentShoppers = (data) => api.get(`${url.RECENT_SHOPPERS}`, data)
export const getInStoreEngagement = (data) => api.get(`${url.REPORT}/${url.IN_STORE_ENGAGEMENT}`, data)

// Inventory - Product
export const getProducts = (data) => api.get(url.PRODUCT, data);
export const getProduct = (productID) => api.get(`${url.PRODUCT}/${productID}`, {});
export const postProduct = (data) => api.create(url.PRODUCT, data);
export const updateProduct = (groupID, data) => api.update(`${url.PRODUCT}/${groupID}`, data);
export const deleteProduct = (groupID) => api.delete(`${url.PRODUCT}/${groupID}`, {});

export const getVariants = (productID) => api.get(`${url.PRODUCT}/${productID}${url.VARIANT}`, {});
export const getVariant = (data) => api.get( `${url.PRODUCT}/${data.productID}${url.VARIANT}/${data.variantID}`, {} );
export const postVariant = (productID, data) => api.create(`${url.PRODUCT}/${productID}${url.VARIANT}`, data);
export const updateVariant = (productID, variantID, data) => api.update( `${url.PRODUCT}/${productID}${url.VARIANT}/${variantID}`, data );
export const deleteVariant = (productID, variantID) => api.delete( `${url.PRODUCT}/${productID}${url.VARIANT}/${variantID}`, {} );

export const getMyBrands = () => api.get(`${url.BRANDS}/${url.INTERNAL}`, {});

// Inventory - Bundle
export const getBundles = (data) => api.get(url.BUNDLE, data);
export const getBundle = (bundleID) => api.get(`${url.BUNDLE}/${bundleID}`, {});
export const postBundle = (data) => api.create(url.BUNDLE, data);
export const updateBundle = (bundleID, data) => api.update(`${url.BUNDLE}/${bundleID}`, data);
export const deleteBundle = (bundleID) => api.delete(`${url.BUNDLE}/${bundleID}`, {});

export const getBundleProducts = (bundleID) => api.get(`${url.BUNDLE}/${bundleID}${url.BUNDLE_PRODUCT}`, {});
export const getBundleProduct = (data) => api.get( `${url.BUNDLE}/${data.bundleID}${url.BUNDLE_PRODUCT}/${data.productID}`, {} );
export const postBundleProduct = (bundleID, data) => api.create(`${url.BUNDLE}/${bundleID}${url.BUNDLE_PRODUCT}`, data);
export const updateBundleProduct = (bundleID, productID, data) => api.update( `${url.BUNDLE}/${bundleID}${url.BUNDLE_PRODUCT}/${productID}`, data );
export const deleteBundleProduct = ( variantID, bundleID) => api.delete( `${url.BUNDLE}/${bundleID}${url.BUNDLE_PRODUCT}/${variantID}`, {} );

// Campaign
export const getCampaigns = () => api.get(`${url.CAMPAIGN}`)
export const postCampaign = (data) => api.create(url.CAMPAIGN, data);
export const updateCampaign = (camapignId, data) => api.update(`${url.CAMPAIGN}/${camapignId}`, data);
export const deleteCampaign = (camapignId) => api.delete(`${url.CAMPAIGN}/${camapignId}`, {});
export const getApps = () => api.get(`${url.APPS}`)

// audience missed connections
export const getMissedConnections = () => api.get(`${url.MISSED_CONNECTIONS}`)
export const sendEmailToConnection = (data) => api.create(url.EMAIL_CONNECTION, data )
export const getBrands = () => api.get(url.BRANDS);
export const getBrandProducts = (brandId) => api.get(`${url.BRANDS}/${brandId}/${url.PRODUCTS}`)
export const getColorBenchmarks = (data) => api.get(`${url.REPORT}/${url.COLOR_BENCHMAKRS}`, data)

// reporting    
export const getSearches = (data) => api.get(`${url.REPORT}/${url.SEARCHES}`, data)
export const getClicks = (data) => api.get(`${url.REPORT}/${url.CLICKS}`, data)
export const getPurchases = (data) => api.get(`${url.REPORT}/${url.PURCHASES}`, data)
export const getPurchasesCount = (data) => api.get(`${url.REPORT}/${url.PURCHASES_COUNT}`, data)
export const getTopProducts = (data) => api.get(`${url.REPORT}/${url.TOP_PRODUCTS}`, data)
export const getTopShades = (data) => api.get(`${url.REPORT}/${url.TOP_SHADES}`, data)
export const getTopCoverages = (data) => api.get(`${url.REPORT}/${url.COVERAGES}`, data)

// AI training
// training
export const getTraining = (productId) => api.get(`${url.TRAINING}/${productId}`,{})
export const getTrainingHistory = (productId) => api.get(`${url.TRAINING}/${url.LABELS}/${productId}`,{})
export const patchTrainingHistory = (data) => api.update(`${url.TRAINING}/${url.LABEL}/${data.id}`,data)
export const postTraining = (data) => api.create(url.TRAINING, data)
export const getBestPractises = (productId) => api.get(`${url.TRAINING}/${url.BEST_PRACTISES}/${productId}`, {})
// returns
export const getReturns = () => api.get(`${url.RETURN}`, {})
export const postReturn = (data) => api.create(url.RETURN, data)
export const deleteReturn = (returnId) => api.delete(`${url.RETURN}/${returnId}`, {})

// settings
export const getAppDetails = (appId) => api.get(`${url.APPS}/${appId}`, {})
export const updateAppDetails = (appId, data) => api.update(`${url.APPS}/${appId}`, data);
export const getAppQuestions = (appId) => api.get(`${url.APPS}/${appId}/${url.QUESTION}`, {})
export const patchAppQuestions = (appId, data) => api.update(`${url.APPS}/${appId}/${url.QUESTION}`, data)
export const getUserProfile = () => api.get(`${url.PROFILE}`, {})
export const patchUserProfile = (data) => api.update(`${url.PROFILE}`, data)
export const getInviteTeammates = () => api.get(`${url.TEAMS}`, {})
export const postInvite = (data) => api.create(`${url.INVITE}`, data)
export const patchInvite = (data) => api.update(`${url.TEAMS}/${data.id}`, data)
export const deleteInvite = (id) => api.delete(`${url.TEAMS}/${id}`, {})
    