import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
// common helper
import CommonCard from "./Common/CommonCard";
import Loader from "../../Components/Common/Loader";
import { getComplexionsList } from "../../store/reporting/action";

const TopComplexions = () => {
  const dispatch = useDispatch();

  const { dateRange, complexions, isFetchingData } = useSelector((state) => ({
    dateRange: state.Dashboard.dateRange,
    complexions: state.Report.complexions,
    isFetchingData: state.Report.complexions.loading,
  }));

  useEffect(() => {
    const filterValues = {
      startTime: dateRange[0],
      endTime: dateRange[1],
      grouping: "month",
    };
    dispatch(getComplexionsList(filterValues));
  }, [dispatch, dateRange]);

  return (
    <CommonCard title="Top Complexions">
      {isFetchingData ? <Loader /> : null}
      {!isFetchingData && complexions.data.length ? (
        <tbody>
          {complexions.data.map((complexion, idx) => (
            <tr className="item item-sm" key={idx}>
              <td className="item-image">
                <div
                  className="item-color"
                  style={{
                    backgroundColor: complexion.color,
                  }}
                ></div>
              </td>
              <td className="item-name">{complexion.name}</td>
              <td className="item-value">
                {complexion.total} / {complexion.percent}%
              </td>
            </tr>
          ))}
        </tbody>
      ) : !isFetchingData && !complexions.length ? (
        <span>No data available for this time period</span>
      ) : null}
    </CommonCard>
  );
};

export default TopComplexions;
