export class MenuItemEnums {
  static dashboard = "dashboard";

  static inventory = "inventory";
  static product = "product";
  static bundle = "bundle";

  static campaign = "campaign";
  static myCampaign = "my-campaign";

  static audience = "audience";

  static revenue = "revenue";
  static reporting = "reporting";

  static aiTraining = "ai-training";
  static training = "training";
  static history = "history";

  static settings = "settings";
  static account = "account";
}

export class RouteParams {
  static productId = "product-id";
  static createProduct = "create-product";
  static editProduct = "edit-product";

  static variantId = "variant-id";
  static createVariant = "create-variant";
  static editVariant = "edit-variant";

  static bundleId = "bundle-id";
  static createBundle = "create-bundle";
  static editBundle = "edit-bundle";

  static campaignId = "campaign-id";
  static createCampaign = "create-campaign";
  static editCampaign = "edit-campaign";

  static bundleProductId = "bundle-product-id";
  static createBundleProduct = "create-bundle-product";
  static editBundleProduct = "edit-bundle-product";

  static opportunities = "opportunities";

  static missedConnections = "missed-connections";
  static createConnection = "create-connection";
  static userId = "user-id";

  static undeserved = "undeserved";
  static analysis = "analysis";
  static myAudience = "my-audience";
  static colorBenchMarks = "color-benchmarks";

  static training = "training";

  static returns = "returns";
  static manageReturns = "manage-returns";

  static conversion = "conversion";
  static reportingRevenue = "revenue";
  static topProducts = "top-products";
  static topShades = "top-shades";
  static topCoverages = "top-coverages";

  static myApps = "my-apps";
  static metaElements = "meta-elements";
  static basics = "basics"
  static questions = "questions";
  static legal = "legal";
  static branding = "branding";

  static myTeam = "my-team";
  static aboutMe = "about-me";
  static emailPassword = "email-password";
  static logout = "logout";
}
