import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Col, Row, Table } from "reactstrap";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Loader from "../../../Components/Common/Loader";

// enums
import ErrorController from "../../../Components/Common/ErrorController";

// common helper
import PageHeader from "../../../Components/Common/PageHeader";
import TimeInterval from "../../../Components/Hooks/UseIntervalHook";

// redux
import { useSelector } from "react-redux";

const Conversions = () => {
  const barColors = useMemo(
    () => ["#3f5289", "#06b39c", "#f7b94b", "#7b728e", "#7b728e"],
    []
  );

  const { searches, clicks, purchasesCount, fetchingData, errorMsg } = useSelector(
    (state) => ({
      fetchingData: state.Report.loading,
      errorMsg: state.Report.errorMsg,
      searches: state.Report.searches,
      clicks: state.Report.clicks,
      purchasesCount: state.Report.purchasesCount,
    })
  );

  const hasReports = !(
    !searches.data.length &&
    !clicks.data.length &&
    !purchasesCount.data.length
  );

  const hasNoData = !hasReports && !fetchingData;

  const getPercentValue = useCallback((topValue, bottomValue) => {
    if (topValue === 0 || bottomValue === 0) return 0;
    return ((topValue / bottomValue) * 100).toFixed(2);
  }, []);

  const [conversionData, setConversionData] = useState([]);

  useEffect(() => {
    if (fetchingData) return;
    const tableData = [
      // {
      //   id: "App Start",
      //   lable: "App Start",
      //   events: 0,
      //   percent: 0,
      //   revenue: 0,
      // },
      {
        id: "Search",
        lable: "Search",
        events: searches.totalEvents,
        // percent: 0,
        revenue: searches.totalEvents,
      },
      {
        id: "Click to PDP",
        lable: "Click to PDP",
        events: clicks.totalEvents,
        percent: getPercentValue(clicks.totalEvents, searches.totalEvents),
        revenue: clicks.totalEvents,
      },
      {
        id: "Purchase",
        lable: "Purchase",
        events: purchasesCount.totalEvents,
        percent: getPercentValue(purchasesCount.totalEvents, clicks.totalEvents),
        revenue: purchasesCount.totalEvents,
      },
      {
        id: "Overall",
        lable: "Overall",
        events: 0,
        percent: getPercentValue(purchasesCount.totalEvents, searches.totalEvents),
        revenue: 0,
      },
    ];

    setConversionData([...tableData]);
  }, [fetchingData, clicks, purchasesCount, searches]);

  const barData = conversionData.slice(0, -1);
  const renderCustomLegend = (props) => {
    const { payload } = props;

    return (
      <ul>
        {payload.map((item, index) => {
          return (
            <div className="custom-legend-item" key={`item-${index}`}>
              <div
                className="legend-surface"
                style={{ backgroundColor: barColors[index] }}
              ></div>
              <li>{item.lable}</li>
            </div>
          );
        })}
      </ul>
    );
  };

  const CustomTooltip = useCallback(({ active, payload, valueTitle }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{payload[0]?.payload?.id}</p>
          <p>
            {valueTitle}: {payload[0]?.payload?.events} &nbsp;
          </p>
        </div>
      );
    }
  }, []);

  return (
    <Col className="product-list scroll-container conversion-container">
      <div className="header">
        <PageHeader title="Conversion" />
        <span>
          <TimeInterval prefix="Last updated" />
        </span>
      </div>

      {fetchingData ? (
        <Loader />
      ) : !errorMsg && hasReports ? (
        <Row className={`bar-chart ${hasReports ? "h-400" : ""}`}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              width={500}
              height={300}
              data={barData}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="1 3" vertical={false} />
              <XAxis tick={false} stroke="#e6e7e7" />
              <YAxis
                orientation="right"
                axisLine={false}
                tickLine={false}
                tickFormatter={(tick) => tick}
              />
              <Tooltip
                active={false}
                cursor={{ fill: "transparent" }}
                content={<CustomTooltip valueTitle="Events" />}
              />

              <Bar
                dataKey="revenue"
                barSize={60}
                label={{
                  position: "inside",
                  stroke: "white",
                }}
              >
                {barData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                ))}
              </Bar>
              <Legend
                className="legend-items"
                payload={barData.map((item) => item)}
                align="center"
                content={renderCustomLegend}
              />
            </BarChart>
          </ResponsiveContainer>
        </Row>
      ) : errorMsg ? (
        <ErrorController messsage={errorMsg} />
      ) : (
        <span>No data found</span>
      )}

      <PageHeader title="Breakdown" />

      {fetchingData ? (
        <Loader />
      ) : !errorMsg && hasReports ? (
        <Row>
          <Col className="product-list">
            <Table className="align-middle table-nowrap mb-0">
              <tbody>
                {conversionData &&
                  conversionData.map((conversion, idx) => (
                    <tr className="product-item" key={idx}>
                      <td className="rounded-container product-item-content">
                        <div style={{ backgroundColor: barColors[idx] }}></div>{" "}
                        <div className="title">{conversion.lable}</div>
                      </td>
                      <td className="product-item-content">
                        {conversion.lable !== "Overall" ? (
                          <div className="title">
                            {conversion.events} events
                          </div>
                        ) : null}
                      </td>
                      <td className="product-item-content">
                        <div className="title">
                          {conversion.percent
                            ? `${conversion.percent}%`
                            : conversion.percent ?? null}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      ) : errorMsg ? (
        <ErrorController messsage={errorMsg} />
      ) : (
        <span>No data found</span>
      )}
    </Col>
  );
};

export default Conversions;
