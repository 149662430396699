import React from "react";
import { Col, Container, Row, Table } from "reactstrap";
import { useNavigate } from "react-router-dom";

// common helpers
import { MenuItemEnums } from "../../../common/enums";
import Loader from "../../../Components/Common/Loader";
import PageHeader from "../../../Components/Common/PageHeader";
import ErrorController from "../../../Components/Common/ErrorController";

// redux
import { useSelector } from "react-redux";
import { productsStateSelector } from "../../../store/inventory/product/productSelectors";

const Trainings = () => {
  const navigate = useNavigate();
  const {
    data: products,
    total: productCounts,
    loading: fetchingProducts,
  } = useSelector(productsStateSelector);

  const { errorMessage } = useSelector((state) => ({
    errorMessage: state.Product.errorMsg,
  }));

  const redirectToDetailsPage = (productId) => {
    navigate(`/${MenuItemEnums.aiTraining}/${MenuItemEnums.training}/${productId}`);
  };

  document.title = "AI Training | mime"

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container main-container">
            <PageHeader
              title="Showing all products"
              count={productCounts}
              subtitleSuffix="Product groups"
            />

            {fetchingProducts ? <Loader /> : null}

            {!fetchingProducts && productCounts && !errorMessage ? (
              <Row>
                <Col className="product-list">
                  <Table className="align-middle table-nowrap mb-0">
                    <tbody>
                      {products.map((product, idx) => (
                        <tr className="product-item" key={idx}>
                          <td className="product-item-image">
                            <img src={product.imageUrl} alt="" />
                          </td>
                          <td
                            className="product-item-content"
                            onClick={() =>
                              redirectToDetailsPage(product.groupId)
                            }
                            style={{ cursor: "pointer" }}
                          >
                            <div className="title">{product.name}</div>
                            <div className="sub-title">
                              {product.variants ? product.variants : "0"}
                              &nbsp;variants
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : errorMessage ? (
              <ErrorController messsage={errorMessage} />
            ) : !fetchingProducts && !productCounts ? (
              <span>No data found</span>
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Trainings;
