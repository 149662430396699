import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Col, Form, FormFeedback, Input, Label, Row } from "reactstrap";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Trash2 from "feather-icons-react/build/IconComponents/Trash2";
import { toast } from "react-toastify";

import FormSelectOptions from "../../../Components/Common/FormSelectOptions";
import {
  addBundleProduct,
  clearBundleProduct,
  getBundlesList,
  resetBundleFlag,
  addBundle,
  updateBundle,
  removeBundle,
} from "../../../store/actions";

import { MenuItemEnums, RouteParams } from "../../../common/enums";
import { getIDFromPath, getUrlParam } from "../../../helpers/location-helper";
import {
  OptionsBundleType,
  OptionsPriority,
} from "../../../common/form-selector-values";
import UseDropdowns from "../../../Components/Hooks/UseDropdowns";

const BundleForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [state, setState] = useState({ showForm: false, update: false });
  const [recommend, setRecommend] = useState(true);
  const editableBundleID = getIDFromPath(location.pathname);

  const { bundleProducts, bundleInEdit } = useSelector((state) => ({
    bundleProducts: state.Bundle.products,
    bundleInEdit: state.Bundle.bundleInEdit,
  }));

  useEffect(() => {
    if (getUrlParam(RouteParams.createBundle, location.search) === "true") {
      dispatch(clearBundleProduct());
      setState({ update: false, showForm: true });
    } else if (getUrlParam(RouteParams.editBundle, location.search) === "true") {
      setState({ update: true, showForm: true });
    } else {
      setState({ update: false, showForm: false });
    }
  }, [location.search]);

  useEffect(() => {
    if (bundleInEdit.added || bundleInEdit.updated || bundleInEdit.removed) {
      dispatch(getBundlesList());
      dispatch(resetBundleFlag());
      if (bundleInEdit.added) {
        toast.success("Bundle created successfully");
      } else if (bundleInEdit.updated) {
        toast.success("Bundle updated successfully");
      } else {
        toast.success("Bundle deleted successfully");
      }
      bundleValidation.resetForm();
      navigate(`/${MenuItemEnums.inventory}/${MenuItemEnums.bundle}`);
    }
  }, [bundleInEdit.added, bundleInEdit.updated, bundleInEdit.removed]);

  const submitBundle = async () => {
    const values = {
      baseProductId: bundleValidation.values.baseVariantId,
      name: bundleValidation.values.bundleName,
      products: bundleProducts.map((item) => {
        const product = {
          productId: item.variantId,
          bundleType: item.bundleType,
          priority: item.priority,
        };
        return product;
      }),
    };

    if (state.update) {
      dispatch(updateBundle(editableBundleID, values));
    } else {
      dispatch(addBundle(values));
    }
  };

  const deleteBundle = async () => {
    dispatch(removeBundle(editableBundleID));
  };

  const addProduct = () => {
    const data = {
      ...productValidation.values,
      groupId: +productValidation.values.productId,
    };
    delete data.productId;
    const isProductExist = bundleProducts.some(
      (product) =>
        product.groupId === data.groupId &&
        product.priority === data.priority &&
        product.variantId === data.variantId &&
        product.bundleType === data.bundleType
    );
    if (isProductExist)
      return toast.error("This product already added, try another one!");
    dispatch(addBundleProduct(data));
  };

  const onRecommendHandler = (event) => {
    if (event.target.value === "Yes") {
      setRecommend(true);
    } else {
      setRecommend(false);
    }
  };

  const bundleValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      recommend: bundleInEdit.showInRecommendations || recommend,
      bundleName: bundleInEdit.name || "",
      baseProduct: bundleInEdit?.baseParentGroupId || "",
      baseVariantId: bundleInEdit?.baseParentVariantId || "",
    },
    validationSchema: Yup.object({
      bundleName: Yup.string().required("Please Enter Product Title"),
      baseProduct: Yup.string().required("Please Select Base Product"),
      baseVariantId: Yup.string().required(
        "Please Select Base Product Variant"
      ),
    }),
    onSubmit: () => {
      submitBundle();
    },
  });

  const productValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      productId: "",
      variantId: "",
      bundleType: "",
      priority: "",
    },
    validationSchema: Yup.object({
      productId: Yup.string().required("Please Select Product"),
      variantId: Yup.string().required("Please Select Product Variant"),
      bundleType: Yup.string().required("Please Select Bundle Type"),
      priority: Yup.number().required("Please Select Priority"),
    }),
    onSubmit: () => {
      addProduct();
      productValidation.handleReset();
    },
  });

  const {
    productOptions: baseProductOptions,
    variantOptions: baseVariantOptions,
  } = UseDropdowns(+bundleValidation.values.baseProduct);

  const { productOptions, variantOptions } = UseDropdowns(
    +productValidation.values.productId
  );

  return (
    <React.Fragment>
      {state.showForm ? (
        <Col xs={4} className="mime-form right-edit scroll-container">
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              bundleValidation.handleSubmit();
              return false;
            }}
          >
            <Row className="form-header">
              <Col className="title">
                {state.update ? "Edit Bundle" : "Create Bundle"}
              </Col>

              <Col className="action-button">
                {state.update ? (
                  <Button
                    type="button"
                    className="icon-btn"
                    onClick={() => deleteBundle()}
                  >
                    <Trash2
                      size="24"
                      stroke="#d26767"
                      className="delete-icon"
                    />
                  </Button>
                ) : null}

                <Button
                  type="submit"
                  className="form-control btn-small btn-text-primary"
                >
                  Save
                </Button>
              </Col>
            </Row>
            <Row className="mt-40">
              <Col>
                <Label htmlFor="recommend_yes" className="form-label">
                  Show in Recommendation?
                </Label>
                <Input
                  name="recommend_yes"
                  type="button"
                  className={
                    "form-control btn" + (recommend === true ? " active" : "")
                  }
                  onClick={onRecommendHandler}
                  value={"Yes"}
                />
              </Col>
              <Col className="pl-10">
                <Label htmlFor="recommend_no" className="form-label hidden">
                  Show in Recommendation?
                </Label>
                <Input
                  name="recommend_no"
                  type="button"
                  className={
                    "form-control btn" + (recommend === false ? " active" : "")
                  }
                  onClick={onRecommendHandler}
                  value={"No"}
                />
              </Col>
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Bundle Name*</Label>
              <Input
                name="bundleName"
                type="text"
                className="form-control"
                value={bundleValidation.values.bundleName || ""}
                placeholder="Type the title here"
                onChange={bundleValidation.handleChange}
                onBlur={bundleValidation.handleBlur}
                invalid={
                  bundleValidation.touched.bundleName &&
                  bundleValidation.errors.bundleName
                    ? true
                    : false
                }
              />
              {bundleValidation.touched.bundleName &&
              bundleValidation.errors.bundleName ? (
                <FormFeedback type="invalid">
                  {bundleValidation.errors.bundleName}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Bundle Base Product*</Label>
              <select
                name="baseProduct"
                value={bundleValidation.values.baseProduct}
                onChange={bundleValidation.handleChange}
                className={
                  "form-select" +
                  (bundleValidation.values.baseProduct ? "" : " empty") +
                  (bundleValidation.touched.baseProduct &&
                  bundleValidation.errors.baseProduct ? " is-invalid" : "") +
                  (baseProductOptions.length === 0 ? " loading" : "")
                }
                disabled={baseProductOptions.length === 0}
              >
                <FormSelectOptions
                  categories={baseProductOptions}
                  defaultOptionLabel={
                    baseProductOptions.length === 0 ? "No data found." : ""
                  }
                  defaultOption={
                    bundleValidation.values.baseProduct ? null : {}
                  }
                />
              </select>
              {bundleValidation.touched.baseProduct &&
              bundleValidation.errors.baseProduct ? (
                <FormFeedback type="invalid">
                  {bundleValidation.errors.baseProduct}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Bundle Base Product Variant*</Label>
              <select
                name="baseVariantId"
                value={bundleValidation.values.baseVariantId}
                onChange={(e) => {
                  const baseVariantId = +e.target.value;
                  bundleValidation.handleChange(e);
                  bundleValidation.setFieldValue(
                    "baseVariantId",
                    baseVariantId,
                    true
                  );
                }}
                className={
                  "form-select" +
                  (bundleValidation.values.baseVariantId ? "" : " empty") +
                  (bundleValidation.touched.baseVariantId &&
                  bundleValidation.errors.baseVariantId ? " is-invalid" : "") +
                  (baseVariantOptions.length === 0 ? " loading" : "")
                }
                disabled={baseVariantOptions.length === 0}
              >
                <FormSelectOptions
                  categories={baseVariantOptions}
                  defaultOptionLabel={
                    baseProductOptions.length === 0 ? "No data found." :
                    baseVariantOptions.length === 0 ? "Loading..." : ""
                  }
                  defaultOption={
                    bundleValidation.values.baseVariantId ? null : {}
                  }
                />
              </select>
              {bundleValidation.touched.baseVariantId &&
              bundleValidation.errors.baseVariantId ? (
                <FormFeedback type="invalid">
                  {bundleValidation.errors.baseVariantId}
                </FormFeedback>
              ) : null}
            </Row>
          </Form>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              productValidation.handleSubmit();
              return false;
            }}
          >
            <Row className="form-header mt-20">
              <Col className="title">Add Product(s) to Bundle</Col>
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Product*</Label>
              <select
                name="productId"
                onChange={productValidation.handleChange}
                className={
                  "form-select" +
                  (productValidation.values.productId ? "" : " empty") +
                  (productValidation.touched.productId &&
                  productValidation.errors.productId ? " is-invalid" : "") +
                  (productOptions.length === 0 ? " loading" : "")
                }
                disabled={productOptions.length === 0}
              >
                <FormSelectOptions
                  categories={productOptions}
                  defaultOptionLabel={
                    productOptions.length === 0 ? "No data found." : ""
                  }
                  defaultOption={productValidation.values.productId ? null : {}}
                />
              </select>
              {productValidation.touched.productId &&
              productValidation.errors.productId ? (
                <FormFeedback type="invalid">
                  {productValidation.errors.productId}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Product Variant*</Label>
              <select
                name="variantId"
                onChange={(e) => {
                  const variantId = +e.target.value;
                  productValidation.handleChange(e);
                  productValidation.setFieldValue("variantId", variantId, true);
                }}
                className={
                  "form-select" +
                  (productValidation.values.variantId ? "" : " empty") +
                  (productValidation.touched.variantId &&
                  productValidation.errors.variantId ? " is-invalid" : "") +
                  (variantOptions.length === 0 ? " loading" : "")
                }
                disabled={variantOptions.length === 0}
              >
                <FormSelectOptions
                  categories={variantOptions}
                  defaultOptionLabel={
                    productOptions.length === 0 ? "No data found." :
                    variantOptions.length === 0 ? "Loading..." : ""
                  }
                  defaultOption={productValidation.values.variantId ? null : {}}
                />
              </select>
              {productValidation.touched.variantId &&
              productValidation.errors.variantId ? (
                <FormFeedback type="invalid">
                  {productValidation.errors.variantId}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Bundle Type*</Label>
              <select
                name="bundleType"
                onChange={productValidation.handleChange}
                className={
                  "form-select" +
                  (productValidation.values.bundleType ? "" : " empty") +
                  (productValidation.touched.bundleType &&
                  productValidation.errors.bundleType ? " is-invalid" : "")
                }
              >
                <FormSelectOptions
                  categories={OptionsBundleType}
                  defaultOption={
                    productValidation.values.bundleType ? null : {}
                  }
                />
              </select>
              {productValidation.touched.bundleType &&
              productValidation.errors.bundleType ? (
                <FormFeedback type="invalid">
                  {productValidation.errors.bundleType}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Label className="form-label">Priority*</Label>
              <select
                name="priority"
                onChange={(e) => {
                  const priority = +e.target.value;
                  productValidation.handleChange(e);
                  productValidation.setFieldValue("priority", priority, true);
                }}
                className={
                  "form-select" +
                  (productValidation.values.priority ? "" : " empty") +
                  (productValidation.touched.priority &&
                  productValidation.errors.priority ? " is-invalid" : "")
                }
              >
                <FormSelectOptions
                  categories={OptionsPriority}
                  defaultOption={productValidation.values.priority ? null : {}}
                />
              </select>
              <Col className="mt-1 opacity-08">
                1 being highest and shown first
              </Col>
              {productValidation.touched.priority &&
              productValidation.errors.priority ? (
                <FormFeedback type="invalid">
                  {productValidation.errors.priority}
                </FormFeedback>
              ) : null}
            </Row>
            <Row className="mt-20">
              <Button
                type="submit"
                className="form-control btn-text-primary btn-small"
              >
                Add Another Product
              </Button>
            </Row>
          </Form>
        </Col>
      ) : null}
    </React.Fragment>
  );
};

export default BundleForm;
