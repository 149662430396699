import {
  APP_LIST, APP_LIST_FAILURE, APP_LIST_SUCCESS,
  APP_DETAILS, APP_DETAILS_FAILURE, APP_DETAILS_SUCCESS,
  APP_DETAILS_UPDATE, APP_DETAILS_UPDATE_FAILURE, APP_DETAILS_UPDATE_SUCCESS,

  APP_QUESTION_DETAILS, APP_QUESTION_DETAILS_FAILURE, APP_QUESTION_DETAILS_SUCCESS,
  UPDATE_QUESTIONS, UPDATE_QUESTIONS_SUCCESS, UPDATE_QUESTIONS_FAILURE,

  PROFILE_DETAILS, PROFILE_DETAILS_SUCCESS, PROFILE_DETAILS_FAILURE,
  UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE,
  
  GET_TEAMMATES, GET_TEAMMATES_FAILURE, GET_TEAMMATES_SUCCESS,
  SEND_INVITE, SEND_INVITE_SUCCESS, SEND_INVITE_FAILURE,
  UPDATE_INVITE, UPDATE_INVITE_SUCCESS, UPDATE_INVITE_FAILURE,
  DELETE_TEAMMATE, DELETE_TEAMMATE_SUCCESS, DELETE_TEAMMATE_FAILURE,

  RESET_APP_FLAG,
} from "./actionType";

export const getAppList = ({ isUpdateMenuList }) => {
  return {
    type: APP_LIST,
    payload: { isUpdateMenuList },
  };
};

export const getAppListSuccess = (payload) => {
  return {
    type: APP_LIST_SUCCESS,
    payload,
  };
};

export const getAppListFailure = (error) => {
  return {
    type: APP_LIST_FAILURE,
    payload: error,
  };
};

export const getAppDetails = (appId) => {
  return {
    type: APP_DETAILS,
    payload: appId,
  };
};

export const getAppDetailsSuccess = (payload) => {
  return {
    type: APP_DETAILS_SUCCESS,
    payload,
  };
};

export const getAppDetailsFailure = (error) => {
  return {
    type: APP_DETAILS_FAILURE,
    payload: error,
  };
};

export const updateAppDetails = (appId, data) => {
  return {
    type: APP_DETAILS_UPDATE,
    payload: { appId, data },
  };
};

export const updateAppDetailsSuccess = (payload) => {
  return {
    type: APP_DETAILS_UPDATE_SUCCESS,
    payload,
  };
};

export const updateAppDetailsFailure = (error) => {
  return {
    type: APP_DETAILS_UPDATE_FAILURE,
    payload: error,
  };
};

export const getQuestionsDetails = (appId) => {
  return {
    type: APP_QUESTION_DETAILS,
    payload: appId,
  };
};

export const getQuestionsDetailsSuccess = (payload) => {
  return {
    type: APP_QUESTION_DETAILS_SUCCESS,
    payload,
  };
};

export const getQuestionsDetailsFailure = (error) => {
  return {
    type: APP_QUESTION_DETAILS_FAILURE,
    payload: error,
  };
};

export const updateQuestions = (appId, data) => {
  return {
    type: UPDATE_QUESTIONS,
    payload: { appId, data },
  };
};

export const updateQuestionsSuccess = (payload) => {
  return {
    type: UPDATE_QUESTIONS_SUCCESS,
    payload,
  };
};

export const updateQuestionsFailure = (error) => {
  return {
    type: UPDATE_QUESTIONS_FAILURE,
    payload: error,
  };
};

export const getUserProfile = () => {
  return {
    type: PROFILE_DETAILS,
    payload: {},
  };
};

export const getUserProfileSuccess = (payload) => {
  return {
    type: PROFILE_DETAILS_SUCCESS,
    payload,
  };
};

export const getUserProfileFailure = (error) => {
  return {
    type: PROFILE_DETAILS_FAILURE,
    payload: error,
  };
};

export const updateUserProfile = (data) => {
  return {
    type: UPDATE_PROFILE,
    payload: data,
  };
};

export const updateUserProfileSuccess = (payload) => {
  return {
    type: UPDATE_PROFILE_SUCCESS,
    payload,
  };
};

export const updateUserProfileFailure = (error) => {
  return {
    type: UPDATE_PROFILE_FAILURE,
    payload: error,
  };
};

export const getTeammates = () => {
  return {
    type: GET_TEAMMATES,
    payload: {},
  };
};

export const getTeammatesSuccess = (payload) => {
  return {
    type: GET_TEAMMATES_SUCCESS,
    payload,
  };
};

export const getTeammatesFailure = (error) => {
  return {
    type: GET_TEAMMATES_FAILURE,
    payload: error,
  };
};

export const sendInvite = (data) => {
  return {
    type: SEND_INVITE,
    payload: data,
  };
};

export const sendInviteSuccess = (payload) => {
  return {
    type: SEND_INVITE_SUCCESS,
    payload,
  };
};

export const sendInviteFailure = (error) => {
  return {
    type: SEND_INVITE_FAILURE,
    payload: error,
  };
};

export const updateInvite = (data) => {
  return {
    type: UPDATE_INVITE,
    payload: data,
  };
};

export const updateInviteSuccess = (payload) => {
  return {
    type: UPDATE_INVITE_SUCCESS,
    payload,
  };
};

export const updateInviteFailure = (error) => {
  return {
    type: UPDATE_INVITE_FAILURE,
    payload: error,
  };
};

export const deleteTeammate = (userId) => {
  return {
    type: DELETE_TEAMMATE,
    payload: userId,
  };
};

export const deleteTeammateSuccess = (userId) => {
  return {
    type: DELETE_TEAMMATE_SUCCESS,
    payload: userId,
  };
};

export const deleteTeammateFailure = (error) => {
  return {
    type: DELETE_TEAMMATE_FAILURE,
    payload: error,
  };
};

export const resetAppFlag = () => {
  return {
    type: RESET_APP_FLAG,
  };
};
