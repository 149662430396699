import React, { useEffect, useMemo, useState } from "react";
import Trash2 from "feather-icons-react/build/IconComponents/Trash2";
import {
  Container, Row, Col, Form, FormFeedback, Label, Input, Button, Spinner,
} from "reactstrap";
import { toast } from "react-toastify";

// location helpers
import { useLocation, useNavigate } from "react-router-dom";
import { getIDFromPath, getUrlParam } from "../../helpers/location-helper";

// common helpers
import Loader from "../../Components/Common/Loader";
import CopyTextToClipboard from "../../Components/Common/CopyTextToClipboard";
import { MenuItemEnums, RouteParams } from "../../common/enums";
import FormSelectOptions from "../../Components/Common/FormSelectOptions";
import {
  OptionsCampaignCategory,
  OptionsIntegrations,
} from "../../common/form-selector-values";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// redux
import { useSelector, useDispatch } from "react-redux";
import {
  getCampaignList, createCampaign, updateCampaign, removeCampaign, setCampaign, resetCampaignFlag,
} from "../../store/actions";
// APIs

const CampaignForm = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const campaignPagePath = `/${MenuItemEnums.campaign}/${MenuItemEnums.myCampaign}`;

  const [isUpdate, setIsUpdate] = useState(false);
  const [campType, setCampType] = useState("affiliate");

  const { campaigns, campaignInEdit, fetchingCampaigns, campaignList, appList } = useSelector(
    (state) => ({
      campaigns: state.Campaign.campaigns,
      campaignInEdit: state.Campaign.campaignInEdit,
      fetchingCampaigns: state.Campaign.loading,
      campaignList: state.Campaign.campaignList,
      appList: state.Setting.appList,
    })
  );

  const editableCamapignId = useMemo(
    () => getIDFromPath(location.pathname),
    [location.pathname]
  );

  useEffect(() => {
    if (getUrlParam(RouteParams.createCampaign, location.search) === "true") {
      setIsUpdate(false);
    } else if (getUrlParam(RouteParams.editCampaign, location.search) === "true") {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
  }, [location.search]);

  useEffect(() => {
    if (editableCamapignId === null) {
      dispatch(setCampaign({}));
      return;
    }
    if (!campaigns.length) return;
    const matchedCamapaign = campaignList[editableCamapignId];

    if (!matchedCamapaign) return;
    dispatch(setCampaign(matchedCamapaign));
  }, [campaigns, editableCamapignId]);

  useEffect(() => {
    if (campaignInEdit.added || campaignInEdit.updated || campaignInEdit.removed) {
      dispatch(getCampaignList());
      dispatch(resetCampaignFlag());
      if (campaignInEdit.added) {
        toast.success("Campaign created successfully");
      } else if (campaignInEdit.updated) {
        toast.success("Campaign updated successfully");
      } else {
        toast.success("Campaign deleted successfully");
      }
      navigate(campaignPagePath);
    }
  }, [campaignInEdit.added, campaignInEdit.updated, campaignInEdit.removed]);

  const submitCampaign = async () => {
    if (isUpdate) {
      dispatch(updateCampaign(editableCamapignId, validation.values));
    } else {
      dispatch(createCampaign(validation.values));
    }
  };

  const deleteCampaign = async () => {
    dispatch(removeCampaign(editableCamapignId));
  };

  const campaignTypeHandler = (event) => {
    if (event.target.value === "Affiliate") {
      setCampType("affiliate");
    } else {
      setCampType("publisher");
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      campaignType: campaignInEdit?.campaignType || campType,
      name: campaignInEdit?.name || "",
      category: campaignInEdit?.category || "",
      appId: campaignInEdit?.appId || "",
      affiliateIntegration: campaignInEdit?.affiliateIntegration || "",
      affiliateId: campaignInEdit?.affiliateId || "",
      partner: campaignInEdit?.partner || "",
      imageUrl: campaignInEdit?.imageUrl || "",
      smartLink: campaignInEdit?.smartLink || "",
      webInstructions: campaignInEdit?.webInstructions || "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter name"),
      category: Yup.string().required("Please Select Category"),
      appId: Yup.number().required("Please Enter App ID"),
      affiliateIntegration: Yup.string(),
      affiliateId: Yup.string().when("affiliateIntegration", {
        is: (affiliateIntegration) => (affiliateIntegration ? true : false),
        then: () => Yup.string().required("Please Enter Affiliate ID"),
      }),
      partner: Yup.string().required(
        "Please Enter Publisher or Influencer Name"
      ),
      imageUrl: Yup.string(),
    }),
    onSubmit: () => {
      submitCampaign();
    },
  });

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="ms-0">
            <Col className="campaign-content mime-form scroll-container">
              <>
                {fetchingCampaigns ? <Loader /> : null}
                {!isUpdate ? (
                  <div className="top-container create-content">
                    <div>
                      <h2 className="title">Ready to create a new campaign?</h2>
                      <p className="description">
                        Begin by filling out the form to the right.<br />
                        Just a few steps and you can share the<br />
                        link instantly!
                      </p>
                    </div>
                  </div>
                ) : null}
                {isUpdate ? (
                  <>
                    <div className="top-container">
                      <img
                        src={validation.values.imageUrl ?? ""}
                        className="logo-image"
                        alt=""
                      ></img>
                      <h2 className="title">
                        Your campaign is ready to go live!
                      </h2>
                      <p className="description">
                        Now share the following codes with your affiliate
                        <br />
                        or influencer.
                      </p>
                    </div>
                    <div>
                      <Label className="form-label">
                        Smart Link to share anywhere
                      </Label>
                      <Row className="campaign-input">
                        <Col>
                          <Input
                            name="smartLink"
                            type="text"
                            className="form-control"
                            value={validation.values.smartLink || ""}
                            placeholder="https://tilburyshadefinder.getmime.com/a/bella"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.smartLink &&
                              validation.errors.smartLink
                                ? true
                                : false
                            }
                          />
                          {validation.touched.smartLink &&
                          validation.errors.smartLink ? (
                            <FormFeedback type="invalid">
                              {validation.errors.smartLink}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <CopyTextToClipboard
                          copyText={validation.values.smartLink || ""}
                        />
                      </Row>
                    </div>
                    <div className="mt-20 integration-container">
                      <Label className="form-label">
                        For .com web integrations use the SDK -{" "}
                        <span>Documentation</span>
                      </Label>
                      <Row className="campaign-input">
                        <Col>
                          <Input
                            name="webInstructions"
                            type="textarea"
                            className="form-control"
                            value={validation.values.webInstructions || ""}
                            placeholder="<script type=“text/javascript” src=“//cdn.getmime.com/scripts/publishers.js>var a=‘bellahadid’;b=“charlotte-tilbury”</script>"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            invalid={
                              validation.touched.webInstructions &&
                              validation.errors.webInstructions
                                ? true
                                : false
                            }
                          />
                          {validation.touched.webInstructions &&
                          validation.errors.webInstructions ? (
                            <FormFeedback type="invalid">
                              {validation.errors.webInstructions}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <CopyTextToClipboard
                          copyText={validation.values.webInstructions || ""}
                        />
                      </Row>
                    </div>
                  </>
                ) : null}
              </>
            </Col>

            <Col xs={4} className="mime-form right-edit scroll-container">
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
                action="#"
              >
                <Row className="form-header">
                  <Col className="title">
                    {isUpdate ? "Edit Campaign" : "Add Campaign"}
                  </Col>

                  <Col className="action-button">
                    {isUpdate ? (
                      <Button
                        type="button"
                        className="icon-btn"
                        onClick={() => deleteCampaign()}
                      >
                        {campaignInEdit.loading ? (
                          <div className="spinner">
                            <Spinner size="sm" color="danger">
                              Loading...
                            </Spinner>
                          </div>
                        ) : (
                          <Trash2
                            size="24"
                            stroke="#d26767"
                            className="delete-icon"
                          />
                        )}
                      </Button>
                    ) : null}

                    <Button
                      type="submit"
                      className="form-control btn-small btn-text-primary"
                      disabled={campaignInEdit.loading}
                    >
                      {campaignInEdit.loading ? (
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      ) : null}
                      Save
                    </Button>
                  </Col>
                </Row>
                <Row className="mt-40">
                  <Col>
                    <Label className="form-label">Campaign Type*</Label>
                    <Input
                      type="button"
                      className={
                        "form-control btn" +
                        (campType === "affiliate" ? " active" : "")
                      }
                      value={"Affiliate"}
                      onClick={campaignTypeHandler}
                    />
                  </Col>
                  <Col className="pl-10">
                    <Label className="form-label hidden">
                      Campaign Type*
                    </Label>
                    <Input
                      type="button"
                      className={
                        "form-control btn" +
                        (campType === "publisher" ? " active" : "")
                      }
                      value={"Publisher"}
                      onClick={campaignTypeHandler}
                    />
                  </Col>
                </Row>
                <Row className="mt-20">
                  <Label className="form-label">Campaign Name*</Label>
                  <Input
                    name="name"
                    type="text"
                    className="form-control"
                    value={validation.values.name || ""}
                    placeholder="Write the title here"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.name && validation.errors.name
                        ? true
                        : false
                    }
                  />
                  {validation.touched.name && validation.errors.name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.name}
                    </FormFeedback>
                  ) : null}
                </Row>
                <Row className="mt-20">
                  <Label className="form-label">Category*</Label>
                  <select
                    name="category"
                    value={validation.values.category}
                    onChange={validation.handleChange}
                    className={
                      "form-select" +
                      (validation.values.category ? "" : " empty") +
                      (validation.touched.category &&
                      validation.errors.category ? " is-invalid" : "")
                    }
                  >
                    <FormSelectOptions
                      categories={OptionsCampaignCategory}
                      defaultOption={validation.values.category ? null : {}}
                    />
                  </select>
                  {validation.touched.category &&
                  validation.errors.category ? (
                    <FormFeedback type="invalid">
                      {validation.errors.category}
                    </FormFeedback>
                  ) : null}
                </Row>
                <Row className="mt-20">
                  <Label className="form-label">App*</Label>
                  <select
                    name="appId"
                    value={validation.values.appId}
                    onChange={(e) => {
                      const appId = +e.target.value;
                      validation.handleChange(e);
                      validation.setFieldValue("appId", appId, true);
                    }}
                    className={
                      "form-select" +
                      (validation.values.appId ? "" : " empty") +
                      (validation.touched.appId && validation.errors.appId ? " is-invalid" : "") +
                        (appList.length === 0 ? " loading" : "")
                      }
                      disabled={appList.length === 0}
                    >
                    <FormSelectOptions
                      categories={appList}
                      defaultOptionLabel={
                        appList.length === 0 ? "No data found." : ""
                      }
                      defaultOption={validation.values.appId ? null : {}}
                    />
                  </select>
                  {validation.touched.appId && validation.errors.appId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.appId}
                    </FormFeedback>
                  ) : null}
                </Row>
                <Row className="mt-20">
                  <Label className="form-label">Affiliate Integration</Label>
                  <select
                    name="affiliateIntegration"
                    value={validation.values.affiliateIntegration}
                    onChange={validation.handleChange}
                    className={
                      "form-select" +
                      (validation.values.affiliateIntegration
                        ? ""
                        : " empty") +
                      (validation.touched.affiliateIntegration &&
                      validation.errors.affiliateIntegration ? " is-invalid" : "")
                    }
                  >
                    <FormSelectOptions
                      categories={OptionsIntegrations}
                      defaultOption={
                        validation.values.affiliateIntegration ? null : {}
                      }
                      defaultOptionLabel="Please Select - Optional"
                    />
                  </select>
                  {validation.touched.affiliateIntegration &&
                  validation.errors.affiliateIntegration ? (
                    <FormFeedback type="invalid">
                      {validation.errors.affiliateIntegration}
                    </FormFeedback>
                  ) : null}
                </Row>
                <Row className="mt-20">
                  <Label className="form-label">Affiliate ID</Label>
                  <Input
                    name="affiliateId"
                    type="text"
                    className="form-control"
                    value={validation.values.affiliateId || ""}
                    placeholder="Type affiliate ID here"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.affiliateId &&
                      validation.errors.affiliateId
                        ? true
                        : false
                    }
                  />
                  {validation.touched.affiliateId &&
                  validation.errors.affiliateId ? (
                    <FormFeedback type="invalid">
                      {validation.errors.affiliateId}
                    </FormFeedback>
                  ) : null}
                </Row>
                <Row className="mt-20">
                  <Label className="form-label">Partner Name*</Label>
                  <Input
                    name="partner"
                    type="text"
                    className="form-control"
                    value={validation.values.partner || ""}
                    placeholder="Type publisher or influencer name here"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.partner && validation.errors.partner
                        ? true
                        : false
                    }
                  />
                  {validation.touched.partner && validation.errors.partner ? (
                    <FormFeedback type="invalid">
                      {validation.errors.partner}
                    </FormFeedback>
                  ) : null}
                </Row>
                <Row className="mt-20">
                  <Label className="form-label">
                    Profile Image or Logo URL
                  </Label>
                  <Input
                    name="imageUrl"
                    type="text"
                    className="form-control"
                    value={validation.values.imageUrl || ""}
                    placeholder="https://www.domain.com/image.jpg"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.imageUrl &&
                      validation.errors.imageUrl
                        ? true
                        : false
                    }
                  />
                  {validation.touched.imageUrl &&
                  validation.errors.imageUrl ? (
                    <FormFeedback type="invalid">
                      {validation.errors.imageUrl}
                    </FormFeedback>
                  ) : null}
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CampaignForm;
