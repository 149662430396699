import {
  CHANGE_PASSWORD, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_ERROR,
} from "./actionTypes";

const initialState = {
  message: "",
  loading: false,
  success: false,
  error: false,
};

const changePassword = (state = initialState, action) => {
  switch (action.type) {
    case CHANGE_PASSWORD:
      state = { ...state, message: "", loading: true, success: false, error: false, };
      break;
    case CHANGE_PASSWORD_SUCCESS:
      state = { ...state, message: action.payload, success: true, loading: false, };
      break;
    case CHANGE_PASSWORD_ERROR:
      state = { ...state, message: action.payload, loading: false, error: true };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default changePassword;
