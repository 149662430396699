import { useCallback, useEffect, useState } from "react";
import { getLoggedinUser } from "../../helpers/auth-user";

const useProfile = () => {
  const userProfileSession = getLoggedinUser();
  const accessToken =
    userProfileSession && userProfileSession["jwtAccessToken"];
  const [token, setToken] = useState("");
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState(null);

  const verifyAuthorization = useCallback(async () => {
    setUserProfile(userProfileSession ? userProfileSession : null);
    setToken(accessToken);
    setLoading(accessToken ? false : true);
  }, [userProfileSession, accessToken]);

  useEffect(() => {
    verifyAuthorization();
  }, []);

  return { userProfile, loading, token };
};

export { useProfile };
