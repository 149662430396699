import {
  CLEAR_ERROR,
  MISSED_CONNECTION_LIST, MISSED_CONNECTION_LIST_SUCCESS, MISSED_CONNECTION_LIST_FAILURE,
  RETRIEVE_BRANDS, RETRIEVE_BRANDS_SUCCESS, RETRIEVE_BRANDS_FAILED,
  RETRIEVE_BRAND_PRODUCTS, RETRIEVE_BRAND_PRODUCTS_SUCCESS, RETRIEVE_BRAND_PRODUCTS_FAILED,
  SEND_EMAIL, SEND_EMAIL_SUCCESS, SEND_EMAIL_FAILED,
} from "./actionType";

export const getMissedConnectionList = () => {
  return {
    type: MISSED_CONNECTION_LIST,
  };
};

export const getMissedConnectionsSuccess = (connections) => {
  return {
    type: MISSED_CONNECTION_LIST_SUCCESS,
    payload: connections,
  };
};

export const getMissedConnectionsFailure = (error) => {
  return {
    type: MISSED_CONNECTION_LIST_FAILURE,
    payload: error,
  };
};

export const sendMail = (value) => {
  return {
    type: SEND_EMAIL,
    payload: value,
  };
};

export const sendMailSuccess = (connections) => {
  return {
    type: SEND_EMAIL_SUCCESS,
    payload: connections,
  };
};

export const sendMailFailed = (error) => {
  return {
    type: SEND_EMAIL_FAILED,
    payload: error,
  };
};

export const clearError = (error) => {
  return {
    type: CLEAR_ERROR,
    payload: error,
  };
};

export const retrieveBrands = () => {
  return {
    type: RETRIEVE_BRANDS,
  };
};

export const retrieveBrandsSuccess = (connections) => {
  return {
    type: RETRIEVE_BRANDS_SUCCESS,
    payload: connections,
  };
};

export const retrieveBrandsFailed = (error) => {
  return {
    type: RETRIEVE_BRANDS_FAILED,
    payload: error,
  };
};

export const retrieveBrandProducts = (brandId) => {
  return {
    type: RETRIEVE_BRAND_PRODUCTS,
    payload: brandId,
  };
};

export const retrieveBrandProductsSuccess = (connections) => {
  return {
    type: RETRIEVE_BRAND_PRODUCTS_SUCCESS,
    payload: connections,
  };
};

export const retrieveBrandProductsFailed = (error) => {
  return {
    type: RETRIEVE_BRAND_PRODUCTS_FAILED,
    payload: error,
  };
};
