import React, { useEffect } from "react";
import Trash2 from "feather-icons-react/build/IconComponents/Trash2";
import { Button, Col, Container, Row, Spinner, Table } from "reactstrap";
import { toast } from "react-toastify";

// common helpers
import PageHeader from "../../../Components/Common/PageHeader";
import ErrorController from "../../../Components/Common/ErrorController";
import Loader from "../../../Components/Common/Loader";

// redux
import { useDispatch, useSelector } from "react-redux";
import { returnsStateSelector } from "../../../store/AiTraining/returns/returnSelector";
import {
  getReturnList, removeReturn, resetReturnFlag,
} from "../../../store/AiTraining/returns/actions";

import ReturnForm from "./ReturnForm";

const Returns = () => {
  const dispatch = useDispatch();
  const {
    returns,
    returnInEdit,
    total: returnCounts,
    loading,
    errorMsg,
  } = useSelector(returnsStateSelector);

  useEffect(() => {
    if (returnInEdit.removed) {
      dispatch(getReturnList());
      dispatch(resetReturnFlag());
      toast.success("Return deleted successfully");
    }
  }, [returnInEdit.removed]);

  const deleteReturn = async (returnId) => {
    if (!returnId) return;
    dispatch(removeReturn(returnId));
  };

  document.title = "AI Training | mime"

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container returns-container main-container">
            <PageHeader
              title="Showing all Returns"
              count={returnCounts}
              subtitleSuffix="Product Groups"
            />

            {loading ? <Loader /> : null}

            {!loading && returnCounts ? (
              <Row>
                <Col className="product-list">
                  <Table className="align-middle table-nowrap mb-0">
                    <tbody>
                      {returns &&
                        returns.map((item, idx) => (
                          <tr className="product-item" key={idx}>
                            <td className="product-item-image">
                              <img src={item.imageUrl ?? ""} alt="" />
                            </td>
                            <td className="product-item-content">
                              <div className="title">{item.mask_email}</div>
                              <div className="sub-title">{item.reason}</div>
                            </td>
                            <td className="fit-content">
                              <Button
                                type="button"
                                className="icon-btn"
                                onClick={() => deleteReturn(item.id)}
                              >
                                {returnInEdit.loading ? (
                                  <div className="spinner">
                                    <Spinner size="sm" color="danger">
                                      Loading...
                                    </Spinner>
                                  </div>
                                ) : (
                                  <Trash2
                                    size="24"
                                    stroke="#d26767"
                                    className="delete-icon"
                                  />
                                )}
                              </Button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            ) : errorMsg ? (
              <ErrorController messsage={errorMsg} />
            ) : !loading && !returnCounts ? (
              <span>No data found</span>
            ) : null}
          </Col>
          <ReturnForm />
        </Row>
      </Container>
    </div>
  );
};

export default Returns;
