import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Account Redux states
import { REGISTER_USER } from "./actionTypes";
import { registerUserSuccessful, registerUserFailed } from "./actions";

// Include Both Helper File with needed methods
import {
	getIP, postRegister,
} from "../../../helpers/backend_helper";

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
	try {
		const ipInfo = yield call(getIP, {})
		user.countryCode = ipInfo.country_code
		user.tosAcceptedByIp = ipInfo.ip
		user.consentOptIn = true

		const response = yield call(postRegister, user)
		yield put(registerUserSuccessful(response));
	} catch (error) {
		yield put(registerUserFailed(error));
	}
}

export function* watchUserRegister() {
	yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
	yield all([fork(watchUserRegister)]);
}

export default accountSaga;
