import { menuItems as menuData } from "../Layouts/MimeLayout/MenuData"
import { MenuItemEnums } from "../common/enums"


export const getIDFromPath = (pathname) => {
  const paths = pathname.split('/')
  const value = paths[paths.length - 1]
  const number = Number(value)
  if (number === -1 || isNaN(number))
    return null
  return number
}

export const getUrlParam = (key, search) => {
  return new URLSearchParams(search).get(key)
}

export const getMenuStatus = (location, menuItems) => {
  const pathname = location.pathname
 
  const menuStatus = { menu: MenuItemEnums.dashboard, subMenu: menuData()[0].subItems[0] }
  if (pathname == null || pathname == '') return menuStatus

  const paths = pathname.split('/').filter(Boolean)
  if (paths.length < 2) return menuStatus

  menuStatus.menu = paths[0]

  if (paths.length == 2) {
    for (const item of menuItems) {
      if (paths[0] == item.id) {
        for (const subItem of item.subItems) {
          if (paths[1] == subItem.id)
            return { ...menuStatus, subMenu: subItem }
        }
      }
    }
  } else {
    for (const item of menuItems) {
      if (paths[0] == item.id) {
        for (const subItem of item.subItems) {
          if (paths[1] == subItem.id) {
            for (const childItem of subItem.childItems) {
              if (paths[2] == childItem.id)
                return { ...menuStatus, subMenu: childItem }
            }
          }
        }
      }
    }
  }

  return menuStatus
}
