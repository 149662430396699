import React, { useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { Collapse, Container, Input } from "reactstrap";
import withRouter from "../../Components/Common/withRouter";

// redux
import { useSelector, useDispatch } from "react-redux";
// i18n
import { withTranslation } from "react-i18next";
// SimpleBar
import SimpleBar from "simplebar-react";

// Import Data
import VerticalLayout from "../VerticalLayouts";
import { setActiveMenu, updateMenuStatus } from "../../store/menu/actions";
import { retrieveMenuData } from "./MenuData";

import { pImage } from "../../helpers/utils";
import { MenuItemEnums } from "../../common/enums";
import { getMenuStatus } from "../../helpers/location-helper";
import { Lock } from "feather-icons-react/build/IconComponents";

const MimeLayout = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeMenu, activeSubMenu, menuItems } = useSelector((state) => ({
    activeMenu: state.Menu.menuStatus.menu,
    activeSubMenu: state.Menu.menuStatus.subMenu.id,
    menuItems: state.Menu.menuItems,
  }));
  const [selectedMenu, setSelectedMenu] = useState(MenuItemEnums.dashboard);
  const [expandedSubMenus, setExpandedSubMenus] = useState({});
  const userData = JSON.parse(localStorage.getItem("authUser"));
  const isAdmin = userData.role === "customer";
  const clickMenu = (menu) => {
    dispatch(setActiveMenu(menu.id));
    setSelectedMenu(menu.id);
  };

  function clickSubMenu(menu, subMenu) {
    var menuStatus = { menu: menu.id, subMenu: subMenu };
    // Menus is container of links
    if (subMenu.childItems) {
      const status = !(expandedSubMenus[subMenu.id] === true);
      setExpandedSubMenus({
        ...expandedSubMenus,
        [subMenu.id]: status,
      });
    }
    // Menu is button
    else if (subMenu.button === true) {
      // console.log(subMenu);
    }
    // Otherwise menu is just link
    else {
      menuStatus = { ...menuStatus, subMenu: subMenu };
    }
    // Update Menu Status
    dispatch(updateMenuStatus(menuStatus));
  }

  // We are updating menu data, but if user enter page url directly, menu status get from path
  useEffect(() => {
    const menuStatus = getMenuStatus(location, menuItems);
    setSelectedMenu(menuStatus.menu);
    if (menuStatus.menu == activeMenu && menuStatus.subMenu.id == activeSubMenu)
      return;

    dispatch(updateMenuStatus(menuStatus));
  }, [dispatch, location.pathname]);

  // When user click each menu, retrieve menu data and show menu with dynamic data
  useEffect(() => {
    retrieveMenuData(selectedMenu).forEach((action) => {
      dispatch(action);
    });
  }, [dispatch, selectedMenu]);

  // Resize sidebar
  const [isMenu, setIsMenu] = useState("twocolumn");
  const windowResizeHover = () => {
    var windowSize = document.documentElement.clientWidth;
    if (windowSize < 767) {
      document.documentElement.setAttribute("data-layout", "vertical");
      setIsMenu("vertical");
    } else {
      document.documentElement.setAttribute("data-layout", "twocolumn");
      setIsMenu("twocolumn");
    }
  };

  useEffect(function setupListener() {
    if (props.layoutType === "twocolumn") {
      window.addEventListener("resize", windowResizeHover);

      // remove classname when component will unmount
      return function cleanupListener() {
        window.removeEventListener("resize", windowResizeHover);
      };
    }
  });

  const [value, setValue] = useState("");
  const onChangeData = (value) => {
    setValue(value);
  };

  return (
    <React.Fragment>
      {isMenu === "twocolumn" ? (
        <>
          {/* {activeMenu === "inventory" ? (
            <div className="product-searchbar">
              <Input
                type="text"
                className="form-control"
                placeholder="Search..."
                id="search-options"
                value={value}
                onChange={(e) => {
                  onChangeData(e.target.value);
                }}
              />
            </div>
          ) : null} */}
          <div id="scrollbar">
            <Container fluid>
              <div id="two-column-menu">
                <div className="twocolumn-iconview">
                  <Link to="/" className="logo">
                    <img
                      src={pImage("icons/icon-mime.png")}
                      alt=""
                      width="22"
                    />
                  </Link>
                  <SimpleBar className="vertical-center">
                    {menuItems.map((item, key) => (
                      <React.Fragment key={key}>
                        <Link
                          to={
                            item.id !== "settings"
                            ? item.subItems[0]?.link
                            : isAdmin
                            ? item.subItems[1]?.childItems?.[0]?.link
                            : item.subItems[1]?.childItems?.[0]?.link 
                          }
                        >
                          <li
                            data-bs-toggle="collapse"
                            onClick={() => {
                              clickMenu(item);
                            }}
                            className={
                              "nav-icon" +
                              (activeMenu === item.id ? " active" : "")
                            }
                          >
                            <img src={pImage(item.icon)} width={20} alt="" />
                          </li>
                        </Link>
                      </React.Fragment>
                    ))}
                  </SimpleBar>
                </div>
              </div>
              <SimpleBar id="navbar-nav" className="navbar-nav">
                {menuItems.map((item, key) => (
                  <React.Fragment key={key}>
                    {item.subItems ? (
                      <li className="nav-item">
                        <Collapse
                          id={item.id}
                          className="menu-dropdown"
                          isOpen={selectedMenu === item.id}
                        >
                          <ul className="nav nav-sm flex-column test">
                            {/* subItms  */}
                            {item.subItems &&
                              (item.subItems || []).map((subItem, key) => (
                                <React.Fragment key={key}>
                                  {subItem.childItems ? (
                                    <li className="nav-item">
                                      {"isClickable" in subItem &&
                                      subItem?.isClickable ? (
                                        <Link
                                          to={subItem.link || ""}
                                          data-bs-toggle="collapse"
                                          onClick={() => {
                                            clickSubMenu(item, subItem);
                                          }}
                                          className="nav-link"
                                        >
                                          {subItem.label}
                                        </Link>
                                      ) : (
                                        <div className="nav-link">
                                          {subItem.label}
                                        </div>
                                      )}

                                      <Collapse
                                        id={item.id}
                                        className="menu-dropdown"
                                        isOpen={
                                          true
                                          // expandedSubMenus[subItem.id] === true
                                        }
                                      >
                                        <ul className="nav nav-sm flex-column">
                                          {/* child subItms  */}
                                          {subItem.childItems.map(
                                            (childItem, key) => {
                                              return (
                                                <li
                                                  className={"nav-item"}
                                                  key={key}
                                                >
                                                  {childItem.link ? (
                                                    <Link
                                                      to={
                                                        childItem.link
                                                          ? childItem.link
                                                          : "/#"
                                                      }
                                                      onClick={() => {
                                                        clickSubMenu(
                                                          item,
                                                          childItem
                                                        );
                                                      }}
                                                      className={
                                                        "nav-link private-item" +
                                                        (childItem?.isChildSubItem
                                                          ? " sub-item"
                                                          : "") +
                                                        (activeMenu ===
                                                          item.id &&
                                                        activeSubMenu ===
                                                          childItem.id
                                                          ? " active"
                                                          : "")
                                                      }
                                                    >
                                                      {childItem.label}
                                                      {childItem?.isPrivate ? (
                                                        <Lock />
                                                      ) : null}
                                                    </Link>
                                                  ) : (
                                                    <div className="nav-link">
                                                      {childItem.label}
                                                    </div>
                                                  )}
                                                </li>
                                              );
                                            }
                                          )}
                                        </ul>
                                      </Collapse>
                                    </li>
                                  ) : (
                                    <li
                                      className={
                                        "nav-item" +
                                        (activeMenu === item.id &&
                                          activeSubMenu === subItem.id)
                                          ? " active"
                                          : ""
                                      }
                                    >
                                      {subItem.button ? (
                                        <Link
                                          className="nav-link nav-button"
                                          onClick={() => {
                                            clickSubMenu(item, subItem);
                                          }}
                                          to={subItem?.link ?? "#"}
                                        >
                                          {subItem.label}
                                        </Link>
                                      ) : (
                                        <Link
                                          className="nav-link"
                                          to={
                                            subItem.link ? subItem.link : "/#"
                                          }
                                          onClick={() => {
                                            clickSubMenu(item, subItem);
                                          }}
                                        >
                                          {subItem.label}
                                        </Link>
                                      )}
                                    </li>
                                  )}
                                </React.Fragment>
                              ))}
                          </ul>
                        </Collapse>
                      </li>
                    ) : null}
                  </React.Fragment>
                ))}
              </SimpleBar>
            </Container>
          </div>
        </>
      ) : (
        <SimpleBar id="scrollbar" className="h-100">
          <Container fluid>
            <div id="two-column-menu"></div>
            <ul className="navbar-nav" id="navbar-nav">
              <VerticalLayout />
            </ul>
          </Container>
        </SimpleBar>
      )}
    </React.Fragment>
  );
};

MimeLayout.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(MimeLayout));
