import React, { useState, useEffect } from "react";

import { Mail, Check } from "feather-icons-react/build/IconComponents";
import { Button, Col, Container, Row } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";

// utils
import { calculateTimeFromNow } from "../../helpers/time_helper";
import { getUrlParam, getIDFromPath } from "../../helpers/location-helper";
import TimeInterval from "../../Components/Hooks/UseIntervalHook";

import { MenuItemEnums, RouteParams } from "../../common/enums";
import Loader from "../../Components/Common/Loader";
import ErrorController from "../../Components/Common/ErrorController";
import PageHeader from "../../Components/Common/PageHeader";

// redux
import { useSelector } from "react-redux";

import MissedConnectionForm from "./MissedConnectionForm";

const MissedConnections = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const userID = getIDFromPath(location.pathname);
  const [showForm, setShowForm] = useState(false);

  const { connections, fetchingConnections, errorMessage } = useSelector(
    (state) => ({
      connections: state.Audience.connections,
      errorMessage: state.Audience.errorMsg,
      fetchingConnections: state.Audience.loading,
    })
  );

  useEffect(() => {
    if (getUrlParam(RouteParams.createConnection, location.search) === "true") {
      setShowForm(true);
    } else {
      setShowForm(false);
    }
  }, [location.search]);

  const handleConnection = (selectedUser) => {
    if (selectedUser.userId === userID) return;

    navigate(
      `/${MenuItemEnums.audience}/${RouteParams.opportunities}/${RouteParams.missedConnections}/${selectedUser.userId}?${RouteParams.createConnection}=true&${RouteParams.userId}=${selectedUser.userId}`
    );
  };

  const handleOnError = (e) => {
    e.target.src = "/sample/avatar.png";
  };

  document.title = "Audience | mime";

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container connection-container">
            <div className="header">
              <PageHeader
                title={`${connections.length || 0} shoppers need your help!`}
              />
              <Col className="active-duration">
                <TimeInterval prefix="Last updated" />
              </Col>
            </div>

            {fetchingConnections ? <Loader /> : null}

            {!fetchingConnections && connections.length ? (
              <Row>
                <Col className="connection-list">
                  {connections.map((user, idx) => (
                    <div className="connection-card" key={idx}>
                      <img
                        src={user.imageUrl}
                        alt="user-dp"
                        onError={handleOnError}
                      />
                      <Row className="title">
                        {/* {user.mask_email} {`(${user.countryCode})`} */}
                      </Row>
                      <Row className="connection-card-footer">
                        <Col xs={9}>
                          <Button className="connection-btn">
                            {user.type}
                          </Button>
                          <Col className="duration">
                            {calculateTimeFromNow(+user.timestamp)}
                          </Col>
                        </Col>
                        <Button
                          xs={1}
                          onClick={() => handleConnection(user)}
                          className={`icon-container ${
                            userID === user.userId ? "check-mark" : ""
                          }`}
                        >
                          {userID === user.userId ? <Check /> : <Mail />}
                        </Button>
                      </Row>
                    </div>
                  ))}
                </Col>
              </Row>
            ) : errorMessage ? (
              <ErrorController messsage={errorMessage} />
            ) : !fetchingConnections && !connections.length ? (
              <span>No Data Found</span>
            ) : null}
          </Col>
          {showForm ? <MissedConnectionForm /> : null}
        </Row>
      </Container>
    </div>
  );
};

export default MissedConnections;
