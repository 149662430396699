import React, {memo} from "react";
import classnames from "classnames";
import { usePagination, DOTS } from "./usePagination";
import LeftSIdeArrrowIcon from "../../assets/svgs/LeftSIdeArrrowIcon";
import RightSideArrowIcon from "../../assets/svgs/RightSideArrowIcon";

const Paginatior = (props) => {
  const {
    onPageChange,
    totalCount = 0,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const currentResults =
    currentPage * pageSize < totalCount ? currentPage * pageSize : totalCount;

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <div className="paginator">
      <div className="paginator-results">
        showing <span>{currentResults}</span> of <span>{totalCount}</span>{" "}
        results
      </div>
      <ul
        className={classnames("pagination-container", {
          [className]: className,
        })}
      >
        {/* Left navigation arrow */}
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === 1,
          })}
          onClick={onPrevious}
        >
          <LeftSIdeArrrowIcon />
        </li>
        {paginationRange.map((pageNumber, idx) => {
          // If the pageItem is a DOT, render the DOTS unicode character
          if (pageNumber === "DOTS") {
            return (
              <li className="pagination-item dots" key={`${pageNumber}-${idx}`}>
                &#8230;
              </li>
            );
          }

          // Render our Page Pills
          return (
            <li
              className={classnames("pagination-item", {
                selected: pageNumber === currentPage,
              })}
              onClick={() => onPageChange(pageNumber)}
              key={`${pageNumber}-${idx}`}
            >
              {pageNumber}
            </li>
          );
        })}
        {/*  Right Navigation arrow */}
        <li
          className={classnames("pagination-item", {
            disabled: currentPage === lastPage,
          })}
          onClick={onNext}
        >
          <RightSideArrowIcon />
        </li>
      </ul>
    </div>
  );
};

export default memo(Paginatior);
