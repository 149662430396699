import {
  DATE_RANGE,
  GET_REVENUES,
  GET_REVENUES_SUCCESS,
  GET_REVENUES_FAILURE,
  GET_UNDERTONES,
  GET_UNDERTONES_SUCCESS,
  GET_UNDERTONES_FAILURE,
  GET_COLOR_PREFERENCES,
  GET_COLOR_PREFERENCES_SUCCESS,
  GET_COLOR_PREFERENCES_FAILURE,
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAILURE,
  GET_RECENT_SHOPPERS,
  GET_RECENT_SHOPPERS_SUCCESS,
  GET_RECENT_SHOPPERS_FAILURE,
  GET_IN_STORE_ENGAGEMENT,
  GET_IN_STORE_ENGAGEMENT_SUCCESS,
  GET_IN_STORE_ENGAGEMENT_FAILURE,
} from "./actionTypes";
import { calculatePercentValue } from "../../helpers/utils";
import lookup from "country-code-lookup";

const dateNow = new Date();
const yearMM = dateNow.getFullYear() - 2000;
const startTime = `01-01-${yearMM}`;
const endTime = `${dateNow.getMonth() + 1}-${dateNow.getDate()}-${yearMM}`;

const initialState = {
  dateRange: [startTime, endTime],

  revenues: { data: [], loading: false },
  undertones: { data: [], loading: false },
  colorPreferences: { data: [], loading: false },
  countries: { data: [], loading: false },
  shoppers: { data: [], loading: false },
  inStoreEngagement: { data: [], loading: false },

  error: false,
  errorMsg: "",
};

function makeUndertoneData(response) {
  if (!response.success) return [];

  const topUndertones = {};
  let totalCount = 0;

  for (const item of response.data) {
    const undertoneKey = item.undertone;
    totalCount += +item.total;

    if (undertoneKey in topUndertones) {
      topUndertones[undertoneKey].total += +item.total;
    } else {
      topUndertones[undertoneKey] = {
        total: +item.total,
        order: item.order,
        color: item.hexColor,
        name: undertoneKey,
      };
    }
  }

  const undertoneValues = Object.values(topUndertones);
  const mappedUndertones = undertoneValues
    .map((item) => ({
      ...item,
      percent: calculatePercentValue(item?.total ?? 0, totalCount),
    }))
    .sort((a, b) => b.total - a.total);

  return mappedUndertones;
}

function makeColorPreferencesData(response) {
  if (!response.success) return [];

  const topColors = {};
  let totalCount = 0;

  for (const item of response.data) {
    const colorKey = item.preference;
    totalCount += +item.count;

    if (colorKey in topColors) {
      topColors[colorKey].value += +item.count;
    } else {
      topColors[colorKey] = {
        value: +item.count,
        name: colorKey,
      };
    }
  }

  const data = Object.values(topColors).sort((a, b) => a.count - b.count);

  return data;
}

function makeCountriesData(response) {
  if (!response.success) return [];

  const topCountries = {};
  let totalCount = 0;

  for (const item of response.data) {
    const countryKey = item.countryCode;
    totalCount += +item.users;

    if (countryKey in topCountries) {
      topCountries[countryKey].users += +item.users;
    } else {
      const country = lookup.byIso(countryKey)?.country ?? countryKey;
      topCountries[countryKey] = {
        users: +item.users,
        countryCode: countryKey,
        country: country,
      };
    }
  }

  const data = Object.values(topCountries)
    .sort((a, b) => b.users - a.users)
    .slice(0, 5);

  return data;
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case DATE_RANGE: {
      return {
        ...state,
        dateRange: action.payload || initialState.dateRange,
      };
    }

    case GET_REVENUES:
      return {
        ...state,
        error: false,
        errorMsg: "",
        revenues: { data: [], loading: true },
      };
    case GET_REVENUES_SUCCESS:
      return {
        ...state,
        revenues: { ...action.payload, loading: false },
      };
    case GET_REVENUES_FAILURE:
      return {
        ...state,
        error: true,
        errorMsg: action.payload,
        revenues: { ...state.revenues, loading: false },
      };

    case GET_UNDERTONES:
      return {
        ...state,
        error: false,
        errorMsg: "",
        undertones: { data: [], loading: true },
      };
    case GET_UNDERTONES_SUCCESS:
      return {
        ...state,
        undertones: { data: makeUndertoneData(action.payload), loading: false },
      };
    case GET_UNDERTONES_FAILURE:
      return {
        ...state,
        error: true,
        errorMsg: action.payload,
        undertones: { ...state.undertones, loading: false },
      };

    case GET_COLOR_PREFERENCES:
      return {
        ...state,
        error: false,
        errorMsg: "",
        colorPreferences: { data: [], loading: true },
      };
    case GET_COLOR_PREFERENCES_SUCCESS:
      return {
        ...state,
        colorPreferences: {
          data: makeColorPreferencesData(action.payload),
          loading: false,
        },
      };
    case GET_COLOR_PREFERENCES_FAILURE:
      return {
        ...state,
        error: true,
        errorMsg: action.payload,
        colorPreferences: { ...state.colorPreferences, loading: false },
      };

    case GET_COUNTRIES:
      return {
        ...state,
        error: false,
        errorMsg: "",
        countries: { data: [], loading: true },
      };
    case GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: { data: makeCountriesData(action.payload), loading: false },
      };
    case GET_COUNTRIES_FAILURE:
      return {
        ...state,
        error: true,
        errorMsg: action.payload,
        countries: { ...state.countries, loading: false },
      };

    case GET_RECENT_SHOPPERS:
      return {
        ...state,
        error: false,
        errorMsg: "",
        shoppers: { data: [], loading: true },
      };
    case GET_RECENT_SHOPPERS_SUCCESS:
      return {
        ...state,
        shoppers: { data: action.payload.users, loading: false },
      };
    case GET_RECENT_SHOPPERS_FAILURE:
      return {
        ...state,
        error: true,
        errorMsg: action.payload,
        shoppers: { ...state.shoppers, loading: false },
      };

    case GET_IN_STORE_ENGAGEMENT:
      return {
        ...state,
        error: false,
        errorMsg: "",
        inStoreEngagement: { data: [], loading: true },
      };
    case GET_IN_STORE_ENGAGEMENT_SUCCESS:
      return {
        ...state,
        inStoreEngagement: { data: action.payload.data, loading: false },
      };
    case GET_IN_STORE_ENGAGEMENT_FAILURE:
      return {
        ...state,
        error: true,
        errorMsg: action.payload,
        inStoreEngagement: { ...state.inStoreEngagement, loading: false },
      };

    default:
      return { ...state };
  }
};

export default dashboard;
