import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  BUNDLE_LIST, BUNDLE_DETAILS, BUNDLE_ADD, BUNDLE_UPDATE, BUNDLE_REMOVE, BUNDLE_PRODUCT_REMOVE,
} from "./actionTypes";

import {
  getBundlesSuccess, getBundlesFailure, bundlesUpdated,
  getBundleDetailsSuccess, getBundleDetailsFailure,
  addBundleSuccess, addBundleFailure,
  updateBundleSuccess, updateBundleFailure,
  removeBundleSuccess, removeBundleFailure, removeBundleProduct, bundleProductRemoveSuccess, bundleProductRemoveFailure,
} from "../../actions";

// Include Both Helper File with needed methods
import {
  getBundles, getBundle, postBundle, updateBundle, deleteBundle, deleteBundleProduct,
} from "../../../helpers/backend_helper";

function* retrieveBundles() {
  try {
    const response = yield call(getBundles, {});
    yield put(getBundlesSuccess(response));
    yield put(bundlesUpdated(response));
  } catch (error) {
    yield put(getBundlesFailure(error));
  }
}

function* retrieveBundle({ payload: { bundleID } }) {
  try {
    const response = yield call(getBundle, bundleID);
    yield put(getBundleDetailsSuccess(response));
  } catch (error) {
    yield put(getBundleDetailsFailure(error));
  }
}

function* addBundle({ payload: data }) {
  try {
    const response = yield call(postBundle, data);
    yield put(addBundleSuccess(response));
  } catch (error) {
    yield put(addBundleFailure(error));
  }
}

function* modifyBundle({ payload: {bundleID, data} }) {
  try {
    const response = yield call(updateBundle, bundleID, data);
    yield put(updateBundleSuccess(response));
  } catch (error) {
    yield put(updateBundleFailure(error));
  }
}

function* removeBundle({ payload: bundleID }) {
  try {
    const response = yield call(deleteBundle, bundleID);
    yield put(removeBundleSuccess(response));
  } catch (error) {
    yield put(removeBundleFailure(error));
  }
}

function* bundleProductRemove({ payload: { variantID, bundleID } }) {
  try {
    const response = yield call(deleteBundleProduct,  variantID, bundleID );
    yield put(bundleProductRemoveSuccess(response));
  } catch (error) {
    yield put(bundleProductRemoveFailure(error));
  }
}

function* bundleSaga() {
  yield takeEvery(BUNDLE_LIST, retrieveBundles);
  yield takeEvery(BUNDLE_DETAILS, retrieveBundle);
  yield takeEvery(BUNDLE_ADD, addBundle);
  yield takeEvery(BUNDLE_UPDATE, modifyBundle);
  yield takeEvery(BUNDLE_REMOVE, removeBundle);
  yield takeEvery(BUNDLE_PRODUCT_REMOVE, bundleProductRemove);
}

export default bundleSaga;
