import {
  VERIFY_RESET_TOKEN, VERIFY_RESET_TOKEN_SUCCESS, VERIFY_RESET_TOKEN_FAILED,
} from "./actionTypes";

export const verifyResetToken = (key) => {
  return {
    type: VERIFY_RESET_TOKEN,
    payload: { key },
  };
};

export const verifyResetTokenSuccess = (response) => {
  return {
    type: VERIFY_RESET_TOKEN_SUCCESS,
    payload: response,
  };
};

export const verifyResetTokenFailed = (error) => {
  return {
    type: VERIFY_RESET_TOKEN_FAILED,
    payload: error,
  };
};
