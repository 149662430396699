import { PlusCircle } from "feather-icons-react/build/IconComponents";
import React, { useCallback, useEffect, useId, useState } from "react";
import {
  Button,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";

// common
import Loader from "../../../../Components/Common/Loader";
import FormSelectOptions from "../../../../Components/Common/FormSelectOptions";
import PageHeader from "../../../../Components/Common/PageHeader";

// formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  getQuestionsDetails,
  updateQuestions,
} from "../../../../store/settings/action";
import { useLocation } from "react-router";
import { getIDFromPath } from "../../../../helpers/location-helper";

const QuestionInput = ({
  labelName,
  fieldName,
  fieldValue,
  onChangeHandler,
  onBlurHandler,
  placeholder = "",
  touchedField,
  errorField,
  percentValue,
  hasCategories = false,
  categories = [],
  isActivePercentValue = false,
  hasPlusButton = true,
  plusButtonHandler = () => {},
}) => {
  const id = useId();
  return (
    <Row className="mt-20 input-container" id={id}>
      <Col lg={8} xs={8} className="p-0">
        <div className="question-input-section">
          <div className="left-content">
            <Label className="form-label">{labelName}</Label>
            {!hasCategories ? (
              <Input
                name={fieldName}
                type="text"
                className="form-control"
                defaultValue={fieldValue ?? ""}
                placeholder={placeholder}
                onChange={onChangeHandler}
                onBlur={onBlurHandler}
                invalid={touchedField && errorField ? true : false}
              />
            ) : (
              <select
                name={fieldName}
                value={fieldValue}
                onChange={onChangeHandler}
                className={
                  "form-select" +
                  (fieldValue ? "" : " empty") +
                  (touchedField && errorField ? " is-invalid" : "")
                }
              >
                <FormSelectOptions
                  categories={categories}
                  defaultOption={fieldValue ? null : {}}
                />
              </select>
            )}
          </div>
          {hasPlusButton ? (
            <Button className="icon-btn" onClick={plusButtonHandler}>
              <PlusCircle />
            </Button>
          ) : null}
        </div>
        {touchedField && errorField ? (
          <FormFeedback type="invalid">{errorField}</FormFeedback>
        ) : null}
      </Col>
      <Col className="question-percent">
        <div className={isActivePercentValue ? "active-value" : ""}>
          {percentValue}%
        </div>
      </Col>
    </Row>
  );
};

const Questions = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [questionViwes, setQuestionViwes] = useState({});
  const appId = getIDFromPath(location.pathname);

  const { questionGroups, isFetching } = useSelector((state) => ({
    questionGroups: state.Setting.questions.data,
    isFetching: state.Setting.questions.loading,
  }));

  const [question1Variants, setQuestion1Variants] = useState([]);
  const [question2Variants, setQuestion2Variants] = useState([]);
  const [question3Variants, setQuestion3Variants] = useState([]);
  const [question4Variants, setQuestion4Variants] = useState([]);
  const [question5Variants, setQuestion5Variants] = useState([]);
  const [question6Variants, setQuestion6Variants] = useState([]);

  useEffect(() => {
    if (!appId) return;
    dispatch(getQuestionsDetails(appId));
  }, [dispatch, appId]);

  const setQuestionVariants = useCallback((questions) => {
    const defaultValue = [{ views: 0 }];
    const questionVariants = questions?.length ? questions : defaultValue;

    return questionVariants;
  }, []);

  useEffect(() => {
    if (!questionGroups?.length) return;
    const questionViews = {};
    for (const group of questionGroups) {
      // calculate sum of questions viwes
      const mappedViews = group.variants.map((question) => question.views);
      const totalQuestionsViews = mappedViews.reduce(
        (total, curVal) => total + curVal
      );
      // add highest viwes field to show green color in percent field
      questionViews[group.groupId] = {
        totalQuestionsViews,
        highestViews: Math.max(...mappedViews),
      };
    }

    setQuestionViwes(questionViews);
    setQuestion1Variants(setQuestionVariants(questionGroups[0].variants));
    setQuestion2Variants(setQuestionVariants(questionGroups[1].variants));
    setQuestion3Variants(setQuestionVariants(questionGroups[2].variants));
    setQuestion4Variants(setQuestionVariants(questionGroups[3].variants));
    setQuestion5Variants(setQuestionVariants(questionGroups[4].variants));
    setQuestion6Variants(setQuestionVariants(questionGroups[5].variants));
  }, [questionGroups]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      // variant_A_question: "",
    },
    validationSchema: Yup.object({
      //   variant_A_question: Yup.string().required("Please Enter Question"),
    }),
    onSubmit: () => {},
  });

  const group1Handler = useCallback(() => {
    const lastVariant =
      question1Variants[question1Variants?.length - 1]?.variant || "A";
    const nextVariant = String.fromCharCode(lastVariant.charCodeAt(0) + 1);

    setQuestion1Variants((prevQuestionVariants) => [
      ...prevQuestionVariants,
      { variant: nextVariant, views: 0 },
    ]);
  }, [question1Variants]);

  const group2Handler = useCallback(() => {
    const lastVariant =
      question2Variants[question2Variants?.length - 1]?.variant || "A";
    const nextVariant = String.fromCharCode(lastVariant.charCodeAt(0) + 1);

    setQuestion2Variants((prevQuestionVariants) => [
      ...prevQuestionVariants,
      { variant: nextVariant, views: 0 },
    ]);
  }, [question2Variants]);

  const group3Handler = useCallback(() => {
    const lastVariant =
      question3Variants[question3Variants?.length - 1]?.variant || "A";
    const nextVariant = String.fromCharCode(lastVariant.charCodeAt(0) + 1);

    setQuestion3Variants((prevQuestionVariants) => [
      ...prevQuestionVariants,
      { variant: nextVariant, views: 0 },
    ]);
  }, [question3Variants]);

  const group4Handler = useCallback(() => {
    const lastVariant =
      question4Variants[question4Variants?.length - 1]?.variant || "A";
    const nextVariant = String.fromCharCode(lastVariant.charCodeAt(0) + 1);

    setQuestion4Variants((prevQuestionVariants) => [
      ...prevQuestionVariants,
      { variant: nextVariant, views: 0 },
    ]);
  }, [question4Variants]);

  const group5Handler = useCallback(() => {
    const lastVariant =
      question5Variants[question5Variants?.length - 1]?.variant || "A";
    const nextVariant = String.fromCharCode(lastVariant.charCodeAt(0) + 1);

    setQuestion5Variants((prevQuestionVariants) => [
      ...prevQuestionVariants,
      { variant: nextVariant, views: 0 },
    ]);
  }, [question5Variants]);

  const group6Handler = useCallback(() => {
    const lastVariant =
      question6Variants[question6Variants?.length - 1]?.variant || "A";
    const nextVariant = String.fromCharCode(lastVariant.charCodeAt(0) + 1);

    setQuestion6Variants((prevQuestionVariants) => [
      ...prevQuestionVariants,
      { variant: nextVariant, views: 0 },
    ]);
  }, [question6Variants]);

  const calculateQuestionPercent = useCallback((value, totalValue) => {
    if (totalValue === 0) return 0;
    return ((value / totalValue) * 100).toFixed();
  }, []);

  const handleFieldChange = (variant, value, groupId) => {
    const updated1Variants = question1Variants.map((question) => {
      if (question.variant === variant && groupId === 1) {
        return { ...question, question: value };
      }
      return question;
    });
    setQuestion1Variants(updated1Variants);

    const updated2Variants = question2Variants.map((question) => {
      if (question.variant === variant && groupId === 2) {
        return { ...question, question: value };
      }
      return question;
    });

    setQuestion2Variants(updated2Variants);

    const updated3Variants = question3Variants.map((question) => {
      if (question.variant === variant && groupId === 3) {
        return { ...question, question: value };
      }
      return question;
    });

    setQuestion3Variants(updated3Variants);

    const updated4Variants = question4Variants.map((question) => {
      if (question.variant === variant && groupId === 4) {
        return { ...question, question: value };
      }
      return question;
    });

    setQuestion4Variants(updated4Variants);

    const updated5Variants = question5Variants.map((question) => {
      if (question.variant === variant && groupId === 5) {
        return { ...question, question: value };
      }
      return question;
    });

    setQuestion5Variants(updated5Variants);

    const updated6Variants = question6Variants.map((question) => {
      if (question.variant === variant && groupId === 6) {
        return { ...question, question: value };
      }
      return question;
    });

    setQuestion6Variants(updated6Variants);
  };

  const onSaveButtonHandler = () => {
    const updatedQuestionGroups = questionGroups.map((group) => {
      if (group.groupId === 1) {
        return { ...group, variants: [...question1Variants] };
      }
      if (group.groupId === 2) {
        return { ...group, variants: [...question2Variants] };
      }
      if (group.groupId === 3) {
        return { ...group, variants: [...question3Variants] };
      }
      if (group.groupId === 4) {
        return { ...group, variants: [...question4Variants] };
      }
      if (group.groupId === 5) {
        return { ...group, variants: [...question5Variants] };
      }
      if (group.groupId === 6) {
        return { ...group, variants: [...question6Variants] };
      }
      return group;
    });

    dispatch(updateQuestions( appId, { questions: updatedQuestionGroups } ));
  };
  
  document.title = "Settings | mime";

  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container questions-container">
            <div className="header">
              <PageHeader title="Question Management" />
              <Button
                type="submit"
                className="btn-lg"
                disabled={isFetching}
                onClick={onSaveButtonHandler}
              >
                Save
              </Button>
            </div>

            <Col className="mime-form">
              {isFetching ? <Loader /> : null}
              {!isFetching ? (
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  action="#"
                >
                  <div
                    className={
                      question1Variants?.length > 1 ? "questions-list" : ""
                    }
                  >
                    {question1Variants?.length
                      ? question1Variants?.map((question, index) => (
                          <React.Fragment key={index}>
                            <QuestionInput
                              labelName={`Choose your complexion - Variant ${question.variant}`}
                              fieldName={`variant_${question.variant}_question`}
                              fieldValue={question.question}
                              placeholder={`Choose your complexion - Variant ${question.variant}`}
                              touchedField={
                                validation.touched[
                                  `variant_${question.variant}_question`
                                ]
                              }
                              errorField={
                                validation.errors[
                                  `variant_${question.variant}_question`
                                ]
                              }
                              percentValue={calculateQuestionPercent(
                                question.views,
                                questionViwes[1].totalQuestionsViews
                              )}
                              isActivePercentValue={
                                question.views === questionViwes[1].highestViews
                              }
                              hasPlusButton={
                                question1Variants?.length === index + 1
                              }
                              onChangeHandler={(e) => {
                                handleFieldChange(
                                  question.variant,
                                  e.target.value,
                                  1
                                );
                                validation.handleChange(e); // Call the handleChange from the validation object
                              }}
                              onBlurHandler={validation.handleBlur}
                              plusButtonHandler={group1Handler}
                              validation={validation}
                            />
                          </React.Fragment>
                        ))
                      : null}
                  </div>

                  <div className="mt-4 pr-20">
                    <div
                      className={
                        question2Variants?.length > 1 ? "questions-list" : ""
                      }
                    >
                      {question2Variants.map((question, index) => (
                        <React.Fragment key={index}>
                          <QuestionInput
                            labelName={`Choose your complexion - (2nd ask) ${question.variant}`}
                            fieldName={`variant_${question.variant}_question_2nd`}
                            fieldValue={question.question}
                            placeholder={`Choose your complexion - Variant ${question.variant}`}
                            touchedField={
                              validation.touched[
                                `variant_${question.variant}_question_2nd`
                              ]
                            }
                            errorField={
                              validation.errors[
                                `variant_${question.variant}_question_2nd`
                              ]
                            }
                            percentValue={calculateQuestionPercent(
                              question.views,
                              questionViwes[2].totalQuestionsViews
                            )}
                            isActivePercentValue={
                              question.views === questionViwes[2].highestViews
                            }
                            hasPlusButton={
                              question2Variants?.length === index + 1
                            }
                            onChangeHandler={(e) => {
                              handleFieldChange(
                                question.variant,
                                e.target.value,
                                2
                              );
                              validation.handleChange(e); // Call the handleChange from the validation object
                            }}
                            onBlurHandler={validation.handleBlur}
                            plusButtonHandler={group2Handler}
                            validation={validation}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    <div
                      className={
                        question3Variants?.length > 1 ? "questions-list" : ""
                      }
                    >
                      {question3Variants.map((question, index) => (
                        <React.Fragment key={index}>
                          <QuestionInput
                            labelName={`Choose your undertone - Variant ${question.variant}`}
                            fieldName={`variant_${question.variant}_question_3`}
                            fieldValue={question.question}
                            placeholder={`Choose your undertone - Variant ${question.variant}`}
                            touchedField={
                              validation.touched[
                                `variant_${question.variant}_question_3`
                              ]
                            }
                            errorField={
                              validation.errors[
                                `variant_${question.variant}_question_3`
                              ]
                            }
                            percentValue={calculateQuestionPercent(
                              question.views,
                              questionViwes[3].totalQuestionsViews
                            )}
                            isActivePercentValue={
                              question.views === questionViwes[3].highestViews
                            }
                            hasPlusButton={
                              question3Variants?.length === index + 1
                            }
                            onChangeHandler={(e) => {
                              handleFieldChange(
                                question.variant,
                                e.target.value,
                                3
                              );
                              validation.handleChange(e); // Call the handleChange from the validation object
                            }}
                            onBlurHandler={validation.handleBlur}
                            plusButtonHandler={group3Handler}
                            validation={validation}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    <div
                      className={
                        question4Variants?.length > 1 ? "questions-list" : ""
                      }
                    >
                      {question4Variants.map((question, index) => (
                        <React.Fragment key={index}>
                          <QuestionInput
                            labelName={`Choose your coverage - Variant ${question.variant}`}
                            fieldName={`variant_${question.variant}_question_4`}
                            fieldValue={question.question}
                            placeholder={`Choose your coverage - Variant ${question.variant}`}
                            touchedField={
                              validation.touched[
                                `variant_${question.variant}_question_4`
                              ]
                            }
                            errorField={
                              validation.errors[
                                `variant_${question.variant}_question_4`
                              ]
                            }
                            percentValue={calculateQuestionPercent(
                              question.views,
                              questionViwes[4].totalQuestionsViews
                            )}
                            isActivePercentValue={
                              question.views === questionViwes[4].highestViews
                            }
                            hasPlusButton={
                              question4Variants?.length === index + 1
                            }
                            onChangeHandler={(e) => {
                              handleFieldChange(
                                question.variant,
                                e.target.value,
                                4
                              );
                              validation.handleChange(e); // Call the handleChange from the validation object
                            }}
                            onBlurHandler={validation.handleBlur}
                            plusButtonHandler={group4Handler}
                            validation={validation}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                    <div
                      className={
                        question5Variants?.length > 1 ? "questions-list" : ""
                      }
                    >
                      {question5Variants.map((question, index) => (
                        <React.Fragment key={index}>
                          <QuestionInput
                            labelName={`Choose your finish - Variant ${question.variant}`}
                            fieldName={`variant_${question.variant}_question_5`}
                            fieldValue={question.question}
                            placeholder={`Choose your finish - Variant ${question.variant}`}
                            touchedField={
                              validation.touched[
                                `variant_${question.variant}_question_5`
                              ]
                            }
                            errorField={
                              validation.errors[
                                `variant_${question.variant}_question_5`
                              ]
                            }
                            percentValue={calculateQuestionPercent(
                              question.views,
                              questionViwes[5].totalQuestionsViews
                            )}
                            isActivePercentValue={
                              question.views === questionViwes[5].highestViews
                            }
                            hasPlusButton={
                              question5Variants?.length === index + 1
                            }
                            onChangeHandler={(e) => {
                              handleFieldChange(
                                question.variant,
                                e.target.value,
                                5
                              );
                              validation.handleChange(e); // Call the handleChange from the validation object
                            }}
                            onBlurHandler={validation.handleBlur}
                            plusButtonHandler={group5Handler}
                            validation={validation}
                          />
                        </React.Fragment>
                      ))}
                    </div>

                    <div
                      className={
                        question5Variants?.length > 1 ? "questions-list" : ""
                      }
                    >
                      {question6Variants.map((question, index) => (
                        <React.Fragment key={index}>
                          <QuestionInput
                            labelName={`User upload screen - top copy - Variant ${question.variant}`}
                            fieldName={`variant_${question.variant}_question_6`}
                            fieldValue={question.question}
                            placeholder={`upload screen- top copy - Variant ${question.variant}`}
                            touchedField={
                              validation.touched[
                                `variant_${question.variant}_question_6`
                              ]
                            }
                            errorField={
                              validation.errors[
                                `variant_${question.variant}_question_6`
                              ]
                            }
                            percentValue={calculateQuestionPercent(
                              question.views,
                              questionViwes[6].totalQuestionsViews
                            )}
                            isActivePercentValue={
                              question.views === questionViwes[6].highestViews
                            }
                            hasPlusButton={
                              question6Variants?.length === index + 1
                            }
                            onChangeHandler={(e) => {
                              handleFieldChange(
                                question.variant,
                                e.target.value,
                                6
                              );
                              validation.handleChange(e); // Call the handleChange from the validation object
                            }}
                            onBlurHandler={validation.handleBlur}
                            plusButtonHandler={group6Handler}
                            validation={validation}
                          />
                        </React.Fragment>
                      ))}
                    </div>
                  </div>
                </Form>
              ) : null}
            </Col>
          </Col>
          <Col
            xs={5}
            className="right-edit scroll-container meta-right-section"
          ></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Questions;