import { call, put, takeEvery } from "redux-saga/effects";
import {
  getReturns, postReturn, deleteReturn,
} from "../../../helpers/backend_helper";

// Login Redux States
import { RETURN_ADD, RETURN_LIST, RETURN_REMOVE } from "./actionType";

// Include Both Helper File with needed methods
import {
  getReturnListFailure, getReturnListSuccess,
  addReturnSuccess, addReturnFailure,
  removeReturnSuccess, removeReturnFailure,
} from "./actions";

function* retrieveReturns() {
  try {
    const connectionsResponse = yield call(getReturns, {});
    yield put(getReturnListSuccess(connectionsResponse));
  } catch (error) {
    yield put(getReturnListFailure(error));
  }
}

function* addReturn({ payload: data }) {
  try {
    const response = yield call(postReturn, data);
    yield put(addReturnSuccess(response));
  } catch (error) {
    yield put(addReturnFailure(error));
  }
}

function* removeReturn({ payload: productID }) {
  try {
    const response = yield call(deleteReturn, productID);
    yield put(removeReturnSuccess(response));
  } catch (error) {
    yield put(removeReturnFailure(error));
  }
}

function* returnSaga() {
  yield takeEvery(RETURN_LIST, retrieveReturns);
  yield takeEvery(RETURN_ADD, addReturn);
  yield takeEvery(RETURN_REMOVE, removeReturn);
}

export default returnSaga;
