export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAILURE = "PRODUCT_LIST_FAILURE";

export const PRODUCT_DETAILS = "PRODUCT_DETAILS";
export const PRODUCT_DETAILS_SUCCESS = "PRODUCT_DETAILS_SUCCESS";
export const PRODUCT_DETAILS_FAILURE = "PRODUCT_DETAILS_FAILURE";
export const CLEAR_PRODUCT_DETAILS = "CLEAR_PRODUCT_DETAILS";

export const PRODUCT_ADD = "PRODUCT_ADD";
export const PRODUCT_ADD_SUCCESS = "PRODUCT_ADD_SUCCESS";
export const PRODUCT_ADD_FAILURE = "PRODUCT_ADD_FAILURE";

export const BRAND_LIST = "BRAND_LIST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAILURE = "BRAND_LIST_FAILURE";

export const PRODUCT_UPDATE = "PRODUCT_UPDATE";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAILURE = "PRODUCT_UPDATE_FAILURE";

export const PRODUCT_REMOVE = "PRODUCT_REMOVE";
export const PRODUCT_REMOVE_SUCCESS = "PRODUCT_REMOVE_SUCCESS";
export const PRODUCT_REMOVE_FAILURE = "PRODUCT_REMOVE_FAILURE";

export const VARIANT_LIST = "VARIANT_LIST";
export const VARIANT_LIST_SUCCESS = "VARIANT_LIST_SUCCESS";
export const VARIANT_LIST_FAILURE = "VARIANT_LIST_FAILURE";

export const VARIANT_DETAILS = "VARIANT_DETAILS";
export const VARIANT_DETAILS_SUCCESS = "VARIANT_DETAILS_SUCCESS";
export const VARIANT_DETAILS_FAILURE = "VARIANT_DETAILS_FAILURE";
export const CLEAR_VARIANT_DETAILS = "CLEAR_VARIANT_DETAILS";

export const VARIANT_ADD = "VARIANT_ADD";
export const VARIANT_ADD_SUCCESS = "VARIANT_ADD_SUCCESS";
export const VARIANT_ADD_FAILURE = "VARIANT_ADD_FAILURE";

export const VARIANT_UPDATE = "VARIANT_UPDATE";
export const VARIANT_UPDATE_SUCCESS = "VARIANT_UPDATE_SUCCESS";
export const VARIANT_UPDATE_FAILURE = "VARIANT_UPDATE_FAILURE";

export const VARIANT_REMOVE = "VARIANT_REMOVE";
export const VARIANT_REMOVE_SUCCESS = "VARIANT_REMOVE_SUCCESS";
export const VARIANT_REMOVE_FAILURE = "VARIANT_REMOVE_FAILURE";

export const RESET_PRODUCT_FLAG = "RESET_PRODUCT_FLAG";
export const RESET_VARIANT_FLAG = "RESET_VARIANT_FLAG";
