import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import { CAMPAIGN_LIST, CAMPAIGN_ADD, CAMPAIGN_UPDATE, CAMPAIGN_REMOVE } from "./actionType";
import {
  getCampaignsSuccess, getCampaignsFailure,
  createCampaignSuccess, createCampaignFailure,
  updateCampaignSuccess, updateCampaignFailure,
  removeCampaignSuccess, removeCampaignFailure,
} from "./action";

// Include Both Helper File with needed methods
import {
  getCampaigns, postCampaign, updateCampaign, deleteCampaign,
} from "../../helpers/backend_helper";
import { camapaignUpdated } from "../actions";

function* retrieveCampaigns() {
  try {
    const response = yield call(getCampaigns, {});
    yield put(getCampaignsSuccess(response));
    yield put(camapaignUpdated(response));
  } catch (error) {
    yield put(getCampaignsFailure(error));
  }
}

function* saveCampaign({ payload: data }) {
  try {
    const response = yield call(postCampaign, data);

    yield put(createCampaignSuccess(response));
  } catch (error) {
    yield put(createCampaignFailure(error));
  }
}

function* modifyCampaign({ payload: {campaignID, data} }) {
  try {
    const response = yield call(updateCampaign, campaignID, data);
    yield put(updateCampaignSuccess(response));
  } catch (error) {
    yield put(updateCampaignFailure(error));
  }
}

function* removeCampaign({ payload: campaignID }) {
  try {
    const response = yield call(deleteCampaign, campaignID);
    yield put(removeCampaignSuccess(response));
  } catch (error) {
    yield put(removeCampaignFailure(error));
  }
}

function* campaignSaga() {
  yield takeEvery(CAMPAIGN_LIST, retrieveCampaigns);
  yield takeEvery(CAMPAIGN_ADD, saveCampaign);
  yield takeEvery(CAMPAIGN_UPDATE, modifyCampaign);
  yield takeEvery(CAMPAIGN_REMOVE, removeCampaign);
}

export default campaignSaga;
