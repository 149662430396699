import { call, put, takeEvery } from "redux-saga/effects";

// Login Redux States
import {
  PRODUCT_LIST, PRODUCT_DETAILS, PRODUCT_ADD, PRODUCT_UPDATE, PRODUCT_REMOVE,
  VARIANT_LIST, VARIANT_DETAILS, VARIANT_ADD, VARIANT_UPDATE, VARIANT_REMOVE,
  BRAND_LIST,
} from "./actionTypes";

import {
  getProductsSuccess, getProductsFailure, productsUpdated,
  getProductDetailsSuccess, getProductDetailsFailure,
  addProductSuccess, addProductFailure,
  updateProductSuccess, updateProductFailure,
  removeProductSuccess, removeProductFailure,

  getVariantsSuccess, getVariantsFailure,
  getVariantDetailsSuccess, getVariantDetailsFailure,
  addVariantSuccess, addVariantFailure,
  updateVariantSuccess, updateVariantFailure,
  removeVariantSuccess, removeVariantFailure,

  getBrandsSuccess, getBrandsFailure,
} from "../../actions";

// Include Both Helper File with needed methods
import {
  getProducts, getProduct, postProduct, updateProduct, deleteProduct,
  getVariants, getVariant, postVariant, updateVariant, deleteVariant,
  getMyBrands,
} from "../../../helpers/backend_helper";

function* retrieveProducts() {
  try {
    const response = yield call(getProducts, {});
    yield put(getProductsSuccess(response));
    yield put(productsUpdated(response));
  } catch (error) {
    yield put(getProductsFailure(error));
  }
}

function* retrieveProduct({ payload: { groupID } }) {
  try {
    const response = yield call(getProduct, groupID);
    yield put(getProductDetailsSuccess(response.product));
  } catch (error) {
    yield put(getProductDetailsFailure(error));
  }
}

function* addProduct({ payload: data }) {
  try {
    const response = yield call(postProduct, data);
    yield put(addProductSuccess(response));
  } catch (error) {
    yield put(addProductFailure(error));
  }
}

function* modifyProduct({ payload: {productID, data} }) {
  try {
    const response = yield call(updateProduct, productID, data);
    yield put(updateProductSuccess(response));
  } catch (error) {
    yield put(updateProductFailure(error));
  }
}

function* removeProduct({ payload: productID }) {
  try {
    const response = yield call(deleteProduct, productID);
    yield put(removeProductSuccess(response));
  } catch (error) {
    yield put(removeProductFailure(error));
  }
}

function* retrieveVariants({ payload: { productID } }) {
  try {
    const response = yield call(getVariants, productID);
    yield put(getVariantsSuccess(response));
  } catch (error) {
    yield put(getVariantsFailure(error));
  }
}

function* retrieveVariant({ payload: { productID, variantID } }) {
  try {
    const response = yield call(getVariant, { productID, variantID });
    yield put(getVariantDetailsSuccess(response.variant));
  } catch (error) {
    yield put(getVariantDetailsFailure(error));
  }
}

function* addVariant({ payload: {productID, data} }) {
  try {
    const response = yield call(postVariant, productID, data);
    yield put(addVariantSuccess(response));
  } catch (error) {
    yield put(addVariantFailure(error));
  }
}

function* modifyVariant({ payload: {productID, variantID, data} }) {
  try {
    const response = yield call(updateVariant, productID, variantID, data);
    yield put(updateVariantSuccess(response));
  } catch (error) {
    yield put(updateVariantFailure(error));
  }
}

function* removeVariant({ payload: {productID, variantID} }) {
  try {
    const response = yield call(deleteVariant, productID, variantID);
    yield put(removeVariantSuccess(response));
  } catch (error) {
    yield put(removeVariantFailure(error));
  }
}

function* retrieveBrands() {
  try {
    const response = yield call(getMyBrands, {});
    yield put(getBrandsSuccess(response));
  } catch (error) {
    yield put(getBrandsFailure(error));
  }
}

function* productSaga() {
  yield takeEvery(PRODUCT_LIST, retrieveProducts);
  yield takeEvery(PRODUCT_DETAILS, retrieveProduct);
  yield takeEvery(PRODUCT_ADD, addProduct);
  yield takeEvery(PRODUCT_UPDATE, modifyProduct);
  yield takeEvery(PRODUCT_REMOVE, removeProduct);

  yield takeEvery(VARIANT_LIST, retrieveVariants);
  yield takeEvery(VARIANT_DETAILS, retrieveVariant);
  yield takeEvery(VARIANT_ADD, addVariant);
  yield takeEvery(VARIANT_UPDATE, modifyVariant);
  yield takeEvery(VARIANT_REMOVE, removeVariant);

  yield takeEvery(BRAND_LIST, retrieveBrands);
}

export default productSaga;
