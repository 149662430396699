import React from "react";
import { Col, Container, Row } from "reactstrap";

import BrandingForm from "./BrandingForm";

const Branding = () => {
  document.title = "Settings | mime"
  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container branding-container">
            <BrandingForm />
          </Col>
          <Col
            xs={5}
            className="right-edit scroll-container meta-right-section"
          ></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Branding;
