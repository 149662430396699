import React, { useEffect, useCallback, useState } from "react";
import {
  Button, Col, Container, Input, Label, Row, Form, FormFeedback, Spinner,
} from "reactstrap";
import { toast } from "react-toastify";

// common helper
import Loader from "../../../../Components/Common/Loader";
import PageHeader from "../../../../Components/Common/PageHeader";

// formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// redux
import { useDispatch, useSelector } from "react-redux";
import {
  getAppDetails, updateAppDetails, resetAppFlag,
} from "../../../../store/settings/action";

import { useLocation } from "react-router";
import { getIDFromPath } from "../../../../helpers/location-helper";

const Legal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const appId = getIDFromPath(location.pathname);

  const { apps, appDetails } = useSelector((state) => ({
    apps: state.Setting.appList,
    appDetails: state.Setting.appDetails,
  }));

  useEffect(() => {
    if (appDetails.updated) {
      dispatch(resetAppFlag());
      toast.success("Successfully updated the app settings.");
    }
  }, [appDetails.updated]);

  const handleAppSettings = useCallback(async (appId, values) => {
    dispatch(updateAppDetails(appId, values));
  }, []);

  useEffect(() => {
    if (!appId || appDetails.id === appId) return;
    dispatch(getAppDetails(appId));
  }, [dispatch, apps]);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      legalPrivacyPolicyUrl: appDetails?.legalPrivacyPolicyUrl ?? "",
      legalTermsUrl: appDetails?.legalTermsUrl ?? "",
      legalCookieUrl: appDetails?.legalCookieUrl ?? "",
      legalContactEmail: appDetails?.legalContactEmail ?? "",
    },
    validationSchema: Yup.object({
      legalPrivacyPolicyUrl: Yup.string(),
      legalTermsUrl: Yup.string(),
      legalCookieUrl: Yup.string(),
      legalContactEmail: Yup.string(),
    }),
    onSubmit: (values) => handleAppSettings(appId, values),
  });

  document.title = "Settings | mime"
  
  return (
    <div className="products-page page-content">
      <Container fluid>
        <Row className="ms-0">
          <Col className="product-list scroll-container legal-container">
            {appDetails.loading ? (
              <Loader />
            ) : (
              <Col className="mime-form">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="header p-0">
                    <PageHeader title="Legal" />
                    <Button
                      type="submit"
                      className="btn-lg"
                      disabled={appDetails.loading}
                    >
                      {appDetails.loading ? (
                        <Spinner size="sm" className="me-2">
                          Loading...
                        </Spinner>
                      ) : null}
                      Save
                    </Button>
                  </div>
                  <Col xs={4} lg={6}>
                    <Row className="mt-20">
                      <Label className="form-label">Privacy Policy*</Label>
                      <Input
                        name="legalPrivacyPolicyUrl"
                        type="text"
                        className="form-control"
                        value={validation.values.legalPrivacyPolicyUrl || ""}
                        placeholder="Type policy URL here"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.legalPrivacyPolicyUrl &&
                          validation.errors.legalPrivacyPolicyUrl
                            ? true
                            : false
                        }
                      />
                      {validation.touched.legalPrivacyPolicyUrl &&
                      validation.errors.legalPrivacyPolicyUrl ? (
                        <FormFeedback type="invalid">
                          {validation.errors.legalPrivacyPolicyUrl}
                        </FormFeedback>
                      ) : null}
                    </Row>
                    <Row className="mt-20">
                      <Label className="form-label">
                        Terms and Conditions*
                      </Label>
                      <Input
                        name="legalTermsUrl"
                        type="text"
                        className="form-control"
                        value={validation.values.legalTermsUrl || ""}
                        placeholder="Type terms URL here"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.legalTermsUrl &&
                          validation.errors.legalTermsUrl
                            ? true
                            : false
                        }
                      />
                      {validation.touched.legalTermsUrl &&
                      validation.errors.legalTermsUrl ? (
                        <FormFeedback type="invalid">
                          {validation.errors.legalTermsUrl}
                        </FormFeedback>
                      ) : null}
                    </Row>
                    <Row className="mt-20">
                      <Label className="form-label">Cookie Policy*</Label>
                      <Input
                        name="legalCookieUrl"
                        type="text"
                        className="form-control"
                        value={validation.values.legalCookieUrl || ""}
                        placeholder="Type Policy URL here"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.legalCookieUrl &&
                          validation.errors.legalCookieUrl
                            ? true
                            : false
                        }
                      />
                      {validation.touched.legalCookieUrl &&
                      validation.errors.legalCookieUrl ? (
                        <FormFeedback type="invalid">
                          {validation.errors.legalCookieUrl}
                        </FormFeedback>
                      ) : null}
                    </Row>
                    <Row className="mt-20">
                      <Label className="form-label">Legal Contact Email*</Label>
                      <Input
                        name="legalContactEmail"
                        type="text"
                        className="form-control"
                        value={validation.values.legalContactEmail || ""}
                        placeholder="Type email here"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.legalContactEmail &&
                          validation.errors.legalContactEmail
                            ? true
                            : false
                        }
                      />
                      {validation.touched.legalContactEmail &&
                      validation.errors.legalContactEmail ? (
                        <FormFeedback type="invalid">
                          {validation.errors.legalContactEmail}
                        </FormFeedback>
                      ) : null}
                    </Row>
                  </Col>
                </Form>
              </Col>
            )}
          </Col>
          <Col
            xs={5}
            className="right-edit scroll-container meta-right-section"
          ></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Legal;
