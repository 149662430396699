import React, { useCallback, useLayoutEffect, useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Row } from "reactstrap";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

import Loader from "../../Components/Common/Loader";
import { getColorPreferences } from "../../store/dashboard/actions";

const TopColorPreferences = () => {
	const dispatch = useDispatch();

	const {
		dateRange, colorPreferences, isFetchingData
	} = useSelector((state) => ({
		dateRange: state.Dashboard.dateRange,
		colorPreferences: state.Dashboard.colorPreferences.data,
		isFetchingData: state.Dashboard.colorPreferences.loading,
	}));

	useEffect(() => {
		dispatch(getColorPreferences({startTime: dateRange[0], endTime: dateRange[1], grouping: "month"}));
	}, [dispatch, dateRange]);

	const COLORS = ["#06b39c", "#f06647", "#3f5189", "#f7b94b"];

	const renderCustomLegend = useCallback((props) => {
		const { payload } = props;

		const percentValue = (value = 0) => {
			return `${parseInt(Math.round(+(value * 100).toFixed()))}%`;
		};

		return (
			<ul>
				{payload.map((item, index) => (
					<div className="custom-legend-item" key={`item-${index}`}>
						<div
							className="legend-surface"
							style={{ backgroundColor: COLORS[index] }}
						></div>
						<li>
							{item.value} {percentValue(item?.payload?.percent)}
						</li>
					</div>
				))}
			</ul>
		);
	}, []);

	// Chart size - Responsive
	const chartRef = useRef(null);
	const [chartWidth, setChartWidth] = useState(0);

	useLayoutEffect(() => {
		const handleResize = () => {
			// 38px(padding applied on container) deduct from full width
			const chartOffsetWidth = chartRef.current.offsetWidth - 38;
			const renderChartWidth = chartOffsetWidth > 180 ? chartOffsetWidth : 180;
			setChartWidth(renderChartWidth);
		};
		handleResize();
		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className="common-card" ref={chartRef}>
			<Row>
				<Col className="card-title">Top Color Preferences</Col>
			</Row>

			{isFetchingData ? <Loader /> : null}
			{!isFetchingData && colorPreferences.length ? (
				<div className="chart">
					<PieChart width={chartWidth} height={300}>
						<Pie
							data={colorPreferences}
							cx={chartWidth / 2}
							cy={100}
							innerRadius={55}
							outerRadius={80}
							fill="#8884d8"
							paddingAngle={2}
							dataKey="value"
						>
							{colorPreferences.map((entry, index) => (
								<Cell
									key={`cell-${index}`}
									fill={COLORS[index % COLORS.length]}
								/>
							))}
						</Pie>
						<Tooltip />
						<Legend content={renderCustomLegend} />
					</PieChart>
				</div>
			) : !isFetchingData && !colorPreferences.length ? (
				<span>No data available for this time period</span>
			) : null}
		</div>
	);
};

export default TopColorPreferences;
