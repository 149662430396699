import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from "react";
import { Col } from "reactstrap";
import { Cell, Legend, Pie, PieChart, Tooltip } from "recharts";

// common helpers
import PageHeader from "../../../Components/Common/PageHeader";
import Loader from "../../../Components/Common/Loader";
import ErrorController from "../../../Components/Common/ErrorController";
import TimeInterval from "../../../Components/Hooks/UseIntervalHook";

// redux
import { useSelector } from "react-redux";

const TopCoverages = () => {
  const chartRef = useRef(null);

  const [chartWidth, setChartWidth] = useState(0);

  const { topCoverages, errorMessage } = useSelector((state) => ({
    topCoverages: state.Report.topCoverages,
    errorMessage: state.Report.topCoverages.errorMessage,
  }));
  const fetchingData = topCoverages.loading;

  const chartData = topCoverages.data.length
    ? topCoverages.data.slice(1)
    : [];

  useEffect(() => {
    const handleResize = () => {
      // 38px(padding applied on container) deduct from full width
      const chartOffsetWidth = chartRef?.current?.offsetWidth;
      const renderChartWidth = chartOffsetWidth > 500 ? chartOffsetWidth : 500;
      setChartWidth(renderChartWidth);
    };
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const COLORS = useMemo(
    () => ["#06b39c", "#f06647", "#3f5189", "#f7b94b", "#2a9cdb", "#8333FF"],
    []
  );

  const renderCustomLegend = useCallback(
    (props) => {
      const { payload } = props;
      return (
        <ul>
          {payload.map((item, index) => (
            <div className="custom-legend-item" key={`item-${index}`}>
              <div
                className="legend-surface"
                style={{ backgroundColor: COLORS[index] }}
              ></div>
              <li>{item.value}</li>
            </div>
          ))}
        </ul>
      );
    },
    [COLORS]
  );

  const CustomTooltip = useCallback(({ active, payload, valueTitle }) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p>{payload[0]?.payload?.name}</p>
          <p>
            {valueTitle}: {payload[0]?.payload?.value} &nbsp;
          </p>
        </div>
      );
    }
  }, []);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = useCallback(
    ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
      const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
      const x = cx + radius * Math.cos(-midAngle * RADIAN);
      const y = cy + radius * Math.sin(-midAngle * RADIAN);

      return (
        <text
          x={x}
          y={y}
          fill="white"
          textAnchor={x > cx ? "start" : "end"}
          dominantBaseline="central"
        >
          {`${(percent * 100).toFixed(0)}%`}
        </text>
      );
    },
    [RADIAN]
  );

  document.title = "Top Skins | mime";

  return (
    <Col className="product-list scroll-container main-container reporting-container">
      <div className="page-header">
        <PageHeader title="Top Coverages" />
        <span>
          <TimeInterval prefix="Last updated" />
        </span>
      </div>

      {fetchingData ? <Loader /> : null}
      {!fetchingData && !errorMessage && topCoverages.data.length ? (
        <div className="common-card" ref={chartRef}>
          <div className="chart d-flex justify-content-center ">
            <PieChart width={chartWidth} height={300}>
              <Pie
                data={chartData}
                cx={chartWidth / 2}
                cy={110}
                innerRadius={70}
                outerRadius={110}
                paddingAngle={0}
                dataKey="value"
                label={renderCustomizedLabel}
                labelLine={false}
                stroke=""
              >
                {chartData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index]} />
                ))}
              </Pie>
              <Tooltip content={<CustomTooltip valueTitle="Count" />} />
              <Legend content={renderCustomLegend} />
            </PieChart>
          </div>
        </div>
      ) : errorMessage ? (
        <ErrorController messsage={errorMessage} />
      ) : !topCoverages.data.length && !fetchingData ? (
        <span>No data found.</span>
      ) : null}
    </Col>
  );
};

export default TopCoverages;
