import React from "react";

export const currencyFormat = (value, decimals = 2) => {
  // Nine Zeroes for Billions
  const formatedValue =
    Math.abs(Number(value)) >= 1.0e9
      ? (Math.abs(Number(value)) / 1.0e9).toFixed(decimals) + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(value)) >= 1.0e6
      ? (Math.abs(Number(value)) / 1.0e6).toFixed(decimals) + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(value)) >= 1.0e3
      ? (Math.abs(Number(value)) / 1.0e3).toFixed(decimals) + "K"
      : Math.abs(Number(value));

  return formatedValue;
};

const CurrencyFormatBox = ({ number, prefix = "", decimals = 2 }) => {
  return (
    <>
      {number === null || number === undefined || number === 0
        ? "0"
        : `${prefix}${currencyFormat(+number, decimals)}`}
    </>
  );
};

export default CurrencyFormatBox;
