import { getFonts } from "../../helpers/backend_helper";

export class CommonApiService {
  static getFontOptions = async () => {
    try {
      const res = await getFonts();

      return res;
    } catch (error) {
      return {
        success: false,
      };
    }
  };
}
