import {
	APP_LIST, APP_LIST_FAILURE, APP_LIST_SUCCESS,
	APP_DETAILS, APP_DETAILS_FAILURE, APP_DETAILS_SUCCESS,
	APP_DETAILS_UPDATE, APP_DETAILS_UPDATE_FAILURE, APP_DETAILS_UPDATE_SUCCESS,

	APP_QUESTION_DETAILS, APP_QUESTION_DETAILS_FAILURE, APP_QUESTION_DETAILS_SUCCESS,
	UPDATE_QUESTIONS, UPDATE_QUESTIONS_SUCCESS, UPDATE_QUESTIONS_FAILURE,

	PROFILE_DETAILS, PROFILE_DETAILS_SUCCESS, PROFILE_DETAILS_FAILURE,
	UPDATE_PROFILE, UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_FAILURE,
	
	GET_TEAMMATES, GET_TEAMMATES_FAILURE, GET_TEAMMATES_SUCCESS,
	SEND_INVITE, SEND_INVITE_SUCCESS, SEND_INVITE_FAILURE,
	UPDATE_INVITE, UPDATE_INVITE_SUCCESS, UPDATE_INVITE_FAILURE,
	DELETE_TEAMMATE, DELETE_TEAMMATE_SUCCESS, DELETE_TEAMMATE_FAILURE,

	RESET_APP_FLAG,
} from "./actionType";

const initialState = {
	appList: [],
	appDetails: {},
	questions: { data: [] },
	userProfile: {},
	teamMates: [],
	myTeamStatus: {
		refreshTeamMates: false,
		loading: false,
		sended: false,
		updated: false,
		deleted: false,
	},

	loading: true,
	errorMsg: "",
	error: false,
	isFetching: false,
};

const setting = (state = initialState, action) => {
	switch (action.type) {
		case APP_LIST: {
			return {
				...state, loading: true, error: false, errorMsg: "",
				appList: [],
			};
		}
		case APP_LIST_SUCCESS: {
			const { apps } = action.payload ?? { apps: [] };
			const formattedList = apps.map((app) => ({
				value: app.id,
				label: app.name,
			}));
			return {
				...state,
				loading: false,
				appList: formattedList,
			};
		}
		case APP_LIST_FAILURE: {
			return {
				...state, loading: false, error: true, errorMsg: action.payload,
			};
		}

		case APP_DETAILS: {
			return {
				...state, error: false, errorMsg: "",
				appDetails: { loading: true },
			};
		}
		case APP_DETAILS_SUCCESS: {
			return {
				...state,
				appDetails: { ...action.payload.app, loading: false },
			};
		}
		case APP_DETAILS_FAILURE:
			return {
				...state, error: true, errorMsg: action.payload,
				appDetails: { ...state.appDetails, loading: false },
			};

		case APP_DETAILS_UPDATE:
			return {
				...state, error: false, errorMsg: "",
				appDetails: { ...state.appDetails, loading: true, updated: false },
			};
		case APP_DETAILS_UPDATE_SUCCESS:
			return {
				...state,
				appDetails: { ...action.payload.app, loading: false, updated: true },
			};
		case APP_DETAILS_UPDATE_FAILURE:
			return {
				...state, error: true, errorMsg: action.payload,
				appDetails: { ...state.appDetails, loading: false, updated: false },
			};

		case APP_QUESTION_DETAILS:
			return {
				...state, error: false, errorMsg: "",
				questions: { data: [], loading: true },
			};
		case APP_QUESTION_DETAILS_SUCCESS:
			return {
				...state,
				questions: { data: action.payload.questions, loading: false },
			};
		case APP_QUESTION_DETAILS_FAILURE:
			return {
				...state, error: true, errorMsg: action.payload,
				questions: { ...state.questions, loading: false },
			};

		case UPDATE_QUESTIONS:
			return {
				...state, error: false, errorMsg: "",
				questions: { ...state.questions, loading: true, updated: false },
			};
		case UPDATE_QUESTIONS_SUCCESS:
			return {
				...state,
				questions: { ...state.questions, loading: false, updated: true },
			};
		case UPDATE_QUESTIONS_FAILURE:
			return {
				...state, error: true, errorMsg: action.payload,
				questions: { ...state.questions, loading: false, updated: false },
			};

		case PROFILE_DETAILS:
			return {
				...state, error: false, errorMsg: "",
				userProfile: { ...state.userProfile, loading: true },
			};
		case PROFILE_DETAILS_SUCCESS:
			return {
				...state,
				userProfile: { ...action.payload.user, loading: false },
			};
		case PROFILE_DETAILS_FAILURE:
			return {
				...state, error: true, errorMsg: action.payload,
				userProfile: { ...state.userProfile, loading: false },
			};

		case UPDATE_PROFILE:
			return {
				...state, error: false, errorMsg: "",
				userProfile: { ...state.userProfile, loading: true, updated: false },
			};
		case UPDATE_PROFILE_SUCCESS:
			return {
				...state,
				userProfile: { ...state.userProfile, loading: false, updated: true },
			};
		case UPDATE_PROFILE_FAILURE:
			return {
				...state, error: true, errorMsg: action.payload,
				userProfile: { ...state.userProfile, loading: false, updated: false },
			};

		case GET_TEAMMATES:
			return {
				...state, loading: true, error: false, errorMsg: "",
			};
		case GET_TEAMMATES_SUCCESS:
			return {
				...state, teamMates: action.payload.users, loading: false,
			};
		case GET_TEAMMATES_FAILURE:
			return {
				...state, loading: false, error: true, errorMsg: action.payload,
			};

		case SEND_INVITE:
			return {
				...state, error: false, errorMsg: "",
				myTeamStatus: { ...state.myTeamStatus, loading: true, sended: false }
			};
		case SEND_INVITE_SUCCESS:
			return {
				...state, loading: false,
				teamMates: [ action.payload.user, ...state.teamMates ],
				myTeamStatus: { ...state.myTeamStatus, loading: false, sended: true, refreshTeamMates: true }
			};
		case SEND_INVITE_FAILURE:
			return {
				...state, error: true, errorMsg: action.payload,
				myTeamStatus: { ...state.myTeamStatus, loading: false, sended: false }
			};

		case UPDATE_INVITE:
			return {
				...state, error: false, errorMsg: "",
				myTeamStatus: { ...state.myTeamStatus, loading: true, updated: false }
			};
		case UPDATE_INVITE_SUCCESS:
			return {
				...state,
				myTeamStatus: { ...state.myTeamStatus, loading: false, updated: true }
			};
		case UPDATE_INVITE_FAILURE:
			return {
				...state, error: false, errorMsg: action.payload,
				myTeamStatus: { ...state.myTeamStatus, loading: true, updated: false }
			};

		case DELETE_TEAMMATE:
			return {
				...state, error: false, errorMsg: "",
				myTeamStatus: { ...state.myTeamStatus, loading: true, deleted: false }
			};
		case DELETE_TEAMMATE_SUCCESS:
			return {
				...state,
				teamMates: state.teamMates.filter(user => user.id !== action.payload),
				myTeamStatus: { ...state.myTeamStatus, loading: false, deleted: true },
			};
		case DELETE_TEAMMATE_FAILURE:
			return {
				...state, error: false, errorMsg: action.payload,
				myTeamStatus: { ...state.myTeamStatus, loading: false, deleted: false }
			};

		case RESET_APP_FLAG:
			return {
				...state, error: false, errorMsg: "",
				appDetails: { ...state.appDetails, loading: false, updated: false },
				userProfile: { ...state.userProfile, loading: false, updated: false },
				myTeamStatus: { refreshTeamMates: false, loading: false, sended: false, updated: false, deleted: false },
			};

		default:
			state = { ...state };
			break;
	}
	return state;
};

export default setting;
