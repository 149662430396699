export const BUNDLE_LIST = "BUNDLE_LIST";
export const BUNDLE_LIST_SUCCESS = "BUNDLE_LIST_SUCCESS";
export const BUNDLE_LIST_FAILURE = "BUNDLE_LIST_FAILURE";

export const BUNDLE_DETAILS = "BUNDLE_DETAILS";
export const BUNDLE_DETAILS_SUCCESS = "BUNDLE_DETAILS_SUCCESS";
export const BUNDLE_DETAILS_FAILURE = "BUNDLE_DETAILS_FAILURE";

export const BUNDLE_PRODUCT_ADD = "BUNDLE_PRODUCT_ADD";
export const BUNDLE_PRODUCT_REMOVE = "BUNDLE_PRODUCT_REMOVE";
export const EDIT_BUNDLE_PRODUCT_REMOVE = "EDIT_BUNDLE_PRODUCT_REMOVE";
export const BUNDLE_PRODUCT_REMOVE_SUCCESS = "BUNDLE_PRODUCT_REMOVE_SUCCESS";
export const BUNDLE_PRODUCT_REMOVE_FAILURE = "BUNDLE_PRODUCT_REMOVE_FAILURE";
export const BUNDLE_PRODUCT_CLEAR = "BUNDLE_PRODUCT_CLEAR";

export const BUNDLE_ADD = "BUNDLE_ADD";
export const BUNDLE_ADD_SUCCESS = "BUNDLE_ADD_SUCCESS";
export const BUNDLE_ADD_FAILURE = "BUNDLE_ADD_FAILURE";

export const BUNDLE_UPDATE = "BUNDLE_UPDATE";
export const BUNDLE_UPDATE_SUCCESS = "BUNDLE_UPDATE_SUCCESS";
export const BUNDLE_UPDATE_FAILURE = "BUNDLE_UPDATE_FAILURE";

export const BUNDLE_REMOVE = "BUNDLE_REMOVE";
export const BUNDLE_REMOVE_SUCCESS = "BUNDLE_REMOVE_SUCCESS";
export const BUNDLE_REMOVE_FAILURE = "BUNDLE_REMOVE_FAILURE";

export const RESET_BUNDLE_FLAG = "RESET_BUNDLE_FLAG";
