import React, { useEffect, useState } from "react";
import {
  Row, Col, Form, FormFeedback, Label, Input, Button, Spinner,
} from "reactstrap";

import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// helpers
import { getIDFromPath } from "../../helpers/location-helper";
import { MenuItemEnums, RouteParams } from "../../common/enums";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";
// redux
import { useSelector, useDispatch } from "react-redux";
import FormSelectOptions from "../../Components/Common/FormSelectOptions";
import UseDropdowns from "../../Components/Hooks/UseDropdowns";
import { clearError, sendMail } from "../../store/audience/actions";

const MissedConnectionForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const connectionPath = `/${MenuItemEnums.audience}/${RouteParams.opportunities}/${RouteParams.missedConnections}`;
  const selectedUserID = getIDFromPath(location.pathname);

  const [showProductWithBundle, setShowProductWithBundle] = useState(true);
  const [selectedUser, setSelectedUser] = useState({});

  const { mailInEdit, connections, connectionListWithId } = useSelector((state) => ({
    mailInEdit: state.Audience.mailInEdit,
    connections: state.Audience.connections,
    connectionListWithId: state.Audience.connectionListWithId,
  }));

  useEffect(() => {
    if (mailInEdit.sent) {
      dispatch(clearError());
      toast.success("Sent Message to customer");
      navigate(connectionPath);
    }
  }, [mailInEdit.sent]);

  useEffect(() => {
    if (selectedUserID === null || !connections.length) return;
    const selectedUser = connectionListWithId[selectedUserID];
    if (!selectedUser) return;
    setSelectedUser(selectedUser);
  }, [dispatch, selectedUserID, connections]);

  const sendMailtoUser = async (values) => {
    if (mailInEdit.loading) return;

    let data = { ...values, productId: values.variantId };
    delete data.variantId;
    dispatch(sendMail(data));
  };

  const showProductHandler = (event) => {
    if (event.target.value === "Yes") {
      setShowProductWithBundle(true);
    } else {
      setShowProductWithBundle(false);
    }
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      showProduct: showProductWithBundle,
      name: "",
      productId: "",
      userId: selectedUser?.userId || "",
      imageId: selectedUser?.imageId || "",
      variantId: "",
      personalMessage: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Please Enter name"),
      productId: Yup.number().required("Please Select Product"),
      userId: Yup.number().required("Please Enter User ID"),
      imageId: Yup.number().required("Please Select Image"),
      variantId: Yup.string().required("Please Select Variant"),
      personalMessage: Yup.string().required("Please Enter Pesonal Message"),
    }),
    onSubmit: (values) => {
      sendMailtoUser(values);
    },
  });

  const { productOptions, variantOptions } = UseDropdowns(
    +validation.values.productId
  );

  return (
    <React.Fragment>
      <Col xs={4} className="mime-form right-edit scroll-container">
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
          action="#"
        >
          <Row className="form-header">
            <Col className="title">Email Shopper in Real-time</Col>
          </Row>
          <Row className="mt-40">
            <Label htmlFor="show_product" className="form-label">
              Show product with bundle?
            </Label>
            <Col>
              <Input
                type="button"
                name="show_product"
                value={"Yes"}
                className={
                  "form-control btn" +
                  (showProductWithBundle === true ? " active" : "")
                }
                onClick={showProductHandler}
              />
            </Col>
            <Col className="pl-10">
              <Input
                type="button"
                name="hide_product"
                value={"No"}
                className={
                  "form-control btn" +
                  (showProductWithBundle === false ? " active" : "")
                }
                onClick={showProductHandler}
              />
            </Col>
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Artist Name*</Label>
            <Input
              name="name"
              type="text"
              className="form-control"
              value={validation.values.name || ""}
              placeholder="Type your name here"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.name && validation.errors.name
                  ? true
                  : false
              }
            />
            {validation.touched.name && validation.errors.name ? (
              <FormFeedback type="invalid">
                {validation.errors.name}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Product to suggest*</Label>
            <select
              name="productId"
              type="number"
              value={validation.values.productId}
              onChange={(e) => {
                const productId = +e.target.value;
                validation.handleChange(e);
                validation.setFieldValue("productId", productId, true);
              }}
              className={
                "form-select" +
                (validation.values.productId ? "" : " empty") +
                (validation.touched.productId && validation.errors.productId ? " is-invalid" : "")
              }
            >
              <FormSelectOptions
                categories={productOptions}
                defaultOption={validation.values.productId ? null : {}}
              />
            </select>
            {validation.touched.productId && validation.errors.productId ? (
              <FormFeedback type="invalid">
                {validation.errors.productId}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Shade to suggest*</Label>
            <select
              name="variantId"
              value={validation.values.variantId}
              onChange={(e) => {
                const variantId = +e.target.value;
                validation.handleChange(e);
                validation.setFieldValue("variantId", variantId, true);
              }}
              className={
                "form-select" +
                (validation.values.variantId ? "" : " empty") +
                (validation.touched.variantId && validation.errors.variantId ? " is-invalid" : "")
              }
            >
              <FormSelectOptions
                categories={variantOptions}
                defaultOption={validation.values.variantId ? null : {}}
              />
            </select>
            {validation.touched.variantId && validation.errors.variantId ? (
              <FormFeedback type="invalid">
                {validation.errors.variantId}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Label className="form-label">Personalized message*</Label>
            <Input
              name="personalMessage"
              type="textarea"
              className="form-control personal-text"
              value={validation.values.personalMessage || ""}
              placeholder="Type your message here"
              onChange={validation.handleChange}
              onBlur={validation.handleBlur}
              invalid={
                validation.touched.personalMessage &&
                validation.errors.personalMessage
                  ? true
                  : false
              }
              style={{ height: "200px" }}
            />
            {validation.touched.personalMessage &&
            validation.errors.personalMessage ? (
              <FormFeedback type="invalid">
                {validation.errors.personalMessage}
              </FormFeedback>
            ) : null}
          </Row>
          <Row className="mt-20">
            <Button type="submit" className="btn-lg" disabled={mailInEdit.loading}>
              {mailInEdit.loading ? (
                <Spinner size="sm" className="me-2">
                  Loading...
                </Spinner>
              ) : null}
              Send Now
            </Button>
          </Row>
        </Form>
      </Col>
    </React.Fragment>
  );
};

export default MissedConnectionForm;
